import React, {Component} from 'react';
import Slider from 'react-slick';

import { SliderService } from '../services';

class SliderHome extends Component {

    constructor(props){
        super(props);
        this.state = {
            sliders: [],
        };
    }

    async componentDidMount(){
        const sliders = await SliderService.getAllActivated();
         this.setState({
            sliders
        })
    }

    render() {
        const sliders = this.props.sliders || this.state.sliders
        return (
            <Slider className="slide-1 home-slider">
                {sliders.map((element, index) => (
                    <div key={index}>
                        <div
                            className="home text-center p-right"
                        >
                            <img src={element?.url_image} alt={element?.description} />
                        </div>
                    </div>
                    )
                )}
            </Slider>
        )
    }

}
export {
    SliderHome
}