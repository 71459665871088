import {axios} from '../axios';
import {jsonToParams} from "../index";
const END_POINT = 'contacts';


const openChat = async params => {
  try{
    const r = await axios.post(`${END_POINT}/store`, params);
    return r.data
  }catch(e){
    throw e
  }
}


export default {

  openChat

}
