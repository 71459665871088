import React from 'react'

const Banners = (props) => {
    return(
        <section className="banner-padding absolute-banner pb-0 ratio2_1">
            <div className="container absolute-bg">
                <div className="row partition2">
                    {
                        props.banners.map((element, i) => {
                            return(
                                <div
                                    className="col-md-6 col-sm-12"
                                    key={i}
                                >
                                    <div className="collection-banner p-right text-center">
                                        <div>
                                            <img
                                                src={element.uri}
                                                className="img-fluid  bg-img"
                                                style={{
                                                    padding: '0 2%'
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Banners