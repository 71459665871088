import React from "react";
import MoneyText from "../../widgets/MoneyText";

const icStyle = {
  width: '20px',
  height: '20px'
}

const StickersControl = ({stickers=[], sticker, onRemove, onSelectedSticker}) => {
  
  if (!stickers.length) return null
  
  const handleRemove = (evt, sticker) => {
    evt.preventDefault()
    if (!onRemove) return
    onRemove(sticker)
  }
  
  const handleSelectedSticker = sticker => {
    if (!onSelectedSticker) return
    onSelectedSticker(sticker)
  }
  
  return (
    <div className="v-box scroll_custom" style={{ overflow: 'auto' }}>
      
      <div style={{ minWidth: 500 }}>
      <span className="mt-4 mb-2 font-sand">
        Estampados
      </span>
      
      <div className="row">
        
        <div className="col" style={{
          ...icStyle,
          maxWidth: 50
        }}/>
        
        <span className="col text-right font-sand">
          Ancho
        </span>
  
        <span className="col text-right font-sand">
          Alto
        </span>
  
        <span className="col text-right font-sand">
          Precio
        </span>
  
        <div style={icStyle}/>
        
      </div>
      
      {stickers.map( it =>
        <div
          key={it.uuid}
          onClick={() => handleSelectedSticker(it) }
          className="row"
          style={{
            /* borderBottom: it?.uuid===sticker?.uuid ? '1.5px solid #033268' : '0.5px solid lightgray', */
            padding: '10px 0'
          }}>
  
          <div className="col" style={{ maxWidth: 50 }}>
          <img
            style={icStyle}
            src={it.url}
            alt=""/>
          </div>
          
          <span className="col text-right font-sand">
            {it.width.toFixed(1)}cm
          </span>
  
          <span className="col text-right font-sand">
            {it.height.toFixed(1)}cm
          </span>
  
          <MoneyText
            className="col"
            text={it.price-(it.discount || 0)}/>
            
          <i
            className="fa fa-trash"
            onClick={ evt => handleRemove(evt, it) }/>
          
        </div>
      )}
      </div>
    </div>
  )
  
}

export default StickersControl
