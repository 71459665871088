import React from 'react'
import DistrictInput from '../register/DistrictInput'
import { Button } from '../../widgets'
import { AddressService } from '../../services'
import { Globals } from '../../utils'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

const CreateAddress = props => {

    const [form, setForm] = React.useState({
        district_id: '',
        address: '',
        reference: ''
    })

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        const _form = {
            ...form,
            [name]: value,
        }
        setForm(_form)
    };
    const handleDistrictChange = district_id => {
        const _form = {
            ...form,
            district_id
        }
        setForm(_form)
    }


      const  isValid = () => {
          //  toast.warn('validamos');
         //   return false;
            return true;
          };



    const submit = async () => {

        if (!isValid()) return;
        try {
            const res = await AddressService.save({ ...form, user_id: props.user.id });
            Globals.showSuccess(res.msg)
        } catch (error) {
            Globals.showError()
        } finally {
            props.onClose()
        }
    }

    return (
        <div className="">
            <div className="row">
                <DistrictInput onDistrictChange={handleDistrictChange} addDivParent classNameParent="col-12 col-lg-4 mb-4" />

                    <div className="col-12 mb-4">
                        <div className="form-group">
                            <label htmlFor="address">
                                Dirección &nbsp;
                                <span className="text-danger">
                                    *
                                </span>
                            </label>
                            <textarea
                                name="address"
                                value={form.address}
                                onChange={onChange}
                                
                                id="review"
                                placeholder="Ingresa la dirección"
                                className="mb-3 font-sand form-control input_custom_40 font-weight-500"
                                style={{ height: 100 }}
                                required="" />
                        </div>
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="form-group">
                            <label htmlFor="reference">Referencia</label>
                            <textarea
                                name="reference"
                                value={form.reference}
                                onChange={onChange}
                                
                                id="review"
                                placeholder="Escriba el punto de referencia"
                                className="mb-3 font-sand form-control input_custom_40 font-weight-500"
                                style={{ height: 100 }}
                                required="" />
                        </div>
                    </div>


            </div>

            <div className="w-100 text-center">
                <div className="btn-solid btn" onClick={() => submit()}>
                    Guardar
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})

export default connect(mapStateToProps)(CreateAddress)
