import React, { Component } from 'react';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';

import { fabric } from 'fabric';
import EditorBackground2 from './EditorBackground2';
import { A4 } from './Custom';
import html2canvas from 'html2canvas';

class Editor extends Component {
  state = {
    rectangle: { x: 0, y: 0, width: 0, height: 0 },
    sizeFont: 10,
    objeto: null,
    statusText: this.props.statusText,
  };

  componentDidMount() {
    this.initCanvas(this.props.view);
    this.paintDrawArea(this.props.view);
    /* this.paintStickers(this.props.stickers); */
  }

  componentDidUpdate(props, prevState, snapshot) {
    const {
      view,
      stickers,
      product,
      sticker,
      statusText,
      textSize,
      handleAddTexts,
      texts
    } = this.props;
 
    if (props.view === this.canvas.view)
    {
      if (statusText) {
        this.insertText();
      }
      if(texts){
       // console.log('cambio')
        texts.map(text => {if( this.canvas.view ==text.view ) this.canvas.add(text)})
  
      }
      if (props.stickers.length !== stickers.length)
      this.onChangeStickers(stickers);

    if (
      !!product &&
      (!!props.product !== !!product || props.product.id !== product.id)
    )
      this.onChangeProduct(product);

    this.addFocusToSticker(sticker);
    } 
  
    /* if (textSize && this.state.objeto) {
      console.log(textSize, this.state.objeto);
      this.setStyle(this.state.objeto, 'fontSize', parseInt(textSize, 10));
    } */
    if (props.view !== view) this.onChangeView(view);


  }

  insertText = () => {
    var text = new fabric.IText('Agregar Texto', {
    
      left: 10,
      top: 100,
      fontSize: 25,
    });
    text.view = this.props.view;
  //  console.log('text', text)
    
    this.props.handleAddTexts(text)
  //  console.log( this.canvas)
    this.canvas.add(text);
    this.props.setStatusText();
  };

  onChangeView = (view) => this.paintDrawArea(view);

  onChangeStickers = (stickers) => this.paintStickers(stickers);

  onChangeProduct = (product) => this.paintDrawArea(this.props.view);

  addFocusToSticker = (sticker) => {
  if (!this.canvas) return;

    if (!sticker) return;

    if (sticker.view !== this.props.view) return;

    const currentObject = this.canvas.getActiveObject();
    if (
      currentObject &&
      currentObject.uuid &&
      currentObject.uuid === sticker.uuid
    )
      return;

    const index = this.props.stickers.findIndex(
      (it) => it.uuid === sticker.uuid
    );
    if (index < 0) return;

    const object = this.canvas.getObjects()[index];

    if (object) {
      if (this.props.onFocusSticker) this.props.onFocusSticker(sticker);
      this.canvas.setActiveObject(object);
      this.canvas.renderAll();
    }
  };

  paintDrawArea = (view) => {
    const normalizeRectangle = this.props.rectangles.find(
      (it) => it.view === view
    );
    this.dimension = normalizeRectangle.dimensions.max((it) => it.width) || {
      width: 1,
      height: 1,
    };
    const container = document.getElementById('editor-container-' + this.props.view);
    if (!container) return;
    const width = container.clientWidth;
    const height = container.clientHeight;

    const rectangle = {
      x: width * normalizeRectangle.x,
      y: height * normalizeRectangle.y,
      width: width * normalizeRectangle.width,
      height: height * normalizeRectangle.height,
    };

    this.setState({ rectangle }, () => {
      this.paintRectangle(rectangle);
      if (this.props.onChangeDimension)
        this.props.onChangeDimension({
          width: rectangle.width,
          height: rectangle.height,
        });
    });
  };

  paintRectangle = () => {
    if (!this.canvas) return;
    const { rectangle } = this.state;
    this.canvas.setWidth(rectangle.width);
    this.canvas.setHeight(rectangle.height);
    this.paintStickers(this.props.stickers);
  };

  paintStickers = (stickers) => {
    const { rectangle } = this.state;
    this.canvas.clear();
    stickers
      .filter((sticker) => sticker.view === this.props.view)
      .forEach((sticker) => {
        const width = rectangle.width * sticker.w;
        const height = rectangle.height * sticker.h;
        const left = sticker.x * rectangle.width;
        const top = sticker.y * rectangle.height;

        fabric.Image.fromURL(sticker.url, (image) => {
          image.uuid = sticker.uuid;
          image.set({
            left,
            top,
            hasRotatingPoint: false,
            scaleX: width / image.width,
            scaleY: height / image.height,
          });
          this.canvas.add(image);
        }, {
          crossOrigin: 'Anonymous'
        });
      });
  };

  initCanvas = (view) => {
    
    this.canvas = new fabric.Canvas('editor-canvas-' + view, {
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue',
    });
    this.canvas.view = view
    if (!this.canvas) return;
    this.canvas.on({
      'mouse:down': (e) => {
        if (e.target) this.onClickSticker(e.target);
      },
      'object:moved': (e) => this.onMovedObject(e.target),
      'object:scaled': (e) => this.onScaledObject(e.target),
      'text:editing:exited': (e) => this.onRemoveText(e),
    });
  };

  getCanvasBounds = () => {
    const { rectangle } = this.state;
    return {
      width: `${rectangle.width}px`,
      height: `${rectangle.height}px`,
      left: `${rectangle.x}px`,
      top: `${rectangle.y}px`,
    };
  };

  onClickSticker = (target) => {
    if (this.props.statusText === false) {
      if (this.props.sticker?.uuid === target.uuid) return;
      if (this.props.onFocusSticker)
        this.props.onFocusSticker(
          this.props.stickers.find((it) => it.uuid === target.uuid)
        );
    }
  };

  update = () => {
    this.onChangeStickers(this.props.stickers);
  };

  onMovedObject = (object) => {
    if (!object) return;
    const { rectangle } = this.state;
    const { uuid, left, top } = object;
    const stickers = [...this.props.stickers];
    const index = stickers.findIndex((sticker) => sticker.uuid === uuid);
    if (index < 0) return;
    const sticker = stickers[index];
    sticker.x = left / rectangle.width;
    sticker.y = top / rectangle.height;
    stickers[index] = sticker;
    if (this.props.onUpdateStickers) this.props.onUpdateStickers(stickers);
  };

  onScaledObject = (object) => {
    if (!object) return;
    const { rectangle } = this.state;
    const { uuid, scaleX, scaleY, width, height } = object;
    const stickers = [...this.props.stickers];
    const index = stickers.findIndex((sticker) => sticker.uuid === uuid);
    if (index < 0) return;



    const sticker = stickers[index];
    const w = (scaleX * width) / rectangle.width;
    const h = (scaleY * height) / rectangle.height;
    const bounds = {
      w,
      h,
      width: w * this.dimension.width,
      height: h * this.dimension.height,
    };

    const alto = A4.height;
    const ancho = A4.width;
    const medio = 13.8;
    const stickeralto = bounds.height;
    const stickerancho = bounds.width;
    var resultado = 1; // 1 es falla y 0 ok


    if (stickeralto > ancho && stickerancho > ancho){ // primer caso el alto y el ancho son mayores a 19
      resultado = 1; /// no pasa
    } else {
      if (stickeralto > alto || stickerancho > alto) {
        resultado = 1; /// no pasa
      }else{ // ya desde aqui sabemos que el sticker no es mas grande que la hoja A4
        if (stickeralto > medio && stickerancho > medio){ // primer caso el alto y el ancho son mayores a 19
          resultado = 2; /// esto me indica que el sticker es mayor a media hoja
        } else {
          if (stickeralto > ancho || stickerancho > ancho) {
            resultado = 2; /// esto me indica que el sticker es mayor a media hoja
          }else{
           resultado = 0; // es menor a media hoja
          }
        }
      }
    } 

    if (resultado === 1) {
      alert('El estampado no puede ser mayor a 19cm x 27.7 cm');
      this.update();

    } else {

      stickers[index] = sticker;
      sticker.w = bounds.w;
      sticker.h = bounds.h;
      sticker.width = bounds.width;
      sticker.height = bounds.height;
      sticker.physical_width = bounds.width;
      sticker.physical_height = bounds.height;

      if (resultado == 2) {
        sticker.price = A4.price;
      }else{
        sticker.price = A4.price / 2;
      }
      if (this.props.onUpdateStickers) this.props.onUpdateStickers(stickers);
    }


  };

  capture = async () => {
    // const container = document.getElementById('editor-container');
   // console.log(container);
    // const canvas = await html2canvas(container);
   // console.log(canvas.toDataURL());


   this.canvas.discardActiveObject().renderAll();

   document.getElementById('drawingArea-' + this.props.view).style.border = '0px'
   const canvas = await html2canvas(document.querySelector('#editor-container-' + this.props.view), {
      useCORS: true,
      width: 500,
      height: 500,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
    })

   // console.log('prueba', canvas.toDataURL("image/png"))


    return canvas.toDataURL();
  };

  onRemoveText = (e) => {
    //
  //  console.log('remove', e);
    var obj = this.canvas.getActiveObject();
  //  console.log(obj);
    if (obj.text == '') {
      this.canvas.remove(obj);
    }
  };

  render() {
    const { style, className, image, color, width } = this.props;
    return !!image ? (
      <div ref={(it) => (this.parent = it)}>
        <EditorBackground2
          id={'editor-container-' + this.props.view}
          className={className}
          style={style}
          image={image}
          color={color ? color.value : '#ffffff'}
          width={width}
        >
          <If condition={ this.props.view == 1}>
            <div
              id={'drawingArea-1'}
              style={{
                padding: 0,
                margin: 0,
                // zIndex: 10,
                position: 'relative',
                border: '1px solid black',
                ...this.getCanvasBounds(),
              }}
            >
              <canvas id={'editor-canvas-1'} />
          
            </div>
            <Else>
            <span></span>

            </Else>
          </If>
        
          <If condition={ this.props.view == 2}>
          <div
              id={'drawingArea-2'}
              style={{
                padding: 0,
                margin: 0,
                // zIndex: 10,
                position: 'relative',
                border: '1px solid black',
                ...this.getCanvasBounds(),
              }}
            >
              <canvas id={'editor-canvas-2'} />
          
            </div>
            <Else>
            <span></span>

            </Else>
          </If>
        </EditorBackground2>
      </div>
    ) : (
      <div style={style} />
    );
  }
}

export default Editor;
