import React from "react";
import {getSticker} from "./Custom";

let input

const InputSticker = ({onClick, view, dimension, product, className, style, onAddImage}) => {
  
  const handleChange = async evt => {
    if (!onClick) return
    const file = evt.target.files[0];
    const url = URL.createObjectURL(file)
    const sticker = await getSticker(url, product, view, dimension)
    onClick(sticker)
    if (onAddImage) onAddImage({uuid: sticker.uuid, file})
  }
  
  const handleClick = evt => {
    if (input) input.click()
  }
  
  return <div
    className={`h-box center m-0 p-0 selected ${className}`}
    style={{
      background: 'lightgray',
      padding: '4px',
      borderRadius: 10,
      ...style
    }}
    onClick={handleClick}>
    
    <input
      ref={ it => input = it }
      type="file"
      accept="image/*"
      onChange={handleChange}
      style={{
        display: "none"
      }}/>
  
    <div
      className="h-box center"
      style={{
        minWidth: '30px',
        minHeight: '30px',
        maxWidth: '30px',
        maxHeight: '30px'
      }}>
  
      <i className="fa fa-upload"/>
      
    </div>
    
    <input
      type="text"
      disabled
      placeholder="Subir Imagen"
      className="less-focus"
      style={{
        background: 'transparent',
        border: 'none',
        minWidth: '75px'
      }}/>
      
  </div>
}

export default InputSticker
