import React from "react";
import MoneyText from "../../widgets/MoneyText";

const ProductHeader = ({product, total}) => {
  if (!product) return null;
  
  return (
    <div className="">
      <h3 className="font-sand">{product.name}</h3>
      <MoneyText text={total}/>
    </div>
  )
  
}

export default ProductHeader
