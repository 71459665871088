import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Breadcrumb from '../common/breadcrumb';
import { addToCartAndRemoveWishlist, removeFromWishlist } from '../../actions';

class wishList extends Component {
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  render() {
    const { Items, symbol } = this.props;
    return (
      <div>
        <Breadcrumb title={'Wishlist'} />
        {Items.length > 0 ? (
          <section className="wishlist-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">Imagen</th>
                        <th scope="col">Nombre del producto</th>
                        <th scope="col">precio</th>
                        <th scope="col">descuento</th>
                        <th scope="col">haber</th>
                        <th scope="col">opciones</th>
                      </tr>
                    </thead>
                    {Items.map((item, index) => {
                      console.log(
                        item.price - (item.price * item.discount_rate) / 100
                      );
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link to={`/detalle/productos/${item.id}`}>
                                <img
                                  src={
                                    item?.images
                                      ? item?.images[0].uri
                                      : item?.variants[0]?.images
                                  }
                                  alt=""
                                />
                              </Link>
                            </td>
                            <td>
                              <Link to={`/detalle/productos/${item.id}`}>
                                {item.name}
                              </Link>
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  {(item.stock-1) >= 1 ? (
                                    <p style={{color:'green'}}>En stock</p>
                                  ) : (
                                    <p style={{color:'red'}}>Sin stock</p>
                                  )}
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <span className="money">
                                      {symbol}
                                      {item.price}
                                    </span>
                                  </h2>
                                </div>
                                <div className="col-xs-3">
                                  {parseFloat(item.discount_rate) > 0 ? (
                                    <h2 className="td-color">
                                      {symbol}{' '}
                                      {item.price -
                                        (item.price *
                                          parseFloat(item.discount_rate)) /
                                          100}
                                    </h2>
                                  ) : (
                                    'n/a'
                                  )}
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <a
                                      href="javascript:void(0)"
                                      className="icon"
                                      onClick={() =>
                                        this.props.removeFromWishlist(item)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="cart"
                                      onClick={() =>
                                        this.props.addToCartAndRemoveWishlist(
                                          item,
                                          1
                                        )
                                      }
                                    >
                                      <i className="fa fa-shopping-cart"></i>
                                    </a>
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td>
                              {parseFloat(item.discount_rate) > 0 ? (
                                <del>
                                  <h2>
                                    <span className="text-muted">
                                      {symbol}
                                      {item.price}
                                    </span>
                                  </h2>
                                </del>
                              ) : (
                                <h2>
                                  <span className="money">
                                    {symbol}
                                    {item.price}
                                  </span>
                                </h2>
                              )}
                            </td>
                            <td>
                              {parseFloat(item.discount_rate) > 0 ? (
                                <h2 className="td-color">
                                  {symbol}{' '}
                                  {item.price -
                                    (item.price *
                                      parseFloat(item.discount_rate)) /
                                      100}
                                </h2>
                              ) : (
                                'N/A'
                              )}
                            </td>
                            <td>
                              {(item.stock-1) >= 1 ? (
                                <p style={{color:'green'}}>En stock </p>
                              ) : (
                                <p style={{color:'red'}}>Sin stock</p>
                              )}
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                className="icon"
                                onClick={() =>
                                  this.props.removeFromWishlist(item)
                                }
                              >
                                <i className="fa fa-times"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="cart"
                                onClick={() =>
                                  this.props.addToCartAndRemoveWishlist(item, 1)
                                }
                              >
                                <i className="fa fa-shopping-cart"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
              <div className="row wishlist-buttons">
                <div className="col-12">
                  <Link
                    to={`/left-sidebar/collection`}
                    className="btn btn-solid"
                  >
                    seguir comprando
                  </Link>
                  <Link to={`/checkout`} className="btn btn-solid">
                    ir a pagar
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`/assets/images/empty-wishlist.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>Lista de deseos vacía</strong>
                      </h3>
                      <h4>Explore mas listas de items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Items: state.wishlist.list,
  symbol: state.data.symbol,
});

export default connect(mapStateToProps, {
  addToCartAndRemoveWishlist,
  removeFromWishlist,
})(wishList);
