import {axios} from '../axios';
const END_POINT = 'users/address';

const save = async form => {
    try {
        const res = await axios.post(`${END_POINT}`, form);
        return res.data;
    } catch(e){
        throw e
    }
}

export default {
    save
}
