import React, { useEffect, useState } from 'react';
//import checkout from '../pages/niubiz/desacoplado.html';
import { Modal } from 'react-bootstrap';
import KRGlue from '@lyracom/embedded-form-glue';
import './styles.scss';
import IzipayService from '../../services/modules/IzipayService';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import { connect } from 'react-redux';

function ModalIzipay({
  handleIzipayResponse,
  sendPayment,
  setPaymentMethod,
  izipaytotal,
  total,
  size = 'md'
}) {
  const [showModal, setShowModal] = useState(true);
  const [formToken, setFormToken] = useState(null);
  const user = !!localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state'))
    : '';
  const email = user?.user?.user?.email;
  const [title, setTitle] = useState('Cargando formulario...');
  const callFormToken = async () => {
    try {
      const token = await IzipayService.get(total, email);
      setFormToken(token);
    } catch (e) {
      console.log('>>: error > e', e);
    }
  };

  const load = () => {
    const endpoint = 'https://api.micuentaweb.pe';
    const publicKey =
      '10814422:publickey_nCTkJMvCaGgLXctzoEJwAHp8EXGSyhpmWQUA4TINkiIFE';
    KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
      .then(({ KR }) => {
        setTitle('Formulario de pago');
        return KR.setFormConfig({
          /* set the minimal configuration */
          formToken: formToken,
          'kr-language': 'es-PE' /* to update initialization parameter */,
        });
      }) 
      .then(({ KR }) =>
        KR.onSubmit((resp) => {
          const answer = resp.clientAnswer;
          const hash = resp.hash;
          const answerHash = Hex.stringify(
            hmacSHA256(
              JSON.stringify(answer),
              '3Y15TrZCQKFm59QhmpuiIdOjyjehZsRMTN5ZN0OhGyTlC'
            )
          );
          if (hash === answerHash) {
            // console.table(hash, answerHash)
            // console.log('respuesta', resp);
            handleIzipayResponse(
              resp.clientAnswer?.transactions[0]?.transactionDetails
                ?.cardDetails?.legacyTransId
            );
            setShowModal(false);
            sendPayment();
          } else {
            console.log('error de validacion');
          }
        })
      )
      .then(({ KR }) =>
        KR.addForm('#myPaymentForm')
      ) /* add a payment form  to myPaymentForm div*/
      .then(({ KR, result }) =>
        KR.showForm(result.formId)
      ); /* show the payment form */
  };

  useEffect(() => {
    callFormToken();
  }, []);

  useEffect(() => {
    if (!!formToken) {
      load();
    }
  }, [formToken]);

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentMethod(0);
  };
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size={size} centered >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="font-sand">
            Pago Izipay
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <h4 className="title text-uppercase font-sand mt-2">{title}</h4>
            <div className="d-flex justify-content-center">
              <div id="myPaymentForm" className="font-sand"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  izipaytotal: state.checkout.total,
});
export default connect(mapStateToProps)(ModalIzipay);
