import React from 'react';

const Input	= props => (
	<div className="form-group">
		{ props.label && <label htmlFor={ props.name } className="font-sand">{ props.label }</label> }
		<input 
			{ ...props }
			type={ props.type ? props.type : 'text' }
			className={ `form-control font-sand font-weight-500 ${ props.color ? 'input-'+props.color : 'input-white' }` + ' ' + props.className }
			name={ props.name } />
	</div>
)

export default Input;