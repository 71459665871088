import React, { useEffect, useState } from 'react';
import Dotting from '../Dotting';
import SubcategoryFilter from './SubcategoryFilter';
var a = new Array();
const CategoryFilter = ({ categories = [], category,setSubcategory,subcategory, onChangeCategory }) => {
  const [loading, setLoading] = useState(true);
  const [catvisible, setCatvisible] = useState([]);


  /* if (category) return null;
  
  if (!categories.length) return null; */
  useEffect(() => {
    /* console.log('category filter', categories)
    console.log('category filter category', category) */
    //console.log(categories.map((categorxy) =>{catvisible[categorxy.id] = false; return catvisible[categorxy.id] }));
    categories.map((categorxy) =>{catvisible[categorxy.id] = typeof catvisible[categorxy.id]!=="undefined"?catvisible[categorxy.id]:false;  })
    //setCatvisible();
    if (categories.length > 0) {
      setLoading(false);
    }
  }, [categories]);
  var toggle = previous => !previous;
  const handleClick = (category) => {
    
    setCatvisible(prev => {
      const clone = [ ...prev ];
      clone[category.id] = prev[category.id]==false?true:false;

      return clone;
    });
     
    
    if (onChangeCategory) onChangeCategory(category);
  };

  const RenderCategories = () => {
    return (
      <>
        {categories.map((category) => (
        <div   key={category.id}>
          <div
            className="item-section-items color-inherit font-sand f-sand-300 cur-pointer color_hover_primary"
          
            onClick={() =>
             handleClick(category)}
          >
            {category.name} 
          </div>

          <div  style={{
              display: !catvisible[category.id] ? 'none' : 'block'
            }}>
              <SubcategoryFilter
           
           subcategories={category.sub_categories}
           onChangeSubcategory={setSubcategory}
         /> 
            </div>
          
        </div>
        ))}
      </>
    );
  };
  return (
    <div className="section-items mb-3">
      
      {/* <RenderCategories /> */}
      {loading ? (
        <p  className="loading color-inherit font-sand f-sand-300">
          Cargando Categorias <Dotting />
        </p>
      ) : (
        <RenderCategories />
      )}
    </div>
  );
};

export default CategoryFilter;
