import React from "react";
import Required from "./Required";

const Options = ({ id=(10000*Math.random()).toFixed(0),
                  label="",
                  name,
                  options,
                  required=false,
                  value,
                  onChange,
                  className,
                  inputClassName="form-control", style = {}}) => {
  
  const handleChange = evt => {
    if (!onChange) return
    onChange(evt.target.value, evt.target.name)
  }
  
  return (
    <div className={className}>
      <label htmlFor={name || id} className="form-label">{label} <Required required={required}/> </label>
      <select id={name || id}
              name={name}
              onChange={handleChange}
              className={inputClassName}
              value={value}
              style={style}
              required={required}>
        {options?.map( ({value, text}, index) =>
          <option key={index} value={value}>{text}</option>
        )}
      </select>
    </div>
  )
  
}
 
export default Options
