import {axios} from '../axios';

const END_POINT = 'promotion-codes';
const get = async params => {
    try{
        const res = await axios.post(`${END_POINT}`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    get
}