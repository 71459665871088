import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProductCell from './ProductCell';
import Search from './Search';
import StickerCell from './StickerCell';
import InputSticker from './InputSticker';
import InputRange from 'react-input-range';

const LateralBar = ({
  onAddText,
  onAddImage,
  statusText,
  view,
  products,
  product,
  onProductChange,
  onAddSticker,
  dimension,
  prints = [],
  changeTextSize,
  textSize,
  isLoading = true
}) => {
  const [search, setSearch] = useState('');
  const [searchPrint, setSearchPrint] = useState('');

  const handleProductPress = (product) => {
    if (!onProductChange) return;
    onProductChange(product);
  };

  const handleStickerPress = (sticker) => {
    if (!onAddSticker) return;
    onAddSticker(sticker);
  };

  const _prints =
    prints.filter((it) =>
      it.name?.toLowerCase().includes(searchPrint.toLowerCase())
    ) || [];

  return (
    <div className="col-12">
    

      <div className="border-shadow-custom p-12 mt-4">

      <Tabs className="">
      
      <h4 style={{ color: '#093467' }}></h4>
      <br></br>
      <TabPanel>
          <div className="tab-pane active" id="tab1">
          {/*    <Search onChangeText={setSearch} /> */}
            
            {isLoading ? 
            <div className="mt-4 text-center">
               <i className="fa fa-circle-notch fa-spin mr-2"></i><span>Cargando</span>
            </div> : <div className="scroll_custom mt-5" style={{ overflow: 'auto' }}>
              <div className="d-flex flex-nowrap" style={{ height: '141px' }}>
                {[
                  ...products,
                ]
                  .filter((product) => !!product)
                  .filter((product) =>
                    product.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((it) => (
                    <ProductCell
                      key={it.id}
                      onClick={handleProductPress}
                      product={it}
                      selected={!product ? false : it.id === product.id}
                    />
                  ))}
              </div>
            </div>}
          </div>
        </TabPanel>
      
          
        <TabList>
          <br></br>
          <Tab className="font-sand mr-4">
            <button className="btn btn-danger">
              Tipo de Polo
            </button>
          </Tab>
          {!!product && (
            <Tab className="font-sand mr-4">
              <button className="btn btn-success">Diseños pre definidos</button>
            </Tab>
          )}
          {!!product && (
            <Tab className="font-sand mr-4">
              <button className="btn btn-warning">Texto</button>
            </Tab>
          )}

          {!!product && (
            <Tab className="font-sand mr-4">
              <div style={{ flex: 1, maxWidth: '80%' }}>
                <InputSticker
                  view={view}
                  product={product}
                  dimension={dimension}
                  onClick={handleStickerPress}
                  onAddImage={onAddImage}
                />
              </div>
            </Tab>
          )}
        </TabList>
         
        

        

        {!!product && (
          <TabPanel>
            <div className="tab-pane" id="tab2">
              <div className="h-box m-0 p-0 justify-content-between" style={{ maxWidth: '100%' }}>
              <Search
                  style={{ flex: 1, maxWidth: '49%' }}
                  onChangeText={setSearchPrint}
                /> 

                
              </div>

              <div className="scroll_custom mt-5" style={{ overflow: 'auto' }}>
                <div className="d-flex flex-nowrap">
                  {!_prints.length ? (
                    <div className="h-box center mt-5 w-100">
                      <span className="font-sand">No hay coincidencias</span>
                    </div>
                  ) : (
                    _prints.map((print) => (
                      <StickerCell
                        key={print.id}
                        product={product}
                        view={view}
                        dimension={dimension}
                        onClick={handleStickerPress}
                        print={print}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
        )}

        {!!product && (
          <TabPanel>
            <div className="tab-pane" id="tab3">
              <div
                className="d-flex mt-4 mb-4 justify-content-center"
                style={{ maxWidth: '100%' }}
              >
                <div className="row">
                  <div className="col-12">
                    <button className="btn btn-primary" onClick={onAddText}>
                      Agregar Texto
                    </button>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  {statusText ? (
                    <div className="col-12 d-flex flex-column">
                      <div className="form-group d-flex align-items-center justify-content-center">


                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </TabPanel>
        )}
      </Tabs>

    </div>
    </div>
  );
};

export default LateralBar;
