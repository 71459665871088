import React from "react";
import InputLabel from "./InputLabel";

const Input = ({ id=(10000*Math.random()).toFixed(0),
                 type='text',
                 label="",
                 name,
                 required=false,
                 value,
                 onChange,
                 className,
                 min,
                 max,
                 step,
                 minLength,
                 maxLength,
                 placeholder,
                 inputClassName="form-control",
                 security=false,
                 message,
                 styleParent= {},
                 leftIcon, style}) => {
  
  const handleChange = evt => {
    if (!onChange) return
    onChange(evt.target.value, evt.target.name)
  }
  
  return (
    <div className={className} style={styleParent}>
      {!!label ? 
      <InputLabel
        htmlFor={name || id}
        text={label}
        required={required}
        leftIcon={leftIcon}/> : null
        }
      
      <input
        id={name || id}
        className={inputClassName}
        type={type}
        name={name}
        required={required}
        placeholder={placeholder || label}
        value={value}
        min={min}
        max={max}
        step={step}
        minLength={minLength}
        maxLength={maxLength}
        onChange={handleChange}
        style={style}
        onCopy={ e => {
          if (security) e.preventDefault()
        }}
        onPaste={ e => {
          if (security) e.preventDefault()}
        }/>
      {!!message &&
        <div id={name || id}
             className="form-text"
             style={{
               fontWeight: "normal",
               fontSize: "small"
             }}>
          {message}
        </div>
      }
    </div>
  )
  
}

export default Input
