import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {
    Slider4,
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment} from '../../../services/script'

// Import custom components
import LogoBlocks from "../common/logo-block"
import BlogSection from "../common/blogsection"
import Trending from "./trending";
import TrandingCollection from "../common/collection"
import Special from "../common/special";
import Instagram from "../common/instagram"
import HeaderFive from "../../common/headers/header-five"
import FooterFour from "../../common/footers/footer-four";
import ThemeSettings from "../../common/theme-settings"
import {APP_NAME} from '../../../utils'
import { SliderHome } from '../../SliderHome';
class Watch extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `/assets/css/color4.css` );
    }

    render(){
        return (
            <div>
                <Helmet>
                    <title>{APP_NAME} | Modelo 2</title>
                </Helmet>
                <HeaderFive logoName={'logo/logo-market2.png'} />
                <section className="p-0 small-slider">
                    <SliderHome />
                </section>

                

                {/*Timer Banner
                <section className="pt-0">
                    <div className="container">
                        <div className="row banner-timer">
                            <div className="col-md-6">
                                <div className="banner-text">
                                    <h2> <span>30% de descuento</span> Ropa</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="timer-box">
                                    <div className="timer">
                                        <p id="demo"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                Timer Banner End*/}

                {/*category wrapper*/}
                <section className="section-b-space ratio_portrait">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Slider {...Slider4} className="slide-4 category-m no-arrow">
                                    <div>
                                        <div className="category-wrapper">
                                            <div>
                                                <div>
                                                    <img src={`/assets/images/watch/cat1.png`}
                                                         className="img-fluid blur-up lazyload bg-img" alt="" />
                                                </div>
                                                <h4>Ropa de hombre</h4>
                                                <ul className="category-link">
                                                    <li><a href="#">Pantalones</a></li>
                                                    <li><a href="#">Polos</a></li>
                                                    <li><a href="#">Casacas</a></li>
                                                    <li><a href="#">Boxers</a></li>
                                                </ul>
                                                <a href="#" className="btn btn-outline">ver más</a></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-wrapper">
                                            <div>
                                                <div>
                                                    <img src={`/assets/images/watch/cat2.png`}
                                                         className="img-fluid blur-up lazyload bg-img" alt="" />
                                                </div>
                                                <h4>Ropa de mujer</h4>
                                                <ul className="category-link">
                                                    <li><a href="#">Blusas</a></li>
                                                    <li><a href="#">Vestidos</a></li>
                                                    <li><a href="#">Jeans</a></li>
                                                    <li><a href="#">Zapatillas</a></li>
                                                </ul>
                                                <a href="#" className="btn btn-outline">ver más</a></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-wrapper">
                                            <div>
                                                <div>
                                                    <img src={`/assets/images/watch/cat3.png`}
                                                         className="img-fluid blur-up lazyload bg-img" alt="" />
                                                </div>
                                                <h4>Ropa de niño</h4>
                                                <ul className="category-link">
                                                    <li><a href="#">Camisas</a></li>
                                                    <li><a href="#">Shorts</a></li>
                                                    <li><a href="#">Polos de niño</a></li>
                                                    <li><a href="#">Otros</a></li>
                                                </ul>
                                                <a href="#" className="btn btn-outline">ver más</a></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-wrapper">
                                            <div>
                                                <div>
                                                    <img src={`/assets/images/watch/cat2.png`}
                                                         className="img-fluid blur-up lazyload bg-img" alt="" />
                                                </div>
                                                <h4>Ofertas</h4>
                                                <ul className="category-link">
                                                    <li><a href="#">Ofertas del mes</a></li>
                                                    <li><a href="#">Promociones</a></li>
                                                    <li><a href="#">Mejores descuentos</a></li>
                                                    <li><a href="#">Lo más vendido</a></li>
                                                </ul>
                                                <a href="#" className="btn btn-outline">ver más</a></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-wrapper">
                                            <div>
                                                <div>
                                                    <img src={`/assets/images/watch/cat1.png`}
                                                         className="img-fluid blur-up lazyload bg-img" alt="" />
                                                </div>
                                                <h4>Otros productos</h4>
                                                <ul className="category-link">
                                                    <li><a href="#">Relojes</a></li>
                                                    <li><a href="#">Televisores</a></li>
                                                    <li><a href="#">Smartphones</a></li>
                                                    <li><a href="#">Sonido</a></li>
                                                </ul>
                                                <a href="#" className="btn btn-outline">ver más</a></div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                {/*category wrapper end*/}

                {/*Special Products Start*/}
                <Trending type={'watch'} />
                {/*Special Products End*/}

                {/* Parallax banner*/}
                <TrandingCollection type={'watch'} />
                {/* Parallax banner end*/}

                {/*Content Banner*/}
                <section className="ratio_45">
                    <div className="container">
                        <div className="row partition3">
                            <div className="col-md-4">
                                <a href="#">
                                    <div className="collection-banner p-left">
                                        <div className="img-part">
                                            <img src={`/assets/images/banner1.jpg`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            <div>
                                                <h4>10% de descuento</h4>
                                                <h2>nuevos</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="#">
                                    <div className="collection-banner p-left text-left">
                                        <div className="img-part">
                                            <img src={`/assets/images/banner2.jpg`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="#">
                                    <div className="collection-banner p-left">
                                        <div className="img-part">
                                            <img src={`/assets/images/banner.jpg`}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3">
                                            <div>
                                                <h4>mínimo 50% de descuento</h4>
                                                <h2>publicidad</h2>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Content Banner End*/}

                <Special type={'watch'} />

                {/* Blog Section Section*/}
                <section className="blog blog-bg section-b-space ratio2_3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title4">
                                    <h4>Nuestra collección</h4>
                                    <h2 className="title-inner4">Productos destacados</h2>
                                    <div className="line"><span></span></div>
                                </div>
                                <BlogSection />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Blog Section End*/}

                {/*Service Layout*/}
                <div className="container">
                    <section className="service section-b-space border-section border-top-0">
                        <div className="row partition4">
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                <h4>Detalles de envío</h4>
                                <p>7 soles por envío a lima, por envío a provincia se cobrará adicional 10 soles por producto</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                <h4>Servicio 24 x 7</h4>
                                <p>Puede comprar a cualquier hora!</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                <h4>Ofertas de temporada</h4>
                                <p>Aproveche nuestras mejores ofertas</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1">
                                <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                                <h4>Pago online</h4>
                                <p>Pague de manera segura sus productos mediante nuestros métodos de pago</p>
                            </div>
                        </div>
                    </section>
                </div>
                {/*Service Layout End*/}

                <Instagram type="watch"/>

                {/*Logo Blocks section*/}
                <LogoBlocks />
                {/*Logo Blocks section end*/}
                <ThemeSettings/>

                <FooterFour logoName={'logo/logo-market2.png'} />

            </div>
        )
    }
}


export default Watch;