import React from 'react';
import { UserService } from '../../services';
import { Globals } from '../../utils';
import { Link } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import Slider from 'react-slick';

class Shops extends React.Component {
  state = [
    {
      shop: {
        name: '',
        uri: '',
        products: [],
    },
    rating: 0
    },
  ];

  componentDidMount() {
    this.load();
  }

  load = async () => {
    try {
      const shop = await UserService.getShopById(this.props.match.params);
      this.setState({
        shop,
        rating: shop.user.rating
      });
      console.log(shop);
    } catch (e) {
      console.log('error', e);
      Globals.showError();
      // this.props.history.push('/')
    }
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    return (
      <div className="w-100 view-shop text-center">
        <div
          className="image-container w-100"
          style={{
            padding: '0 30%',
          }}
        >
          <img
            src={this.state.shop?.user?.uri}
            alt={this.state.shop?.user?.name}
            className="rounded-circle"
            style={{
              width: '25%',
            }}
          />
        </div>

        <div
          className=""
        >
       <h4>Proveedor: {this.state.shop?.user?.user_name}  RUC: {this.state.shop?.user?.ruc}</h4>
        </div>
        <h2> {this.state.shop?.user?.username} rating {this.state.rating} </h2>
        <div className="w-100 text-center d-flex justify-content-center">
          <ReactStars value={this.state.rating} edit={false} />

        </div>
        <div className="p-5 container">
          <div className="row">
            
            <div className="col-md-6 col-sm-12">
              <i className="fa fa-circle text-success"></i>
              <p className="text-dark">
                <strong>Ventas completadas: &nbsp;</strong>
                {this.state.shop?.user?.sales_done}
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <i className="fa fa-circle text-info"></i>
              <p className="text-dark">
                <strong>% Ventas Concretadas: &nbsp;</strong>
              </p>
            </div>



          </div>
        </div>
        
        <div className="w-100 container">
          <h3 className="text-dark text-center w-100 p-3">Productos</h3>
          <div className="row">
            {this.state.shop?.user?.products.map((element, i) => {
            var acortado = element.name;
            var nuevo = acortado.substring(0,31);

            var acortado2 = element.description;
            var nuevo2 = acortado2.substring(0,31);
              return (
                <div className="col-md-2 col-sm-6" key={i}>
                <Link
                        to={'../../detalle/productos/' + element.id}
                      >
                  <div className="card" style={{
                        alignItems: 'center',
                      }}>
                    <img
                      className="card-img-top"
                      src={
                        element.images &&
                        element.images[0] &&
                        element.images[0].uri
                      }
                      style={{
                        width: '130px',
                        height: '130px',
                        alignItems: 'center',
                      }}
                      alt={element.nuevo}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{element.nuevo}</h5>
                      <p className="card-text text-dark">
                        {nuevo2}
                      </p>
                      <div className="w-100 text-center d-flex justify-content-center">
                        <ReactStars value={element.rating} edit={false} />
                      </div>
                      
                       
                     
                    </div>
                  </div>
                   </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Shops;
