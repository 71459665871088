import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import { UserService } from '../../../services';
import LogoImage from '../headers/common/logo';
import { useHistory } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {Showmessage,ShowmessageError} from   "../../../actions";
import './footer-one.scss';
import { ToastContainer, toast } from 'react-toastify';

class FooterOne extends Component {
  state = { suscribed:0,email:""}
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.onChange('suscribed',this.props.user?.user?.suscribed);
  }

  onChange = (key, value) => {
    this.setState(() => {
        return {
            ...this.state,
            [key]: value
        };
    });
  };
  
  handleChange = (e) => {
    this.onChange('email', e.target.value);
  }

  handleClickTag = (e, tagName) => {
    e.preventDefault();
    let history = useHistory();
    console.log(history);
    if (history.location.pathname === '/search') {
      history.replace('/search', { tagName });
    } else {
      history.push('/search', { tagName });
    }
  };


  check = () => {
    return typeof this.state.suscribed  == typeof undefined
  }


  check2 = (status) => {
    return this.state.suscribed == status
  }


  makeSuscription = async (status) => {
    
  ///  this.props.user.user.suscribed = status;
    //console.log(status)
    if((this.state.email.length === 0 && status == 1) == true )
    {
      ShowmessageError("Necesitas escribir tu email para suscribirte");
    }else{

      if(status == 1)
      {
        Showmessage('Suscripción creada exitosamente')
      }
      else{
        ShowmessageError("Suscripción cancelada exitosamente");
      }
  
      this.onChange('suscribed', status);
     

      const res = await UserService.suscribe({
        id: this.props.user?.user?.id, 
        suscribed: status 
      });
  
      await this.props.dispatch({
        type: 'SET_USER',
        payload: this.props.user,
      });

    }
    
  }


  render() {
      return (
          <footer className="footer-light">
            <ToastContainer/>
            <div className="light-layout">
              <div className="container">
                <section className="small-section border-section border-top-0">
                  <div className="row">
                    <div className="col-lg-12">
                        <span className="mb-0 font-sand fondo" ><strong> COMPRA</strong> FACIL es un sitio web que brinda el servicio de venta al por menor de productos de terceros de forma rápida y segura. Ante cualquier duda puede contactarnos a través del correo soporte@comprafacil.com.pe</span>
                    </div>
    <br></br>
                    <div className="col-lg-4">
                      <div className="subscribe">
                        <div className="footer-logo mb-4 mb-lg-0">
                          <LogoImage logo={this.props.logoName} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 align-self-center">
                    
              


                                <If condition={this.props.user?.user?.id==5}>
                                  <div class="d-flex flex-column flex-lg-row">
                                    <div class="p-0 mr-0 mr-lg-2 mb-2-movil">
                                      <input type="text" onChange={this.handleChange}  class="form-control input_custom font-sand f-sand-300" id="exampleFormControlInput1" placeholder="Ingrese su email" />
                                    </div>
                                    <Link to={`/pages/register`}  class="btn btn-solid mb-2 mb-lg-0 align-self-center" style={{height: "50px", margin: "0px"}}>
                                        Suscribirse
                                    </Link>
                                   
                                  </div>
                                  <Else>
                                    <If condition={this.check() == true}>
                                      <div class="d-flex flex-column flex-lg-row">
                                        <div class="p-0 mr-0 mr-lg-2 mb-2-movil">
                                          <input type="text" onChange={this.handleChange}  class="form-control input_custom font-sand f-sand-300" id="exampleFormControlInput1" placeholder="Ingrese su email" />
                                        </div>
                                        <button type="button" class="btn btn-info mb-2 mb-lg-0 align-self-center"  onClick={()=>{ this.makeSuscription(1);}}   style={{height: "50px", margin: "0px"}}>Suscribirse</button>
                                      </div>
                                      <Else>
                                        <If  condition={this.check2(1) == true}>
                                              <div class="d-flex flex-column flex-lg-row">
                                                <button type="button" class="btn btn-danger mb-2 mb-lg-0 align-self-center"  onClick={()=>{ this.makeSuscription(0) }}  style={{height: "50px", margin: "0px"}}>Desuscibirse</button>
                                              </div>
                                              <Else>
                                                <span></span>
                                              </Else>
                                        </If>
                                      
                                        <If condition={this.check2(0) == true}>
                                          
                                          <div class="d-flex flex-column flex-lg-row">
                                            <div class="p-0 mr-0 mr-lg-2 mb-2-movil">
                                              <input type="text" onChange={this.handleChange} class="form-control input_custom font-sand f-sand-300" id="exampleFormControlInput1" placeholder="Ingrese su email" />
                                            </div>
                                            <button type="button" class="btn btn-solid mb-2 mb-lg-0 align-self-center"  onClick={()=>{  this.makeSuscription(1);}}  style={{height: "50px", margin: "0px"}}>Suscribirse</button>
                                          </div>
                                            
                                          <Else>
                                            <span></span>
                                          </Else>
                                        </If>
                  
                                      </Else>
                                    </If>
                                  </Else>
                                </If>
                          
                          
                                     
                                      
                                       
                                     

                               
                      </div>
                    
                  </div>
                </section>
              </div>
            </div>

            <section className="section-b-space light-layout">


              <div className="container">

                <div className="footer-theme partition-f" id="footer_grid">

                
                  <div className="">
                    <div className="sub-title">
                      <div className="footer-title mb-3">
                        <h4 className="mb-0 font-sand">Mi cuenta</h4>
                      </div>
                      <div className="footer-contant">
                        <ul>
                          <li className="font-sand f-sand-300">
                            <Link to={`/pages/create-support`}>
                              Quejas y reclamos
                            </Link>
                          </li>
                          <li className="font-sand f-sand-300">
                            <a
                              href="/"
                              onClick={(e) => this.handleClickTag(e, 'mujer')}
                            >
                              Mujeres
                            </a>
                          </li>
                          <li className="font-sand f-sand-300">
                            <a  href="/"
                              onClick={(e) => this.handleClickTag(e, 'ropa')}>Ropa</a>
                          </li>
                        </ul>
                      </div> 
                    </div>
                  </div>
                  <div className="">
                    <div className="sub-title">
                      <div className="footer-title">
                        <h4>Mis opciones</h4>
                      </div>
                      <div className="footer-contant">
                        <ul>
                          <li className="font-sand f-sand-300">
                            <Link to={`/legales`}>
                            <a href="#">Pedidos y reembolso</a>
                            </Link>
                          </li>
                          <li className="font-sand f-sand-300">
                            <a href="#">Compras seguras</a>
                          </li>
                          <li className="font-sand f-sand-300">
                            <a href="#">Contacto</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="sub-title">
                      <div className="footer-title">
                        <h4>Contacto</h4>
                      </div>
                      <div className="footer-contant">
                      <ul className="d-flex justify-content-center justify-content-lg-start">
                          <li className="font-sand f-sand-300">
                          
                  <img
                    style={{ width: 50 }}
                   src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png" />
                
                <div className="d-flex flex-column align-self-center">
                  <div className="font-weight-bold font-sand" style={{ fontSize: 20, color: 'white' }}>994 552 270</div>
                  <div>Escríbenos</div>
                </div>
                       </li>
                         </ul>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="sub-title">
                      <div className="footer-title">
                        <h4>Medios de Pago</h4>
                      </div>
                      <div className="footer-contant">
                        <ul className="d-flex justify-content-center justify-content-lg-start">
                          <li className="mr-3">
                            <a href="#">
                              <img src={`/assets/images/icon/visa.png`} alt="" />
                            </a>
                          </li>
                          <li className="mr-3">
                            <a href="#">
                              <img
                                src={`/assets/images/icon/mastercard.png`}
                                alt=""
                              />
                            </a>
                          </li>
                          <li className="mr-3">
                            <a href="#">
                              <img src={`/assets/images/icon/paypal.png`} alt="" />
                            </a>
                          </li>
                          
                          <li className="mr-3">
                            <a href="#">
                              <img
                                src={`https://media-exp1.licdn.com/dms/image/C560BAQGxJdKetOsQfQ/company-logo_200_200/0/1519881779276?e=2147483647&v=beta&t=Mn8hDDDpFTRFvfVnY283xj44rXJmej3Pwbp6xPoUXCs`}
                                alt=""
                              className='izipaylogo'
                              />
                            </a>
                          </li>
                      
                          <li>
                            <a href="#">
                              <img
                                src={`/assets/images/icon/izipay.png`}
                                alt=""
                                className="izipaylogo"
                              />
                            </a> 
                          </li>  
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="sub-footer ">
              <div className="container">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <div className="">
                    <div className="footer-end">
                      <p>
                        <i className="fa fa-copyright" aria-hidden="true"></i> 2024
                        Compra Fácil
                      </p>
                    </div>
                  </div>
                  <div
                    className=" d-flex justify-content-end"
                    style={{ paddingTop: 20, paddingBottom: 20 }}
                  >
                    <div className="footer-social m-0">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href={'https://www.facebook.com/comprafacilpe'}
                          >
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        
                        <li>
                            <a target="_blank" href={'https://twitter.com/comprafacilpe/'}><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        </li> 
                        <li>
                          <a
                            target="_blank"
                            href={'https://www.instagram.com/comprafacilpe/'}
                          >
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                            <a target="_blank" href={'https://www.linkedin.com/company/comprafacil'} ><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </li>
                        {/* <li>
                          <a target="_blank" href={'https://rss.com/'}>
                            <i className="fa fa-rss" aria-hidden="true"></i>
                          </a>
                        </li> */}
                        <li>
                          <a
                            target="_blank"
                            href={'https://www.youtube.com/c/CompraFacilpe'}
                          >
                            <i className="fa fa-youtube" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> 
                </div>
              </div>
            </div> 
            <CookieConsent
              
              location="bottom"
              buttonText="Entendido!"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
              enableDeclineButton
            >
            Este sitio web utiliza cookies para mejorar la experiencia del usuario.
            </CookieConsent>
          
          </footer>
        );
    }
  }
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withRouter(FooterOne));
