import React, { Component } from 'react';
import Input from '../form/Input';
import Options from '../form/Options';
import moment from 'moment';
import DistrictInput from './DistrictInput';
import { toast } from 'react-toastify';
import TextArea from '../form/TextArea';
import Nick from '../form/Nick';
import EmailInput from '../form/EmailInput';
import InputSelect from '../form/InputSelect';
import './ClientSection.css';
const now = moment().add(-15, 'years');
const minDate = now.format('YYYY-MM-DD');
   
const genderOptions = [
  { value: '', text: 'Seleccione' },
  { value: 'F', text: 'Femenino' },
  { value: 'M', text: 'Masculino' },
  { value: 'x', text: 'Prefiero no decirlo' },
];

const documentTypeOptions = [
  { value: '0', text: 'DNI' },
 // { value: '1', text: 'RUC' },
  { value: '2', text: 'Pasaporte' },
  { value: '3', text: 'CE' },
];

const typeDirection = [
  { value: '', text: 'Seleccione' },
  { value: 'Av', text: 'Av' },
  { value: 'Jr', text: 'Jr' },
  { value: 'Ca', text: 'Ca' },
  { value: 'Pje', text: 'Pje' },
];

class ClientSection extends Component {
  state = {
    user_name: '',
    name: '',
    last_name: '',
    email: '',
    birthdate: '',
    ruc: '',
    document_type: '0',
    sex: '',
    phone: '',
    district_id: '',
    region_id: '',
    province_id: '',
    address: '',
    type_address: '',
    reference: '',
    password: '',
    password_confirmation: '',
  };

  constructor(props) {
    super(props);
    const { value } = props;
    if (!value) return;
    this.state = {
      user_name: value.user_name || '',
      name: value.name || '',
      last_name: value.last_name || '',
      email: value.email || '',
      birthdate: value.birthdate || '',
      ruc: value.ruc || '',
      document_type: value.document_type || '0',
      sex: value.sex || '',
      phone: value.phone || '',
      district_id: value.district_id || '',
      region_id: value.region_id || '',
      province_id: value.province_id || '',
      type_address: value.type_address || '',
      address: value.address || '',
      reference: value.reference || '',
      password: value.password || '',
      password_confirmation: value.password_confirmation || '',
    };
  }

  handleChangeInput = (text, name) => {
    console.log(text);
    this.setState(
      {
        ...this.state,
        [name]: text,
      },
      this.onChangeClient
    );
  };

  handleChangeSelectTypeD = (text, name) => {
    console.log('value', text, name);
    this.setState(
      {
        ...this.state,
        [name]: text,
      },
      this.onChangeClient
    );
  };

  handleRegionChange = (region_id) =>
    this.setState({ region_id }, this.onChangeClient);

  handleDistrictChange = (district_id) =>
    this.setState({ district_id }, this.onChangeClient);

  handleProvinceChange = (province_id) =>
    this.setState({ province_id }, this.onChangeClient);

  onChangeClient = () => {
    if (!this.props.onChangeClient) return;
    this.props.onChangeClient(this.state);
  };

  isValid = () => {
    const {
      user_name,
      name,
      phone,
      ruc,
      last_name,
      password,
      password_confirmation,
      district_id,
      province_id,
      region_id,
      address,
      urbanizacion,
      document_type,
    } = this.state;

    

    if (!this.nick.isValid()) {
      toast.warn(`El nombre de usuario ${user_name} ya esta en uso`);
      return false;
    }

    let verificar = new RegExp('[0-9]');
    if (verificar.test(name)) {
      toast.warn('El nombre no puede contener números');
      return false;
    }

    let verificar4 = new RegExp('[A-Z]');
    if (verificar4.test(ruc)) {
      toast.warn('El número de documento no puede contener letras');
      return false;
    }

    if (!ruc) {
      toast.warn('El numero de documento es requerido');
      return false;
    }

    console.log('por aqui pasamos al validar el documento');



    let verificar42 = new RegExp('[a-z]');
    if (verificar42.test(ruc)) {
      toast.warn('El número de documento no puede contener letras');
      return false;
    }
 

    let verificarp = new RegExp('[A-Z]');
    if (verificarp.test(phone)) {
      toast.warn('El número de teléfono no puede contener letras');
      return false;
    }

    let verificarp2 = new RegExp('[a-z]');
    if (verificarp2.test(phone)) {
      toast.warn('El número de teléfono no puede contener letras');
      return false;
    }

    let verificar2 = new RegExp('[0-9]');
    if (verificar2.test(last_name)) {
      toast.warn('El Apellido no puede contener números');
      return false;
    }

    if (district_id && province_id && region_id && address) {

    }else{

    if (!district_id || !province_id || !region_id || !address) {
      

      if (!district_id && !province_id && !region_id && !address) {

      }else{

       // toast.warn('Para continuar se deben cargar todos los datos de dirección o mantener vacíos');

        if (!province_id) {
          toast.warn('La Provincia es requerido');
          return false;
        }

        if (!district_id) {
          toast.warn('El distrito es requerido');
          return false;
        }

        if (!address) {
          toast.warn('La dirección es requerida');
          return false;
        }

        let direccion = new RegExp('[0-9]');
        if (!direccion.test(address)) {
          toast.warn('La dirección debe contener un número de ubicación');
          return false;
        }

        

        // return false;
      }


     
    }
  }







     
  
    let capitalize = new RegExp('[A-Z]');
    if (!capitalize.test(password)) {
      toast.warn(
        'La contraseña debe incluir al menos un carácter en mayúscula'
      );
      return false;
    }

    let number = new RegExp('[0-9]');
    if (!number.test(password)) {
      toast.warn('La contraseña debe incluir al menos un número');
      return false;
    }

    if (password !== password_confirmation) {
      toast.warn('Las contraseñas deben coincidir');
      return false;
    }

    return true;
  };

  rucLabel = () => {
    switch (this.state.document_type) {

      case "1":
        return "Número de Documento";
      case "2":
        return "Número de Documento";
      case "3":
        return "Número de Documento";
      default:
        return "Número de Documento"

    }
  };

  render() {
    const { title, className, style } = this.props;
    const {
      user_name,
      name,
      last_name,
      email,
      birthdate,
      ruc,
      document_type,
      phone,
      reference,
      address,
      sex,
      type_address,
      password,
      password_confirmation,
      urbanizacion,
      region_id,
    } = this.state;
    return (
      <div className={className} style={style}>
        <h3 className="font-sand mb-3"> {title || 'Datos del representante'} </h3>

        {/* USER NAME, NAME, LAST_NAME, EMAIL */}
        <div className="row mb-4">
          {/* USER NAME */}
          <Nick
            ref={(it) => (this.nick = it)}
            name="user_name"
            className="col-lg-4"
            label="Nombre de usuario"
            required={true}
            value={user_name}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* NAME */}
          <Input
            name="name"
            className="col-lg-4"
            required={true}
            label="Nombre"
            value={name}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* LAST NAME */}
          <Input
            name="last_name"
            className="col-lg-4"
            label="Apellido"
            value={last_name}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* EMAIL */}
          <EmailInput
            name="email"
            className="col-lg-4"
            label="Correo electrónico"
            required={true}
            value={email}
            onChange={this.handleChangeInput}
            rol={this.props.rol}
            style={{ height: 60 }}
            inputClassName="font-sand form-control input_custom_40 font-weight-500"
          />
          {/* BIRTHDAY, ruc, PHONE, GENDER */}
          {/* BIRTHDAY */}
          <Input
            name="birthdate"
            className="col-lg-4"
            required={true}
            type="date"
            label="Fecha de nacimiento"
            value={birthdate}
            onChange={this.handleChangeInput}
            max={minDate}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />  
           {/* GENDER */}
          <Options
            name="sex"
            className="col-lg-4"
            label="Sexo"
            required={true}
            value={sex}
            options={genderOptions}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
          {/* DOCUMENT_TYPE */}
          <Options
            name="document_type"
            className="col-lg-4"
            label="Tipo de documento"
            value={document_type}
            options={documentTypeOptions}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
          {/* <InputSelect
            name="document_type"
            label="Tipo de documento"
            className="col-lg-4"
            value={document_type}
            options={documentTypeOptions}
            required={true}
            optionLabel={(it) => it.text}
            emptyMessage="No hay registros"
            onChange={this.handleChangeInput}
          /> */}

          {/* RUC */}
          <Input
            name="ruc"
            className="col-lg-4"
            label={this.rucLabel()}
            required={true}
            value={ruc}
            minLength="8"
            maxLength="11"
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* PHONE */}
          <Input
            name="phone"
            className="col-lg-4"
            required={true}
            type="tel"
            label="Teléfono"
            value={phone}
            minLength={7}
            maxLength={9}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

         
          {/* <InputSelect
            name="sex"
            label="Sexo"
            className="col-lg-4"
            value={sex}
            options={genderOptions}
            required={true}
            optionLabel={(it) => it.text}
            emptyMessage="No hay registros"
            onChange={this.handleChangeInput}
          /> */}
        </div>

        {/* DISTRICT_ID */}
        <div className="row mb-4">
          <DistrictInput regiona={this.state.region_id} districta={this.state.district_id} provincea={this.state.province_id} onDistrictChange={this.handleDistrictChange} onProvinceChange={this.handleProvinceChange} onRegionChange={this.handleRegionChange} addDivParent classNameParent="col-lg-4" />
        </div>

        {/* ADDRESS, REFERENCE */}
        <div className="row mb-4">

          <Options
            name="type_address"
            className="col-lg-2"
            label="Tipo"
          
            value={type_address}
            options={typeDirection}
            onChange={this.handleChangeSelectTypeD}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
          <TextArea
            name="address"
            className="col-lg-4 textArea"
            label="Dirección"
            
            value={address}
            onChange={this.handleChangeInput}
            style={{ minHeight: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
          <Input
              name="urbanizacion"
              className="col-lg-3 p-0"
              type="text"
              label="Urbanización"
              value={urbanizacion}
              minLength={3}
              maxLength={20}
              onChange={this.handleChangeInput}
              style={{ height: 60 }}
              inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            />
          {/* ADDRESS */}

          {/* REFERENCE */}
          <TextArea
            name="reference"
            className="col-lg-3"
            label="Referencia"
            value={reference}
            onChange={this.handleChangeInput}
            style={{ minHeight: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
        </div>

        {/* PASSWORD, PASSWORD CONFIRM */}
        <div className="row mb-4">
          {/* PASSWORD */}
          <Input
            name="password"
            className="col-lg-4"
            required={true}
            type="password"
            label="Contraseña"
            minLength="8"
            value={password}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/*  PASSWORD CONFIRM */}
          <Input
            name="password_confirmation"
            className="col-lg-4"
            required={true}
            type="password"
            label="Confirmar contraseña"
            minLength="8"
            value={password_confirmation}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
        </div>
      </div>
    );
  }
}

export default ClientSection;
