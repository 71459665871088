import {axios} from "../axios";

const END_POINT = 'nick/';

class NickDao {
  
  async nickExist(nick) {
    const r = await axios.get(`${END_POINT}${nick}`)
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    return r.data
  }
  
}

const nickDao = new NickDao()

export default nickDao
