import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import {UserService} from '../../../services'
import {connect} from 'react-redux'
import { toast } from 'react-toastify';
import {STATUS_SELLER_PENDING_TO_ACCEPT, STATUS_COMISSIONIST_PENDING_TO_ACEEPT, APP_NAME} from '../../../utils'
import {Datepicker, File, Textarea} from '../../../widgets'
import moment from 'moment'
class Seller extends Component {
    state = {
        form: {
            email: '',
            password: '',
            address: '',
            birthday: moment().toDate(),
            terms: '',
            commissionist: '',
            user_id: this.props.user && this.props.user.user && this.props.user.user.id || null,
            image: null
        }
    }

    componentDidMount(){
        if(!this.props.user)
            this.props.history.push('/')
    }

    submit = async e => {
        e.preventDefault()
        try{
            const form = {
                ... this.state.form,
                birthday: moment(this.state.form.birthday).format('YYYY-MM-DD')
            }
            console.log('>>: form > ', form)
            const isToComs = !!this.state.form.commissionist
            const res = await UserService[isToComs ? 'beComs' : 'beSeller'](form)
            toast.success(res.msg)
            this.props.dispatch({
                type: 'SET_USER',
                payload: {
                    ... this.props.user,
                    user: {
                        ... this.props.user.user,
                        status: isToComs ? STATUS_COMISSIONIST_PENDING_TO_ACEEPT :  STATUS_SELLER_PENDING_TO_ACCEPT
                    }
                }
            });
            this.props.history.push('/paginas/tablero')
        }catch(err){
            console.log('>>: error > ', err)
            toast.error(e.response.data.msg)
        }
    }
    
    onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        this.setState(() => {
            return {
                ...this.state,
                form:{
                    ... this.state.form,
                    [name]: value,
                },
            };
        });
    }

    onChangeCheckBox = e => {
        const name = e.target.name
        const value = e.target.checked
        this.setState(() => {
            return {
                ...this.state,
                form:{
                    ... this.state.form,
                    [name]: value,
                },
            };
        });
    }

    render (){
        return (
            <div>
                <Breadcrumb
                    title={'Quiero ser un vendedor'}
                    parent="forms"
                    url="seller"
                />
                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.submit}>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 text-dark">
                                                <Datepicker
                                                    color="dark"
                                                    label="Fecha de nacimiento"
                                                    name="birthday"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode= "scroll"
                                                    // minDate={this.state.dates.minDate}
                                                    value={ this.state.form.birthday }
                                                    onChange={ birthday => {
                                                            this.setState({
                                                                form: {
                                                                    ... this.state.form,
                                                                    birthday
                                                                }
                                                            });
                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <File
                                                    placeholder={"Agregar DNI"}
                                                    placeholderSuccess="DNI Agregado"
                                                    showCheck={ true }
                                                    onChange={ this.onChange }
                                                    name="image"
                                                    value={ this.state.form.image }
                                                    inputStyle={{
                                                        display: 'contents'
                                                    }}
                                                    className="btn-product"
                                                />
                                            </div>
                                        </div>
                                        <Textarea
                                            label="Direccion"
                                            onChange={this.onChange}
                                            value={this.state.form.address}
                                            name="address"
                                        />
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                value={this.state.form.terms}
                                                name="terms"
                                                onChange={this.onChangeCheckBox}
                                                style={{marginTop: '.1rem'}}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleCheck1"
                                            >
                                                Acepto los terminos y condiciones de uso en {APP_NAME}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck2"
                                                name="commissionist"
                                                onChange={this.onChangeCheckBox}
                                                value={this.state.form.commissionist}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleCheck2"
                                                style={{marginTop: '.3rem'}}
                                            >
                                                Quiero ser un comisionista en {APP_NAME}
                                            </label>
                                        </div>
                                        <div style={{marginTop: '2rem'}}>
                                            <button
                                                type="submit"
                                                className="btn btn-solid"
                                            >
                                                Enviar Solicitud 
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(
    mapStateToProps
)(Seller);