import { axios } from "../axios";

const END_POINT = "promotions";

class PromotionDao {
	async get(userID, code, products) {
		try {
			const r = await axios.post(`${END_POINT}/get-promotion`, {
				userID,
				code,
				products, 
			});
			return r.data;
		} catch (e) {
			if (e.response) throw Error(e.response.data.error);
			throw Error("Disculpe ha ocurrido un error");
		}
	}
}

const promotionDao = new PromotionDao();

export default promotionDao;
