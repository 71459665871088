import React, {Component} from 'react';
import {connect} from "react-redux";
// import Custom Components
import MultiSliderItem from "./multi-slider-item"
class MultipleSlider extends Component {
    render (){
        return (
            <div>
                <section className="">
                    <div className="container">
                        <div className="row multiple-slider">
                            {
                                this.props.clasifications.map((element, index) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6" key={index}>
                                            <div className="theme-card">
                                                <h5 className="title-border">{element.name}</h5>
                                                <MultiSliderItem
                                                    items={[...element.products.map(_element => _element.product)]}
                                                    NoOfProducts={3}
                                                    symbol={this.props.symbol}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps
)(MultipleSlider)