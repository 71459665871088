import React from 'react';

class Select extends React.Component {

	render() {
		return (
			<div className="form-group">
				{ this.props.label && <label htmlFor={ this.props.name }>{ this.props.label }</label> }
				<select { ...this.props } className={ `form-control ${ this.props.color ? this.props.color : '' }` }>
					<option value="" disabled>Seleccione</option>
					{ this.props.options.map((i,index) => {
						return (
							<option 
								key={ index } 
								value={ i.value }>
								{ i.label }
							</option>
						)				
					}) }
				</select>
			</div>
		)
	}
}

export default Select;