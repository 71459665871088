import React, {Component} from "react";
import Required from "./Required";
import {nickDao} from "../../services";
import "./form.scss"

class Nick extends Component {
  
  state = {
    value: "",
    loading: false,
    valid: false,
    error: ""
  }

  componentDidMount() {
    const {value} = this.props;
    if (!value) return
    this.setValue(value).then()
  }
  
  setValue = async value => {
    this.setState({
      value,
      valid: false,
      loading: true,
      error: ""
    })
    try {
      const {exist} = await nickDao.nickExist(value)
      const valid = !exist
      this.setState({valid})
      if (!this.props.onChange) return
      this.props.onChange(value, this.props.name)
    } catch (e) {
      this.setState({error: "No se pudo verificar su nombre de usuario"})
    } finally {
      this.setState({loading: false})
    }
  }
  
  handleChange = async evt => await this.setValue(evt.target.value)
  
  isValid = () => this.state.valid
  
  render() {
    const { id=(10000*Math.random()).toFixed(0),
      label="",
      name,
      required=false,
      className,
      placeholder,
      style = {},
      inputClassName="form-control"} = this.props
    return (
      <div className={className}>
        <label htmlFor={name || id} className="form-label">{label} <Required required={required}/> </label>
        
        <div
          style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center !important',
          }} >
          
          <input
            id={name || id}
            className={inputClassName}
            type="text"
            name={name}
            required={required}
            placeholder={placeholder || label}
            value={this.state.value}
            style={style}
            onChange={this.handleChange}/>
  
          {this.state.loading && <div className="spinner ml-2"/>}
          
          <div className="align-self-center">
          {this.state.valid && <i className="ml-2 fa fa-check text-success" />}
          </div>
          
        </div>

        {(!this.state.valid && !!this.state.value.length) &&
          <div id={name || id}
               className="form-text font-sand"
               style={{fontWeight: "normal" , fontSize: "x-small"}} >
            El nombre de usuario {this.state.value} ya esta en uso
          </div>
        }
  
        {this.state.error &&
          <div id={name || id}
               className="form-text font-sand"
               style={{fontWeight: "normal" , fontSize: "x-small", color: "red"}} >
            {this.state.error}
          </div>
        }
        
      </div>
    )
  }
  
}

export default Nick;
