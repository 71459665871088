import React, { useState } from "react";
import { promotionDao } from "../../services";

const maxHeightStyle = {
	height: "40px",
	maxHeight: "40px",
};

const PromotionCode = ({currentPayment, promotion, onFetchCode, products,total }) => {
	const user = !!localStorage.getItem("state")
		? JSON.parse(localStorage.getItem("state"))
		: "";
	const userID = user?.user?.user?.id;
	const [code, setCode] = useState("");

	const [exitoso, setExitoso] = useState(false);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const handleChangeCode = (e) => {
		setError("");
		setExitoso(false); 
		setCode(e.target.value); 

	};

	const enableButton = () => !loading && !!code;

	const clearError = () => setError("");

	const clearExitoso = () => setExitoso(false);

	

	const search = async () => {
		setLoading(true);
		try {
			const { promotion, reseller = null } = await promotionDao.get(
				userID,
				code,
				products.map((it) => it.id)
			);

			const scope = promotion.scope;

			const sellers = scope
				.filter((it) => !!it.seller_id)
				.map((it) => it.seller_id);

			const categories = scope
				.filter((it) => !!it.category_id)
				.map((it) => it.category_id);

			const subcategories = scope
				.filter((it) => !!it.subcategory_id)
				.map((it) => it.subcategory_id);

			const _products = scope
				.filter((it) => !!it.product_id)
				.map((it) => it.product_id);

			promotion.scope = {
				sellers,
				categories,
				subcategories,
				products: _products,
			};


				if(promotion.id==9)
				{
					setError('El código seleccionado no existe');
					setExitoso(false);
					if (onFetchCode) onFetchCode({ promotion, reseller:null });

				}else{

		
					if(currentPayment==0)
					{
						setError("por favor debe seleccionar un metodo de pago");
						setExitoso(false);
						if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
							sellers: [],
							categories: [],
							subcategories: [],
							products: [],
						}}, reseller:null });
						
					}else{
		
						


						if(promotion.min_discount_amount!=null)
						{
							if(parseFloat(promotion.min_discount_amount)>=total)
							{
								setError("error debe realizar una compra por un monto minimo de : S./:"+promotion.min_discount_amount);
								setExitoso(false);
								if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
									sellers: [],
									categories: [],
									subcategories: [],
									products: [],
								}}, reseller:null });
							}else{
								if((currentPayment==1 && promotion.paymentmethodoffer.transferencia==true)==true)
								{
									if (onFetchCode) onFetchCode({ promotion, reseller });
									setError("");
									setExitoso(true);
								}
								if((currentPayment==1 && promotion.paymentmethodoffer.transferencia==false)==true)
								{
									setError("Este código no existe o no aplica con el método de pago seleccionado ");
									setExitoso(false);
									if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
										sellers: [],
										categories: [],
										subcategories: [],
										products: [],
									}}, reseller:null });
								}
		
								if((currentPayment==2 && promotion.paymentmethodoffer.transferencia2==true)==true)
								{
									if (onFetchCode) onFetchCode({ promotion, reseller });
									setError("");
									setExitoso(true);
								}


								if((currentPayment==2 && promotion.paymentmethodoffer.transferencia2==false)==true)
								{
									setError("Este código no existe o no aplica con el método de pago seleccionado ");
									setExitoso(false);
									if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
										sellers: [],
										categories: [],
										subcategories: [],
										products: [],
									}}, reseller:null });
								}
		
								if((currentPayment==3 && promotion.paymentmethodoffer.paypal==true)==true)
								{
									if (onFetchCode) onFetchCode({ promotion, reseller });
									setError("");
									setExitoso(true);
								}
		
								if((currentPayment==3 && promotion.paymentmethodoffer.paypal==false)==true)
								{

									setError("Este código no existe o no aplica con el método de pago seleccionado ");
									setExitoso(false);
									if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
										sellers: [],
										categories: [],
										subcategories: [],
										products: [],
									}}, reseller:null });
								}


								if((currentPayment==4 && promotion.paymentmethodoffer.niubiz==true)==true)
								{
									if (onFetchCode) onFetchCode({ promotion, reseller });
									setError("");
									setExitoso(true);
								}
		

								if((currentPayment==4 && promotion.paymentmethodoffer.niubiz==false)==true)
								{

									setError("Este código no existe o no aplica con el método de pago seleccionado ");
									setExitoso(false);
									if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
										sellers: [],
										categories: [],
										subcategories: [],
										products: [],
									}}, reseller:null });
								}


								if((currentPayment==5 && promotion.paymentmethodoffer.pagoEfectivo==true)==true)
								{
									if (onFetchCode) onFetchCode({ promotion, reseller });
									setError("");
									setExitoso(true);
								}

								if((currentPayment==5 && promotion.paymentmethodoffer.pagoEfectivo==false)==true)
								{

									setError("Este código no existe o no aplica con el método de pago seleccionado ");
									setExitoso(false);
									if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
										sellers: [],
										categories: [],
										subcategories: [],
										products: [],
									}}, reseller:null });
								}
							}
						}else{
							
						
						
						}
					
					
					}
			
				}
		


		} catch (e) {

			setError('El código seleccionado no existe2');
			setExitoso(false);
			if (onFetchCode) onFetchCode({ promotion, reseller:null });
			if (onFetchCode) onFetchCode({ promotion:{id:0 , code: "",min_discount_amount:"", promotion_codes: [], scope: {
				sellers: [],
				categories: [],
				subcategories: [],
				products: [],
			}}, reseller:null }); 

		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="mb-2 h-box align-items-center d-flex flex-column flex-lg-row">
				<div className="expand number-label font-sand mb-2">Cupón de descuento</div>

				<input
					className="expand mr-2 text-right font-sand input_custom_40 font-weight-500 mb-2"
					style={maxHeightStyle}
					type="text"
					name="promotion_code"
					onChange={handleChangeCode}
					value={code}
				/>

				<button
					type="button"
					style={maxHeightStyle}
					className="pt-0 pb-0 btn-solid btn btn-small m-0 font-sand mb-2"
					onClick={search}
					disabled={!enableButton()}>
					{loading ? "Buscando" : "Buscar"}
				</button>
			</div>

			{!!error && (
				<div
					className="h-box align-items-center alert alert-danger alert-dismissible fade show pr-2 font-sand"
					role="alert">
					<span className="expand">{error}</span>
					<i className="fa fa-close selected" onClick={clearError} />
				</div>
			)}

			{!!exitoso && (
				<div
					className="h-box align-items-center alert alert-success alert-dismissible fade show pr-2 font-sand"
					role="alert">
					<span className="expand">La promoción cargada es {code}</span>
					<i className="fa fa-close selected" onClick={clearExitoso} />
				</div>
			)}

			<hr />
		</>
	);
};

export default PromotionCode;
