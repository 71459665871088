import {axios} from '../axios';

const END_POINT = 'regions';
const get = async params => {
    try{
        const res = await axios.get(`${END_POINT}`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    get
}