import * as types from '../constants/ActionTypes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { postApi } from '../services/postApi';
import { WISH_LIST, WISH_LIST_DELETE } from '../services/endpoints';

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});
export const Showmessage = (products) =>  {
  toast.success(products);

};

export const ShowmessageError = (products) =>  {
  toast.error(products);
 
};



export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
};

export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => (dispatch) => {
  toast.success('Producto agregado al carrito');
  dispatch(addToCartUnsafe(product, qty));
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success('Producto agregado al carrito');
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty) => {
  console.log('>>: addToCartUnsafe.product: ', product);
  return {
    type: types.ADD_TO_CART,
    product,
    qty,
  };
};
export const removeFromCart = (product_id) => (dispatch) => {
  toast.error('Producto removido del carrito');
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};

export const removeCart = () => (dispatch) => {
  //console.log('removeCart');
  dispatch({
    type: types.REMOVE_CART,
    product: [],
  });
};
export const incrementQty = (product, qty) => (dispatch) => {

console.log(product, qty);
if (product.qty < product.stock) {
  dispatch(addToCartUnsafe(product, qty));
}

  // toast.success("Producto agregado al carrito");
  
};
export const decrementQty = (productId) => (dispatch) => {
  // toast.warn("Item Decrement Qty to Cart");
  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

const user = !!localStorage.getItem('state')
  ? JSON.parse(localStorage.getItem('state'))
  : '';
const userID = user?.user?.user?.id;
const userToken = !!user?.user?.access_token ? user?.user?.access_token : '';
//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  const dataForm = {
    user_id: userID,
    product_id: product?.id,
  };
  if (!!product) {
    postApi(WISH_LIST, dataForm, userToken)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.success('Producto agregado a favoritos');
          dispatch(addToWishlistUnsafe(product));
        }
      })
      .catch((error) => {
        toast.success('El producto no fue agregado a favoritos');
        if (error.response) {
          console.log('Error');
        }
      });
  }
};
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
  const dataForm = {
    product_id: product_id?.id,
  };
  if (!!product_id) {
    postApi(WISH_LIST_DELETE, dataForm, userToken)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.error('Producto removido de favoritos');
          dispatch({
            type: types.REMOVE_FROM_WISHLIST,
            product_id,
          });
        }
      })
      .catch((error) => {
        toast.success('El producto no fue removido de favoritos');
        if (error.response) {
          console.log('Error');
        }
      });
  }
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  // toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

// Checkout
export const addToPriceCheckout = (total) => (dispatch) => {
  dispatch({
    type: types.ADD_CHECKOUT_TOTAL,
    total,
  });
};
