import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROLE_SELLER, Globals } from '../../utils';
import { emit } from 'jetemit';
import DashboardRight from './dashboard_tabs/dashboard_data';
import Purchases from './dashboard_tabs/purchases';
import { UserService } from '../../services';
import ReactStars from 'react-rating-stars-component';
class Dashboard extends Component {
  constructor(props) {
    super(props);
  } 

  tabs = {
    dashboard: 1,
    purchases: 2,
  };
  state = {
    tabs: this.tabs,
    tab: this.tabs.dashboard,
    purchases: [],
    products: [],
    sellers: [],
    showNav: false
  };
  componentDidMount() {
    this.load();
  }

  load = async () => {
    try {
      const purchases = await UserService.purchases();
      let products = [];
      purchases.forEach((element) => {
        products = [...products, ...element.details];
      });
      let sellers = [];
      purchases.forEach((element) => {
        element.details.forEach((_element) => {
          const exist = sellers.find(
            (_element_) => _element_.id === _element.seller.id
          );
          if (!exist) {
            sellers.push({
              ..._element.seller,
              qualified: !!!_element.qualify_id,
              detail_id: _element.id,
              payment_id: element.id,
            });
          }
        });
      });
      this.setState({
        purchases,
        products,
        sellers,
      });
    } catch (error) {
      console.log('>>: error > ', error);
    }
  };
  changeTab = (tab) => {
    this.setState({
      tab,
    });
  };
  getRightComponent = () => {
    let component = null;
    switch (this.state.tab) {
      case this.state.tabs.dashboard:
        component = <DashboardRight user={this.props.user} />;
        break;
      case this.state.tabs.purchases:
        component = (
          <Purchases
            purchases={this.state.purchases}
            products={this.state.products}
            user={this.props.user}
          />
        );
        break;
      default:
        component = <DashboardRight user={this.props.user} />;
        break;
    }
    return component;
  };

  changeRating = (element, i, rating) => {
    let sellers = [...this.state.sellers];
    sellers[i] = {
      ...sellers[i],
      rating,
      newRating: rating,
      changed: true,
    };
    this.setState({
      sellers,
    });
  };

  qualifyPurchase = async (element) => {
    try {
      const res = await UserService.qualifyPurchase(element);
      Globals.showSuccess(res.msg);
      this.load();
    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  };



  render() {
    console.log('que soy'+this.props?.user?.role_id);
    return (
      <div>
        <Breadcrumb title={'Dashboard'} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="d-block d-lg-none mb-4">
                  <div className="btn btn-solid" onClick={_ => this.setState({ showNav: !this.state.showNav })}>
                    {!this.state.showNav ? 'Mi cuenta' : <>
                      <span className="filter-back font-sand">
                        <i className="fa fa-angle-left" aria-hidden="true"></i>{' '}
                        volver
                      </span>
                    </>}
                  </div>
                </div>
                <div
                  className={'dashboard-lef2 scroll_custom w-100 mb-4 ' + (this.state.showNav ? 'open-l2' : '')}
                  style={{
                    maxHeight: '31rem',
                    overflowY: 'auto',
                  }}
                >
                  {/* <div className="collection-mobile-back" style={{ border: 0 }}>
                    <span className="filter-back font-sand">
                      <i className="fa fa-angle-left" aria-hidden="true"></i>{' '}
                      volver
                    </span>
                  </div> */}
                  <div className="block-content border-shadow-custom mb-4">
                    <ul>
                      <li className="active mb-3">
                        <a className="font-sand cur-pointer" onClick={() => this.changeTab(this.tabs.dashboard)} style={{
                          color: 'inherit',
                        }}>
                          Información de cuenta
                        </a>
                      </li>
                      {this.props?.user?.formularioventa !== 1 && ( // esta debe validar que no sea vendedor
                        <li className="mb-3">
                          <Link
                            to="/registrarse"
                            style={{
                              color: 'inherit',
                            }} className="font-sand"
                          >
                            ¿Quiere ser un vendedor?
                          </Link>
                        </li>
                      )}
                      <li className="mb-3">
                        <a className="font-sand cur-pointer" onClick={() => this.changeTab(this.tabs.purchases)}>
                          Historial de compras
                        </a>
                      </li>
                      <li className="last font-sand">
                        <a onClick={() => emit('logout')} className=" cur-pointer">Cerrar sesión</a>
                      </li>
                    </ul>
                  </div>
                  {this.state.sellers.map((element, i) => {
                    return (
                      <div className="card border-shadow-custom mb-4" key={i}>
                        <div className="card-body">
                          <Link to={'/catalogo/' + element.user_name}>
                            <h5 className="card-title m-0 font-sand">{element.name}</h5>
                          </Link>
                          <div className="p-1">
                            <ReactStars
                              value={element.rating}
                              edit={element.qualified}
                              isHalf={true}
                              onChange={(rating) =>
                                this.changeRating(element, i, rating)
                              }
                            />
                          </div>
                          {element.changed && (
                            <React.Fragment>
                              <div class="form-group">
                                <input
                                  value={element.qualification_text}
                                  onChange={(e) => {
                                    let { sellers } = this.state;
                                    const value = e.target.value;
                                    sellers[i].qualification_text = value;
                                    this.setState({
                                      sellers,
                                    });
                                  }}
                                  type="email"
                                  className="form-control font-sand input_custom_40"
                                  style={{ height: 60 }}
                                  placeholder="Mensaje"
                                />
                              </div>
                              <button
                                className="btn btn-solid"
                                onClick={() => this.qualifyPurchase(element)}
                                disabled={!element.changed}
                              >
                                Calificar
                              </button>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-9">{this.getRightComponent()}</div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    user: (state.user && state.user.user) || null,
  };
})(Dashboard);
