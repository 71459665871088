import io from 'socket.io-client';
const url = process.env.REACT_APP_SOCKET
const Socket = io(url,{
    reconnection: true,
    reconnectionDelay: 25000,
    reconnectionDelayMax: 25000,
    reconnectionAttempts: Infinity,
    forceNew: false 
});

export default Socket;