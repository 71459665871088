import React from 'react';
import Required from './Required';

const InputFile = ({
  id = (10000 * Math.random()).toFixed(0),
  label = '',
  name,
  required = false,
  value,
  onChange,
  className,
  accept,
  placeholder,
  inputClassName = 'form-control',
  maxSize,
  onMaxSize,
  onBase64,
}) => {
  const handleChange = async (evt) => {
    if (!onChange) return;
    const file = evt.target.files[0];
    if (!file) return;

    if (maxSize && file.size > maxSize) {
      if (onMaxSize) onMaxSize();
      return;
    }

    onChange(file, evt.target.name);
    if (onBase64) {
      const base64 = await toBase64(file);
      onBase64(base64);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  
  return (
    <div className={className}>
      
      <input
        id={name || id}
        className={inputClassName}
        type="file"
        accept={accept}
        name={name}
        required={required}
        placeholder={placeholder || label}
        onChange={handleChange}
        // value={!!value ? value : null}
        // eslint-disable-next-line react/style-prop-object
        style={{ display: 'none' }}
      />
      <div className="row mb-0 mt-2">
        <div className="col-12 d-flex">
          <label for={name || id} className="btn btn-solid font-sand" style={{ height: 50 }}>
            {placeholder || label}
          </label>
        </div>
      </div>
    </div>
  );
};

export default InputFile;
