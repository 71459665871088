import React, {useState} from "react";
import {getSticker} from "./Custom";

const StickerCell = ({view, print, onClick, dimension, product}) => {
  if (!print) return null;
  
  let height = 0;
  let [element, setElement] = useState(null)
  
  if (!!element && !height) height = element.clientWidth
  
  const handleClick = async evt => {
    if (!onClick) return
    const url = evt.target.src;
    const sticker = await getSticker(url, product, view, dimension)
    onClick({...sticker, id: print.id})
  }
  
  return <div
    ref={ it => {
      if (element) return
      element = it
      setElement(element)
    }}
     className="p-2 product_polos_image"
     style={{
      height: 170,
      width: 140,
      minWidth: '16%'
    }}>
    
    <img
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        width: '80%',
        height: '100%',
        borderRadius: 10,
        border:'2px solid #093467'
      }}
      src={print.url}
      alt={print.name}/>
  
  </div>
  
}

export default StickerCell
