import React from "react";
import {CirclePicker} from "react-color";

const ColorControl = ({product, color, onChangeColor, variants}) => {
  if (!product || !Array.isArray(variants)) return null;
  
  let _variants = variants
    .filter( it => !!it.stock)
    .flatMap( it => it.attributes )
    .filter( it => !!it.attribute.color )
    .map( it => it.value )
  _variants = [...new Set(_variants)]
  
  const colors = product.category.attributes
    .find( it => !!it.color ).values
    .filter( value => _variants.some( variant => variant===value.value ) )
    .map( it => ({name: it.value, value: it.payload}))
  
  const handleChangeColor = hex => {
    if (!onChangeColor) return
    const color = colors.find( it => it.value===hex )
    onChangeColor(color)
  }
  
  if (!color && Array.isArray(colors)) {
    handleChangeColor(colors[0].value)
    return null
  }
  
  return <div className="v-box">
    <span className="mt-6 mb-2 font-sand">
      Seleccione un color
    </span>
    <CirclePicker
      className="color-picker-polos mt-12 mb-12"
      color={color.value}
      colors={colors.map( it => it.value )}
      onChange={ evt => handleChangeColor(evt.hex) }/>
  </div>
  
}

export default ColorControl
