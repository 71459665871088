import { axios } from '../axios';
const END_POINT = 'activities';

const getListActivities = async (userId) => {
  try {
    const res = await axios.get(`${END_POINT}`);
    const map = res.data.map((item) => {
      return {
        value: item.id,
        text: item.name,
      };
    });
    const arr = [{ value: '', text: 'Seleccione' }, ...map];
    return arr;
  } catch (e) {
    throw e;
  }
};

export default { getListActivities };
