import React, {Component} from 'react';

class AttributeFilter extends Component {
  
  state = {
    value: null
  }
  
  constructor(props) {
    super(props);
    if (props.defaultValue) this.state.value = props.defaultValue
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue!=null && this.state.value==null) this.setState({value: this.props.defaultValue})
    if (prevProps.defaultValue!==this.props.defaultValue) this.setState({value: this.props.defaultValue})
  }
  
  handleOnPress = value => this.setState({value},() => {
    if (!!this.props.onChangeValue) this.props.onChangeValue(value)
  })
  
  variantsToValues = () => {
    const values = this.props.variants
      .flatMap(variant => variant.attributes)
      .filter(({attribute_id}) => attribute_id === this.props.attribute.id)
      .map( it => it.value_text ) || []
    return [...new Set(values)]
  }
  
  existForAttributeFocus = value => {
    const {attributeFocus, variants, attribute} = this.props;
    if (!attributeFocus) return false;
    if (attributeFocus.id===attribute.id) return true;
    return variants
      .filter( variant => {
        return variant.attributes.some( att => (att.attribute_id===attributeFocus.id && att.value_text===attributeFocus.value) )
      })
      .flatMap( it => it.attributes )
      .some( it => it.value_text===value )
  }
  
  isSelected = value => value===this.state.value
  
  render() {
    return (
      <div style={{
        margin: 0,
        padding: 0
      }}>
        <h4 style={{
          marginTop: '12px',
          marginLeft: '8px'
        }}>{this.props.attribute.name}</h4>
        <hr/>
        <div
          className="row"
          style={{
            width: '100%',
            margin: 0,
            padding: 0
          }}>
          { this.variantsToValues().map( value =>
            <div
              key={value}
              onClick={ () => {
                if (this.existForAttributeFocus(value)) this.handleOnPress(value)
              } }
              style={{
                margin: '4px',
                border: '1px solid darkblue',
                color: this.existForAttributeFocus(value)
                        ? this.isSelected(value) ? 'white' : 'darkblue'
                        : 'black',
                backgroundColor: this.existForAttributeFocus(value)
                  ? (this.isSelected(value)) ? 'darkblue' : 'white'
                  : 'gray',
                padding: '4px',
              }}
              className="col-2 text-center">
              {value}
            </div>
          )}
        </div>
      </div>
    )
  }
  
}

export default AttributeFilter;
