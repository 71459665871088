import React, { Component } from 'react';
import { Link } from 'react-router-dom'

const CartHeader = ({ item, total, symbol, removeFromCart }) => (
    <li >
        <div className="media">
            <Link to={`/detalle/productos/${item.id}`}>
                {
                    item && item.images && item.images[0] && (
                        <img alt="" className="mr-3" src={`${item.images[0].uri ? item.images[0].uri : item.images[0].url}`} style={{ width: 70, height: 'auto' }} />
                    )
                }
            </Link>
            <div className="ml-4 media-body">
                <Link to={`/detalle/productos/${item.id}`}>
                    <h4 className="font-weight-bold text-primary">{item.name}</h4>
                </Link>
                <h4>
                    <span>{item.qty} x {symbol} {(item.price)}</span>
                </h4>
            </div> 
        </div>
        {/*<span>{cart}</span>*/}
        <div className="close-circle">
            <a href={null} onClick={removeFromCart}><i className="fa fa-times" aria-hidden="true"></i></a>
        </div>
    </li>
)



export default CartHeader;
