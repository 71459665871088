import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import '../../common/index.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Import custom components
import TopCollection from '../common/collection';
import HeaderOne from '../../common/headers/header-one';
import FooterOne from '../../common/footers/footer-one';
import Instagram from '../common/instagram';
import LogoBlock from '../common/logo-block';
import MultiSlider from './multiple-slider';
import {
  SubcategoryService, 
  ClasificationService,
  SectionService,
} from '../../../services';
import { SliderHome } from '../../SliderHome';
import { Globals } from '../../../utils';
import Banners from './types/Banners';
import Parallax from './types/Parallax';
import OffersService from '../../../services/modules/OffersService';
import RecomendadosService from '../../../services/modules/RecomendadosService';
import MasvendidosService from '../../../services/modules/MasvendidosService';
import NuevosService from '../../../services/modules/NuevosService';
import RandomService from '../../../services/modules/RandomService';
import DamasService from '../../../services/modules/DamasService';
import ModalChat from '../../ModalChat';

import Slider from 'react-slick';
class Kids extends Component { 
  types = {
    categories: 1,
    slider: 2,
    parallax: 3,
    banners: 4,
    info_banner: 5,
    offers: 6,
  };
  state = {
    sections: [],
    subcategories: [],
    clasifications: [],
    offers: [],
    objOffers: [],
    recomendados: [],
    objrecomendados: [],
    masvendidos: [],
    objmasvendidos: [],
    nuevos: [],
    objnuevos: [],
    random: [],
    objrandom: [],
    damas: [],
    objdamas: [],
    primero: 0,
    sliderGenerated: false,
  };
  componentDidMount() {
    document
      .getElementById('color')
      .setAttribute('href', `/assets/css/color10.css`);
    this.load();

  }
 
  load = async () => {
    Globals.setLoading();
    try {
      const objSections = await SectionService.get();
      const objOff = await OffersService.get(); // consulta para ofertas
      const objrecom = await RecomendadosService.get(); // consulta para ofertas
      const objmasv = await MasvendidosService.get(); // consulta para ofertas
      const objnue = await NuevosService.get(); // consulta para ofertas
      const objran = await RandomService.get(); // consulta para ofertas
      const objdam = await DamasService.get(); // consulta para ofertas
      const filter = objSections.filter((item) => item.name !== 'sliders');
     // const objRecomendados = await OffersService.get(); // Consulta para recomendados

      /*
        Rotar en aleatorio a los ultimos 30 productos para recomendados 
      */

      /*
        Crear categorias nuevos : productos que se hayan creado hace menos 
        de dos semanas por proveedores unidos hace menos de un mes
      */

      /*
        Productos mas vendidos
      */

      // en las categorias del landing no pueden caer productos de categoria especial

      //console.log(filter)

      // aqui nosotros creamos manualmente la sección de ofertas 
      this.setState({
        objOffers: {
          id: 17,
          type: 1,
          name: 'OFERTAS',
          order: 2,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'OFERTAS',
                can_go_home: 0,
                order: 2,
                status: 1,
                default_products: objOff,
              },
            },
          ],
        },
      });

       this.setState({
        objrecomendados: {
          id: 18,
          type: 1,
          name: 'RECOMENDADOS',
          order: 1,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'RECOMENDADOS',
                can_go_home: 0,
                order: 1,
                status: 1,
                default_products: objrecom,
              },
            },
          ],
        },
      });


      this.setState({
        objmasvendidos: {
          id: 18,
          type: 1,
          name: 'MÁS VENDIDOS',
          order: 3,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'MÁS VENDIDOS',
                can_go_home: 0,
                order: 3,
                status: 1,
                default_products: objmasv,
              },
            },
          ],
        },
      });



      this.setState({
        objnuevos: {
          id: 18,
          type: 1,
          name: 'NUEVOS PROVEEDORES',
          order: 4,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'NUEVOS PROVEEDORES',
                can_go_home: 0,
                order: 4,
                status: 1,
                default_products: objnue,
              },
            },
          ],
        },
      });

      this.setState({
        objdamas: {
          id: 18,
          type: 1,
          name: 'DAMAS',
          order: 8,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'DAMAS',
                can_go_home: 0,
                order: 8,
                status: 1,
                default_products: objdam,
              },
            },
          ],
        },
      });

      this.setState({
        objrandom: {
          id: 18,
          type: 1,
          name: 'TAMBIÉN TE PUEDE INTERSAR',
          order: 10,
          status: 1,
          status_text: 'Activo', 
          type_text: 'Categorias',
          items: [
            {
              sectionable: {
                name: 'TAMBIÉN TE PUEDE INTERSAR',
                can_go_home: 0,
                order: 10,
                status: 1,
                default_products: objran,
              },
            },
          ],
        },
      });

      console.log('que son estos:' + objOrder);

      const objOrder = [
        {
          ...objSections[0],
        },
        {
          ...this.state.objOffers,
        },
        {
          ...objSections[1],
        },
        {
          ...this.state.objmasvendidos,
        },
        {
          ...this.state.objrecomendados,
        },
        {
          ...this.state.objnuevos,
        },
        {
          ...objSections[2],
        },
        {
          ...this.state.objdamas,
        },
        {
          ...objSections[3],
        },
        {
          ...this.state.objrandom,
        },
       // ...filter,
      ];
      const sections = objOrder;
      //console.log(sections);
      this.setState({
        sections,
        // subcategories,
        // clasifications
      });
    } catch (e) {
      console.log('>>: error > e', e);
    }
    Globals.quitLoading();
  };
  getComponentBySection = (section) => {
    
    console.log(section);
    switch (section.type) {
      case this.types.slider:
        const sliders = section.items.map((_element) => _element.sectionable);
        if (!this.state.sliderGenerated) {
      //  console.log('sliders', sliders)
        // Establece el estado para evitar futuras generaciones
          return <SliderHome sliders={sliders} />;
          
        }
        break;
      case this.types.banners:
        const banners = section.items.map((_element) => _element.sectionable);
        return <Banners banners={banners} />;
        break;
      case this.types.parallax:
        const parallax = section.items.map(
          (_element) => _element.sectionable
        )[0];
        return <Parallax image={parallax.uri} />;
        break;
      case this.types.categories:
        const items = section.items.map((element, i) => {
          return (
            <TopCollection
              key={i}
              products={element.sectionable?.default_products}
              name={element.sectionable?.name}
            />
          );
        });
        return items;
        break;
      case this.types.offers:
        return console.log('offers');
      default:
        return null;
      // return <TopCollection
      //     // key={index}
      //     products={element.products}
      //     name={element.name}
      // />
    }
  };
  render() {
    return (
      <div>
        <HeaderOne logoName={'logo/logo-market.png'} />
        {this.state.sections.map((element, i) => {
          return this.getComponentBySection(element);
        })}
        {/*Product Slider*/}
        <MultiSlider type={'kids'} clasifications={this.state.clasifications} />
        {/*Product Slider End*/}

        {/*Instagram Section*/}
       {/* <Instagram />*/}
        {/*Instagram Section End*/}

      {/*  <div className="container">
          <div className="d-flex flex-column align-items-center flex-lg-row justify-content-between mt-4">
            {[
              {
                id: 1,
                uri: '/assets/images/logos/1.png',
              },
              {
                id: 2,
                uri: '/assets/images/logos/2.png',
              },
              {
                id: 3,
                uri: '/assets/images/logos/3.png',
              },
            ].map((vari, index) => <div key={vari.id || index}>
              <div className="mb-4">
                <img src={vari.uri} style={{ maxWidth: 200 }} />
              </div>
            </div>
            )}
          </div>
        </div>*/}

        {/* Logo Block Section*/}
        {/* Logo Block Section End*/}
        {/* Modal Chat */}
        <ModalChat />
        {/* Modal Chat End */}

        <FooterOne logoName={'logo/logo-market2.png'} />
        {/* <MessengerCustomerChat
          pageId="935324413282526"
          appId="518260914887313"
        /> */}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    categories: state.categories,
  };
})(Kids);
