import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { AuthService } from "../../services";
import Breadcrumb from "../common/breadcrumb";
import Switch from "../switch/Switch";
import ClientSection from "../register/ClientSection";
import BankSection from "../register/BankSection";
import { APP_NAME } from "../../utils";
import ResellerSection from "./ResellerSection";
import { Link } from "react-router-dom";

class RegisterReseller extends Component {

  state = {
    loading: false,
    client: null,
    reseller: null,
    bank: null,
    agree: false,
    adult: false,
    index: !this.props.user || this.props?.user?.user?.id == 5 ? 0 : 1,
    sectionLength: 3
  }

  setChecked = () => this.setState({ agree: true});

  handleChangeClient = client => this.setState({ client });

  handleChangeReseller = reseller => this.setState({ reseller });

  handleChangeBank = bank => this.setState({ bank });

  handleNextCard = () => this.setState({ index: this.state.index + 1 })

  handlePreviousCard = () => this.setState({ index: this.state.index - 1 })



  
  submit = async evt => {
    evt.preventDefault();

    const { user } = this.props;
    const { index, sectionLength, agree, client, reseller, bank } = this.state;

    // VALIDANDO LA SECCIÓN ACTUAL
    if (!this.section.isValid()) return;

    if (index !== (sectionLength - 1)) return this.handleNextCard()

    if (!agree) return toast.error('Debes aceptar los términos y condiciones');

    this.setState({ loading: true })
    try {
      const form = {
        user_id: user ? user.user.id : null,
        client,
        reseller,
        bank
      }
      const res = await AuthService.registerReseller(form)
      this.props.dispatch({
        type: 'SET_USER',
        payload: res
      })
      toast.success('Se registro su cuenta como comisionista')
      this.props.history.push('/paginas/tablero')
    } catch (e) {
      toast.error(e)
    } finally {
      this.setState({ loading: false })
    }

  }

  render() {
    const { user } = this.props;
    const { agree, index, sectionLength, loading, client, reseller, bank } = this.state;
    return (
      <>
        <Breadcrumb title='Registro de comisionista' />
        <section className="container">
          <form className="theme-form" onSubmit={this.submit}>

            <Switch index={index}>

              <ClientSection 
                ref={it => this.section = it}
                onChangeClient={this.handleChangeClient}
                value={client}
                rol="reseller" />

              <ResellerSection ref={it => this.section = it}
                onChange={this.handleChangeReseller}
                value={reseller} />

              <BankSection ref={it => this.section = it}
                formType="seller"
                onChange={this.handleChangeBank}
                value={bank} />

            </Switch>

            <div className="form-check col-12 mb-3 mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={agree}
                id="agree"
                checked={agree}
                onChange={() =>{window.open('https://comprafacil.com.pe/api/web/terms-pdf/3', '_blank');this.setState({ agree: !agree })} } />
               <label
                className="form-check-label font-sand ml-2 mr-2"
                htmlFor="agree"
              >
              <a
                  href="https://comprafacil.com.pe/api/web/terms-pdf/3"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={()=>this.setChecked()}
                >
                  Acepto los términos y condiciones de uso en {APP_NAME}
                </a>
              </label>
            </div>

            {/*<div className="form-check col-12 mb-3">*/}
            {/*  <input*/}
            {/*    className="form-check-input"*/}
            {/*    type="checkbox"*/}
            {/*    value={adult}*/}
            {/*    id="adult"*/}
            {/*    checked={adult}*/}
            {/*    onChange={ () => this.setState({adult: !adult}) }/>*/}
            {/*  <label className="form-check-label" htmlFor="adult">*/}
            {/*    Reconozco que soy mayor de edad para  el uso de {APP_NAME}*/}
            {/*  </label>*/}
            {/*</div>*/}

            <div className="h-box justify-content-end">

              {index > (user ? 1 : 0) &&
                <button className="btn btn-solid mb-4 mr-2"
                  type="button"
                  onClick={this.handlePreviousCard}>
                  Anterior
                </button>
              }

              <button type="submit"
                className="btn btn-solid mb-4"
                disabled={loading}>
                {loading
                  ? "Enviando..."
                  : index === (sectionLength - 1)
                    ? "Crear cuenta"
                    : "Siguiente"
                }
              </button>
  
            </div>

          </form>
        </section>
      </>
    )
  }

}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(RegisterReseller);
