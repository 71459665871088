import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";
import { addToCart, addToCompare, addToWishlist } from "../../../../actions";
import PriceLabel from "../../../PriceLabelCard";
import { STATUS_ACTIVE } from '../../../../utils'

const ImageContainer = ({ product, className }) => {

  if (!product.images || !product.images[0] || !product.images[0].uri) return null;

  const getDiscount = () => "-" + (100 - ((product.offer_price / product.price) * 100)).toFixed(0) + "%";

  const discountTagStyle = {
    padding: "1px",
    borderRadius: "3px",
    background: "orange",
    color: "white",
    right: 0,
    top: "2px",
    position: "absolute"
  }

  return (
    <div className={className} style={className === 'back' ? {
      left: 30
    } : {
      position: 'relative'
    }}>

      {!!product.has_discount &&
        <span className="shadow"
          style={discountTagStyle}>
          {getDiscount()}
        </span>
      }

      <Link to={`/detalle/productos/${product.id}`} >
        <img src={product.images[0].uri}

          className="img-fluid"
          alt={product.name} />
      </Link>
    </div>
  )

}

class ProductStyleFive extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      stock: 'InStock',
      quantity: 1,
      image: ''
    }
  }

  onOpenModal = () => this.setState({ open: true });

  onCloseModal = () => this.setState({ open: false });

  onClickHandle = image => this.setState({ image });

  minusQty = () => {
    if (this.state.quantity <= 1) return;
    this.setState({
      stock: 'InStock',
      quantity: this.state.quantity - 1
    })
  }

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 })
    } else {
      this.setState({ stock: 'Out of Stock !' })
    }
  }

  changeQty = (e) => this.setState({ quantity: parseInt(e.target.value) })

  getPrice = () => {
    const { product } = this.props;
    return product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price
  }

  getDiscount = () => {
    const { product } = this.props;
    return "-" + (100 - ((product.offer_price / product.price) * 100)).toFixed(0) + "%";
  }

  aadCart = () => {
    const _product = { ...this.props.product };


    const product = {
      ..._product,
      price: this.getPrice(),
      originalPrice: _product.price
    }
    this.props.addToCart(product, 1)
  }

  render() {
    const { product, symbol, onAddToWishlistClicked, onAddToCompareClicked } = this.props;
    const { open } = this.state;
    let RatingStars = []
    for (let i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />)
    }

    const item = product;

    var acortado = item.description;
    var nuevo = acortado.substring(0,80);

    return (
      <div style={{
        margin: 0, padding: 4
      }}>
        <div className="product-box product-wrap border-shadow-custom" style={{ borderRadius: 20, padding: 4, paddingTop: 12, paddingBottom: 10, margin: 0, border: '1px solid #ececec', }}>

          <div className={`lable-block product-item font-sand ${product && product.new ? 'product-new' : (product && product.sale ? 'product-sale' : '')}`}>
            {(product && product.new) && <span className="lable3">Nuevo</span>}
            {(product && product.sale) && <span className="lable4">En Venta</span>}
          </div>
          <div className="img-wrapper d-flex justify-content-center">

            <ImageContainer className="front" product={product} />
 
            {/* <ImageContainer className="back" product={product} /> */}

            <div className="cart-box d-flex" style={{ borderRadius: 5, background: '#fff', border: '1px solid #007BFF', padding: 0 }}>
              {/* <button title="Add to cart" onClick={this.aadCart}>
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button> */}
              <div style={{ padding: 10 }}>
                <a href="javascript:void(0)" title="Add to Wishlist" onClick={onAddToWishlistClicked} >
                  <i className="fa fa-heart" aria-hidden="true" />
                </a> 
              </div>
              <div style={{ padding: 10 }}>
                <a href="javascript:void(0)" data-toggle="modal" data-target="#quick-view" title="Ver detalles" onClick={this.onOpenModal}>
                  <i className="fa fa-info-circle" aria-hidden="true" />
                </a>
              </div>
              
            </div>
          </div>

          <div className="product-detail">
            <div className="rating" style={{ height: 21 }}>
              {RatingStars}
            </div>
            <Link to={`/detalle/productos/${product.id}`}>
              <h6 className="mb-2 mt-3 color-dark font-sand" style={{ height: 20, margin: 5 }}><strong>{product.name}</strong></h6>
              <span style={{fontSize: 11, marginBottom: 15}}>{nuevo}...</span>
            </Link>
            <div className="d-flex justify-content-between">
              <PriceLabel product={product} />
              <div style={{ width: 60 }}>
                <button title="Add to cart" className="alert alert-primary mb-0 pt-1 pb-1 btn-primary" onClick={this.aadCart} style={{ minWidth: '100%' }}>
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </button>
              </div>
            </div>
            {product.variants &&
              <ul className="color-variant">
                {product.variants.map((vari, i) =>
                  <li className={vari.color}
                    key={i}
                    title={vari.color}
                    onClick={() => this.onClickHandle(vari.images)} />
                )}
              </ul>
            }
          </div>

          <Modal open={open} onClose={this.onCloseModal} center className="modal_custom">
            <div className="modal-dialog modal-lg modal-dialog-centered modal_custom_product" role="document">
              <div className="modal-content quick-view-modal">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6  col-xs-12 d-flex justify-content-center align-items-center">
                      <div className="quick-view-img">
                        {product.images && product.images[0] && product.images[0].uri &&
                          <img
                            src={product.images[0].uri}
                            className="img-fluid"
                            alt={product.name || ''} />
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 rtl-text">
                      <div className="product-right">
                        <h2 className="font-sand"> {product.name} </h2>
                        <div className="d-flex flex-column">
                          
                          <del>
                            <span className="money">
                            {symbol}{product.price}
                            </span>
                          </del>
                          <h3 className="mb-1">

                          {symbol}{product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price}

                          </h3>
                        </div>
                        {product.variants &&
                          <ul className="color-variant d-flex flex-column">
                            {product.variants.map((vari, i) =>
                              <li key={i}
                                className={vari.color + ' mb-2'}
                                title={vari.color}
                                onClick={() => this.onClickHandle(vari.images)} />
                            )}
                          </ul>
                        }
                        <div className="border-product">
                          <h6 className="product-title">Descripción</h6>
                          <p style={{ color:'black' }}>{product.description}</p>
                        </div>
                        <div className="product-description border-product">
                          {product.size &&
                            <div className="size-box">
                              <ul>
                                {product.size.map((size, i) =>
                                  <li key={i}><a href="#">{size}</a></li>
                                )}
                              </ul>
                            </div>
                          }
                          <h6 className="product-title">Cantidad</h6>
                          <div className="qty-box">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <div type="button" className="btn quantity-left-minus" onClick={this.minusQty} datatype="minus" data-field="">
                                  <i className="fa fa-angle-left" />
                                </div>
                              </span>
                              <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" style={{
                                marginRight: 5, border: '1px solid #d3d3d3'
                              }} />
                              <span className="input-group-prepend">
                                <div type="button" className="btn quantity-right-plus" onClick={this.plusQty} datatype="plus" data-field="">
                                  <i className="fa fa-angle-right" />
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="product-buttons d-flex flex-column flex-lg-row">
                          <div className="mb-3">
                            <button className="btn btn-solid" onClick={this.aadCart} >Añadir al Carrito</button>
                          </div>
                          <div>
                            <Link to={`/detalle/productos/${product.id}`} className="btn btn-solid">Ver detalle</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default connect(null, { addToCart, addToWishlist, addToCompare })(ProductStyleFive);
