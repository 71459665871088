import {axios} from '../axios';
const END_POINT = 'events';

const get = async (params) => {
    try{
        const res = await axios.get(`${END_POINT}`);
        return res.data;
    }catch(e){
        throw e
    }
}

const getCheckout = async (params) => {
    try{
        const res = await axios.get(`${END_POINT}/checkout`);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    get,
    getCheckout
}