import React from 'react'
import './styles.scss';

const HeadOptions = ({handleModeVist, modeView, onChangeOrder, orderBy}) => {
    
    return (
        <div className="head-options container mb-5">
          <div className="d-flex justify-content-between flex-column flex-xl-row">
            <div className="form-group m-0 d-flex">
              <label className="mb-0 font-sand f-sand-300 align-self-center" style={{ width: 150 }} htmlFor="orderBy">Ordenar por</label>
              <select
                className="select form-control font-sand ml-4 color-inherit custom_select"
                id="orderBy"
                name="orderBy"
                onChange={onChangeOrder}
                value={orderBy}
              >
                <option value="">-</option>
                <option value="A-Z">A-Z</option>
                <option value="Z-A">Z-A</option>
                <option value="low-price">De menor a mayor precio</option>
                <option value="high-price">De mayor a menor precio</option>
                <option value="recommended">Recomendados</option>
              </select>
            </div>
            <div className="d-flex align-items-center pt-3">
              <p className="text-dark mb-0 mr-3 font-sand f-sand-300">Modo Vista:</p>
              <div className="content-icon mr-3" onClick={() => handleModeVist('list')}>
                <i className={modeView === 'list' ? 'text-primary fas fa-list-ul' : 'text-secondary fas fa-list-ul'} aria-hidden="true"></i>
              </div>
              <div className="content-icon" onClick={() => handleModeVist('row')}>
                <i className={modeView === 'row' ? 'text-primary fas fa-th' : 'text-secondary fas fa-th'} aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

    )
}

export default HeadOptions
