import {axios} from '../axios';
const END_POINT = 'sections';

const get = async () => {
    try{
        const res = await axios.get(`${END_POINT}`);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    get
}