import $ from 'jquery'
import { toast } from 'react-toastify';

class Globals{

  setLoading = () => {
    $('.loader-wrapper').show()
  }
  
  quitLoading = () => {
    $('.loader-wrapper').hide()
  }

  showSuccess = msg => {
    toast.success(msg || 'Action realizada exitosamente')
  }

  showError = msg => {
    toast.error(msg || 'Disculpe, ha ocurrido un error')
  }
  
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  
}

export default new Globals()
