import React from "react";
import Required from "./Required";

const TextArea = ({id=(10000*Math.random()).toFixed(0),
                  label="",
                  name,
                  required=false,
                  value,
                  onChange,
                  style = {},
                  className,
                  placeholder,
                  inputClassName="form-control"}) => {
  
  const handleChange = evt => {
    if (!onChange) return
    onChange(evt.target.value, evt.target.name)
  }
  
  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">{label} <Required required={required}/> </label>
      <textarea id={name || id}
                className={inputClassName}
                name={name}
                required={required}
                onChange={handleChange}
                value={value}
                style={style}
                placeholder={placeholder || label}/>
    </div>
  )
  
}

export default TextArea
