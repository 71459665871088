import React from "react";
import {moneyFormat} from "../utils";

const MoneyText = ({text, className, ...props}) =>
  <span
    { ...props }
    className={`font-sand ${className}`}>
    {moneyFormat(text)}
  </span>

export default MoneyText
