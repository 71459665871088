import React from 'react';
import $ from 'jquery';

class Button extends React.Component {

	// componentDidMount() {
	//   $('[data-toggle="tooltip"]').tooltip();
	// }

	// componentDidUpdate() {
	//   $('[data-toggle="tooltip"]').tooltip();
	// }

	render() {
		return (
			<button 
				{ ...this.props } 
				title={ this.props.title }
				data-toggle="tooltip"
				className={ `btn btn-primary ${ this.props.className }
					${ this.props.outline ? 'btn-outline' : '' }
					${ this.props.clear ? 'btn-clear' : '' }
					${ this.props.color ? 'btn-' + this.props.color : 'btn-blue' }
					${ this.props.small ? 'btn-sm' : '' }
					${ this.props.block ? 'btn-block' : '' }` }>
				{ this.props.children }
			</button>
		)
	}
}

export default Button;