import {axios} from '../axios';
const END_POINT = 'getformtoken';
const VALIDATE_PAYMENT = 'validatePayment'

const get = async (amount, email) => {
    console.log(amount)
    const fixedAmount = amount.toFixed(2)
    try{
        const res = await axios.get(`${END_POINT}/${fixedAmount}/${email}`);
        //console.log(res)
        return res.data;
    }catch(e){
        throw e
    }
}
 const post = async (params) => {
     try{
    const r = await axios.post(`${VALIDATE_PAYMENT}`, params);
    return r.data
  }catch(e){
    throw e
  }
 }

export default {
    get,
    post
}