import React from 'react';
import { Button, Input, Textarea } from '../../widgets';
import { axios, RegionService } from '../../services';
import { Globals } from '../../utils';
import Select from 'react-select';
import { ProductService } from '../../services';
import { toast } from 'react-toastify';

class CreateChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        document_type: '',
        document: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        region_id: '',
        province_id: '',
        district_id: '',
        reason: '',
        support_type_id: '',
        description: '',
        comprobante: '',
      },
      reasons: [
        {
          label: 'Reclamo',
          value: 0,
        },
        {
          label: 'Queja',
          value: 1,
        },
      ],
      document_types: [
        {
          label: 'DNI',
          value: 0,
        },
        {
          label: 'Pasaporte',
          value: 1,
        },
        {
          label: 'Carnet de Extranjería',
          value: 2,
        },
      ],
      regions: [],
      districts: [],
      provinces: [],
      types: [],
      edit: false,
      textButton: 'Crear',
    };
  }
  componentDidMount() {
    this.load();
    this.loadRegions();
    console.log('prosp', this.props)
  }

  loadRegions = async () => {
    try {
      const regions = await RegionService.get();
      this.setState({
        regions,
      });
    } catch (error) {
      Globals.showError();
      console.log('>>: error > ', error);
    }
  };

  load = () => {
    Globals.setLoading();
    axios
      .post('/support/support-types')
      .then((res) => {
        if (res.data.result) {
          const types = res.data.types.map((element) => {
            return {
              label: element.name,
              value: element.id,
            };
          });
          this.setState({
            types,
            form: {
              type: (types && types[0]) || null,
            },
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch((err) => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  isValid = () => {
    const {
      document_type,
      document,
      name,
      phone,
      email,
      address,
      region_id,
      province_id,
      district_id,
      reason,
      support_type_id,
      description,
      comprobante,
    } = this.state.form;

    console.log('este es el tipo: ' + document_type);
    
    if (document_type == undefined) {
      toast.warn('El Tipo de documento es requerido');
      return false;
    }

    if (document == undefined) {
      toast.warn('El documento es requerido');
      return false;
    }

    if (name == undefined) {
      toast.warn('El nombre es requerido');
      return false;
    }

    if (phone == undefined) {
      toast.warn('El Teléfono es requerido');
      return false;
    }

    if (email == undefined) {
      toast.warn('El correo es requerido');
      return false;
    }

    if (address == undefined) {
      toast.warn('La dirección es requerida');
      return false;
    }

    if (region_id == undefined) {
      toast.warn('La región es requerida');
      return false;
    }

    if (province_id == undefined) {
      toast.warn('La provincia es requerida');
      return false;
    }

    if (district_id == undefined) {
      toast.warn('El distrito es requerido');
      return false;
    }

    if (reason == undefined) {
      toast.warn('El motivo es requerido');
      return false;
    }

    if (support_type_id == undefined) {
      toast.warn('El detalle es requerido');
      return false;
    }

    if (description == undefined) {
      toast.warn('El mensaje es requerido');
      return false;
    }
   
    return true;
  };



  submit = async () => {

    
    Globals.setLoading();
    if (!this.isValid()) return;
    axios
      .post('/support/chatCreate', this.state.form)
      .then((res) => {
        if (res.data.result) {
          this.setState({
            form: {},
          });
          Globals.showSuccess(res.data.msg);
          Globals.quitLoading();

          this.props.closeModal()
          
          //this.props.history.push('/');
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch((err) => {
       // Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    //  await ProductService.saveitem({'receptor_mensaje':this.state.form.description,'evento_tipo':"chat"})
  };

  change = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        user_id: this.props.idUser,
        [e.target.name]: e.target.value,
      },
    });
  };

  changeSelect = (val, index) => {
    this.setState({
      form: {
        ...this.state.form,
        [index]: val,
      },
    });
  };

  changeRegion = (item) => {
    const { value: region_id } = item;
    const provinces =
      this.state.regions.find((element) => element.id === region_id)
        .provinces || [];
    this.setState({
      form: {
        ...this.state.form,
        region_id: item,
        province_id: '',
        district_id: '',
      },
      provinces,
    });
  };

  changeProvince = (item) => {
    const { value: province_id } = item;
    const districts =
      this.state.provinces.find((element) => element.id === province_id)
        .districts || [];
    this.setState({
      form: {
        ...this.state.form,
        province_id: item,
        district_id: '',
      },
      districts,
    });
  };

  changeDistrict = (item) => {
    this.setState({
      form: {
        ...this.state.form,
        district_id: item,
      },
    });
  };

  render() {
    return (
      <div className="container">
      <div className="" style={{ paddingBottom: 30 }}>
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <label className="font-sand">Tipo</label>
            <Select
              // color="gray"
              name="document_type"
              defaultname="user"
              onChange={(val) => this.changeSelect(val, 'document_type')}
              value={this.state.form.document_type}
              options={this.state.document_types}
              styles={{
                control: () => ({
                  // none of react-select's styles are passed to <Control />
                  width: '100%',
                  display: 'flex',
                  border: '1px solid #DDDDDD',
                  borderRadius: 5,
                  height: 60,
                  padding: '0px 15px'
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: '500'
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  fontWeight: '500',
                  padding: 0
                }),
              }}
            />
          </div>
          <div className="col-md-4 col-sm-4">
            <Input
              name="document"
              onChange={this.change}
              type="number"
              value={this.state.form.document}
              label="Documento"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar documento"
            />
          </div>
          <div className="col-4">
            <Input
              name="name"
              onChange={this.change}
              value={this.state.form.name}
              label="Nombre cliente"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar nombre"
            />
          </div>
          <div className="col-4">
            <Input
              name="phone"
              onChange={this.change}
              value={this.state.form.phone}
              label="Teléfono celular"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar telefono"
            />
          </div>
          <div className="col-4">
            <Input
              name="email"
              onChange={this.change}
              value={this.state.form.email}
              label="Correo electrónico"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar correo"
            />
          </div>
          <div className="col-4">
            <Input
              name="address"
              onChange={this.change}
              value={this.state.form.address}
              label="Dirección de domicilio"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar dirección"
            />
          </div>
          <div className="form-group col-4">
            <div className="field-label">Región</div>
            <Select
              options={this.state.regions.map((element) => {
                const { name: label, id: value } = element;
                return {
                  label,
                  value,
                };
              })}
              onChange={this.changeRegion}
              value={this.state.form.region_id}
              placeholder="Seleccione su region"
              noOptionsMessage={() => 'No hay registros'}
              styles={{
                control: () => ({
                  // none of react-select's styles are passed to <Control />
                  width: '100%',
                  display: 'flex',
                  border: '1px solid #DDDDDD',
                  borderRadius: 5,
                  height: 60,
                  padding: '0px 15px'
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: '500'
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  fontWeight: '500',
                  padding: 0
                }),
              }}
            />
          </div>
          <div className="form-group col-4">
            <div className="field-label">Provincia</div>
            <Select
              options={this.state.provinces.map((element) => {
                const { name: label, id: value } = element;
                return {
                  label,
                  value,
                };
              })}
              onChange={this.changeProvince}
              value={this.state.form.province_id}
              placeholder="Seleccione su provincia"
              noOptionsMessage={() => 'No hay registros'}
              styles={{
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: '100%',
              display: 'flex',
              border: '1px solid #DDDDDD',
              borderRadius: 5,
              height: 60,
              padding: '0px 15px'
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              padding: 0
            }),
          }}
            />
          </div>
          <div className="form-group col-4">
            <div className="field-label">Distrito</div>
            <Select
              options={this.state.districts.map((element) => {
                const { name: label, id: value } = element;
                return {
                  label,
                  value,
                };
              })}
              onChange={this.changeDistrict}
              value={this.state.form.district_id}
              placeholder="Seleccione su distrito"
              noOptionsMessage={() => 'No hay registros'}
              styles={{
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: '100%',
              display: 'flex',
              border: '1px solid #DDDDDD',
              borderRadius: 5,
              height: 60,
              padding: '0px 15px'
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              padding: 0
            }),
          }}
            />
          </div>
          <div className="form-group col-4">
            <div className="field-label">Motivo</div>
            <Select
              name="reason"
              options={this.state.reasons}
              onChange={(val) => this.changeSelect(val, 'reason')}
              value={this.state.form.reason}
              placeholder="Seleccione el motivo"
              noOptionsMessage={() => 'No hay registros'}
              styles={{
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: '100%',
              display: 'flex',
              border: '1px solid #DDDDDD',
              borderRadius: 5,
              height: 60,
              padding: '0px 15px'
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              padding: 0
            }),
          }}
            />
          </div>
          <div className="form-group col-4">
            <div className="field-label">Detalle</div>
            <Select
              name="support_type_id"
              options={this.state.types}
              onChange={(val) => this.changeSelect(val, 'support_type_id')}
              value={this.state.form.support_type_id}
              placeholder="Seleccione el motivo"
              noOptionsMessage={() => 'No hay registros'}
              styles={{
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: '100%',
              display: 'flex',
              border: '1px solid #DDDDDD',
              borderRadius: 5,
              height: 60,
              padding: '0px 15px'
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              padding: 0
            }),
          }}
            />
          </div>
          <div className="col-md-4 col-sm-4">
            <Input
              name="comprobante"
              onChange={this.change}
              type="number"
              value={this.state.form.comprobante}
              label="Nro de comprobante (Opcional)"
              className="input_custom_40"
              style={{ height: 60 }}
              placeholder="Ingresar Nro de comprobante"
            />
          </div>
          <div className="form-group col-12">
            
            <Textarea
              name="description"
              onChange={this.change}
              value={this.state.form.description}
              label="Escriba su mensaje (6 caracteres mínimo)"
              className="input_custom_40 font-sand"
              style={{ minHeight: 60 }}
            />
          </div>
        </div>
        <div id="button">
          <Button className="btn_custom btn-success" style={{ padding: 10 }} block="true" type="button" onClick={() => this.submit()}>
            {this.state.textButton}
          </Button>
        </div>
      </div>
      </div>
    );
  }
}
export default CreateChat;
