const module = {
    es: {
        locale: 'es',
        messages: {
            topbar_title: 'Bienvenido a nuestra tienda {theme_name}',
            call_us: 'Llámenos',
            home: 'Home',
            features: 'Características',
            new: 'Nuevo',
            blog: 'Blog',
            blog_left_sidebar: 'Barra lateral izquierda',
            blog_right_sidebar: 'Barra lateral derecha',
            blog_detail: 'Detalle del blog',
            category_left_sidebar: 'Barra lateral izquierda',
            category_right_sidebar: 'Barra lateral derecha',
            category_no_sidebar: 'Sin marco',
            category_metro: 'Metro',
            category_full_width: 'Ancho completo',
            products: 'Productos',
            left_sidebar: 'Barra lateral izquierda',
            right_sidebar: 'Barra lateral derecha',
            no_sidebar: 'Sin barra lateral',
            three_col_thumbnail_left: '3 miniaturas a la izquierda',
            three_col_thumbnail_right: '3 miniatura derecha',
            thumbnail_below: 'Miniatura a continuación',
            accordian_details: 'Detalles de acordeón',
            thumbnail_left: 'Miniatura a la izquierda',
            thumbnail_right: 'Miniatura derecha',
            vertical_tab: 'Pestaña vertical',
            pages: 'Paginas',
            about_us: 'A propósito de nosotros',
            lookbook: 'lookbook',
            login: 'Identificar',
            register: 'Registro',
            logout: 'Conectar - Salida',
            search: 'Buscar',
            wishlist: 'Lista de deseos',
            cart: 'Carrito',
            collection: 'Colección',
            forget_password: 'Contraseña olvidada',
            contact: 'Contacto',
            checkout: 'Checkout',
            compare: 'Comparar',
            order_success: 'Orden exitosa',
            dashboard: 'Dashboard',
            FAQ: 'Preguntas Frecuentes',
            shop: 'Tienda',
            mens_fashion: 'Moda para Hombres',
            sports_wear: 'Ropa de deporte',
            top: 'Alto',
            bottom: 'Bajo',
            ethic_wear: 'Desgaste ético',
            shirts: 'Chemisse',
            women_fashion: 'Moda para mujeres',
            dresses: 'Vestidos',
            skirts: 'Faldas',
            westarn_wear: 'Ropa Occidental',
            ethnic_wear: 'Atuendo étnico',
            sport_wear: 'Ropa de deporte',
            bottom_wear: 'Desgaste inferior',
            kids_fashion: 'Moda infantil',
            accessories: 'Accesorios',
            fashion_jewellery: 'Joyería de disfraz',
            caps_and_hats: 'Gorras y sombreros',
            precious_jewellery: 'Joyas preciosas',
            necklaces: 'Collares',
            earrings: 'Pendientes',
            rings_wrist_wear: 'Anillos y ropa',
            men_accessories: 'Accesorios de hombre',
            ties: 'Vinculos',
            cufflinks: 'Gemelos',
            pockets_squares: 'Bolsilos a cuadros',
            helmets: 'Cascos',
            scarves: 'Bufandas',
            phone_cases: 'Forros de teléfono',
            my_account: 'Mi cuenta',
            fashion: 'Moda',
            beauty: 'Belleza',
            electronic: 'Electronica',
            furniture: 'Muebles',
            kids: 'Niños',
            pets: 'Mascotas',
            vegetables: 'Vegetales',
            watch: 'Relojes',
            theme_elements: 'Elementos temáticos',
            element_title: 'Titulo del elemento',
            collection_banner: 'Banner de colección',
            home_slider: 'Slider de inicio',
            category: 'Categoria',
            service: 'Servicio',
            image_size_ratio: 'Relación de tamaño de imagen',
            product_elements: 'Elementos del producto',
            product_box: 'Caja de producto',
            product_slider: 'Deslizador de producto',
            no_slider: 'Sin slider',
            multi_slider: 'Multi Slider',
            tab: 'Tab',
            email_template: 'Plantilla de correo electrónico',
            portfolio: 'Portafolio',
            portfolio_grid_2: 'Portafolio grilla 2',
            portfolio_grid_3: 'Portafolio grilla 3',
            portfolio_grid_4: 'Portafolio grilla 4',
            portfolio_masonary_2: 'Alabañileria 2',
            portfolio_masonary_3: 'Alabañileria 3',
            portfolio_masonary_4: 'Alabañileria 4',
            portfolio_masonary_full: 'Alabañileria completa',
        }
    },
    en: {
        locale: 'en_US',
        messages: {
            topbar_title: 'Welcome to Our store Multikart',
            call_us: 'Call Us',
            home: 'home',
            features: 'features',
            new: 'new',
            blog: 'blog',
            blog_left_sidebar: 'left sidebar',
            blog_right_sidebar: 'right sidebar',
            blog_detail: 'blog detail',
            category_left_sidebar: 'left sidebar',
            category_right_sidebar: 'right sidebar',
            category_no_sidebar: 'no sidebar',
            category_metro: 'metro',
            category_full_width: 'full width',
            products: 'products',
            left_sidebar: 'left sidebar',
            right_sidebar: 'right sidebar',
            no_sidebar: 'no sidebar',
            three_col_thumbnail_left: 'Thumbnail left',
            three_col_thumbnail_right: 'Thumbnail right',
            thumbnail_below: 'thumbnail below',
            accordian_details: 'accordian details',
            thumbnail_left: 'image left',
            thumbnail_right: 'image right',
            vertical_tab: 'vertical tab',
            pages: 'pages',
            about_us: 'about us',
            lookbook: 'lookbook',
            login: 'login',
            register: 'register',
            logout: 'Logout',
            search: 'search',
            wishlist: 'wishlist',
            cart: 'cart',
            collection: 'collection',
            forget_password: 'forget password',
            contact: 'contact',
            checkout: 'checkout',
            compare: 'compare',
            order_success: 'order_success',
            dashboard: 'Dashboard',
            FAQ: 'FAQ',
            shop: 'Shop',
            mens_fashion: 'mens fashion',
            top: 'top',
            bottom: 'bottom',
            ethic_wear: 'ethic wear',
            sports_wear: 'sports wear',
            shirts: 'shirts',
            women_fashion: 'womens fashion',
            dresses: 'dresses',
            skirts: 'skirts',
            westarn_wear: 'westarn wear',
            ethnic_wear: 'ethnic wear',
            sport_wear: 'aport wear',
            bottom_wear: 'bottom wear',
            kids_fashion: 'kidss fashion',
            accessories: 'Accessories',
            fashion_jewellery: 'Fashion Jewellery',
            caps_and_hats: 'caps and hats',
            precious_jewellery: 'precious jewellery',
            necklaces: 'necklaces',
            earrings: 'earrings',
            rings_wrist_wear: 'rings & wrist wear',
            men_accessories: 'mens accessories',
            ties: 'ties',
            cufflinks: 'cufflinks',
            pockets_squares: 'pockets squares',
            helmets: 'helmets',
            scarves: 'scarves',
            phone_cases: 'phone cases',
            my_account: 'My Account',
            fashion: 'Fashion',
            beauty: 'Beauty',
            electronic: 'Electronic',
            furniture: 'Furniture',
            kids: 'Kids',
            pets: 'Pets',
            vegetables: 'Vegetables',
            watch: 'Watch',
            theme_elements: 'theme elements',
            element_title: 'element title',
            collection_banner: 'collection banner',
            home_slider: 'home slider',
            category: 'category',
            service: 'service',
            image_size_ratio: 'image size ratio',
            product_elements: 'product elements',
            product_box: 'product box',
            product_slider: 'product slider',
            no_slider: 'no slider',
            multi_slider: 'multi slider',
            tab: 'tab',
            email_template: 'email template',
            portfolio: 'portfolio',
            portfolio_grid_2: 'portfolio grid 2',
            portfolio_grid_3: 'portfolio grid 3',
            portfolio_grid_4: 'portfolio grid 4',
            portfolio_masonary_2: 'masonary 2',
            portfolio_masonary_3: 'masonary 3',
            portfolio_masonary_4: 'masonary 4',
            portfolio_masonary_full: 'masonary full width',
        }
    }

}

export default module;