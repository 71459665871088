import React from "react";
import Input from "../form/Input";

const ContactRow = ({ contact, index, onChange, onAdd, onRemove }) => {

  const handleChange = (value, name) => {
    if (!onChange) return
    const newContact = {
      ...contact,
      [name]: value
    };
    onChange(newContact, index);   
  }

  const handleActionPress = () => {
    index
      ? onRemove(index)
      : onAdd()
  }

  return (
    <div className="row mb-2">

      <Input name="name"
        className="col-lg-4"
        required={true}
        label="Nombre"
        value={contact.name}
        style={{ height: 60 }}
        inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
        onChange={handleChange} />

      <Input name="email"
        type="email"
        className="col-lg-4"
        required={true}
        label="Correo electrónico"
        value={contact.email}
        style={{ height: 60 }}
        inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
        onChange={handleChange} />

      <div className="col-lg-4" style={ { display: 'grid', gridTemplateColumns: '1fr 40px' }}>
        <Input name="phone"
          type="tel"
          required={true}
          label="Teléfono"
          value={contact.phone}
          style={{ height: 60 }}
          inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          styleParent={{ paddingRight: 20 }}
          onChange={handleChange} />
        <div style={{
          display: "flex",
          flexDirection: "column"
        }}>

          <div style={{ flex: 1 }} />

          <div style={{
            display: "flex",
            background: index ? "red" : "green",
            borderRadius: '4px',
            width: '38px',
            height: '38px',
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 22
          }} className="selected" onClick={handleActionPress}>
            <i className={`fa ${index ? "fa-minus" : "fa-plus"}`} style={{ color: "white", fontSize: "20px" }} />
          </div>

        </div>
      </div>

    </div>
  )

}

export default ContactRow
