/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import support from '../chat/support';
import './index.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

const Support = support;


function ModalChat() {
  const [showModal, setShowModal] = useState(false);

  // const onHide = (event) => {
  //   event.preventDefault();
  //   setShowModal(!showModal);
  // };
  const ModalChat = () => {
    return (
      <>
        {showModal ? (
          <Modal
            show={showModal}
            centered
            size="md"
            onHide={() => setShowModal(!showModal)}
            id="test_modal_support"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <span className="font-sand">Chat de Soporte</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Support />
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </>
    );
  };
  return (
    <>
      <div className="btn-flotante cur-pointer" onClick={() => setShowModal(!showModal)}>
        <img src={`https://www.iconpacks.net/icons/2/free-chat-support-icon-1721-thumb.png`} style={{ width: '80px' }} />
      </div>
      <div className="btn-flotante2 cur-pointer">
        <FloatingWhatsApp 
          phone="51994552270"
          popupMessage="Bienvenido a Compra Fácil! ¿En qué podemos ayudarlo?"
          headerTitle= 'Bienvenido!'
        />
        
      </div>


      <ModalChat />
    </>
  );
}

export default ModalChat;
