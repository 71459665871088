/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Image from '../../widgets/Image';
import MoneyText from './MoneyText';
import { connect } from 'react-redux';
import { removeFromCart, incrementQty} from '../../actions';
import {Link} from 'react-router-dom'

const imageStyle = {
  width: 100,
  height: 100,
  minWidth: 50,
  minHeight: 50,
};

const removeIcon = {
  cursor: 'Pointer',
  marginLeft: '10px', 
};


const ProductRow = ({ 
  product,
  collapsed,
  isProvince,
  promotion,
  discountMode,
  paymentMethod,
  removeFromCart,
  incrementQty,
  incrementar,
  removeProduct,
  direction,
  discountFix,
}) => {
  useEffect(() => {
    //console.log(direction);
  }, [direction]);



  const shouldApplyDiscount = discountFix < product.discount;
const finalValue = shouldApplyDiscount ? product.price * product.qty : product.originalPrice * product.qty;


  return (
    <div className="mb-3 table-reponsive">
      <table
        className="col-md-12"
        width="100%"
        style={{
          opacity: !direction
            ? 1
            : isProvince && !product.to_province
            ? 0.33
            : 1
            
        }}
      >
        <thead align="center">
          <tr className="">
            <th colSpan="" align="left" width="10%">
              <div className="thumbnail">
                <Link to={`/detalle/productos/${product.id}`}>
                  <Image
                    url={product.image}
                   // style={imageStyle}
                    resize="contain"
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                  {(product.custom) ? (
                      <Image
                    url={product.image2}
                   // style={imageStyle}
                    resize="contain"
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                  ): ( 
                          '' 
                  )}
                </Link>
              </div>
            </th>
            <th colSpan="" align="left" width="85%">
              <table className="" width="100%" style={{ fontSize: '11px' }}>
                <thead>
                  <tr align="center">
                    <th colSpan="3" style={{ fontSize: '11px' }}>

                      <h5>

                      <Link to={`/detalle/productos/${product.id}`}>
                        <strong className="font-sand" style={{ fontSize: '14px' }}>
                          {product.name} {product.textvariantes}
                        </strong>
                        </Link>
                          
                            <div type="button" className="btn quantity-left-minus"  datatype="minus" data-field="">
                              <i className="fa fa-angle-left" />
                            </div>
                            {product.qty}
                          
                            <div className="btn quantity-right-plus" datatype="plus" onClick={ async() => { await incrementQty(product, 1); incrementar(product); } }> 
                              <i className="fa fa-angle-right" />
                            </div>
                          
                        <a
                          href={null}
                          style={removeIcon}
                          onClick={() => {
                            removeFromCart(product);
                            removeProduct(product.id);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </h5>
                    </th>
                    <th style={{ textAlign: 'right', fontSize: '13px' }}> Totales </th>
                  </tr>
                  <tr>
                    <th width="30%" className="font-sand">
                    <div className="d-flex justify-content-end p-0">Precio Regular (Unidad)</div>
                    
                    </th>
                    <th width="5%">:</th>

                    <th width="30%" className="font-sand">
                      {!collapsed && (
                        <MoneyText
                          className="col p-0"
                          value={product.originalPrice}
                        />
                      )}

                    </th>
                    <th width="30%" rowSpan="3">


                          <MoneyText
                            className="col p-0"
                            value={finalValue}
                           style={{ fontSize: '15px', verticalAlign: 'middle', color: 'green' }} />
                        
                    </th>
                  </tr>
                  
                  {!collapsed && (
                  <tr>
                    <th>

                    

                    {discountFix < product.discount ? ( 
                        !collapsed &&
                      
                          <div className="d-flex justify-content-end p-0">
                            Descuento
                       
                          </div>
                        
                      ) : (
                        ''
                      )}

                    </th>
                    <th>
                    {discountFix < product.discount ? (
                       
                          <div className="">
                            :
                          </div>
                      
                      ) : (
                        ''
                      )}

                    </th>
                    <th align="center">
                      {discountFix < product.discount ? (
                        
                          <div className="d-flex justify-content-end p-0">
                            <MoneyText value={-product.discount} />({' '}
                            {(product.rate * 100).toFixed(2) + ' %'} )
                          </div>
                     
                      ) : (
                        ''
                      )}
                    </th>
                  </tr>
                   )}
                  {!collapsed && (
                  <tr>
                    <th className="font-sand">

                    {discountFix < product.discount ? (
                        
                          <div className="d-flex justify-content-end p-0">
                            Precio Final (Unidad)
                          </div>
                     
                      ) : (
                        ''
                      )}

                    


                    </th>
                    <th>

                    {discountFix < product.discount ? (
                       
                          <div className="">
                            :
                          </div>
                    
                      ) : (
                        ''
                      )}

                    </th>
                    <th>

                    {discountFix < product.discount ? (
                        !collapsed &&
                        paymentMethod === 1 &&
                        product.paymentmethodoffer?.niubiz ? (
                          <div className="col v-box align-items-end p-0 font-sand">
                          <MoneyText
                            className="col p-0"
                            value={
                              discountFix < product.discount
                                ? paymentMethod === 1 &&
                                  product.paymentmethodoffer?.niubiz
                                  ? product.price
                                  : paymentMethod === 2 &&
                                    product.paymentmethodoffer?.paypal
                                  ? product.price
                                  : paymentMethod === 3 &&
                                    product.paymentmethodoffer?.transferencia
                                  ? product.price
                                  : paymentMethod === 4 &&
                                    product.paymentmethodoffer?.pagoEfectivo
                                  ? product.price
                                  : product.originalPrice
                                : product.originalPrice
                            }
                          />
                        </div>
                        ) : paymentMethod === 2 &&
                          product.paymentmethodoffer?.paypal ? (
                          <div className="col v-box align-items-end p-0 font-sand">
                          <MoneyText
                            className="col p-0"
                            value={
                              discountFix < product.discount
                                ? paymentMethod === 1 &&
                                  product.paymentmethodoffer?.niubiz
                                  ? product.price
                                  : paymentMethod === 2 &&
                                    product.paymentmethodoffer?.paypal
                                  ? product.price
                                  : paymentMethod === 3 &&
                                    product.paymentmethodoffer?.transferencia
                                  ? product.price
                                  : paymentMethod === 4 &&
                                    product.paymentmethodoffer?.pagoEfectivo
                                  ? product.price
                                  : product.originalPrice
                                : product.originalPrice
                            }
                          />
                        </div>
                        ) : paymentMethod === 3 &&
                          product.paymentmethodoffer?.transferencia ? (
                          <div className="col v-box align-items-end p-0 font-sand">
                          <MoneyText
                            className="col p-0"
                            value={
                              discountFix < product.discount
                                ? paymentMethod === 1 &&
                                  product.paymentmethodoffer?.niubiz
                                  ? product.price
                                  : paymentMethod === 2 &&
                                    product.paymentmethodoffer?.paypal
                                  ? product.price
                                  : paymentMethod === 3 &&
                                    product.paymentmethodoffer?.transferencia
                                  ? product.price
                                  : paymentMethod === 4 &&
                                    product.paymentmethodoffer?.pagoEfectivo
                                  ? product.price
                                  : product.originalPrice
                                : product.originalPrice
                            }
                          />
                        </div>
                        ) : paymentMethod === 4 &&
                          product.paymentmethodoffer?.pagoEfectivo ? (
                          <div className="col v-box align-items-end p-0 font-sand">
                          <MoneyText
                            className="col p-0"
                            value={
                              discountFix < product.discount
                                ? paymentMethod === 1 &&
                                  product.paymentmethodoffer?.niubiz
                                  ? product.price
                                  : paymentMethod === 2 &&
                                    product.paymentmethodoffer?.paypal
                                  ? product.price
                                  : paymentMethod === 3 &&
                                    product.paymentmethodoffer?.transferencia
                                  ? product.price
                                  : paymentMethod === 4 &&
                                    product.paymentmethodoffer?.pagoEfectivo
                                  ? product.price
                                  : product.originalPrice
                                : product.originalPrice
                            }
                          />
                        </div>
                        ) : (
                          <MoneyText
                          className="col p-0"
                          value={product.originalPrice-product.discount}
                        />
                        )
                      ) : (
                        ''
                      )}
                      
                       
                      
                    </th>
                  </tr>
                  )}
                </thead>
              </table>

              <div className="name"></div>
            </th>
         
          </tr>
        </thead>
      </table>

      {promotion !== undefined && promotion?.promotion_type_code_id === 3 && discountFix < product.discount ? (
                        !collapsed &&
                        paymentMethod === 1 &&
                        product.paymentmethodoffer?.niubiz ? (
                          ''
                        ) : paymentMethod === 2 &&
                          product.paymentmethodoffer?.paypal ? (
                          ''
                        ) : paymentMethod === 3 &&
                          product.paymentmethodoffer?.transferencia ? (
                          ''
                        ) : paymentMethod === 4 &&
                          product.paymentmethodoffer?.pagoEfectivo ? (
                          ''
                        ) : (
                          <span
          className="p-0 m-0 font-sand text-danger"
          style={{  fontWeight: 'normal', fontSize: 'small' }}
        >


          Este producto no participa de la promoción ingresada
        </span>
                        )
                      ) : (
                        ''
                      )}

  
     
      {!direction ? (
       <div>
       </div>
      ) : (
        <span
          className="p-0 m-0 font-sand text-danger"
          style={{  fontWeight: 'normal', fontSize: 'small' }}
        >
          {isProvince && !product.to_province
            ? 'Este producto no esta disponible fuera de provincia'
            : ''}
        </span>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, { removeFromCart, incrementQty })(ProductRow);
