import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getBestSeller} from "../../services";
import {addToCart, addToWishlist, addToCompare} from "../../actions";
import ProductItem from '../layouts/common/product-item';
import {Link} from 'react-router-dom';


 const PriceLabel = ({ product, symbol }) => {
  const { price, has_discount, offer_price } = product;

  if (!has_discount) return null;

  const rate = (100 - (offer_price / price) * 100).toFixed(1);

  return (
    <span style={{fontSize: '13px', color: 'red'}}> 
      <del style={{fontSize: '16px'}}>
        {symbol} {price}{' '}
      </del>
    </span>
  );
};


class RelatedProduct extends Component {

    Ratin (item) { 
        let RatingStars = []
            for(var i = 0; i < item.rating; i++) {
                RatingStars.push(<i className="fa fa-star" key={i}></i>)
            }

        return RatingStars;
    }

    render (){
        const {items, symbol, addToCart, addToWishlist, addToCompare} = this.props;

        
        

        
        return (
            <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-12 product-related">
                            <h3>Productos Relacionados</h3>
                        </div>
                    </div>
                    <div className="row search-product">
                        { items.slice(0, 6).map((product, i ) =>
                            <div key={i} className="col-xl-2 col-md-4 col-sm-6">
                                <div className="" key={i}>
                                <div className="" style={{
                                    width: 150,
                                    height: 200,
                                    textAlign:'center',
                                  }}>
                                    <Link to={`/detalle/productos/${product.id}`} className="nav-link">
                                        { (!!product.images && !!product.images.length) &&
                                            <img className="img-fluid" src={`${product.images[0].uri}`} alt="" style={{width: 150,
                                    height: 150,}}/>
                                        }
                                    </Link>
                                </div>
                                <div className="product-detail">
                                    <div className="">                         
                                        <div className="rating">
                                            {this.rating}
                                        </div>

                                        <Link to={`/detalle/productos/${product.id}`} className="nav-link">
                                            <h6>{product.name}</h6>
                                        </Link>
                                        <PriceLabel product={product} symbol={symbol} />
                                        <br></br>
                                        {product.has_discount == 1 ? (
                                          
                                                <span style={{color:'green', fontSize: '18px'}}>
                                                {symbol}{(product.offer_price)} 

                                                    
                                                </span>
                                            ) : (
                                              
                                              <span style={{color:'green', fontSize: '18px'}}>
                                                {symbol}{(product.price)} 

                                                    
                                                </span>
                                              
                                            )}
                                        
                                    </div>
                </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        //items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(RelatedProduct);
