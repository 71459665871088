import React from "react";

const Image = ({id, url, style, className, resize = "cover", onClick}) => {
  
  if (!url) return null;
  
  const handleClick = () => {
    if (onClick) onClick()
  }
  
  return <div id={id}
              onClick={handleClick}
              className={className}
              style={{
                ...style,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: resize,
                backgroundImage: `url(${url})`,
              }}/>
  
}

export default Image
