import React from 'react';
import email from '../../assets/images/email.png';
import './verifyAccount.scss'
const VerifyAccount = () => {
  return (
    <>
      <div className="container verifyAccount">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center flex-column flex-md-row">
            <div className="content-img">
              <img src={email} alt="email"/>
            </div>
            <h3 className="title">
              Verifique su correo electronico para validar su cuenta.
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
