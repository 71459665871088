import {axios} from '../axios';
import {jsonToParams} from "../index";
const END_POINT = 'products';
const get = async () => {
  try{
    const r = await axios.get(`${END_POINT}`);
    const items = r.data;
    return items;
  }catch(e){
    throw e
  }
}

const relacionados = async id => {
  try{
    const r = await axios.get(`${END_POINT}/relacionados/${id}`);
    const items = r.data;
    return items;
  }catch(e){
    throw e
  }
}


const hasPurchased = async (userId, itemId) => {
  try {
    const r = await axios.get(`${END_POINT}/has-purchased/${userId}/${itemId}`);
    const hasPurchased = r.data === 1; // Comprueba si la respuesta es igual a 1
    return hasPurchased;
  } catch (e) {
    throw e;
  }
}


const getById = async id => {
  try{
    const r = await axios.get(`${END_POINT}/${id}`);
    const items = r.data;
    return items;
  }catch(e){
    throw e
  }
}

const openChat = async params => {
  try{
    const r = await axios.post(`${END_POINT}/open-chat`, params);
    return r.data
  }catch(e){
    throw e
  }
}

const saveRating = async params => {
  try{
    const r = await axios.post(`${END_POINT}/save-rating`, params);
    return r.data
  }catch(e){
    throw e
  }
}

const saveAsk = async params => {
  try{
    const r = await axios.post(`${END_POINT}/save-ask`, params);
    return r.data
  }catch(e){
    throw e
  }
}

const saveitem = async params => {
  try{
    const r = await axios.post(`save-eventos`, params);
    return r.data
  }catch(e){
    throw e
  }
}



const index = async (query=null) => {
  try {
    const r = await axios.get(`${END_POINT}${jsonToParams(query)}`);
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    return r.data
  } catch(e) {
    throw e
  }
}

const search = async (query=null) => {
  try {
    const r = await axios.get(`${END_POINT}/search${jsonToParams(query)}`, query);
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    return r.data
  } catch(e) {
    throw e
  }
}



export default {
  saveAsk,
  saveRating,
  openChat,
  get,
  getById,
  relacionados,
  hasPurchased,
  search,
  index,
  saveitem
}
