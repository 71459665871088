import React, { Component } from 'react';
import Input from '../form/Input';
import Options from '../form/Options';
import TextArea from '../form/TextArea';
import InputFile from '../form/InputFile';
import { toast } from 'react-toastify';
import DistrictInput from './DistrictInput';
import icYoutube from '../../assets/images/ic_youtube.png';
import icInstagram from '../../assets/images/ic_instagram.png';
import icFacebook from '../../assets/images/ic_facebook.png';
import './sellerSection.scss';
import ActivitiesServices, {
  getListActivities,
} from '../../services/modules/ActivitiesServices';

const billOptions = [
  { value: '', text: 'Seleccione' },
  { value: '1', text: 'Boleta' },
  { value: '0', text: 'Factura' },
];
   
const billOptions2 = [
  { value: '', text: 'Seleccione' },
  { value: '1', text: 'Boleta' },
  { value: '0', text: 'Boleta y Factura' },
];

const salesRangeOptions = [
  { value: '', text: 'Seleccione' },
  { value: '1', text: 'Hasta 5000 soles' },
  { value: '2', text: 'Desde 5001 hasta 10000 soles' },
  { value: '3', text: 'Más de 10000 soles' },
  { value: '4', text: 'Prefiero no decirlo' },
];
const typeDirection = [
  { value: '', text: 'Seleccione' },
  { value: 'Av', text: 'Av' },
  { value: 'Jr', text: 'Jr' },
  { value: 'Ca', text: 'Ca' },
  { value: 'Pje', text: 'Pje' },
];

/* const businessOptions = getListActivities();
console.log(businessOptions); */

class SellerSection extends Component {
  state = {
    business_name: '',
    ruc: '',
    ruc_file: null,
    bill_type: '',
    preferred_bill_type: '',
    business_line: '',
    sales_range: '',
    store_quantity: '0',
    principal_store_address: '',
    type_address: '',
    district_id: '',
    file: null,
    phone: '',
    phone2: '',
    facebook: '',
    instagram: '',
    youtube: '',
    reference: null,
    urbanizacion: null,
    region: null,
    province: null,
    provinces: [],
    district: null,
    districts: [],
    businessOptions: [],
  };

  constructor(props) {
    super(props);
    const { value, defaultRuc } = props;
    this.state = {
      ...this.state,
      ruc: defaultRuc || '',
    };
    if (!value) return;
    this.state = {
      business_name: value.business_name || '',
      ruc: value.ruc || '',
      ruc_file: value.ruc_file || null,
      bill_type: value.bill_type || '',
      preferred_bill_type: value.preferred_bill_type || '',
      business_line: value.business_line || '',
      sales_range: value.sales_range || '',
      store_quantity: value.store_quantity || '0',
      principal_store_address: value.principal_store_address || '',
      type_address: value.type_address || '',
      district_id: value.district_id || '',
      file: value.file || '',
      phone: value.phone || '',
      phone2: value.phone2 || '',
      facebook: value.facebook || '',
      instagram: value.instagram || '',
      youtube: value.youtube || '',
      reference:  value.reference || '',
      urbanizacion:  value.urbanizacion || '',
      region: value.region,
      province: value.province,
      provinces: value.provinces,
      district: value.district,
      districts: value.districts,
      businessOptions: value.businessOptions,
    };
  }

  fetchBusiniess = async () => {
    try {
      const activities = await ActivitiesServices.getListActivities();
      this.setState({ businessOptions: activities });
    } catch (e) {
      alert(
        'Disculpe ha ocurrido un error cargando la lista de giros de negocio.'
      );
    } finally {
      // this.setState({ loading: false });
    }
  };

  componentDidMount() {
    //console.log('didmount')
    this.fetchBusiniess().then();
  }

  handleChangeInput = (text, name) =>
    this.setState(
      {
        ...this.state,
        [name]: text,
      },
      this.onChangeSeller
      //this.onChangeDirectionSeller
    );

  mapStateToClient = () => ({
    business_name: this.state.business_name,
    ruc: this.state.ruc,
    ruc_file: this.state.ruc_file,
    bill_type: this.state.bill_type,
    preferred_bill_type: this.state.preferred_bill_type,
    business_line: this.state.business_line,
    sales_range: this.state.sales_range,
    store_quantity: this.state.store_quantity,
    principal_store_address: this.state.principal_store_address,
    district_id: this.state.district_id,
    file: this.state.file,
    phone: this.state.phone,
    phone2: this.state.phone2,
    facebook: this.state.facebook,
    instagram: this.state.instagram,
    youtube: this.state.youtube,
    type_address: this.state.type_address,
    reference: this.state.reference,
    urbanizacion: this.state.urbanizacion,
    region: this.state.region,
    regions: this.state.regions,
    province: this.state.province,
    provinces: this.state.provinces,
    district: this.state.district,
    districts: this.state.districts,
  });

  onChangeSeller = () => {
    if (!this.props.onChangeSeller) return;
    this.props.onChangeSeller(this.mapStateToClient());
  };


  isValid = () => {
    const { 
      ruc_file,
      principal_store_address,
      store_quantity,
      urbanizacion,
      reference,
      ruc,
      phone2,
      phone,
      district,
      province,
      region,
    } = this.state;


    if (!ruc_file) {
      toast.warn('La imagen del RUC es requerida');
      return false;
    }


    let verificarruc = new RegExp('[A-Z]');
    if (verificarruc.test(ruc)) {
      toast.warn('El RUC solo debe contener números');
      return false;
    }

    let verificarruc2 = new RegExp('[a-z]');
    if (verificarruc2.test(ruc)) {
      toast.warn('El RUC solo debe contener números');
      return false;
    }

    let verificarp = new RegExp('[A-Z]');
    if (verificarp.test(phone)) {
      toast.warn('El "Teléfono" no puede contener letras');
      return false;
    }

    let verificarp2 = new RegExp('[a-z]');
    if (verificarp2.test(phone2)) {
      toast.warn('El "Teléfono 2" solo debe contener números');
      return false;
    }


    let verificarp21 = new RegExp('[a-z]');
    if (verificarp21.test(phone)) {
      toast.warn('El "Teléfono" no puede contener letras');
      return false;
    }

    let verificarp22 = new RegExp('[A-Z]');
    if (verificarp22.test(phone2)) {
      toast.warn('El "Teléfono 2" dos solo debe contener números');
      return false;
    }

    if (store_quantity != 0) {

    if (!principal_store_address || !district || !province || !region || !urbanizacion) {
      

      if (!principal_store_address && !district && !province && !region && !urbanizacion) {

      }else{

        if (!principal_store_address) {
          toast.warn('La dirección es requerida');
          return false;
        }


        if (!district) {
          toast.warn('El distrito es requerido');
          return false;
        }

        if (!province) {
          toast.warn('La provincia es requerida');
          return false;
        }

        if (!region) {
          toast.warn('La region es requerida');
          return false;
        }

        let direccion = new RegExp('[0-9]');
        if (!direccion.test(principal_store_address)) {
          toast.warn('La dirección debe contener un número de ubicación');
          return false;
        }

        let verificaru2 = new RegExp('[a-z]');
        let verificaru = new RegExp('[A-Z]');
        

        if (!verificaru2.test(urbanizacion)) {
          if (!verificaru.test(urbanizacion)) {
            toast.warn('La urbanización debe contener letras');
            return false;
          }
        }

         return false;
      }


     
    }

    
    
  /*  let verificarr2 = new RegExp('[a-z]'); 
    let verificarr = new RegExp('[A-Z]');

    if (!verificarr2.test(reference)) {
      if (!verificarr.test(reference)) {
        toast.warn('La referencia debe contener letras');
        return false;
      }
    } */



  }

    return true;
  };




  /* onChangeDirectionSeller = () => ({
    region: this.state.region,
    regions: this.state.regions,
    province: this.state.province,
    provinces: this.state.provinces,
    district: this.state.district,
    districts: this.state.districts,
  }) */

  handleDistrictChange = (district_id) =>
    this.setState({ district_id }, this.onChangeSeller);

  

  handleChangeSelectTypeD = (text, name) => {
    console.log('value', text, name);
    this.setState(
      {
        ...this.state,
        [name]: text,
      },
      this.onChangeSeller
    );
  };

  handleChangeRegion = (region) => {
    console.log(region);
    this.setState({
      region,
      province:null,
      district:null,
      provinces: region.provinces,
    });
  };

  handleChangeProvince = (province) =>
    this.setState({
      province,
      district:null,
      districts: province.districts,
    });

  handleChangeDistrict = (district) => {
    this.onDistrictChange(district);
    this.setState({ district });
  };

  onDistrictChange = (district) => {
    const value = !district ? null : district.id;
    this.setState({
      district_id: value,
    });
  };

  render() {
    const { title, className, style } = this.props;
    const {
      business_name,
      ruc,
      ruc_file,
      bill_type,
      preferred_bill_type,
      business_line,
      sales_range,
      store_quantity,
      principal_store_address,
      file,
      phone,
      phone2,
      facebook,
      instagram,
      youtube,
      type_address,
      urbanizacion,
      reference,
      region,
      province,
      provinces,
      district,
      districts,
    } = this.state;

    return (
      <div className={className} style={style}>
        <h3 className="mb-4"><strong> {title || 'Datos como proveedor'} </strong></h3>

        {/* BUSINESS NAME, RUC, RUC FILE */}
        {!!ruc_file ? (
          <div className="row mt-4 mb-4">
            <div className="col">
              <div className="content-image d-flex justify-content-center aling-items-center">
                {ruc_file.includes('data:application/pdf') ? (
                  <embed
                    src={ruc_file}
                    type="application/pdf"
                    width="200px"
                   
                  />
                ) : (
                  <img src={ruc_file} alt="imgruc" />
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mb-4">
          {/* BUSINESS NAME */}
          <Input
            name="business_name"
            className="col-lg-4"
            required={true}
            label="Razón social"
            value={business_name}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* RUC */}
          <Input
            name="ruc"
            className="col-lg-4"
            required={true}
            label="RUC"
            value={ruc}
            minLength={8}
            maxLength={11}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* RUC FILE */}

          <InputFile
            className="col-lg-4"
            name="file"
            label="Agregue imagen de su RUC"
            placeholder={file?.name ? file.name : 'Seleccione'}
            value={file}
            onChange={this.handleChangeInput}
            onBase64={(it) => this.handleChangeInput(it, 'ruc_file')}
            maxSize={5 * 1024 * 1024}
            accept="image/*, application/pdf"
            onMaxSize={() => toast.error('No se permiten archivo mayor a 5MB')} 
          />
        </div>

        {/* PHONE, PHONE 2, BILL TYPE, PREFERRED BILL TYPE */}
        <div className="row mb-4">
          {/* PHONE */}
          <Input
            className="col-lg-4"
            name="phone"
            type="tel"
            required={true}
            label="Teléfono"
            min={7}
            minLength={7}
            maxLength={9}
            value={phone}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* PHONE 2 */}
          <Input
            className="col-lg-4"
            name="phone2"
            type="tel"
            label="Teléfono 2"
            min={7}
            minLength={7}
            maxLength={9}
            value={phone2}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* BILL TYPE */}
          <Options
            name="bill_type"
            className="col-lg-4"
            label="Comprobantes que emite"
            required={true}
            value={bill_type}
            options={billOptions2}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* PREFERRED BILL TYPE */}
          <Options
            name="preferred_bill_type"
            className="col-lg-3"
            label="Comprobante que desea recibir"
            required={true}
            value={preferred_bill_type}
            options={billOptions}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
       

        {/* SALES RANGE,  BUSINESS LINE */}
        {/* SALES RANGE */}
        <Options
          name="sales_range"
          className="col-lg-3"
          label="Rango de ventas mensuales"
          required={true}
          value={sales_range}
          options={salesRangeOptions}
          onChange={this.handleChangeInput}
          style={{ height: 40 }}
          inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
        />

        {/* BUSINESS LINE */}
        {/* <TextArea
						name="business_line"
						className="col"
						label="Giro de su negocio"
						required={true}
						value={business_line}
						onChange={this.handleChangeInput}
					/> */}
        <Options
          name="business_line"
          className="col-lg-6"
          label="Giro de su negocio"
          required={true}
          value={business_line}
          options={this.state.businessOptions}
          onChange={this.handleChangeInput}
          style={{ height: 40 }}
          inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
        />
        <Input
          name="store_quantity"
          type="number"
          className="col-lg-4"
          required={true}
          label="Si posee tiendas físicas, especifique la cantidad"
          value={store_quantity}
          min="0"
          step="1"
          onChange={this.handleChangeInput}
          style={{ height: 40 }}
          inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
        />

        {!!parseInt(store_quantity) && (
            
             <>
                
                <TextArea
                  name="principal_store_address"
                  className="col col-md-8"
                  label="Dirección de la sede principal"
                  required={true}
                  onChange={this.handleChangeInput}
                  value={principal_store_address}
                  style={{ minHeight: 60 }}
                  inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
                />
                <Options
                  name="type_address"
                  className="col-12 col-md-4"
                  label="Tipo"
                  required={true}
                  value={type_address}
                  options={typeDirection}
                  onChange={this.handleChangeSelectTypeD}
                  style={{ height: 60 }}
                  inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
                />
                <Input
              name="urbanizacion"
              className="col-lg-6 p-0"
              type="text"
              required={true}
              label="Urbanización"
              value={urbanizacion}
              minLength={3}
              maxLength={20}
              onChange={this.handleChangeInput}
              style={{ height: 60 }}
              inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            />
          {/* ADDRESS */}

          {/* REFERENCE */}
          <TextArea
            name="reference"
            className="col-lg-4"
            label="Referencia"
            required={true}
            value={reference}
            onChange={this.handleChangeInput}
            style={{ minHeight: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
             </>
            
          )}
         </div>

        {/* STORE QUANTITY */}
        
          {/* STORE QUANTITY */}

          {/* DISTRICT, PRINCIPAL STORE ADDRESS */}

          {/* DISTRICT */}
          

          {/* PRINCIPAL STORE ADDRESS */}
          

         
          <div className="row mb-4">

          {!!parseInt(store_quantity) && (
            <DistrictInput
              className="mb-4"
              onDistrictChange={this.handleDistrictChange}
              handleChangeRegion={this.handleChangeRegion}
              region={region}
              province={province}
              provinces={provinces}
              handleChangeProvince={this.handleChangeProvince}
              district={district}
              districts={districts}
              handleChangeDistrict={this.handleChangeDistrict}
              addDivParent
              style={{ height: 40 }}
              classNameParent="col-lg-4"
            />
          )}
        </div>

        <div className="row mb-12">
          <h5 className="col-lg-4">Enlace a sus redes sociales</h5>
        </div>

        {/* SOCIAL NETWORKS */}
        <div className="row mb-4">
          <Input
            className="col-lg-4"
            name="facebook"
            label="Facebook"
            value={facebook}
            placeholder="@usuariofacebook"
            leftIcon={icFacebook}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          <Input
            className="col-lg-4"
            name="instagram"
            label="Instagram"
            value={instagram}
            placeholder="@usuarioinstagram"
            leftIcon={icInstagram}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          <Input 
            className="col-lg-4"
            name="youtube"
            label="YouTube"
            value={youtube}
            placeholder="@usuarioyoutube"
            leftIcon={icYoutube}
            onChange={this.handleChangeInput}
            style={{ height: 40 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
        </div>
      </div>
    );
  } 
}

export default SellerSection;
