import React from 'react';
import Image from '../../widgets/Image';
import MoneyText from './MoneyText';
import { Link } from 'react-router-dom';


const ProductRow2 = ({ isProvince, product, collapsed, promotion, discountMode, discountFix, paymentMethod, direction }) => {
  return (
    <div className="mb-3">
      <table className="col-md-12" width="100%" style={{ opacity: !direction ? 1 : isProvince && !product.to_province ? 0.33 : 1, minWidth: 500 }}>
        <thead align="center">
          <tr className="">
            <th colSpan="" align="left" width="25%">
              <input type="checkbox" />
            </th>
            <th colSpan="" align="left" width="25%">
              <div className="thumbnail left">
                <Link to={`/detalle/productos/${product.id}`}>
                  <Image url={product.image} resize="contain" style={{ width: 100, height: 100 }} />
                </Link>
              </div>
            </th>
            <th colSpan="" align="left">
              <table className="" width="100%" style={{ fontSize: '11px' }}>
                <thead>
                  <tr align="center">
                    <th colSpan="3">
                      <h5>
                        <Link to={`/detalle/productos/${product.id}`}>
                          <strong className="font-sand">
                            {product.code} {product.name} {product.textvariantes}
                          </strong>
                        </Link>
                      </h5>
                    </th>
                    <th style={{ textAlign: 'right', fontSize: '13px' }}> Totales </th>
                  </tr>
                  <tr>
                    <th width="30%" className="font-sand">Precio Regular (Unidad)</th>
                    <th width="5%">:</th>
                    <th width="30%" className="font-sand">
                      {!collapsed && <MoneyText className="col p-0" value={product.originalPrice} />}
                    </th>
                    <th width="30%" rowSpan="3">
                      <MoneyText className="col p-0" value={discountFix < product.discount ? paymentMethod === 1 && product.paymentmethodoffer?.niubiz ? product.price * product.qty : paymentMethod === 2 && product.paymentmethodoffer?.paypal ? product.price * product.qty : paymentMethod === 3 && product.paymentmethodoffer?.transferencia ? product.price * product.qty : paymentMethod === 4 && product.paymentmethodoffer?.pagoEfectivo ? product.price * product.qty : product.originalPrice * product.qty : product.originalPrice * product.qty} style={{ fontSize: '15px', verticalAlign: 'middle', color: 'green' }} />
                    </th>
                  </tr>
                  {!collapsed && (
                    <tr>
                      {/* your discount related rows */}
                    </tr>
                  )}
                  {!collapsed && (
                    <tr>
                      {/* your final price related rows */}
                    </tr>
                  )}
                </thead>
              </table>
              <div className="name"></div>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default ProductRow2;