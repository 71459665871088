import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { AuthService } from "../../../services";
import "./styles.scss";

const RegisterHash = ({ match, dispatch, history }) => {
	const { params } = match;
	const { hash } = params;
	// const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(
		"Su cuenta ha sido verificada, sera redirigido automaticamente."
	);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!!hash) {
			validateHash();
		}
	}, [hash]);

	const validateHash = async () => {
		// setLoading(true);
		try {
			const res = await AuthService.registerHash(hash);
			dispatch({
				type: "SET_USER",
				payload: res,
			});
			console.log("respuesta de hash", res);
			setTimeout(() => {
				if (res) history.push("/");
			}, 2000);
		} catch (e) {
			if (e === "token no encontrado") {
				dispatch({
					type: "SET_USER",
					payload: null,
				});
				setTitle("El token no es valido.");
				setError(true);
				//history.push('/');
			}

			toast.error(e);
		} finally {
			// setLoading(false);
		}
	};
	return (
		<div>
			<div className="container registerHash" style={{height: '500px'}}>

				<div className="row">
					<div className="col-12 d-flex justify-content-center align-items-center flex-column flex-md-row">
						<div className="content-icon mr-4">
							{error ? (
								<i
									class="fa fa-exclamation-triangle error"
									aria-hidden="true"></i>
							) : (
								<i class="fa fa-check-square success" aria-hidden="true"></i>
							)}
						</div>
						<div className="content-info d-flex justify-content-center align-items-center flex-column">
							<h3 className="title">{title}</h3>
							{error ? (
								<button
									className="btn btn-primary mt-4"
									onClick={() => history.push("/")}>
									Ir al Home
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(RegisterHash);
