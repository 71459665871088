import React from 'react'
import Slider from 'react-slick';
import moment from 'moment';
import "./compra.css";
import { UserService } from '../../../services';
import {moneyFormat} from "../../../utils";

class PurchasesDetails extends React.Component {


recibido = async (element) => {
    try {
      await UserService.recibidos({ id: element.id });
        
    } catch (error) {
      console.log('>>: error > ', error);
    }
  };


    render() {
        return (
            <div>
                {
                    this.props.purchase && this.props.purchase.details && (
                        <React.Fragment>
                            <div className="w-100 mb-4">
                                <div className="card" style={{ border: 0 }}>
                                    <div className="card-body border-shadow-custom row">
                                        <div className="col-4 col-md-4col-lg-4 w-100 mb-4">
                                            
                                                <h5 className="card-title font-sand">Pedido Nº {this.props.purchase.id} </h5>
                                                <p className="card-text text-dark font-sand"> {moment(this.props.purchase.created_at).format('DD-MM-YYYY HH:mm A')} </p>
                                               
                                           
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-4 w-100 mb-4">
                                                <h5 className="card-title font-sand">Dirección de entrega: {this.props.purchase.direccion}</h5>
                                        </div>  
                                        <div className="col-4 col-md-4 col-lg-4 w-100 mb-4">
                                                <h5 className="card-title font-sand">Método de pago: {this.props.purchase.metodopago}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row">
                                    {
                                        this.props.purchase.details.map((element, i) => {
                                            return (
                                                <div className="col-12 col-md-12 col-lg-12 w-100 mb-12" key={i}>
                                                    <div className="text-left">
                                                        <div className="card " style={{ border: 0 }}>
                                                            <div className="card-body border-shadow-custom">
                                                                <div className="row">
                                                                    <div className="col-3 mb-3 d-flex justify-content-center medio">
                                                                        
                                                                    </div>
                                                                    <div className="col-12 mb-12  medio">
                                                                        <table className="table tabla2" width="100%">
                                                                            <tr>
                                                                                <th rowSpan={5} >
                                                                                    <div style={{ width: 100 }}>
                                                                                        <Slider>
                                                                                            {
                                                                                            
                                                                                                    <div key={0}>
                                                                                                        <div
                                                                                                            className="home text-center p-right"
                                                                                                        >
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    width: 100,
                                                                                                                    height: 100
                                                                                                                }}
                                                                                                                src={element.product.images[0].uri}
                                                                                                                alt={element.product.images[0]}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                
                                                                                            }
                                                                                        </Slider>
                                                                                    </div>

                                                                                    
                                                                                </th>
                                                                             
                                                                                
                                                                            </tr>
                                                                            <tr>
                                                                                <th >    
                                                                                    Código producto
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    {element.product.code}
                                                                                </th>
                                                                                <th>    
                                                                                    Descripción
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                   { element.product.name }
                                                                                </th>
                                                                            </tr>
                                                                            
                                                                            <tr>
                                                                                <th>    
                                                                                    Precio unitario
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    S/ {parseFloat(element.price)}
                                                                                </th>
                                                                                 <th>    
                                                                                    Cantidad
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    {element.quantity}
                                                                                </th>
                                                                            </tr>
                                                                           
                                                                            <tr>
                                                                                <th>    
                                                                                    Total
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    S/ {parseFloat(element.quantity) * parseFloat(element.price)}
                                                                                </th>
                                                                                <th>
                                                                                
                                                                                </th>
                                                                                <th>
                                                                                
                                                                                </th>
                                                                                <th>    
                                                                                   <div
                                                                                        className="btn btn-primary font-sand  medio"
                                                                                        onClick={() => this.recibido(element)}
                                                                                    >
                                                                                        Recibí este producto
                                                                                    </div>
                                                                                </th>
                                                                               
                                                                            </tr>
                                                                            <tr>
                                                                                <th>    
                                                                                    Vendedor
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                     {element.seller?.user_name}
                                                                                </th>
                                                                                 <th>    
                                                                                    
                                                                                </th>
                                                                                <th>
                                                                                
                                                                                </th>
                                                                                <th>
                                                                                    
                                                                                </th>
                                                                                
                                                                               
                                                                            </tr>
                                                                          {/*  <tr>
                                                                                <th>    
                                                                                    Direccion de envío
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                     {this.props.purchase.direccion}
                                                                                </th>
                                                                                 <th>    
                                                                                    Método de Pago
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    {this.props.purchase.metodopago}
                                                                                </th>
                                                                                
                                                                               
                                                                            </tr>
                                                                           */}
                                                                        </table>
                                                                        
                                                                        
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                                                    <div className="card-footer">
                                                               
                                                                    
                                                                        <div>
                                                                            <span style={{fontWeight: 'bold'}}>
                                                                                Envío: { moneyFormat(this.props.purchase?.shipping_amount) }
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div>
                                                                        <br></br>
                                                                            <span style={{fontWeight: 'bold'}}>
                                                                                Comisión método de pago { moneyFormat(this.props.purchase?.comisionmp) }
                                                                            </span>
                                                                        </div>

                                                                        <div>
                                                                        <br></br>
                                                                            <span style={{
                                                                                fontWeight: 'bold',
                                                                                
                                                                            }}>
                                                                                total: { moneyFormat(this.props.purchase?.totalcc) }
                                                                            </span>
                                                                        </div> 
                                                                    </div>
                                                               
                            </div>
                        </React.Fragment>
                    )

                }
            </div>
        )
    }
}

export default PurchasesDetails