import React, { Component } from "react";
import { toast } from "react-toastify";
import Options from "../form/Options";
import Input from "../form/Input";

const bankOptions = [
  { value: "", text: "Seleccione" },
  { value: "Interbank", text: "Interbank" },
  { value: "BCP", text: "BCP" }
]

const typeOptions = [   
  { value: "", text: "Seleccione" },
  { value: "0", text: "Ahorros soles" },
  { value: "1", text: "Corriente soles" }
]

class BankSection extends Component {

  state = {
    bank: "",
    type: "",
    holder: "",
    account: "",
    account_confirmation: ""
  }

  constructor(props) {
    super(props);
    const { value } = props;
    if (!value) return
    this.state = {
      bank: value.bank || "",
      type: value.type || "",
      holder: value.holder || "",
      account: value.account || "",
      account_confirmation: value.account_confirmation || ""
    }
  }

  handleChangeInput = (text, name) => this.setState({
    ...this.state,
    [name]: text
  }, this.onChangeClient);

  onChangeClient = () => {
    if (!this.props.onChange) return;
    this.props.onChange(this.state);
  }

  isValid = () => {
    const { account, account_confirmation, holder } = this.state

    let number = new RegExp('[0-9]');
    if (number.test(holder)) {
      toast.warn('No se permiten números en el campo "Propietario de cuenta bancaria"')
      return false;
    }

    if (account !== account_confirmation) {
      toast.warn("Los números de cuenta deben coincidir")
      return false;
    }

    let letras = new RegExp('[A-Z]');
    if (letras.test(account)) {
      toast.warn('No se permiten letras en el campo "Cuenta bancaria"')
      return false;
    }

    let letras2 = new RegExp('[a-z]');
    if (letras2.test(account)) {
      toast.warn('No se permiten letras en el campo "Cuenta bancaria"')
      return false;
    }

    return true;
  }

  render() {
    const { title, className, style, formType } = this.props
    const { bank, type, holder, account, account_confirmation } = this.state
    return (
      <div className={className} style={style}>

        <h3> {title || "Datos bancarios"} </h3>

        {/* BANK, TYPE, HOLDER */}
        <div className="row mb-4">

          {/* BANK */}
          <Options name="bank"
            className="col-lg-4"
            label="Banco"
            required={true}
            value={bank}
            options={bankOptions}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* TYPE */}
          <Options name="type"
            className="col-lg-4"
            label="Tipo de cuenta"
            required={true}
            value={type}
            options={typeOptions}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            onChange={this.handleChangeInput} />

          {/* HOLDER */}
          <Input name="holder"
            className="col-lg-4"
            required={true}
            label="Propietario de cuenta bancaria"
            value={holder}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            message={formType === "seller" ? "Debe estar a nombre de la empresa la cuenta bancaria" : null} />

        </div>

        {/* ACCOUNT, ACCOUNT CONFIRMATION */}
        <div className="row mb-4">

          {/* ACCOUNT */}
          <Input name="account"
            className="col-lg-4"
            required={true}
            security={true}
            label="Número de cuenta bancaria"
            minLength="13"
            maxLength="16"
            value={account}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            onChange={this.handleChangeInput} />

          {/* ACCOUNT CONFIRMATION */}
          <Input name="account_confirmation"
            className="col-lg-4"
            required={true}
            security={true}
            label="Confirmar número de cuenta bancaria"
            minLength="13"
            maxLength="16"
            value={account_confirmation}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
            onChange={this.handleChangeInput} />

        </div>

      </div>
    )
  }

}

export default BankSection;
