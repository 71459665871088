export default (state = {}, action) => {
    const copy = {...state};
    switch (action.type) {
        case "SET_PRODUCTS":
            return action.payload;
        case "UPDATE_SOME_PRODUCTS":
            action.payload.forEach( product => {
                const index = copy.findIndex( item => item.id === product.id);
                if (index>=0) copy[index] = product;
            });
            return copy;
        case "CLEAR_PRODUCTS":
            return [];
        case "SET_PRODUCT":
            const index = copy.findIndex( item => item.id === action.payload.id);
            if (index>=0) {
                copy[index] = action.payload;
            } else {
                copy.push(action.payload);
            }
            return copy;
        case "REMOVE_PRODUCT":
            return copy.filter(item => item.id !== action.payload.id);

        // case 'UPDATE_PRODUCTS':
        //   const copy = [...state];
        //   const index = copy.findIndex( item => item.id === action.payload.id );
        //   copy[index] = action.payload;
        //   return copy;
        default:
            return state;
    }
};