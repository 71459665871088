import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import categoryService from "../../../../services/modules/categoryService";
import { toast } from "react-toastify";
import Slider from 'react-slide-out';
import 'react-slide-out/lib/index.css';
import { Button } from 'react-bootstrap';
import './topbar.css';
 
class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navClose: { right: '0px' },
      categories: Array.isArray(props.categories) ? props.categories : [],
      visible: false,
      subcategories: [],
      categorySelected: null,
      viewCategory: props.viewCategory,
      visibleCategories: false,
    }
    this.fetchCategories().then()
  } 

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: '-410px' } })
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } })
    }
  }

  openNav() {
    this.setState({ navClose: { right: '0px' } })
  }

  closeNav() {
    this.setState({ navClose: { right: '-410px' } })
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  // handleSubmenu = (event) => {
  //   if (event.target.classList.contains('sub-arrow')) return;
  //   if (event.target.nextElementSibling.classList.contains('opensubmenu')) {
  //     event.target.nextElementSibling.classList.remove('opensubmenu')
  //   } else {
  //     document.querySelectorAll('.nav-submenu').forEach(function (value) {
  //       value.classList.remove('opensubmenu');
  //     });
  //     document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
  //     event.target.nextElementSibling.classList.add('opensubmenu')
  //   }
  // }

  fetchCategories = async () => {
    /* try {
      const categories = await categoryService.search({
        root: true,
        with: ['sub_categories']
      })
      this.setState({ categories })
    } catch (e) {
      toast.error('Disculpe ha ocurrido un error')
    } */
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="align-self-center">

              {/* <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                <i className="fa fa-bars sidebar-bar" />
              </div> */}

              <ul className="w-100" style={{
                ...this.state.navClose,
                margin: 0,
                padding: 0
              }}>

                {/* <li className="back-btn" onClick={this.closeNav.bind(this)} >
                  <div className="mobile-back text-right">
                    <span >volver</span>
                    <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                  </div>
                </li> */}

                <li style={{ marginRight: 40 }}>
                  <div className="btn btn-primary font-sand gradiente" style={{ lineHeight: '32px', fontSize: 16, minWidth: 300 }} onClick={_ => {
                    this.state.viewCategory()
                    this.setState({ 
                      visibleCategories: !this.state.visibleCategories
                    })
                  }}>
                    <i className="fas fa-border-all mr-2"></i>
                    <span style={{ textTransform: 'none' }}>Todas las Categorías</span>
                    <i className={`ml-4 fas fa-angle-down transition-5 ${this.state.visibleCategories ? 'rotate-180' : ' '}`}></i>
                  </div>
                </li>

                <li >
                  <Link to={`/`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, marginRight: 20 }}>
                    <i className="fas fa-home mr-2"></i>
                    <span>Inicio</span>
                  </Link>
                </li>

                {/*<li >*/}
                {/*  <Link to={`/left-sidebar/collection`}  className="nav-link font-sand">*/}
                {/*    TIENDA*/}
                {/*  </Link>*/}
                {/*</li>*/}

                <li>
                  <Link to="/polos" className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, marginRight: 20 }}>
                    Polos
                  </Link>
                </li> 
                <li >
                  <Link to={`/buscar?category=13`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17 }}>
                    Ropa
                  </Link>
                </li>
                <li >
                  <Link to={`/buscar?category=17`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17 }}>
                    Videojuegos
                  </Link>
                </li>
                <li >
                  <Link to={`/buscar?category=11`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17 }}>
                    Juguetes
                  </Link>
                </li>
                <li >
                  <Link to={`/buscar?category=11`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17 }}>
                     Funkos
                  </Link>
                </li>
 
              </ul> 
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <div className="align-self-center mr-4">
                  <img
                    src={`/assets/images/polop.png`}
                    alt=""
                    style={{ width: 60 }}
                    className="img-fluid align-self-center"
                  />
                </div>
                <div className="d-flex flex-column align-self-center">
                  <div className="text-primary font-weight-bold font-sand" style={{ fontSize: 16, textAlign: 'center' }}>Personaliza tu polo <br></br> AQUÍ</div>
                  
                </div>
              {/* <div className="align-self-center mr-4">
                  <img
                    style={{ width: 50 }}
                   src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png" />
                </div>
                <div className="d-flex flex-column align-self-center">
                  <div className="text-primary font-weight-bold font-sand" style={{ fontSize: 20 }}>994 552 270</div>
                  <div>Escríbenos</div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(({ categories, user }) => ({ categories, user }))(withRouter(NavBar));
