import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import defaultImage from '../../assets/images/image_defaoult.jpg';
import Image from '../../widgets/Image';
import { addToCart, addToCompare, addToWishlist } from '../../actions';
import { connect } from 'react-redux';
import PriceLabelCard from '../PriceLabelCard';
import './ProductView.scss';
import { STATUS_ACTIVE } from '../../utils'
class ProductView extends Component {
  state = {
    width: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: 'InStock',
      quantity: 1,
      image: '',
    };
  }

  componentDidMount() {
    if (!this.state.width) this.setState({ width: this.getWidth() });
  }

  onOpenModal = () => this.setState({ open: true });

  onCloseModal = () => this.setState({ open: false });

  onClickHandle = (image) => this.setState({ image });

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock' });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: 'Sin Stock !' });
    }
  };

  changeQty = (e) => this.setState({ quantity: parseInt(e.target.value) });

  getWidth = () => {
    try {
      return document.getElementById('product-view-image').clientWidth;
    } catch (e) {
      return null;
    }
  };

  handleClickImage = () =>
    this.props.history.push(`/detalle/productos/${this.props.product.id}`);

  getPrice = () => {
    const { product } = this.props;
    return !!product.has_discount ? product.offer_price : product.price;
  };

  getDiscount = () => {
    const { product } = this.props;
    return (
      '-' + (100 - (product.offer_price / product.price) * 100).toFixed(0) + '%'
    );
  };

  aadCart = () => {
    const _product = { ...this.props.product };
    const product = {
      ..._product,
      price: this.getPrice(),
      originalPrice: _product.price,
    };
    this.props.addToCart(product, 1);
  };

  render() {
    const { product, symbol, onAddToCompareClicked, modeView } = this.props;
    // console.log('product', product)
    const { open } = this.state;
    let RatingStars = [];
    let n = product && product.rating ? product.rating : 0;
    for (let i = 0; i < n; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    if (!product) return null;

    const imageStyle = this.state.width && {
      maxHeight: this.state.width + 'px',
      minHeight: 200 + 'px',
      minWidth: 120
    };

    if (
      !this.state.width &&
      !!this.getWidth() &&
      this.state.width !== this.getWidth()
    ) {
      this.setState({ width: this.getWidth() });
    }
    const ModeRow = () => {
      return (
        <div
          className={'product-box product-wrap  border-shadow-custom ' + this.props.className}

          style={{ ...this.props.style, padding: '10px 10px',
          opacity: product.stock > 0
            ? 1
            : product.stock >= 0
            ? 0.33
            : 1,
            
        }}
        >
          
          <div className="img-wrapper">


            {!!product.has_discount && (
              <span
                className="shadow"
                style={{
                  padding: '6px',
                  borderRadius: '3px',
                  background: 'orange',
                  color: 'white',
                  right: 0,
                  top: '17px',
                  position: 'absolute',
                }}
              >
                {this.getDiscount()}
              </span>
            )}


           



            {/* <Image
              id="product-view-image"
              onClick={this.handleClickImage}
              style={imageStyle}
              url={
                product.images && product.images[0] && product.images[0].uri
                  ? product.images[0].uri
                  : defaultImage
              }
              className="img-fluid"
              alt=""
            /> */}

            <div id={"product-view-image"}
              onClick={this.handleClickImage}
              className={'img-fluid mb-0'}
              style={{
                width: '100%',
                height: 230,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }} >
              <img src={product.images && product.images[0] && product.images[0].uri
                ? product.images[0].uri
                : defaultImage} style={{
                  maxHeight: 230
                }} />
            </div>

            { product.stock > 0
            ? 
              <div className="cart-box d-flex" style={{ borderRadius: 5, background: '#fff', border: '1px solid #007BFF', padding: 0 }}>
                {/* <button title="Add to cart" onClick={this.aadCart}>
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </button> */}
                <div style={{ padding: 10 }}>
                  <a
                    href="javascript:void(0)"
                    title="Add to Wishlist"
                    onClick={this.props.addToWishlist}
                  >
                    <i className="fa fa-heart" aria-hidden="true" />
                  </a>
                </div>
                <div style={{ padding: 10 }}>
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#quick-view"
                    title="Quick View"
                    onClick={this.onOpenModal}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </a>
                </div>
           
              </div>

            : 
             ''
            }
            
          </div>

          {/* FOOTER */}
          <div className="product-detail  text-left">
            <div className="rating" style={{ height: 21 }}>{RatingStars}</div>

            { product.stock > 0
            ? 
            <Link to={`/detalle/productos/${product.id}`}>
              <h6 className="mb-2 mt-3 color-dark font-sand" style={{ height: 39, fontSize:13 }}><strong>{product.name}</strong></h6>
            </Link>

            : 
             <h6 className="mb-2 mt-3 color-dark font-sand" style={{ height: 39, fontSize:13 }}><strong>{product.name}</strong></h6>
           
            }
            


            <div className="d-flex justify-content-between align-self-center">
              <PriceLabelCard product={product} />
              <div style={{ width: 60 }}>
            { product.stock > 0
            ? 
            <button title="Add to cart" className="alert alert-primary mb-0 pt-1 pb-1 btn-primary" onClick={this.aadCart} style={{ minWidth: '100%' }}>
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </button>

            : product.stock <= 0
            ? 'AGOTADO'
            : 
            <button title="Add to cart" className="alert alert-primary mb-0 pt-1 pb-1 btn-primary" onClick={this.aadCart} style={{ minWidth: '100%' }}>
                <i className="fa fa-shopping-cart" aria-hidden="true" />
            </button>
            }
                
              </div>
            </div>
            {product.variants && (
              <ul className="color-variant">
                {product.variants.map((vari, i) => (
                  <li
                    key={i}
                    className={vari.color}
                    title={vari.color}
                    onClick={() => this.onClickHandle(vari.images)}
                  />
                ))}
              </ul>
            )}
          </div>

          <Modal open={open} onClose={this.onCloseModal} center>
            <div
              className="modal-dialog modal-lg modal-dialog-centered modal_custom_product"
              role="document"
            >
              <div className="modal-content quick-view-modal">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6  col-xs-12 d-flex justify-content-between align-items-center">
                      <div className="quick-view-img">
                        {product.images && product.images[0] && product.images[0].uri &&
                          <img
                            src={product.images[0].uri}
                            className="img-fluid"
                            alt={product.name || ''} />
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 rtl-text">
                      <div className="product-right">
                        <h2 className="font-sand"> {product.name} </h2>
                        <div className="d-flex flex-column">
                          <h3 className="mb-1">{symbol}{product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price}
                          </h3>
                          <del><span className="money">{symbol}{product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price}</span></del>
                        </div>
                        {product.variants ? (
                          <ul className="color-variant">
                            {product.variants.map((vari, i) => (
                              <li
                                key={i}
                                className={vari.color}
                                title={vari.color}
                                onClick={() => this.onClickHandle(vari.images)}
                              />
                            ))}
                          </ul>
                        ) : (
                          ''
                        )}
                        <div className="border-product">
                          <h6 className="product-title">product details</h6>
                          <p>{product.shortDetails}</p>
                        </div>
                        <div className="product-description border-product">
                          {product.size && (
                            <div className="size-box">
                              <ul>
                                {product.size.map((size, i) => (
                                  <li key={i}>
                                    <a href="#">{size}</a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <h6 className="product-title">quantity</h6>
                          <div className="qty-box">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <div
                                  type="div"
                                  className="btn quantity-left-minus"
                                  onClick={this.minusQty}
                                  datatype="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-left" />
                                </div>
                              </span>
                              <input
                                type="text"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.changeQty}
                                className="form-control input-number"
                                style={{
                                  marginRight: 5, border: '1px solid #d3d3d3'
                                }}
                              />
                              <span className="input-group-prepend">
                                <div
                                  type="div"
                                  className="btn quantity-right-plus"
                                  onClick={this.plusQty}
                                  datatype="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-right" />
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="product-buttons d-flex flex-column flex-lg-row">
                          <div className="mb-3">
                            <button className="btn btn-solid" onClick={this.aadCart} >add to cart</button>
                          </div>
                          <div>
                            <Link to={`/detalle/productos/${product.id}`} className="btn btn-solid">view detail</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    };

    const ModeList = () => {
      return (
        <div className="product-list product-box mb-4 border-shadow-custom">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="img-wrapper img-wrapper-list">
                <div className="label-block">
                  {product && product.new && (
                    <span className="label3">new</span>
                  )}
                  {product && product.sale && (
                    <span className="label4">on sale</span>
                  )}
                </div>

                {!!product.has_discount && (
                  <span
                    className="shadow"
                    style={{
                      padding: '6px',
                      borderRadius: '3px',
                      background: 'orange',
                      color: 'white',
                      right: 0,
                      top: '17px',
                      position: 'absolute',
                    }}
                  >
                    {this.getDiscount()}
                  </span>
                )}

                {/*  <Image
                  id="product-view-image"
                  onClick={this.handleClickImage}
                  style={imageStyle}
                  url={
                    product.images && product.images[0] && product.images[0].uri
                      ? product.images[0].uri
                      : defaultImage
                  }
                  className="img-fluid"
                  alt=""
                /> */}

                <div id={"product-view-image"}
                  onClick={this.handleClickImage}
                  className={'img-fluid mb-0'}
                  style={{
                    width: '100%',
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} >
                  <img className="product_image_list_movil" src={product.images && product.images[0] && product.images[0].uri
                    ? product.images[0].uri
                    : defaultImage} style={{
                      maxHeight: 100
                    }} />
                </div>

                <div className="cart-box d-flex" style={{ borderRadius: 5, background: '#fff', border: '1px solid #007BFF', padding: 0 }}>
                  {/* <button title="Add to cart" onClick={this.aadCart}>
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button> */}
                  <div style={{ padding: 9 }}>
                    <a
                      href="javascript:void(0)"
                      title="Add to Wishlist"
                      onClick={this.props.addToWishlist}
                    >
                      <i className="fa fa-heart" aria-hidden="true" />
                    </a>
                  </div>
                  <div style={{ padding: 9 }}>
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#quick-view"
                      title="Quick View"
                      onClick={this.onOpenModal}
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </a>
                  </div>
                  <div style={{ padding: 9 }}>
                    <Link
                      to={`/compare`}
                      title="Compare"
                      onClick={onAddToCompareClicked}
                    >
                      <i className="fa fa-refresh" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 info">
              <div className="row">
                <div className="col-12">
                  <div className="content-title">
                    <Link to={`/detalle/productos/${product.id}`}>
                      <h2 className="title m-0 mb-2" style={{ fontSize: 16 }}>{product.name}</h2>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6">
                  {RatingStars}
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <h4>
                    <span className="text-primary font-weight-bold" style={{ fontSize: 16 }}>{`${symbol} ${product.price}`}</span>
                    <del className="ml-4 money">
                      <span>
                        {symbol}
                        {product.price}
                      </span>
                    </del>
                  </h4>
                </div>
              </div>
              <div style={{ width: 60 }}>
                <button title="Add to cart" className="alert alert-primary mb-0 pt-2 pb-1 btn-primary" onClick={this.aadCart} style={{ minWidth: '100%' }}>
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <Modal open={open} onClose={this.onCloseModal} center>
            <div
              className="modal-dialog modal-lg modal-dialog-centered modal_custom_product"
              role="document"
            >
              <div className="modal-content quick-view-modal">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6  col-xs-12 d-flex justify-content-between align-items-center">
                      <div className="quick-view-img">
                        {product.images && product.images[0] && product.images[0].uri &&
                          <img
                            src={product.images[0].uri}
                            className="img-fluid"
                            alt={product.name || ''} />
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 rtl-text">
                      <div className="product-right">
                        <h2 className="font-sand"> {product.name} </h2>
                        <div className="d-flex flex-column">
                          <h3 className="mb-1">{symbol}{product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price}
                          </h3>
                          <del><span className="money">{symbol}{product.has_discount === STATUS_ACTIVE ? product.offer_price : product.price}</span></del>
                        </div>
                        {product.variants ? (
                          <ul className="color-variant">
                            {product.variants.map((vari, i) => (
                              <li
                                key={i}
                                className={vari.color}
                                title={vari.color}
                                onClick={() => this.onClickHandle(vari.images)}
                              />
                            ))}
                          </ul>
                        ) : (
                          ''
                        )}
                        <div className="border-product">
                          <h6 className="product-title">product details</h6>
                          <p>{product.shortDetails}</p>
                        </div>
                        <div className="product-description border-product">
                          {product.size && (
                            <div className="size-box">
                              <ul>
                                {product.size.map((size, i) => (
                                  <li key={i}>
                                    <a href="#">{size}</a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <h6 className="product-title">quantity</h6>
                          <div className="qty-box">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <div
                                  type="div"
                                  className="btn quantity-left-minus"
                                  onClick={this.minusQty}
                                  datatype="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-left" />
                                </div>
                              </span>
                              <input
                                type="text"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.changeQty}
                                className="form-control input-number"
                                style={{
                                  marginRight: 5, border: '1px solid #d3d3d3'
                                }}
                              />
                              <span className="input-group-prepend">
                                <div
                                  type="div"
                                  className="btn quantity-right-plus"
                                  onClick={this.plusQty}
                                  datatype="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-right" />
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="product-buttons d-flex flex-column flex-lg-row">
                          <div className="mb-3">
                            <button className="btn btn-solid" onClick={this.aadCart} >add to cart</button>
                          </div>
                          <div>
                            <Link to={`/detalle/productos/${product.id}`} className="btn btn-solid">view detail</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    };
    if (modeView === 'row') {
      return <ModeRow />;
    } else {
      return <ModeList />;
    }
  }
}

export default connect(null, { addToCart, addToWishlist, addToCompare })(
  ProductView
);
