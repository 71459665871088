import React from 'react'

const Params = ({onClearCondition, onClearCategory, onClearSubcategory, params}) => {
  
  if (!params.length) return null;
  
  // const getText = ({key, value}) => {
  //   switch (key) {
  //     case 'condition':
  //       return value ? 'Nuevo' : 'Usado'
  //     case 'category_id':
  //       return categories.find( category => category.id === value ).name
  //     case 'search':
  //       return value
  //     default:
  //       return ''
  //   }
  // }
  
  const handleClick = param => {

    switch (param.type) {
      
      case "condition":
        if (onClearCondition) onClearCondition(null)
        break;
      
      case "category_id":
        if (onClearCategory) onClearCategory(null);
        break;
  
      case "subcategory_id":
        if (onClearSubcategory) onClearSubcategory(null);
        break;
      
    }
    
    // if (onChangeQuery) {
    //   const _query = {...query}
    //   delete _query[param.type]
    //   onChangeQuery(_query)
    // }
    //
    // if (onChangeParams) {
    //   const _params = params.filter( ({key}) => key!==param.key)
    //   onChangeParams(_params)
    // }
    
  }
  
  return (
    <div className="mb-4 d-flex flex-wrap">
      {params.map( (param, index) =>
        <span
          key={index}
          className="badge rounded-pill bg-light text-dark mr-3"
          onClick={ () => handleClick(param) }>
          <i className="fa fa-times mr-1"/>
          {param.key}
        </span>
      )}
    </div>
  )
  
}

export default Params
