import React, { Component } from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux'

import {getTrendingCollection} from '../../../services/index'
import {Product5, Product6} from '../../../services/script'
import {addToCart, addToWishlist, addToCompare} from "../../../actions/index";
import ProductItem from '../../features/product/common/product-style-five';

class TopCollection extends Component {
 
    render () {

        const {symbol, addToCart, addToWishlist, addToCompare, type, products, name} = this.props;
        let properties;
        //if(type === 'kids'){
            properties = Product6
       // }else{
        //    properties = Product5
       // }

        return (
            <div className="container">
                {/*Paragraph*/}
                <div className="mt-5">
                    <h3 className="mb-3 font-sand font-weight-bold" style={{ borderBottom: '3px solid #007bff', color: '#253D4E', fontSize: 32 }}>{name}</h3>
                </div>
                {/*Paragraph End*/}
                <section className="section-b-space p-t-0 mb-4 section_mobile_padding_0">
                    <div className="container absolute-bg p-0">
                        <div className="">
                            <div className={'slider_products ' + (products.length === 1 ? 'col-3' : "p-0")}>
                                <Slider {...properties}  className="product-6 product-m no-arrow">
                                    {
                                        products.map((product, index ) =>{
                                            return(
                                                <div key={index}>
                                                    <ProductItem
                                                        product={product}
                                                        symbol={symbol}
                                                       // onAddToCompareClicked={() => addToCompare(product)}
                                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                                        onAddToCartClicked={() => addToCart(product, 1)}
                                                        key={index}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: getTrendingCollection(state.data.products, ownProps.type),
    symbol: state.data.symbol
})

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare}) (TopCollection);
