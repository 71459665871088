import {axios} from "../axios";
const END_POINT = 'sunat';

const get = async () => {
  try {
    const res = await axios.get(END_POINT);
    return res.data;
  } catch(e) {
    if (e.response) throw Error(e.response.data.error)
    throw Error("Disculpe ha ocurrido un error")
  }
}

export default {
  get
}
