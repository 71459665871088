import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { UserService } from '../../services';
import { connect } from 'react-redux';
import { Globals } from '../../utils';
import { File, Datepicker, Select, Button } from '../../widgets';
import moment from 'moment';
import DistrictInput from '../register/DistrictInput';
import { toast } from 'react-toastify';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ...this.props.user,
        password: '',
        password_confirmation: '',
        document_type: this.props.user.document_type || '0',
        addresses: [],
      },
      preview: null,
    };
  }

  user = !!localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state'))
    : '';

  userID = this.user?.user?.user?.id;
  componentDidMount() {
    if (!this.props.user) this.props.history.push('/');
    else this.load();
  }

  load = async () => {
    try {
      const user = await UserService.getUserData(this.userID);
      const form = {
        ...this.state.form,
        ...this.props.user,
        ...user,
      };
      console.log('>>: form > ', form);
      this.setState({
        form,
      });
    } catch (error) {
      console.log('>>: error > ', error);
    }
  };

  submit = async (e) => {
    e.preventDefault();


    const {   
      password,
      password_confirmation,
      name,
      last_name
     
    } = this.state.form;


    let verificar = new RegExp('[0-9]');
    if (verificar.test(name)) {
      toast.warn('El nombre no puede contener números');
      return false;
    }

    let verificar2 = new RegExp('[0-9]');
    if (verificar2.test(last_name)) {
      toast.warn('El Apellido no puede contener números');
      return false;
    }

  if (password !== '' && password !== null){
    if (password !== password_confirmation) {
      toast.error('Las contraseñas deben coincidir');
      return false;
    }

      let capitalize = new RegExp('[A-Z]');
      if (!capitalize.test(password)) {
        toast.warn(
          'La contraseña debe incluir al menos un carácter en mayúscula'
        );
        return false;
      }
  
      let number = new RegExp('[0-9]');
      if (!number.test(password)) {
        toast.warn('La contraseña debe incluir al menos un número');
        return false;
      }
  
      if (password !== password_confirmation) {
        toast.warn('Las contraseñas deben coincidir');
        return false;
      }
    }


    try {
      const res = await UserService.profile(this.state.form);
      const payload = {
        ...this.props.fullUser,
        user: {
          ...this.props.user,
          ...res,
        },
      };
      this.props.dispatch({
        type: 'SET_USER',
        payload,
      });
      Globals.showSuccess();
      this.props.history.push('/paginas/tablero');
    } catch (err) {
      Globals.showError(err.response.data.msg);
      console.log('>>: error > ', err);
    }
  };

  getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({
        preview: reader.result,
        form: {
          ...this.state.form,
          uri: reader.result,
        },
      });
      console.log(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      () => {
        return {
          form: {
            ...this.state.form,
            [name]: value,
          },
        };
      },
      () => {
        if (name === 'uri') this.getBase64(value);
      }
    );
  };

  handleDistrictChange = (district_id) =>
    this.setState({
      form: {
        ...this.state.form,
        district_id,
      },
    });


  addAddress = () => {
    let addresses = [...this.state.form.addresses];
    addresses.push({
      district_id: '',
      address: '',
      reference: '',
    });
    this.setState({
      form: { 
        ...this.state.form,
        addresses,
      },
    });
  };

  removeAddress = (indexDirection = null) => {
    let addresses = [...this.state.form.addresses];
    addresses.splice(indexDirection, 1)
    this.setState({
      form: {
        ...this.state.form,
        addresses,
      },
    });
  };


  changeDistrict = (district_id, index) => {
    let addresses = this.state.form.addresses;
    addresses[index].district_id = district_id;
    this.setState({
      form: {
        ...this.state.form,
        addresses,
      },
    });
  };

  changeItemInAddres = (e, key, i) => {
    const value = e.target.value;
    let addresses = this.state.form.addresses;
    addresses[i][key] = value;
    this.setState({
      form: {
        ...this.state.form,
        addresses,
      },
    });
  };

  BookDirectionsButtons = (showBtnDelete = true, indexDirection = null) => {
    return (
      <>
        <div className="col-12 text-center w-100 mt-4 mb-4">
          <div className="row">
            <div
              className={
                showBtnDelete
                  ? 'col-12 col-md-6 d-flex align-items-md-center justify-content-md-end'
                  : `col-12 col-md-12 d-flex align-items-md-center justify-content-md-center`
              }
            >
              <Button onClick={() => this.addAddress()} type="button">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </Button>
            </div>
            {showBtnDelete ? (
              <div className="col-12 col-md-6 d-flex align-items-md-center justify-content-md-start">
                <Button
                  type="button"
                  onClick={() => this.removeAddress(indexDirection)}
                  color="danger"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  };







  

  render() {
    return (
      <div>
        <Breadcrumb title="Perfil" parent="pages" url="profile" />
        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="theme-card">
                  <form className="theme-form" onSubmit={this.submit}>
                    <div className="row">
                      
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name">Nombre</label>
                          <input
                            type="text"
                            name="name"
                            onChange={this.onChange}
                            value={this.state.form.name}
                            className="form-control"
                            placeholder="Ingrese su nombre"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name">Apellido</label>
                          <input
                            type="text"
                            name="last_name"
                            onChange={this.onChange}
                            value={this.state.form.last_name}
                            className="form-control"
                            placeholder="Ingrese su apellido"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-4 text-center">
                       {!this.state.form.uri ? (
                          <i
                            className="fa fa-user"
                            style={{
                              fontSize: '5rem',
                            }}
                          />
                        ) : (
                          <img
                            class="rounded-circle w-100"
                            style={{ padding: '0 40%' }}
                            src={this.state.preview || this.state.form.uri}
                            alt={this.state.form.name}
                          />
                        )}
                      </div>
                      <div className="col-2 text-center">
                       
                        <div style={{ padding: '0 0%' }}>
                          <File
                            placeholder="Cambiar foto de perfil"
                            placeholderSuccess="Foto agregada"
                            showCheck={true}
                            onChange={this.onChange}
                            name="uri"
                            value={this.state.form.uri}
                            inputStyle={{
                              display: 'contents',
                            }}
                            className="btn-product"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            name="email"
                            disabled
                            value={this.state.form.email}
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="phone">Nombre de usuario</label>
                          <input
                            name="user_name"
                            onChange={this.onChange}
                            value={this.state.form.user_name}
                            className="form-control"
                            placeholder="Ingrese su nombre de usuario"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <Datepicker
                          color="dark"
                          label="Fecha de nacimiento"
                          name="birthday"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="scroll"
                          // minDate={this.state.dates.minDate}
                          value={moment(
                            this.state.form.birthdate || undefined
                          ).toDate()}
                          onChange={(birthdate) => {
                            this.setState({
                              form: {
                                ...this.state.form,
                                birthdate,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="phone">Telefono</label>
                          <input
                            type="number"
                            name="phone"
                            onChange={this.onChange}
                            value={this.state.form.phone}
                            className="form-control"
                            placeholder="Ingrese numero telefonico"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <Select
                          label="Tipo de documento"
                          name="document_type"
                          value={this.state.form.document_type}
                          onChange={this.onChange}
                          options={[
                            { value: '0', label: 'DNI' },
                            { value: '1', label: 'RUC' },
                            { value: '2', label: 'Pasaporte' },
                            { value: '3', label: 'CE' },
                          ]}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="ruc">Tipo de Documento</label>
                          <input
                            type="text"
                            name="ruc"
                            onChange={this.onChange}
                            value={this.state.form.ruc}
                            className="form-control"
                            placeholder="Ingrese numero telefonico"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-2">
                      <label htmlFor="district">Departamento/Provincia/Distrito</label>
                        <div className="row">

                          <DistrictInput
                            className="mb-2"
                            onDistrictChange={this.handleDistrictChange}
                            district={this.state.form.district}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="address">Dirección</label>
                          <textarea
                            name="address"
                            value={this.state.form.address}
                            onChange={this.onChange}
                            className="form-control"
                            id="review"
                            placeholder="Ingresa la contraseña"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="reference">Referencia</label>
                          <textarea
                            name="reference"
                            value={this.state.form.reference}
                            onChange={this.onChange}
                            className="form-control"
                            id="review"
                            placeholder="Escriba el punto de referencia"
                            required=""
                          />
                        </div>
                      </div>
                      {this.BookDirectionsButtons(false)}
                      <div className="col-12 col-md-12 mb-4">
                        {this.state.form.addresses.map((element, i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="col-12 text-center col-md-12 mb-2">
                                <h4>Direccion {i + 1} </h4>
                              </div>
                              <div className="col-12 col-md-6 mb-2">
                              <label htmlFor="district">Departamento/Provincia/Distrito</label>
                                <div className="row">
                                  <DistrictInput
                                    className="mb-2"
                                    onDistrictChange={(district_id) =>
                                      this.changeDistrict(district_id, i)
                                    }
                                    district={element.district}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <label htmlFor="address">Dirección</label>
                                  <textarea
                                    name="address"
                                    value={element.address}
                                    onChange={(e) =>
                                      this.changeItemInAddres(e, 'address', i)
                                    }
                                    className="form-control"
                                    id="review"
                                    placeholder="Ingresa la contraseña"
                                    required=""
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <label htmlFor="reference">Referencia</label>
                                  <textarea
                                    name="reference"
                                    value={element.reference}
                                    onChange={(e) =>
                                      this.changeItemInAddres(e, 'reference', i)
                                    }
                                    className="form-control"
                                    id="review"
                                    placeholder="Escriba el punto de referencia"
                                    required=""
                                  />
                                </div>
                              </div>
                              {this.BookDirectionsButtons(true, i)}
                            </div>
                          );
                        })}
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="password">Contraseña</label>
                          <input
                            type="password"
                            name="password"
                            value={this.state.form.password}
                            onChange={this.onChange}
                            className="form-control"
                            id="review"
                            placeholder="Ingresa la contraseña"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="password_confirmation">
                            Repetir contraseña
                          </label>
                          <input
                            type="password"
                            name="password_confirmation"
                            value={this.state.form.password_confirmation}
                            onChange={this.onChange}
                            className="form-control"
                            id="review"
                            placeholder="Repita la contraseña"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-solid">
                      Guardar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  fullUser: state.user,
  user: (state.user && state.user.user) || {},
});

export default connect(mapStateToProps)(Profile);
