import React, { useState,useEffect } from 'react';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import { connect } from 'react-redux';
import Input from '../form/Input';
import InputFile from '../form/InputFile';
import { toast } from 'react-toastify';
import AddressInput from './AddressInput';
import { paymentMethods } from './Checkout';
import ProductList2 from './ProductList2';

const PaymentForm = ({
  className,
  onChangeData,
  user,
  paymentMethod,
  total,
  direction,
  has_rut,
  products,
  isProvince,
  promotion,
  discountMode,
  discountTransferenciaDefault,
  removeProduct,
  incrementar,
  discountFix,
  onChange4
}) => {
  const [data, setState] = useState({
    name: user?.name,
    phone: user?.phone,
    email: user?.email,
    telefono: "",
    ruc:"",
    direccion:"",
    razon_social:"",
    checked:false,
  });

  const [hidden, setHidden] = useState(false);


  useEffect(() => {
    if (onChangeData) onChangeData(data);
  });

  const handleChangeInput = (text, name) => {
    //console.log();
    const newData = {
      ...data,
      [name]: text,
    };
    setState(newData);
  };

  const ChangeCick = (e) => {
   
      setHidden( e.target.checked == true);
   
  
  // console.log(hidden)

  };

  const { name, phone, email, ref, file,telefono,ruc,direccion,razon_social } = data;

  return (
    <div>
      <div className="">


        <table className="table table2">
            <thead align="center">
              <th colSpan="2" align="center" style={{
                        height: 30,
                        border: 'none',
                      }}>
                <h3 className="text-left mb-2">
                  <strong className="font-sand text-dark">Detalle de pago</strong>
                </h3>
              </th>
            </thead>
            <tbody>
        

              <tr>
                <th colSpan="2" align="center" style={{
                        height: 30,
                        border: 'none',
                      }}>
                  <label className="text-muted font-sand mb-0">Nombre</label>
                    <Input 
                      name="name"
                      className="mb-2 font-sand form-control Input_custom_40 font-weight-500"
                      required={true}
                      value={name}
                      onChange={handleChangeInput}
                      style={{
                        height: 30,
                        border: 'none',
                      }}
                    />
                </th>
              </tr>
              <tr >
                <th colSpan="2" className='' align="center" style={{
                        height: 30,
                        border: 'none',
                      }}>
                  <label className="text-muted font-sand mb-0">Teléfono</label>
                    <Input
                      name="phone"
                      required={true}
                      type="tel"
                      //label="Teléfono"
                      value={phone}
                      minLength="7"
                      maxLength="14"
                      onChange={handleChangeInput}
                      readOnly
                      className="mb-3 font-sand form-control Input_custom_40 font-weight-500"
                      style={{
                        height: 30,
                        border: 'none',
                      }}
                    />
                </th>
              </tr>
              <tr >
                <th style={{
                        height: 30,
                        border: 'none',
                      }}>
                  <label className="text-muted font-sand mb-0">Correo electrónico</label>
                  <br></br>
                    <Input
                      name="email"
                      type="email"
                      required={true}
                      //label="Correo electrónico"
                      value={email}
                      onChange={handleChangeInput}
                      className=""
                      style={{
                        height: 30,
                        border: 'none',
                        display: 'none',
                      }}
                    />

                    <h4> {email} </h4>
                </th>
                <th style={{
                        height: 30,
                        border: 'none',
                      }}>
                </th>

              </tr>
              <tr>
                <th colSpan="2" style={{
                        height: 30,
                        border: 'none',
                      }}>
                  <AddressInput
                      className="mb-3"
                      name="address"
                      onChange={handleChangeInput}
                    />

                
                
                </th>
              </tr>
              <If condition={has_rut==true}>

                  <tr>
                    <td colSpan="2">
                      <label> <input checked={hidden==true}  type="checkbox" onChange={ChangeCick} /> &nbsp;Existen productos en esta lista en el que el vendedor emite factura, ¿desea que se emita factura sobre dichos productos?</label>
                    
                    </td>

                  </tr>
                  <Else></Else>
              </If>
            </tbody>
        </table>

        
        <If condition={hidden==true}>
          <Then>
          <div className="table-responsive">
          <table className="table table2">
            <thead align="center">
              <th colSpan="2" align="center" style={{
                        height: 30,
                        border: 'none',
                      }}>
                <h3 className="text-left mb-2">
                  <strong className="font-sand text-dark">Datos de facturación</strong>
                </h3>
              </th>
            </thead>
            <tbody>




                <tr>
                  <th colSpan="" align="center" style={{
                          height: 30,
                          border: 'none',
                        }}>
                    <label className="text-muted font-sand mb-0">Ruc</label>
                      <Input 
                        name="ruc"
                        className="mb-2 font-sand form-control Input_custom_40 font-weight-500"
                        required={true}
                        value={ruc}
                        onChange={handleChangeInput}
                        style={{
                          height: 30,
                          border: 'none',
                        }}
                      />
                  </th>

                  <th colSpan="" align="center" style={{
                          height: 30,
                          border: 'none',
                        }}>
                    <label className="text-muted font-sand mb-0">Telefono</label>
                      <Input 
                        name="telefono"
                        className="mb-2 font-sand form-control Input_custom_40 font-weight-500"
                        required={true}
                        value={telefono}
                        onChange={handleChangeInput}
                        style={{
                          height: 30,
                          border: 'none',
                        }}
                      />
                  </th>
                </tr>
                <tr>
                  <th colSpan="" align="center" style={{
                          height: 30,
                          border: 'none',
                        }}>
                    <label className="text-muted font-sand mb-0">Dirección</label>
                      <Input 
                        name="direccion"
                        className="mb-2 font-sand form-control Input_custom_40 font-weight-500"
                        required={true}
                        value={direccion}
                        onChange={handleChangeInput}
                        style={{
                          height: 30,
                          border: 'none',
                        }}
                      />
                  </th>

                  <th colSpan="" align="center" style={{
                          height: 30,
                          border: 'none',
                        }}>
                    <label className="text-muted font-sand mb-0">Razon social</label>
                      <Input 
                        name="razon_social"
                        className="mb-2 font-sand form-control Input_custom_40 font-weight-500"
                        required={true}
                        value={razon_social}
                        onChange={handleChangeInput}
                        style={{
                          height: 30,
                          border: 'none',
                        }}
                      />
                  </th>
                </tr>
            </tbody>
          </table>
          <ProductList2
                    products={products}
                    isProvince={isProvince}
                    promotion={promotion}
                    discountMode={discountMode}
                    paymentMethod={paymentMethod}
                    discountTransferenciaDefault={discountTransferenciaDefault}
                    removeProduct={removeProduct}
                    onChange3={onChange4}
                    incrementar={incrementar}
                    direction={direction}
                    discountFix={discountFix}
                  />

               </div>
          </Then>

            <Else></Else>
        </If> 

      </div>

    </div>

      

      

      

      
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(PaymentForm);
