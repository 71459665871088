import axios from 'axios';
const END_POINT = 'api/products';
const jsonToParams = data => {
    if (!data) return '';
    return "?" + Object.entries(data)
        .map(([key, value]) => {
            if (Array.isArray(value)) return value.map( (it, i) => `${key}[${i}]=${it}` ).join("&")
            if (typeof value === 'object') return '';
            return `${key}=${value}`;
        })
        .join("&")
} 
const get = async (params) => { 
    try{
        const res = await axios.get('https://comprafacil.com.pe/'+`${END_POINT}${jsonToParams(params)}`);
        return res.data;
    }catch(e){
        throw e
    } 
}

export default {
    get
}