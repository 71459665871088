import React from "react";
import ProductViewControl from "./ProductViewControl";
import StickersControl from "./StickersControl";
import ProductHeader from "./ProductHeader";
import ColorControl from "./ColorControl";
import SizeControl from "./SizeControl";
import {Button, Form} from "react-bootstrap";

const RightBar = ({onBuy, total, colors, product, view, onChangeView, stickers, sticker, onRemove, onChangeColor, color, onSelectedSticker, variants, onChangeSizes, sizes, description, onChangeDescription,isChecked,ImageOptimized}) => {
  
  const handleChangeView = view => {
    if (!onChangeView) return
    onChangeView(view)
  }
  
  const handleRemove = sticker => {
    if (!onRemove) return
    onRemove(sticker)
  }
  
  return (
    <div className="w-100">
      
      <ProductHeader
        product={product}
        total={total}/>
      
      <ProductViewControl
        product={product}
        onViewChange={handleChangeView}
        view={view}/>
        
      <ColorControl
        color={color}
        product={product}
        onChangeColor={onChangeColor}
        colors={colors}
        variants={variants}/>
        
      <SizeControl
        product={product}
        variants={variants}
        color={color}
        onChangeSizes={onChangeSizes}
        sizes={sizes}/>
        
      <StickersControl
        stickers={stickers}
        sticker={sticker}
        onRemove={handleRemove}
        onSelectedSticker={onSelectedSticker}/>
  
      {!!product &&
      <Form.Group controlId='description' className="mt-4 mb-2">
        <Form.Label className="font-sand">Descripción</Form.Label>
        <Form.Control
          size="sm"
          as="textarea"
          name="description"
          placeholder="Describa alguna petición extra sobre su estampado"
          value={description}
          className="font-sand input_custom_40 font-weight-light"
          style={{  minHeight: 100 }}
          onChange={ e => onChangeDescription(e.target.value) }/>
      <Form.Label title="Servicio para mejorar la resolución y brillo de las imágenes a estampar" className="font-sand">¿Desea optimizar los stickers? Estas tendran una mejor calidad, el recargo sera de cinco (5) soles. </Form.Label>

      <input
        type="checkbox"
        title="Servicio para mejorar la resolución y brillo de las imágenes a estampar"
        label="image_optimized"
        checked={isChecked}
        onChange={e => ImageOptimized(e.target.checked)}
      />

      </Form.Group>
      }
      
      {!!product &&
        <Button
          className="btn btn-solid mt-2 mb-2"
          onClick={onBuy}>
          Comprar
        </Button>
      }
      
    </div>
  )
  
}

export default RightBar
