import React, { Component } from "react";
import { EventService } from "../../services";
import moment from "moment";

class EventBar extends Component {

  state = {
    events: [],
    events_text: []
  }

  componentDidMount() {
    this.getEvents().then()   
  }

  getEvents = async () => {
    try {
      const events = await EventService.getCheckout()
      const events_text = events.map(element => `(${moment(element.since).format('DD-MM-YYYY') + ' - ' + moment(element.until).format('DD-MM-YYYY')}) - ${element.message}`)
      this.setState({
        events_text,
        events
      })
    } catch (e) {
      console.log('>>: error > ', e)
    }
  }

  render() {
    const { events, events_text } = this.state;
    if (!events_text || !events_text.length) return null;
    return (
      <div className="w-100 text-center border-shadow-custom" >
        <div className="container">
          <h4 className="text-dark font-sand" style={{ fontSize: 13, padding: 5, fontWeight: '500' }}>
            Eventos importantes
          </h4>
        </div>
        {events.map((element, i) =>
          <a href={element.url_event} tile={element.message} target="_blank"><h5 key={i}
            className="bar_content font-sand mb-0"
            style={{
              backgroundColor: element.color || '',
              color: element.text_color || '',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              fontSize: 16,
              padding: 5
            }}>
            {element.message}
          </h5>
          </a>
        )}
      </div>
    )
  }

}

export default EventBar;
