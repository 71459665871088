import {combineReducers} from 'redux';
import {IntlReducer as Intl} from 'react-redux-multilingual'
import prints from "./prints"
import productReducer from './products';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import categories from './categories';
import customProducts from './customProducts'
import user from './user'
import regions from "./regions";
import checkoutReducer from './checkout';

const rootReducer = combineReducers({
    user,
    customProducts,
    categories,
    data: productReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    Intl,
    prints,
    regions,
    checkout: checkoutReducer
});

export default rootReducer;
