import React from "react";
import MoneyText from "./MoneyText";

const LabelRow = ({label, value, gradient=null}) => {
  // console.log(value)
  const signed = gradient==null
    ? ""
    : gradient ? "+" : "-";
  
  const color = gradient==false ? "red" : null;
  
  return (
    <>
      <div className="h-box">
      <span className="expand text-left number-label font-sand">
        {label}
      </span>
        <MoneyText className="expand number-count font-sand" value={value} signed={signed} color={color}/>
      </div>
    </>
  )
  
}


export default LabelRow
