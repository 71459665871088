import React, { Component } from 'react';
import { ProductService } from '../../services';
import { Globals } from '../../utils';
import LateralBar from './LateralBar';
import Editor from './Editor';
import RightBar from './RightBar';
import printDao from '../../services/modules/PrintDao';
import { connect } from 'react-redux';
import productCustomDao from '../../services/modules/ProductCustomDao';
import Breadcrumb from '../common/breadcrumb';
import { addToCart } from '../../actions';
import html2canvas from 'html2canvas';
import './Custom.css';
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row'; 
import Card from 'react-bootstrap/Card'; 

if (!Array.prototype.max) { 
  Array.prototype.max = function (arg) {
    const arr = this.map(arg); 
    let max = 0;
    arr.forEach((it) => {
      if (it > max) max = it;
    });
    const index = arr.findIndex((it) => it === max);
    if (index < 0) return null;
    return this[index];
  };
}

export const A4 = {
  width: 19,
  height: 27.7,
  dimension: 19 * 27.7,
  price: 10,
};

export function getImageFromUrl(url) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => resolve(img);
      img.src = url;
    } catch (e) {
      reject(e);
    }
  });
}

export async function getSticker(url, product, view, dimensionPx) {
  const image = await getImageFromUrl(url);
  const imageAspectRation = image.width / image.height; // Si es mayor a 1, la imagen es mas ancha que alta, si es menor a 1 la imagen es mas alta que ancah
  const medioa4w = 13.7;
  const medioa4h = 19;
  const defaultWidthCm =
    imageAspectRation >= 1 ? medioa4w : medioa4w * imageAspectRation;
  const dimensionCm = product.rectangles
    .find((it) => it.view === view)
    .dimensions.max((it) => it.width);
 
  const defaultWidthPx =
    (defaultWidthCm * dimensionPx?.width) / dimensionCm?.width;

  const width = defaultWidthCm;
  const height = defaultWidthCm / imageAspectRation;

  const price = width * height >= A4.dimension / 2 ? A4.price : A4.price / 2;

  return {
    uuid: new Date().getTime(),
    url,
    x: 0,
    y: 0,
    w: defaultWidthPx / dimensionPx.width,
    h: defaultWidthPx / imageAspectRation / dimensionPx.height,
    width,
    height,
    view,
    price,
  };
}

class Custom extends Component {
  state = {
    products: [],
    product: null,
    stickers: [], // Total de stickers del producto
    sticker: null, // Sticker actual seleccionado
    rectangles: [],
    view: 1,
    image_optimized:false,
    
    color: null,
    colors: [],
    dimension: { width: 0, height: 0 }, // dimensiones en pixeles del area de dibujado
    variants: [],
    sizes: [],
    images: [],
    src: null,
    frontImage: null,
    backImage: null,
    description: null,
    statusText: false,
    textSize: { min: 2, max: 10 },
    texts: [],
    isLoading: true
  };

  componentDidMount() {
    this.fetchProducts().then();
    this.fetchPrints().then();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.stickers.length) {
      const hasDiscount = this.state.stickers.some((it) => it.discount);
      if (!hasDiscount) this.onAddDiscount();
    }
  }

  onAddDiscount = () => {
    const stickers = [...this.state.stickers];
    const first = stickers[0];
    first.discount = 5;
    stickers[0] = first;
    this.setState({ stickers });
  };

  fetchProducts = async () => {
    Globals.setLoading();
    try {
      const query = {
        all: true,
        custom: 1,
        with: [
          'category.attributes.units',
          'attributes.attribute.values',
          'variants.images',
          'variants.attributes.attribute',
          'variantAttributes',
        ], 
      };
      const response = await ProductService.search(query);
      const products = response.filter((it) => it.rectangles.length);
      this.setState({ products, isLoading: false });
    } catch (error) {
      console.log('>>: error > ', error);
    } finally {
      Globals.quitLoading();
    }
  };

  fetchPrints = async () => {
    if (!this.props.prints.length) Globals.setLoading();
    try {
      await printDao.getPrints();
    } catch (error) {
      console.log('>>: error > ', error);
    } finally {
      Globals.quitLoading();
    }
  };

  handleChangeProduct = async (product) => {
    const rectangles = product.rectangles || this.state.rectangles;
    const variants = [product, ...product.variants];
    const colors =
      product.category.attributes
        .find((it) => !!it.color)
        .values.map((it) => it.payload)
        .filter((it) => !!it) || [];
    this.setState({
      product,
      rectangles,
      colors,
      stickers: [],
      sticker: null,
      variants,
      color: null,
      sizes: [],
    });
  };

  handleAddSticker = (_sticker) => {
    const stickers = [...this.state.stickers];
    const discount = !stickers.length ? 5 : 0;
    const sticker = { ..._sticker, discount };
    const some = stickers.some((it) => it.uuid === sticker.uuid);
    if (!some) stickers.push(sticker);
    this.setState({ sticker, stickers }, () =>
      console.log('>>: handleAddSticker.stikers: ', stickers)
    );
    if (this.state.statusText) {
      this.setState({
        statusText: false,
      });
    }
  };

  handleClearStickers = () => this.setState({ stickers: [], sticker: null });

  handleChangeView = (view) =>{this.setState({ view });this.setView(view);} ;
  ImageOptimized = (checked) => {
  this.setState({ image_optimized: checked });
  console.log(checked);
};



  handleRemoveSticker = (sticker) => {
    let { stickers } = this.state;
    const index = stickers.findIndex((it) => it.id === sticker.id);
    if (index < 0) return;
    stickers.splice(index, 1);
    this.setState({ stickers }, () => {
      if (this.editor) this.editor.update();
      if (this.editor2) this.editor2.update();
    });
  };

  handleSelectedSticker = (sticker) => this.setState({ sticker });

  handleUpdateStickers = (stickers) => this.setState({ stickers });

  handleChangeDimension = (dimension) => this.setState({ dimension });

  handleChangeColor = (color) => this.setState({ color, sizes: [] });

  handleChangeSizes = (sizes) => this.setState({ sizes });

  getTotalProducts = () => {
    const { sizes } = this.state;
    return sizes.reduce(
      (accumulator, it) => accumulator + parseInt(it.quantity),
      0
    );
  };

  getTotal = () => {
    const { sizes, stickers } = this.state;
    let totalcheck = 0;
    if (this.state.image_optimized == true) {
       totalcheck = 5;
    }else{
       totalcheck = 0;
    }
    const totalProducts = this.getTotalProducts();
    const totalSizes = sizes.reduce(
      (accumulator, it) => accumulator + it.quantity * it.price,
      0
    );
    const totalStickers = stickers.reduce(
      (accumulator, it) =>
        accumulator + (parseFloat(it.price) - (it.discount || 0)),
      0
    );
    return totalSizes + totalStickers * totalProducts + totalcheck;
  };

  handleAddImage = (image) => {
    const images = [...this.state.images];
    images.push(image);
    this.setState({ images });
  };

  base64ToFile = async (src, filename) => {

//    console.log(src);
    const res = await fetch(src);
    const blob = await res.blob();


  //  console.log(res);
  //  console.log(blob);

    return new File([blob], filename, { type: 'image/png' });

  };







  capture = async () => {
    let src;
    let frontImage, backImage

    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
   
    if (this.state.view === 1) {
      src = await this.editor.capture();
      frontImage = await this.base64ToFile(src, 'frontimage');

      await this.setView(2);
      await timeout(1000);

      src = await this.editor2.capture();
      backImage = await this.base64ToFile(src, 'backimage');
    } else {
      src = await this.editor2.capture();
      backImage = await this.base64ToFile(src, 'backimage');

      await this.setView(1);
      await timeout(1000);

      src = await this.editor.capture();
      frontImage = await this.base64ToFile(src, 'frontimage');
    }
    

  //  console.log(frontImage);
  //  console.log(backImage);

    return { frontImage, backImage };
  };

  setView = (view) =>
    new Promise((resolve) => {

      this.setState({ view }, () => resolve());
      if(view == 1)
      {
        this.editor2.initCanvas(view)
      }else{
        this.editor.initCanvas(view)
      }
     
    });

  handleBuy = async () => {
    if (!this.props.user) return alert('Debes iniciar sesión para continuar');
    if (!this.state.stickers.length)
      return alert('Debes seleccional al menos un sticker');
    if (!this.state.sizes.filter((size) => size.quantity != 0).length)
      return alert('Debes seleccional al menos una talla');

    const images = await this.capture();

    if (document.getElementById('drawingArea-1')) {
      document.getElementById('drawingArea-1').style.border = '1px solid black'
    }
    if (document.getElementById('drawingArea-2')) {
      document.getElementById('drawingArea-2').style.border = '1px solid black'
    }

    // SUBIR UNO A UNO LOS STICKERS DEL USUARIO
    try {
      Globals.setLoading();
      await this.uploadPrints();
      const productCustoms = this.mapping();
      const result = await this.createProductCustoms(productCustoms, images);
     // console.log('>>: Custom.handleBuy.result: ', result);
      result.forEach((it) => {
       // console.log('>>: custom.handleBuy.forEach.it: ', it);
        this.props.addToCart(it, parseInt(it.quantity));
      });
      this.props.history.goBack();
    } catch (e) {
      alert(e);
    } finally {
      Globals.quitLoading();
    }
  };

  uploadPrints = async () => {
    const images = [...this.state.images];
    if (!images.length) return;
    const stickers = [...this.state.stickers];
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      try {
        const print = await printDao.upload(image.file);
        const index = stickers.findIndex((it) => it.uuid === image.uuid);
        if (index < 0) throw 'index not found';
        const sticker = {
          ...stickers[index],
          id: print.id,
          url: print.url,
        };
        stickers[index] = sticker;
      } catch (e) {
        throw 'Disculpe ha ocurrido un error';
      }
    }
    this.state.stickers = stickers;
  };

  mapping = () => {
    const sizes = [...this.state.sizes];
    const products = sizes.filter((size) => size.quantity != 0);
    return products.map(this.mapSizeToProductCustom);
  };

  mapSizeToProductCustom = (size) => {
    const user_id = this.props.user.user.id;
    const product = this.state.variants.find((it) => it.id === size.id);
    const productPrice = parseFloat(product.price);
    const stickersPrice = this.state.stickers.reduce(
      (accumulator, sticker) =>
        accumulator + parseFloat(sticker.price) - (sticker.discount || 0),
      0
    );
    const price = productPrice + stickersPrice;
    return {
      user_id,
      product_id: size.id,
      price,
      stickers: this.mapStickers(size),
      quantity: size.quantity,
      description: this.state.description,
    };
  };

  createProductCustoms = async (productCustoms, images) => {
    const { frontImage, backImage } = images;
    const arr = [];
    for (let i = 0; i < productCustoms.length; i++) {
      try {
        const form = productCustoms[i];
        form.image_optimized = this.state.image_optimized;
        let productCustom = await productCustomDao.store(form);
        productCustom = await productCustomDao.upload(
          frontImage,
          1,
          productCustom.id
        );
        productCustom = await productCustomDao.upload(
          backImage,
          2,
          productCustom.id
        );
        arr.push({
          ...productCustom,
          custom: true,
          quantity: productCustoms[i].quantity,
          total: productCustom.price * productCustoms[i].quantity,
        });
      } catch (e) {
        throw 'Disculpe ha ocurrido un error';
      }
    }
  //  console.log('>>: arr: ', arr);
    return arr;
  };

  mapStickers = (size) => {
    return this.state.stickers.map((sticker) => {
      const dimension = this.state.product.rectangles
        .find((rectangle) => rectangle.view === sticker.view)
        .dimensions.find(
          (dimension) => dimension.attribute_value.value === size.value
        );

        console.log('sticker.x: ' + sticker.x);
        console.log('sticker.y: ' + sticker.y);
        console.log('width: ' + sticker.w);
        console.log('height: ' + sticker.h);
        console.log('width2: ' + sticker.width);
        console.log('height2: ' + sticker.height);
        console.log('physical_width: ' + sticker.physical_width);
        console.log('physical_height: ' + sticker.physical_height);

      return {
        print_id: sticker.id,
        view: sticker.view,
        x: sticker.x,
        y: sticker.y,
        width: sticker.w,
        height: sticker.h,
        price: sticker.price - (sticker.discount || 0),
        physical_width: sticker.width,
        physical_height: sticker.height,
      };
    });
  };

  handleStatusText = () => {
    this.setState({ statusText: !this.state.statusText });
  };

  changeTextSize = (value) => {
    this.setState({ textSize: value });
  };

  handleAddTexts = (value) => {
    let arr = [];
    if (!!value) {
      /*  arr.push(value);
      console.log(arr) */
      this.state.texts.push(value);
    }
  };
  setStatusText = () => {
    if (this.state.statusText) {
      this.setState({
        statusText: false,
      });
    }
  };
  render() {
    return (
      <>
        <Breadcrumb title="Polos personalizados" url="custom" />

        <div className="container" id="todoimagen">
        <br></br>
        <h3>C&oacute;mo personalizar tu polo</h3>

          <br></br>
          <div className="row">
            <div className="col-md-3 col-xs-12">
            <Card title="Card title" className="tarjeta" bordered={false}>
                <h4>1) ELIGE TU POLO:</h4>
    <br></br>
                <p>Diferentes modelos tallas y multutitud de colores.</p>
            </Card>
            </div>
            <div className="col-md-3 col-xs-12">
            <Card title="Card title"  className="tarjeta" bordered={false}>
                <div>
                    <h4>2) AÑADE TU IMAGEN O TEXTO:</h4>
  <br></br>
                    <p>Sube varias im&aacute;genes, tu logo o lema. Tienes libertad total.</p>
                </div>
                <div class="icon"><img src="https://cdn2.crealo.es/themes/base/assets/icons/edit.svg" alt=""/></div>
            </Card>
            </div>
            <div className="col-md-3 col-xs-12">
            <Card title="Card title"  className="tarjeta" bordered={false}>
                <div>
                    <h4>3) CALCULA TU PRECIO:</h4>
  <br></br>
                    <p>Indica la cantidad que deseas y el tipo de producci&oacute;n, est&aacute;ndar o r&aacute;pida.</p>
                </div>
                <div class="icon"><img src="https://cdn3.crealo.es/themes/base/assets/icons/calculator.svg" alt=""/></div>
            </Card>
            </div>
            <div className="col-md-3 col-xs-12">
            <Card title="Card title"  className="tarjeta" bordered={false}>
                <div>
                    <h4>4) AÑÁDELO AL CARRITO:</h4>
  <br></br>
                    <p>Ya tienes tu pedido listo, as&iacute; de f&aacute;cil.</p>
                </div>
                <div class="icon"><img src="https://cdn1.crealo.es/themes/base/assets/icons/box.svg" alt=""/></div>
            </Card>
            </div>
       
        </div>
        <br></br>
            
          <h4>
            <strong> Usa el código "ENVIOGRATISPOLO" en tu compra y el envío sera gratis! (debes comprar un polo personalizado para aplicar la promoción)</strong>
          </h4>
        
          <div className="h-box row mb-4">
            <LateralBar
              view={this.state.view}
              products={this.state.products}
              product={this.state.product}
              onProductChange={this.handleChangeProduct}
              onAddSticker={this.handleAddSticker}
              dimension={this.state.dimension}
              prints={this.props.prints}
              onAddImage={this.handleAddImage}
              onAddText={this.handleStatusText}
              statusText={this.state.statusText}
              changeTextSize={this.changeTextSize}
              textSize={this.state.textSize}
              isLoading={this.state.isLoading}
            />

            {this.state.product && (<>
              <div className="col-lg-6 mt-4">
                <div className="border-shadow-custom p-5">
                  {this.state.view == 1 && <Editor
                    ref={(it) => (this.editor = it)}
                    rectangles={this.state.rectangles}
                    image={
                      this.state.product
                        ? this.state.product.images.find(
                          (image) => image.view === 1
                        ).uri
                        : null
                    }
                    sticker={this.state.sticker}
                    stickers={this.state.stickers}
                    width="500px"
                    style={{ margin: 'auto' }}
                    color={this.state.color}
                    onClearStickers={this.handleClearStickers}
                    onFocusSticker={this.handleSelectedSticker}
                    view={1}
                    onUpdateStickers={this.handleUpdateStickers}
                    onChangeDimension={this.handleChangeDimension}
                    product={this.state.product}
                    statusText={this.state.statusText}
                    setStatusText={this.setStatusText}
                    textSize={this.state.textSize.max}
                    handleAddTexts={this.handleAddTexts}
                    texts={this.state.texts}
                  />}
                  {this.state.view == 2 && <Editor
                    ref={(it) => (this.editor2 = it)}
                    rectangles={this.state.rectangles}
                    image={
                      this.state.product
                        ? this.state.product.images.find(
                          (image) => image.view === 2
                        ).uri
                        : null
                    }
                    sticker={this.state.sticker}
                    stickers={this.state.stickers}
                    width="500px"
                    style={{ margin: 'auto' }}
                    color={this.state.color}
                    onClearStickers={this.handleClearStickers}
                    onFocusSticker={this.handleSelectedSticker}
                    view={2}
                    onUpdateStickers={this.handleUpdateStickers}
                    onChangeDimension={this.handleChangeDimension}
                    product={this.state.product}
                    statusText={this.state.statusText}
                    setStatusText={this.setStatusText}
                    textSize={this.state.textSize.max}
                    handleAddTexts={this.handleAddTexts}
                    texts={this.state.texts}
                  />}
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="border-shadow-custom p-5">
                  <RightBar
                    product={this.state.product}
                    onChangeView={this.handleChangeView}
                    isChecked={this.state.image_optimized}
                    ImageOptimized={this.ImageOptimized}
                    view={this.state.view}
                    stickers={this.state.stickers}
                    sticker={this.state.sticker}
                    onRemove={this.handleRemoveSticker}
                    color={this.state.color}
                    colors={this.state.colors}
                    onChangeColor={this.handleChangeColor}
                    onSelectedSticker={this.handleSelectedSticker}
                    variants={this.state.variants}
                    sizes={this.state.sizes}
                    onChangeSizes={this.handleChangeSizes}
                    total={this.getTotal()}
                    onBuy={this.handleBuy}
                    description={this.state.description}
                    onChangeDescription={(description) =>
                      this.setState({ description })
                    }
                  />
                </div>
              </div>
            </>
            )}

            <div className="medidas row">
            <h6>Medidas</h6>
            <div class="col-md-3 col-xs-12">
                <img
                    src={`/assets/images/polop.png`}
                    alt=""
                    style={{ width: 250 }}
                    className="img-fluid align-self-center"
                  />
            </div>
            <div className="col-md-6 col-xs-12" >
                <table className="tallas" width="100%" style={{ marginTop:'20px', marginLeft:'-20px' }}>
                    <thead>
                      <tr>
                        <th></th>
                        <th colSpan="4">Hombre</th>
                        <th colSpan="4">Mujer</th>
                        
                      </tr>
                      <tr>
                        <th>Medidas</th>
                        <th>S</th>
                        <th>M</th>
                        <th>L</th>
                        <th>XL</th>
                        <th>S</th>
                        <th>M</th>
                        <th>L</th>
                        <th>XL</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Alto (Frontal)</td>
                            <td>50</td>
                            <td>52</td>
                            <td>55</td>
                            <td>56</td>
                            <td>44</td>
                            <td>46</td>
                            <td>47</td>
                            <td>48</td>
                        </tr>
                        <tr>
                            <td>Ancho (Frontal)</td>
                            <td>40</td>
                            <td>42</td>
                            <td>45</td>
                            <td>46</td>
                            <td>35</td>
                            <td>36</td>
                            <td>37</td>
                            <td>38</td>
                        </tr>
                        <tr>
                            <td>Alto (Reverso)</td>
                            <td>60</td>
                            <td>62</td>
                            <td>65</td>
                            <td>66</td>
                            <td>54</td>
                            <td>56</td>
                            <td>57</td>
                            <td>58</td>
                        </tr>
                        <tr>
                            <td>Ancho (Reverso)</td>
                            <td>40</td>
                            <td>42</td>
                            <td>45</td>
                            <td>46</td>
                            <td>34</td>
                            <td>36</td>
                            <td>37</td>
                            <td>38</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>

            <div className="col-md-3 col-xs-12">
               <p class="red2">Las medidas pueden variar +-1.5cm y recuerda que las prendas al lavarlas se pueden encoger entre 1 y 2cm, para que no se encoja mucho, recomendamos no secar a altas temperaturas.</p>
            </div>
            
             </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ prints, user }) => ({ prints, user });

export default connect(mapStateToProps, { addToCart })(Custom);
