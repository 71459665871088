import React from 'react'

class DashboardData extends React.Component{
    render(){
        return (
            <div className="dashboard-right">
                <div className="dashboard border-shadow-custom">
                    <div className="page-title">
                        <h2 className=" font-sand">Cuenta</h2>
                    </div>
                    <div className="welcome-msg">
                        <p className=" font-sand mb-2">Hola, {this.props?.user?.name} !</p>
                        <p className=" font-sand font-weight-500">Desde el Panel de control de Mi cuenta, tiene la capacidad de ver una instantánea de la actividad reciente de su cuenta y actualizar la información de su cuenta.
                            Seleccione un enlace a continuación para ver o editar información.</p>
                    </div> 
                    <div className="box-account box-info">
                        <div className="box-head">
                            <h2 className=" font-sand">Informacion de su cuenta</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <div className="box">
                                    <div className="box-title font-sand">
                                        <h3 className=" font-sand">Informacion de Contacto</h3>
                                        <a href="#" className=" font-sand">Edit</a>
                                    </div>
                                    <div className="box-content font-sand">
                                        <h6>{this.props?.user?.name}</h6>
                                        <h6>{this.props?.user?.email}</h6>
                                        <h6><a href="/" className=" font-sand">Cambiar contraseña</a></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <div className="box">
                                    <div className="box-title">
                                        <h3 className=" font-sand">Novedades</h3>
                                        <a href="#" className=" font-sand">Editar</a>
                                    </div>
                                    <div className="box-content">
                                        <p>
                                            Actualmente no está suscrito a ningún boletín.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="box">
                                <div className="box-title">
                                    <h3 className=" font-sand">Manejo de direcciones</h3>
                                    <a href="#" className=" font-sand">Direcciones</a>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 mb-4">
                                        <h6 className=" font-sand">Direccion de pago por defecto</h6>
                                        <address className=" font-sand">
                                            No ha establecido una dirección de facturación predeterminada.<br/>
                                            <a href="#">Editar direccion</a>
                                        </address>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <h6 className=" font-sand">Direccion de despacho por defecto</h6>
                                        <address className=" font-sand">
                                            No tienes una direccion de despacho por defecto.<br />
                                            <a href="#">Editar direccion</a>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardData;