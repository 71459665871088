import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  APP_NAME,
  PROMOTION_CODE_PERCENTAGE,
  PAYMENT_TYPE_TRANSFER,
  PROMOTION_CODE_SHIPPING,
  PROMOTION_CODE_FIXED_AMOUNT,
  PAYMENT_TYPE_PAYPAL,
  LIMA_PROVINCE,
  IN_LIMA_SHIPPING,
  OUTSIDE_LIMA_SHIPPING,
  LIMA_REGION,
  moneyFormat,
} from '../../utils';
import { PayPalButton as PaypalExpressBtn } from 'react-paypal-button-v2';
import SimpleReactValidator from 'simple-react-validator';
import Breadcrumb from '../common/breadcrumb';
import { removeFromWishlist } from '../../actions';
import {
  getCartTotal,
  PromotionCodeService,
  CheckoutService,
  RegionService,
  EventService,
  UserService,
} from '../../services';
import { Globals, STATUS_ACTIVE } from '../../utils';
import { removeCart } from '../../actions';
import { Button, File, Modal } from '../../widgets';
import Select from 'react-select';
import defaultImage from '../../assets/images/image_defaoult.jpg';
import Image from '../../widgets/Image';
import moment from 'moment';
import CreateAddress from './create-address';
class checkOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: 'transfer',
      showAddressModal: false,
      adult: false,
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      country: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
      create_account: '',
      addresses: [],
      address_id: '',
      disabledSearch: false,
      isSending: false,
      form: {
        ...this.props.user,
        reseller_id: '',
        reseller_code_id: '',
        promotion_code: '',
        city: '',
        state: '',
        total: this.props.total,
        pincode: '',
        subtotal: this.props.total,
        totalShipping: 0,
        totalFixed: 0,
        totalPercentage: 0,
        products: this.props.cartItems || [],
        district_id: '',
        region_id: '',
        province_id: '',
        image: null,
        aditionalShipping: 0,
        toAnotherPerson: false,
      },
      promotion_code: null,
      discounts: {
        shipping: {
          name: 'Descuentos de envío',
          total: 0,
          products_applied: [],
        },
        percentage: {
          name: 'Descuentos por porcentaje',
          total: 0,
          products_applied: [],
        },
        fixed: {
          name: 'Descuentos fijos',
          total: 0,
          products_applied: [],
        },
      },
      regions: [],
      provinces: [],
      districts: [],
      events: [],
      events_text: [],
      hideColumns: true,
      aditionalShipping: IN_LIMA_SHIPPING,
      calcBase: IN_LIMA_SHIPPING,
      oldAditionalShipping: 0,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (!this.props.cartItems.length) {
      this.props.history.push('/');
    } else {
      this.load();
    }
    this.getEvents();
  }
  getEvents = async () => {
    try {
      const res = await EventService.getCheckout();
      const events_text = res.map(
        (element) =>
          `(${moment(element.since).format('DD-MM-YYYY') +
            ' - ' +
            moment(element.until).format('DD-MM-YYYY')}) - ${element.message}`
      );
      this.setState({
        events_text,
        events: res,
      });
    } catch (error) {
      console.log('>>: error > ', error);
    }
  };
  load = async () => {
    try {
      let { products, total } = this.state.form;
      total = 0;

      products = products.map((element) => {
        const subTotal = this.getListSubtotal(element);
        total += parseFloat((subTotal * element.qty).toFixed(2));
        return {
          ...element,
          subTotal,
        };
      });
      const regions = await RegionService.get();
      const user = await UserService.getUserData();
      this.setState(
        {
          regions,
          addresses: user.addresses || [],
          form: {
            ...this.state.form,
            products,
            total,
            subtotal: total,
          },
        },
        () => this.setAditionalShipping()
      );
    } catch (e) {
      Globals.showError();
      console.log('>>: error > ', e);
    }
  };
  changeAddress = (item) => {
    const address = this.state.addresses.find(
      (element) => element.id === item.value
    );
    if (address) {
      const { district } = address;
      this.setState({
        form: {
          ...this.state.form,
          district_id: {
            label: district.name,
            value: district.id,
          },
          address: item,
        },
        address_id: item,
      });
    }
  };
  setAditionalShipping = (isFirstLoad = true) => {
    const totalItems = this.state.form.products.reduce(
      (a, b) => +parseInt(a) + +parseInt(b.qty),
      0
    );
    let { region_id, aditionalShipping, total } = this.state.form;
    aditionalShipping = 0;
    let calcBase = 0;
    if (region_id && region_id['value'] === LIMA_REGION) {
      aditionalShipping += IN_LIMA_SHIPPING;
    } else {
      calcBase = OUTSIDE_LIMA_SHIPPING;
      aditionalShipping +=
        OUTSIDE_LIMA_SHIPPING * totalItems + IN_LIMA_SHIPPING;
    }
    if (!isFirstLoad) total -= this.state.oldAditionalShipping;
    else aditionalShipping = IN_LIMA_SHIPPING;
    if (total >= 200) {
      aditionalShipping -= IN_LIMA_SHIPPING;
    }
    total += aditionalShipping;
    this.setState({
      calcBase,
      oldAditionalShipping: aditionalShipping,
      form: {
        ...this.state.form,
        aditionalShipping,
        total,
      },
    });
  };

  changeRegion = (item) => {
    const { value: region_id } = item;
    const provinces =
      this.state.regions.find((element) => element.id === region_id)
        .provinces || [];
    this.setState(
      {
        form: {
          ...this.state.form,
          region_id: item,
          province_id: '',
          district_id: '',
        },
        provinces,
      },
      () => this.setAditionalShipping(false)
    );
  };

  changeProvince = (item) => {
    const { value: province_id } = item;
    const districts =
      this.state.provinces.find((element) => element.id === province_id)
        .districts || [];
    this.setState({
      aditionalShipping:
        province_id === LIMA_PROVINCE
          ? IN_LIMA_SHIPPING
          : OUTSIDE_LIMA_SHIPPING,
      form: {
        ...this.state.form,
        province_id: item,
        district_id: '',
      },
      districts,
    });
  };

  changeDistrict = (item) => {
    this.setState({
      form: {
        ...this.state.form,
        district_id: item,
      },
    });
  };

  setStateFromCheckbox = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        toAnotherPerson: !!event.target.checked,
      },
    });
  };

  checkhandle(value) {
    this.setState({
      payment: value,
    });
  }

  change = async (e) => {
    const name = e.target.name;
    this.setState({
      form: {
        ...this.state.form,
        changed: !this.state.form.change && name !== 'promotion_code',
        [name]: e.target.value,
      },
    });
  };

  searchCode = async () => {
    try {
      const query = {
        amount: this.state.form.total,
        promotion_code: this.state.form.promotion_code,
      };

      const response = await PromotionCodeService.get(query);
      console.log('>>: response: ', response);
      const { code: res } = response;

      if (!res)
        return alert('No se ha hallado un descuento valido para este código');

      if (!res.promotion_code)
        return alert('No se ha hallado un descuento valido para este código');

      const form = {
        ...this.state.form,
        subtotal: 0,
        total: 0,
        reseller_id: res.reseller_id,
        reseller_code_id:
          (res.reseller_id &&
            res.promotion_code &&
            res.promotion_code.code &&
            res.promotion_code.code.id) ||
          null,
      };

      const discounts = { ...this.state.discounts };

      let disabledSearch = true;
      form.products = form.products.map((element) => {
        const {
          originalPrice,
          offer_price,
          discount_rate,
          qty,
          has_discount,
        } = element;

        //<editor-fold desc="EN ESTE BLOQUE DE CÓDIGO SE BUSCA OBTENER EL PORCENTAJE DE DESCUENTO DEFINITIVO">
        const offer_price_rate = has_discount // Porcentaje de descuento entre el precio lista del producto (originalPrice) y el precio oferta del mismo (offer_price)
          ? 1 - offer_price / originalPrice
          : 0;
        const product_discount_rate = parseFloat(discount_rate); // Porcentaje de descueto maximo pertitido por el producto
        const promotion_discount_rate =
          !!res.promotion_code && !!res.promotion_code.discount_rate // Porcentaje de descuento de la promocion
            ? res.promotion_code.discount_rate / 100
            : 0;
        const promotion_discount_rate_applied =
          product_discount_rate > promotion_discount_rate // Porcentaje de descuento aplicado comparando el porcentaje de descuanto maximo permitido con el porcentaje de descuento de la promocion
            ? promotion_discount_rate
            : product_discount_rate;

        const rate = Math.max(
          offer_price_rate,
          promotion_discount_rate_applied
        ); // Elige el porcentaje que de el mayor descuento entre el porcentaje de la promocion y el porcentaje del precio oferta
        //</editor-fold>

        // if (!have_offer_price) {
        switch (res.promotion_code.code.id) {
          case PROMOTION_CODE_PERCENTAGE:
            const subtotal = originalPrice - originalPrice * rate;
            const total = subtotal * qty;
            element = {
              ...element,
              subTotal: subtotal,
              percentageDiscount: rate,
              total,
              anyDiscountApplied: true,
              percentageDiscountApplied: !!rate,
              percentageValue: rate,
            };

            break;
          case PROMOTION_CODE_SHIPPING:
            const totalShipping = promotion_discount_rate;
            form.total -= totalShipping;
            element = {
              ...element,
              total: element.total - totalShipping,
              shippingValue: totalShipping,
              shippingDiscountApplied: true,
              anyDiscountApplied: true,
            };
            discounts.shipping = {
              ...discounts.shipping,
              total: discounts.shipping.total + totalShipping,
            };
            break;
          case PROMOTION_CODE_FIXED_AMOUNT:
            const AMOUNT_TO_EXCEED = promotion_discount_rate;
            if (form.total >= AMOUNT_TO_EXCEED) {
              form.total -= AMOUNT_TO_EXCEED;
              element = {
                ...element,
                total: element.total - AMOUNT_TO_EXCEED,
                fixedValue: AMOUNT_TO_EXCEED,
                fixDiscountApplied: true,
                anyDiscountApplied: true,
              };
              discounts.fixed = {
                ...discounts.fixed,
                total: discounts.fixed.total + AMOUNT_TO_EXCEED,
              };
            }
            break;
          default:
            form.total += 0;
            break;
        }
        form.subtotal += element.total;
        form.total += element.total;
        return element;
      });
      form.total = parseFloat(form.total.toFixed(2));
      this.setState(
        {
          disabledSearch,
          promotion_code: res.promotion_code,
          form,
          discounts,
        },
        () => this.setAditionalShipping()
      );
    } catch (e) {
      console.log('>>: error  > ', e);
    }
  };

  onSuccess = async (payment) => {
    try {
      const total = this.state.form.total || this.state.form.subtotal;
      const {
        region_id,
        province_id,
        aditionalShipping,
        products,
        toAnotherPerson,
        image,
        name,
        phone,
        email,
        district_id,
        ref_number,
        address,
        reseller_id,
        reseller_code_id,
      } = this.state.form;
      const _products = products.map((it) => {
        return it.stickers
          ? {
              ...it,
              custom_id: it.id,
              id: it.product.id,
            }
          : it;
      });
      const params = {
        promotion_code: JSON.stringify(this.state.promotion_code),
        payment: JSON.stringify(payment),
        total,
        reseller_code_id: reseller_code_id || '',
        reseller_id: reseller_id || '',
        image,
        calcBase: this.state.calcBase,
        aditionalShipping,
        address,
        user: JSON.stringify({
          address,
          name,
          phone,
          email,
          district_id,
          ref_number,
        }),
        products: JSON.stringify(_products),
        toAnotherPerson,
        type:
          this.state.payment === 'transfer'
            ? PAYMENT_TYPE_TRANSFER
            : PAYMENT_TYPE_PAYPAL,
      };

      const res = await CheckoutService.save(params);
      await this.props.removeCart();
      const state = {
        payment: payment || res.payment || null,
        items: this.state.form.products,
        orderTotal: total,
        shippingValue: aditionalShipping,
        symbol: this.props.symbol,
        address: {
          address,
          district_id,
          region_id,
          province_id,
        },
      };
      this.props.history.push({
        pathname: '/order-success',
        state,
      });
      Globals.showSuccess(res.msg);
    } catch (e) {
      const message =
        e.response && e.response.data && e.response.data.msg
          ? e.response.data.msg
          : 'Disculpe ha ocurrido un error';
      Globals.showError(message);
      console.log('>>: error > ', e);
    } finally {
      this.setState({
        isSending: false,
      });
    }
  };

  image = (product) => {
    const images = product.images;
    if (!images || !images.length) return defaultImage;
    const image = images[0];
    return image.uri || image.url;
  };

  name = (item) => {
    const product = item.product ? item.product : item;
    return product.name;
  };

  id = (item) => {
    const product = item.product ? item.product : item;
    return product.id;
  };

  getTotal = (item, discount = 0) => item.price * item.qty - discount;

  priceLabel = (product) => {
    const { price, originalPrice } = product;

    const rate =
      product.anyDiscountApplied && product.percentageDiscountApplied
        ? product.percentageValue * 100
        : 100 - (price / (originalPrice || price)) * 100;

    const mount = (rate / 100) * (originalPrice || price);

    const signedRate = rate <= 0 ? '' : '-';

    return (
      <p className="w-100 text-dark text-right">
        {moneyFormat(-mount)}
        <br />
        {`${signedRate}${rate.toFixed(2)} %`}
      </p>
    );
  };

  hasDiscount = (item) => {
    let value,
      extras = null;
    if (item.percentageDiscountApplied) {
      value = '-' + (parseFloat(item.percentageValue) * 100).toFixed(2) + ' %';
      extras = item.percentageDiscount;
    } else if (item.shippingDiscountApplied) value = item.shippingValue;
    else if (item.fixDiscountApplied) value = item.fixedValue;
    else value = '-';

    return (
      <p className="w-100 text-dark text-right">
        {moneyFormat(extras)}
        <br />
        {value}
      </p>
    );
  };

  getListSubtotal = (item, discount_code = false) => {
    let {
      qty,
      originalPrice,
      total,
      offer_price,
      has_discount: has_discount_value,
      discount_rate,
    } = item;

    let subtotal = 0;
    const has_discount = has_discount_value === STATUS_ACTIVE;
    discount_rate = parseFloat(discount_rate);
    if (discount_code && discount_code <= discount_rate)
      discount_rate = discount_code;
    originalPrice = parseFloat(originalPrice);

    let calc_price = !discount_code ? parseFloat(offer_price) : originalPrice;

    if (offer_price) offer_price = parseFloat(offer_price);

    if (total) total = parseFloat(total);

    if (has_discount) {
      if (!total || total > offer_price) {
        subtotal = offer_price;
      } else {
        subtotal = total;
      }
    } else {
      subtotal = total || originalPrice;
    }

    if (discount_code) {
      const _subtotal = parseFloat((calc_price * discount_rate).toFixed(2));
      const _finalPrice = _subtotal * qty;
      if (offer_price <= _finalPrice) {
        calc_price = offer_price;
      }
      if (_subtotal <= subtotal) {
        subtotal = calc_price;
      }
    }

    return subtotal;
  };

  hideColumns = () => {
    this.setState({
      hideColumns: !this.state.hideColumns,
    });
  };

  closeAddress = () => {
    this.setState(
      {
        showAddressModal: false,
      },
      () => {
        this.load();
      }
    );
  };

  openAddress = () => {
    this.setState({
      showAddressModal: true,
    });
  };
  handleChangeRadio = (event) => {
    if (event.target.value === '1') {
      this.setState({
        adult: true,
      });
    } else {
      this.setState({
        adult: false,
      });
    }
  };

  render() {
    const { symbol } = this.props;
    const { subtotal, total } = this.state.form;

    const onCancel = (data) => console.log('El pago ha sido cancelado!', data);

    const onError = (err) => console.log('Error!', err);

    const paypalEnv = process.env.REACT_APP_PAYPAL_ENV;

    const client = {
      sandbox: process.env.REACT_APP_PAYPAL_KEY_SANDBOX,
      production: process.env.REACT_APP_PAYPAL_KEY_PRODUCTION,
    };

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>{APP_NAME} | Pagar </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={'Checkout'} />
        <Modal
          visible={this.state.showAddressModal}
          title="Crear direcciones"
          onClose={() => this.closeAddress()}
        >
          <CreateAddress onClose={() => this.closeAddress()} />
        </Modal>
        {this.state.events_text && (
          <div className="w-100 text-center">
            <div className="container">
              <h4 className="text-dark font-weight-bold p-2 m-0">
                Eventos importantes
              </h4>
            </div>
            {this.state.events.map((element, i) => {
              return (
                <h5
                  key={i}
                  className="bar_content p-1"
                  style={{
                    backgroundColor: element.color || '',
                    color: element.text_color || '',
                  }}
                >
                  {element.message}
                </h5>
              );
            })}
          </div>
        )}
        <section className="section-b-space">
          <div className="container padding-cls">
            <div className="checkout-page">
              <div className="checkout-form">
                <form>
                  <div className="checkout row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                      <div className="checkout-title">
                        <h3>
                          <strong> Detalle de pago</strong>
                        </h3>
                      </div>
                      <div className="row check-out">
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <div className="field-label">Nombre</div>
                          <input
                            type="text"
                            name="name"
                            value={this.state.form.name}
                            onChange={this.change}
                          />
                          {this.validator.message(
                            'first_name',
                            this.state.form.name,
                            'required|alpha'
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Teléfono</div>
                          <input
                            type="text"
                            name="phone"
                            value={this.state.form.phone}
                            onChange={this.change}
                          />
                          {this.validator.message(
                            'phone',
                            this.state.form.phone,
                            'required|phone'
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                          <div className="field-label">Email</div>
                          <input
                            type="text"
                            name="email"
                            value={this.state.form.email}
                            onChange={this.change}
                          />
                          {this.validator.message(
                            'email',
                            this.state.form.email,
                            'required|email'
                          )}
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <div className="field-label">
                            Direccion
                            <Button
                              onClick={() => this.openAddress()}
                              small={true}
                              type="button"
                            >
                              <i className="fa fa-plus" />
                            </Button>
                          </div>
                          <Select
                            options={this.state.addresses.map((element) => {
                              const { id: value } = element;
                              const label =
                                element.district.region.name +
                                ' - ' +
                                element.district.province.name +
                                ', ' +
                                element.district.name +
                                ' | ' +
                                element.address;
                              return {
                                label,
                                value,
                              };
                            })}
                            onChange={this.changeAddress}
                            value={this.state.address_id}
                            placeholder="Seleccione su direccion"
                            noOptionsMessage={() =>
                              'No hay registros, debe añadir direcciones en su perfil'
                            }
                          />
                        </div>
                        {this.state.payment === 'transfer' && !!this.state.address_id && (
                          <React.Fragment>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                              <div className="field-label">
                                Numero de referencia de la transferencia
                              </div>
                              <input
                                type="text"
                                name="ref_number"
                                value={this.state.form.ref_number}
                                onChange={this.change}
                              />
                              {this.validator.message(
                                'address',
                                this.state.form.ref_number,
                                'required|min:20|max:200'
                              )}
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                              <div className="field-label">
                                Comprobante de transferencia
                              </div>
                              <File
                                placeholder="Agregar Comprobante"
                                placeholderSuccess="Comprobante Agregado"
                                showCheck={true}
                                onChange={this.change}
                                name="image"
                                value={this.state.form.image}
                                inputStyle={{
                                  display: 'contents',
                                }}
                                className="btn-product"
                              />
                              {this.validator.message(
                                'address',
                                this.state.form.district_id,
                                'required|min:20|max:200'
                              )}
                            </div>

                            
                          </React.Fragment>
                        )}
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <input
                            type="checkbox"
                            name="create_account"
                            id="account-option"
                            checked={this.state.form.toAnotherPerson}
                            onChange={this.setStateFromCheckbox}
                          />
                          &ensp;
                          <label htmlFor="account-option">
                            Esta compra es para otro destinatario?
                          </label>
                          {this.validator.message(
                            'checkbox',
                            this.state.form.toAnotherPerson,
                            'create_account'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                      <div className="checkout-details">
                        <div className="order-box ">
                          <div className="w-100 text-center">
                            <button
                              className="btn btn-primary btn-small"
                              onClick={() => this.hideColumns()}
                              type="button"
                            >
                              <i
                                className={
                                  'fa fa-' +
                                  (this.state.hideColumns ? 'plus' : 'minus')
                                }
                              />
                            </button>
                          </div>
                          <div
                            className="row cursor-pointer"
                            onClick={() => this.hideColumns()}
                          >
                            <div className="col-md col-sm-12">Producto</div>
                            {this.state.hideColumns && (
                              <div className="col-md col-sm-12 text-right">
                                Precio lista
                              </div>
                            )}
                            {this.state.hideColumns && (
                              <div className="col-md col-sm-12 text-right">
                                Descuento
                              </div>
                            )}
                            <div className="col-md col-sm-12 text-right">
                              Precio final
                            </div>
                            <div className="col-md col-sm-12 text-right">
                              Subtotal
                            </div>
                          </div>
                          <ul className="qty">
                            {this.state.form.products.map((item, index) => {
                              return (
                                <div key={index} className="row">
                                  <div className="col-md col-sm-12">
                                    <Image
                                      url={this.image(item)}
                                      style={{
                                        width: 50,
                                        height: 50,
                                      }}
                                    />
                                    {this.name(item) + ' x' + item.qty}
                                  </div>
                                  {this.state.hideColumns && (
                                    <div className="col-md col-sm-12 text-right">
                                      {moneyFormat(
                                        item.originalPrice || item.price
                                      )}
                                    </div>
                                  )}
                                  {this.state.hideColumns && (
                                    <div className="col-md col-sm-12">
                                      {this.priceLabel(item)}
                                    </div>
                                  )}
                                  <div className="col-md col-sm-12 text-right">
                                    {moneyFormat(item.subTotal)}
                                  </div>
                                  <div className="col-md col-sm-12 text-right">
                                    {moneyFormat(
                                      item.total ||
                                        (
                                          this.getListSubtotal(item) * item.qty
                                        ).toFixed(2)
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </ul>
                          <ul className="sub-total">
                            <li>
                              Subtotal
                              <span className="count">
                                {symbol}
                                {subtotal}
                              </span>
                            </li>
                            <li>
                              <div className="row">
                                <div className="col-md-4 col-sm-6">
                                  <span>Cupon de Dcto.</span>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                  <div className="text-right">
                                    <div className="row">
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          name="promotion_code"
                                          onChange={this.change}
                                          value={this.state.form.promotion_code}
                                        />
                                      </div>
                                      <div className="col-4">
                                        <button
                                          type="button"
                                          className="btn-solid btn btn-small"
                                          onClick={this.searchCode}
                                          disabled={this.state.disabledSearch}
                                        >
                                          Buscar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul className="total">
                            {this.state.discounts.percentage.total > 0 && (
                              <li>
                                {this.state.discounts.percentage.name}
                                <span className="count">
                                  {symbol +
                                    '-' +
                                    this.state.discounts.percentage.total}
                                </span>
                              </li>
                            )}
                            {this.state.discounts.fixed.total > 0 && (
                              <li>
                                {this.state.discounts.fixed.name}
                                <span className="count">
                                  {symbol +
                                    ' -' +
                                    this.state.discounts.fixed.total}
                                </span>
                              </li>
                            )}
                            {this.state.discounts.shipping.total > 0 && (
                              <li>
                                {this.state.discounts.shipping.name}
                                <span className="count">
                                  {symbol +
                                    ' -' +
                                    this.state.discounts.shipping.total}
                                </span>
                              </li>
                            )}
                            <li title="Monto por coordinacion de envío">
                              Envío
                              <span className="count">
                                {symbol}
                                {this.state.form.aditionalShipping}
                              </span>
                            </li>
                            <li>
                              Total{' '}
                              <span className="count">
                                {symbol}
                                {total || subtotal}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="payment-box">
                          <div className="upper-box">
                            <div className="payment-options">
                              <ul>
                                <li>
                                  <div className="radio-option stripe">
                                    <input
                                      type="radio"
                                      name="payment-group"
                                      id="payment-2"
                                      defaultChecked={true}
                                      onClick={() =>
                                        this.checkhandle('transfer')
                                      }
                                    />
                                    <label htmlFor="payment-2">
                                      Transferencia
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="radio-option paypal">
                                    <input
                                      type="radio"
                                      name="payment-group"
                                      id="payment-1"
                                      onClick={() => this.checkhandle('paypal')}
                                    />
                                    <label htmlFor="payment-1">
                                      PayPal
                                      <span className="image">
                                        <img
                                          src={`/assets/images/paypal.png`}
                                          alt=""
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {(total > 0 || subtotal > 0) && (
                            <div className="text-right">
                              {this.state.payment === 'transfer' ? (
                                <button
                                  type="button"
                                  className="btn-solid btn"
                                  disabled={this.state.isSending}
                                  onClick={() => {
                                    if (!this.state.isSending) {
                                      this.setState(
                                        {
                                          isSending: true,
                                        },
                                        () => this.onSuccess()
                                      );
                                    }
                                  }}
                                >
                                  Reservar pedido
                                </button>
                              ) : (
                                <PaypalExpressBtn
                                  env={paypalEnv}
                                  client={client}
                                  currency={'USD'}
                                  // amount={total || subtotal}
                                  amount={1}
                                  onError={onError}
                                  onSuccess={this.onSuccess}
                                  onCancel={onCancel}
                                  options={{
                                    clientId: client[paypalEnv],
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-t-space">
                    <div className="col-lg-6">
                      <div className="stripe-section">
                        <h5>stripe js example</h5>
                        <div>
                          <h5 className="checkout_class">Nombre de persona</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>numero de tarjeta</td>
                                <td>4242424242424242</td>
                              </tr>
                              <tr>
                                <td>mm/yy</td>
                                <td>2/2020</td>
                              </tr>
                              <tr>
                                <td>cvc</td>
                                <td>2222</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 m-sm-t-2">
                      <div className="stripe-section">
                        <h5>paypal</h5>
                        <div>
                          <h5 className="checkout_class">Nombre de persona</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>numero de tarjeta</td>
                                <td>4152521541244</td>
                              </tr>
                              <tr>
                                <td>mm/yy</td>
                                <td>11/18</td>
                              </tr>
                              <tr>
                                <td>cvc</td>
                                <td>521</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: (state.user && state.user.user) || null,
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, {
  removeFromWishlist,
  removeCart,
})(checkOut);
