import React, { useEffect, useState } from 'react';
//import checkout from '../pages/niubiz/desacoplado.html';
import { Modal, Spinner } from 'react-bootstrap';
import KRGlue from '@lyracom/embedded-form-glue';
import './styles.scss';
import IzipayService from '../../services/modules/IzipayService';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';
import { connect } from 'react-redux';
import moment from 'moment';
import PagoEfectivoService from '../../services/modules/PagoEfectivoService';
function ModalPagoEfectivo({
  handlePagoEfectivoResponse,
  sendPayment,
  setPaymentMethod,
  pagoEfectivoTotal,
  size = 'md'
}) {
  const [showModal, setShowModal] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [activePayment, setActivePayment] = useState(false);
  const user = !!localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state'))
    : '';
  const {
    email,
    name,
    last_name,
    phone,
    document_type_text,
    ruc,
  } = user?.user?.user;
  const [title, setTitle] = useState('Cargando formulario...');
  const [loading, setLoading] = useState(false);

  const callAuthorization = async () => {
    setLoading(true);
    try {
      let accessKey = 'OWUyZTZjZDEyMjQyYTU0';
      let idService = '19702';  
      let date = new Date();
      let dateRequest = moment(date)
        .utcOffset('GMT-05:00')
        .format();
      let secretKey = 'ZrAj+FZ6eZfUNdztVGz91F+jUjIbh4g6+dD66LNs';
      let parametro =
        idService.toString() +
        '.' +
        accessKey +
        '.' +
        secretKey +
        '.' +
        dateRequest;
      let hash = sha256(parametro);
      console.log(hash);
      const data = {
        accessKey: accessKey,
        idService: idService,
        dateRequest: dateRequest,
        hashString: hash.toString(),
        enviroment: 'PRODUCCION',
      };

      const auth = await PagoEfectivoService.authorization(data);
      setAuthToken(auth);
      setLoading(false);
    } catch (e) {
      console.log('>>: error > e', e);
      setLoading(false);
    }
  };

  const generateCIP = async () => {
    setLoading(true);
    try {
      let date = new Date() + 12;

      const data = {
        tokenAuth: authToken,
        amount: pagoEfectivoTotal+4,
        dateExpiry: moment(date)
          .utcOffset('GMT-05:00')
          .add(12, 'hours')
          .format(),
        paymentConcept: 'Compra en Compra Facil',
        additionalData: '[comprafacil]-[19702]-[ecommerce]',
        userEmail: email,
        userName: name,
        userLastName: last_name,
        userDocumentType: document_type_text,
        userDocumentNumber: ruc,
        userCodeCountry: '+51',
        userPhone: phone,
        enviroment: 'PRODUCCION', 
      };

      const cipResponse = await PagoEfectivoService.cip(data);
      console.log(data);

      console.log(cipResponse);


      setLoading(false);
      if (!!cipResponse.data.cip) {
        handlePagoEfectivoResponse(cipResponse.data.cip);
        sendPayment();
        window.open(cipResponse.data.cipUrl, '_blank');
        setShowModal(false);
      }
    } catch (e) {
    //  console.log('>>: error > e', e);
      setLoading(false); 
    }
  };

  useEffect(() => {
    if (activePayment) {
      callAuthorization();
    }
    // console.log(loading);
  }, [activePayment]);

  useEffect(() => {
    if (!!authToken) {
      generateCIP();
    }
  }, [authToken]);

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentMethod(0);
  };
  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size={size} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="font-sand">
            PagoEfectivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? <Spinner animation="border" variant="primary" /> : null}
          <div className="row">


          <div className="col-12">
              <div className="p-4 border-shadow-custom">
                <p className="font-sand text-dark font-weight-normal d-flex">
                  <input type="radio" checked className="mr-2 align-self-center" />
                  <div className="align-self-center">Banca móvil, banca por internet, agentes y bodegas vía PagoEfectivo</div>
                </p>

                <p className="font-sand text-dark font-weight-normal">
                  <strong><i className="fa fa-check"></i> Banca Móvil / Internet - </strong><span>Paga en BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa y Banco Pichincha, a través de la opción pago de servicios.</span>
                </p>
                <p className="font-sand text-dark font-weight-normal">
                  <strong><i className="fa fa-check"></i> Agentes y Bodegas - </strong><span>Deposita en BBVA, BCP, Interbank, Scotiabank, BanBif, Western Union, Tambo+, Kasnet, Ya Ganaste, Red Digital, Comercio Niubiz Multiservicio, MoneyGram, Caja Arequipa, Disashop, Cellpower.</span>
                </p>

                <p className="d-flex flex-wrap justify-content-center" style={{ marginTop: 30 }}>
                  {React.Children.toArray([
                    'agente_red_digital', 'banbif', 'banco_de_la_nacion', 'banco_pichincha', 'bbva', 'bcp', 'caja_arequipa', 'caja_del_santa', 'caja_los_andes', 'caja_paita', 'caja_raiz', 'caja_sullana', 'caja_trujillo', 'cell_power', 'disashop', 'interbank', 'kasnet', 'money_gram', 'niubiz', 'scotiabank', 'tambo', 'western_union', 'ya_ganaste'
                  ]).map(i => {
                    return <img src={'./assets/images/pagos/' + i + '.png'} style={{ width: 100, marginBottom: 10, marginRight: 10 }} />
                  })}
                </p>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center mt-5">




           
              <div
                className="btn btn-solid"
                onClick={() => setActivePayment(true)}
              >
                Generar CIP
              </div>


            </div>
  
 
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  pagoEfectivoTotal: state.checkout.total,
});
export default connect(mapStateToProps)(ModalPagoEfectivo);
