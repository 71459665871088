import React, {Component} from "react";
import "./legales.css"



class Legales extends Component {
  

  render() {
    return (
      <div className="legales">
      <div className="containerl">
        
        
        <h2>Términos y Condiciones de Uso y Política de Privacidad de COMPRA FACIL E.I.R.L</h2>
        
        <br/>

        
<p>Los presentes T&eacute;rminos y Condiciones de Uso (en adelante, <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;</strong>) regulan el uso de la Plataforma de Comercio Electr&oacute;nico &ndash; <strong>&ldquo;</strong><em>Marketplace</em>&rdquo; (en adelante, &ldquo;<strong>La Plataforma</strong>&rdquo;), mediante el cual <strong>COMPRA FACIL E.I.R.L </strong>(en adelante, <strong>&ldquo;COMPRA FACIL&rdquo;</strong>) presta el servicio de venta al por menor de terceros por internet de diversos productos (en adelante,<strong> &ldquo;el servicio de venta al por menor&rdquo;</strong>). Estos T&eacute;rminos y Condiciones de Uso regulan tanto el acceso y uso por parte del cliente de los servicios de venta al por menor de terceros ofrecidos por <strong>COMPRA FACIL</strong>.</p>
<p>&nbsp;</p>
<p>El acceso y utilizaci&oacute;n de la Plataforma atribuye al visitante (en adelante, &ldquo;<strong>El</strong> <strong>Cliente&rdquo;</strong>) e implica su declaraci&oacute;n expresa de conocer y aceptar plenamente todas las disposiciones, normas, instrucciones, responsabilidades y pol&iacute;ticas contenidas en los presentes T&eacute;rminos y Condiciones de Uso. En consecuencia; El Cliente debe leer detenidamente los presentes T&eacute;rminos y Condiciones de Uso cada vez que acceda a la Plataforma pues &eacute;stos podr&iacute;an sufrir variaciones sin previo aviso.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol>
<li><strong><u>Condiciones Generales.</u> </strong></li>
</ol>
<p>&nbsp;</p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL</strong> se rige por los &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo; descritos a continuaci&oacute;n, as&iacute; como por la legislaci&oacute;n que se aplique en la Rep&uacute;blica del Per&uacute;, espec&iacute;ficamente en lo establecido en la Ley No. 29571, C&oacute;digo de Protecci&oacute;n al Consumidor y Defensa del Consumidor. En consecuencia; todas las visitas y todas las transacciones que se realicen en esta Plataforma, como sus efectos jur&iacute;dicos, quedar&aacute;n regidos por estas reglas y sometidas a esta legislaci&oacute;n.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL </strong>se reserva el derecho de actualizar y/o modificar los T&eacute;rminos y Condiciones de Uso que se detallan a continuaci&oacute;n en cualquier momento, sin previo aviso. Por tal motivo; recomendamos revisar los T&eacute;rminos y Condiciones de Uso cada vez que utilice esta Plataforma. Es un requisito fundamental que, para adquirir alg&uacute;n producto o productos en <strong>COMPRA FACIL</strong>, Usted deba de aceptar y reconocer los T&eacute;rminos y Condiciones de venta descritos a continuaci&oacute;n.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL</strong> se compromete a que todos sus proveedores cuenten con el Registro &Uacute;nico de Contribuyentes (RUC) as&iacute; como con la condici&oacute;n de habido y encontrarse en estado activo, seg&uacute;n lo establecido por la Superintendencia Nacional de Aduanas y Administraci&oacute;n Tributaria (SUNAT). Adem&aacute;s, <strong>COMPRA FACIL </strong>asegurar&aacute; que todos sus proveedores tengan m&aacute;s de seis (6) meses en el mercado, as&iacute; como la experiencia vendiendo productos online.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL</strong> se responsabiliza por el funcionamiento correcto de la Plataforma Virtual; as&iacute; como por la informaci&oacute;n y/o publicidad difundida y brindada a los clientes y al p&uacute;blico en general. No obstante; <strong>COMPRA FACIL</strong> no se responsabiliza por la idoneidad de los productos ofrecidos y entregados a los clientes que adquieran dichos productos a trav&eacute;s de la Plataforma. <strong>COMPRA FACIL </strong>tampoco se responsabiliza por el servicio de despacho y entrega de productos adquiridos por los clientes a trav&eacute;s de la presente Plataforma &ndash; <strong>COMPRA FACIL</strong>.</p>
<p>&nbsp;</p>
<p>Con relaci&oacute;n a lo anterior; es importante se&ntilde;alar que <strong>COMPRA FACIL </strong>solamente facilita el contacto del Proveedor con el cliente final para hacer uso de la garant&iacute;a y/o alguna contingencia que surja por una devoluci&oacute;n que se requiera. Finalmente; <strong>COMPRA FACIL</strong> se compromete a seleccionar proveedores de confianza y que cumplan con todos los est&aacute;ndares de calidad del mercado.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="2">
<li><strong><u>M&eacute;todo de Venta:</u></strong></li>
</ol>
<p><strong><u>&nbsp;</u></strong></p>
<p>&nbsp;</p>
<p>En <strong>COMPRA FACIL </strong>queremos siempre ofrecerle una experiencia de compra que sea relevante y personalizada. Para esto tenemos condiciones de venta que rigen sus compras en nuestra Plataforma. Estas establecen sus derechos y condiciones con respecto a sus compras, incluyendo importantes limitaciones y exclusiones. La utilizaci&oacute;n de la Plataforma constituye la aceptaci&oacute;n de que estas condiciones, por lo que debe asegurarse de entenderlas antes de realizar sus pedidos:</p>
<p>&nbsp;</p>
<p>Los productos ofrecidos en la Plataforma electr&oacute;nica de <strong>COMPRA FACIL </strong>solo pueden ser pagados a trav&eacute;s de los medios que aparecen espec&iacute;ficamente en el carrito de compra. En ning&uacute;n caso se aceptar&aacute; la modalidad de pago denominada contra entrega. Sobre el particular; los &uacute;nicos m&eacute;todos de venta que se pueden utilizar en la Plataforma son los siguientes: (i) Pago con Tarjeta de cr&eacute;dito, (ii) Pago con tarjeta de d&eacute;bito (iii)Dep&oacute;sito en Cuenta, (iv) Transferencia Bancaria, (v) Yape y (vi) utilizaci&oacute;n del Sistema Pagoefectivo:</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>Pago con Tarjeta de Cr&eacute;dito: Al realizar una compra en <strong>COMPRA FACIL</strong> con tu tarjeta de cr&eacute;dito, el banco que emiti&oacute; la tarjeta se encarga de pagar al negocio el monto de tus compras, y posteriormente t&uacute; tendr&aacute;s que pagar al banco.&nbsp;Cada vez que haces un pago o una compra con tu tarjeta de cr&eacute;dito o que dispones de efectivo, estos montos se descuentan de tu saldo disponible y se convierten en saldo a pagar.</li>
<li>Pago con Tarjeta de D&eacute;bito: Al realizar una compra en <strong>COMPRA FACIL</strong> con tu tarjeta de d&eacute;bito, &eacute;sta te permite pagar y abonar a partir del dinero que ya tengas previamente en la cuenta de la Entidad Financiera del Banco de tu elecci&oacute;n.</li>
<li>Dep&oacute;sito en Cuenta: Una cuenta de dep&oacute;sito es una cuenta de ahorros, cuenta corriente o cualquier otro tipo de cuenta bancaria que permite depositar y retirar dinero por el titular de la cuenta. Al realizar una compra en <strong>COMPRA FACIL</strong> mediante dep&oacute;sito en cuenta tendr&aacute;s un beneficio exclusivo si lo realizas mediante el banco Interbank.</li>
<li>Transferencia bancaria: Una transferencia bancaria es el giro de dinero de una cuenta a otra. Puede ser entre clientes de un mismo banco o diferentes.</li>
<li>Yape: Aplicaci&oacute;n del BCP para realizar transferencia bancaria.</li>
<li>Sistema Pagoefectivo: Es el medio de pago para comprar por internet y pagar sin tarjeta. Al momento de elegir esta opci&oacute;n recibir&aacute; un c&oacute;digo de pago CIP que podr&aacute;s pagar en cualquiera de los canales de pago autorizados. Los modos de pago son: a) Dep&oacute;sitos en efectivo v&iacute;a PagoEfectivo &ndash; Paga en BBVA, BCP, Interbank, Scoatiabank, Western Uni&oacute;n , Tambo, Kasnet, Full Carga, Red Digital, Money Gram , Caja Arequipa, Disashop , Banco de la Naci&oacute;n, Caja Sullana, Caja los Andes, Caja Trujillo , Banco Azteca, Caja Santa Ana , Caja Ra&iacute;z. B) Transferencias bancarias v&iacute;a PagoEfectivo &ndash; Paga en BBVA, BCP, Interbank, Scoatiabank, BanBif, Caja Arequipa, a trav&eacute;s de la banca por internet o banca m&oacute;vil en la opci&oacute;n pago de servicios.</li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Ahora bien; tomar en cuenta que, si el medio de pago es realizado mediante Pagoefectivo, Yape, Pago por trasferencia bancaria o Pago por dep&oacute;sito en cuenta; <strong>COMPRA FACIL </strong>solo garantizar&aacute; el stock del producto que se pretende adquirir durante las siguientes cuatro (4) horas de efectuar la reserva, luego de ese tiempo la reserva se pierde en autom&aacute;tico por el sistema. En el supuesto concreto que ocurra lo mencionado anteriormente, el cliente deber&aacute; volver a realizar la compra para que se le genere otro c&oacute;digo de pago CIP. Ahora bien; solo y solo si en caso ocurra alg&uacute;n problema entre el momento del pago y el stock del producto, el cliente podr&aacute; enviar un correo a la siguiente direcci&oacute;n electr&oacute;nica: ventas@comprafacil.com.pe con el asunto &ldquo;Problema pago CIP&rdquo;. A su vez; la compra se tomar&aacute; como transada apenas se reciba la notificaci&oacute;n del pago de la venta a <strong>COMPRA FACIL</strong>, que tendr&aacute; una duraci&oacute;n aproximada de dos (2) minutos.</p>
<p>&nbsp;</p>
<p>Finalmente; es importante mencionar que si el cliente efect&uacute;a una compra por medio de un dep&oacute;sito en cuenta o una transferencia bancaria se le adicionar&aacute; un monto equivalente a 8 Soles (Ocho y 00/100 Soles), debido a que; dicho costo de recepci&oacute;n se origina por el cobro que realiza la entidad financiera a la empresa. De la misma manera, si el cliente efect&uacute;a una compra por medio del Sistema &ldquo;Pagoefectivo&rdquo; se le adicionar&aacute; un monto equivalente a 4 Soles (Cuatro y 00/100 Soles) que es el monto m&iacute;nimo que cobra &ldquo;Pagoefectivo&rdquo; para las transacciones.</p>
<p>&nbsp;</p>
<p>De otro lado; es importante mencionar que el cliente debe saber que, dependiendo del m&eacute;todo de pago que realice y dependiendo de la tarjeta que utilice (P.e: Interbank o Yape) podr&aacute; acceder o no a ofertas exclusivas que aparecer&aacute;n dentro de la p&aacute;gina principal o del mismo producto.</p>
<p>&nbsp;</p>
<p>Sobre el particular; <strong>COMPRA FACIL</strong> le informa al cliente que, si efectu&oacute; una compra por el medio de pago denominado &ldquo;Pagoefectivo&rdquo; y est&aacute; no se concret&oacute;, la empresa solo podr&aacute; garantizar la devoluci&oacute;n del 96% del dinero, debido a que; dicho monto es el reembolso total que entrega &ldquo;Pagoefectivo&rdquo;. De lo anterior; <strong>COMPRA FACIL</strong> se compromete en brindar los datos de contacto entre el proveedor y el cliente en el supuesto concreto que exista alg&uacute;n problema y/o inconveniente con la compra efectuada.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>asumir&aacute; que todos los productos fueron recibidos satisfactoriamente por el cliente, luego de un plazo considerable de 14 d&iacute;as calendario (2 semanas) de haberse realizado la compra, salvo que el cliente haga valer su derecho de objeci&oacute;n o indique lo contrario por cualquier medio de comunicaci&oacute;n.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>informa al cliente que tiene la potestad de contactar al proveedor para absolver cualquier tipo de dudas y/o consulta respecto del o los productos que pretende adquirir. No obstante, a ello; por un tema se asegurar la calidad del servicio, est&aacute; terminantemente prohibido intercambiar datos de contacto, tales como; direcciones, n&uacute;meros de tel&eacute;fonos, correos electr&oacute;nicos, entre otros. Asimismo; se informa al cliente que las comunicaciones que podr&aacute; girar con el proveedor ser&aacute;n monitoreadas. Este monitoreo se realizar&aacute; con la finalidad de evitar que alg&uacute;n proveedor realice una venta externamente a la plataforma, debido a que; eso conllevar&iacute;a a que el cliente pierda las garant&iacute;as y facilidades de reembolso que se adquieren a trav&eacute;s de la compra en <strong>COMPRA FACIL.</strong></p>
<p>&nbsp;</p>
<p>Es importante precisar que es absolutamente responsabilidad del proveedor que las im&aacute;genes y la descripci&oacute;n de sus productos se ajusten a la realidad de estos. En tal sentido; <strong>COMPRA FACIL </strong>ser&aacute; responsable de la revisi&oacute;n de la calidad de las im&aacute;genes publicadas antes de aprobarlas en su sistema, no obstante; <strong>COMPRA FACIL</strong> no es responsable por la veracidad de estas ni por cualquier mensaje subliminal u obsceno que estas puedan contener.</p>
<p>&nbsp;</p>
<strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Informaci&oacute;n requerida para las condiciones de tratamiento de datos personales, t&eacute;rminos de uso, pol&iacute;tica de protecci&oacute;n de datos personales y tratamiento de Cookies.</u></strong>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<ul>
<li><strong><em><u>Condiciones de tratamiento de datos personales</u></em></strong><strong><em>:</em></strong></li>
</ul>
<p><strong><em>&nbsp;</em></strong></p>
<p>Sabemos lo valioso que es para el cliente conocer el uso que hacemos de su informaci&oacute;n personal y el modo en que la compartimos. En consecuencia, las pol&iacute;ticas de privacidad de <strong>COMPRA FACIL</strong> explican el tratamiento de los datos personales y la protecci&oacute;n de la privacidad al usar la Plataforma virtual.</p>
<p>&nbsp;</p>
<p>Si utilizas nuestro servicio, aceptas que <strong>COMPRA FACIL</strong> use dichos datos de conformidad con sus pol&iacute;ticas de privacidad. Para realizar una compra,&nbsp; no importando el m&eacute;todo de pago elegido; la Plataforma solicitar&aacute; necesariamente que el cliente consigne la siguiente informaci&oacute;n por &uacute;nica vez: (i) Correo electr&oacute;nico , (ii) n&uacute;mero telef&oacute;nico de su tel&eacute;fono m&oacute;vil, (iii) el n&uacute;mero de su Tarjeta de D&eacute;bito o Cr&eacute;dito y su fecha de vencimiento, (iv) el c&oacute;digo CVV2 de su Tarjeta de D&eacute;bito Visa o MasterCard del respectivo Banco y; (v) realizar cualquier otra medida de seguridad que se pueda implementar en este proceso y que tenga como objeto la validaci&oacute;n de la identidad del cliente para poder generar una transacci&oacute;n exitosa.</p>
<p>&nbsp;</p>
<p>Es obligatorio completar el registro de todos los puntos anteriores con datos v&aacute;lidos y verdaderos para tener la certeza de realizar una transacci&oacute;n efectiva y satisfactoria al momento de la adquisici&oacute;n de alg&uacute;n producto o productos en la Plataforma virtual de <strong>COMPRA FACIL</strong>. Asimismo; en el caso de que se utilice el medio de pago mediante pago con tarjeta de cr&eacute;dito o d&eacute;bito y/o transferencia en cuenta, el cliente declara que se encuentra facultado y con la capacidad legal vigente para realizar operaciones con la respectiva tarjeta.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL</strong> no se responsabiliza por la certeza de los Datos Personales provistos por los clientes. Los clientes garantizan y responden, en cualquier caso, de la exactitud, veracidad, vigencia y autenticidad de los Datos Personales ingresados. En ese sentido, la declaraci&oacute;n realizada por los clientes al momento de registrarse se entender&aacute; como una Declaraci&oacute;n Jurada. <strong>COMPRA FACIL </strong>podr&aacute; realizar los controles que crea convenientes para verificar la veracidad de la informaci&oacute;n dada por el cliente. En ese sentido; se reserva el derecho de solicitar alg&uacute;n comprobante y/o dato adicional a efectos de corroborar los Datos Personales. Sin embargo; si se sospecha o verifica alg&uacute;n uso malintencionado y/o fraudulento contrario a estos T&eacute;rminos y Condiciones de Uso, <strong>COMPRA FACIL</strong> tendr&aacute; el derecho inapelable de dar por terminada la transacci&oacute;n cancelando la misma.</p>
<ul>
<li><strong><em><u>T&eacute;rmino de Uso</u></em></strong>:</li>
</ul>
<p>&nbsp;</p>
<p>Estos t&eacute;rminos y condiciones de uso son un acuerdo legal (en adelante, "T&eacute;rminos") entre usted y <strong>COMPRA FACIL E.I.R.L</strong>., con domicilio en Ca. Los Aviadores 306 Urb. Santa Mar&iacute;a - Santiago de Surco, Per&uacute; (en adelante, "<strong>COMPRA FACIL</strong>"), estableciendo los t&eacute;rminos y condiciones bajo los cuales usted puede acceder y usar los servicios y funciones disponibles en la Plataforma virtual de <strong>COMPRA FACIL</strong> ubicada en www.comprafacil.com.pe.</p>
<p>&nbsp;</p>
<p>Los Servicios de Venta al por menor de<strong> COMPRA FACIL</strong> son muy diversos, por lo que en algunas ocasiones podr&iacute;an aplicar condiciones adicionales o requisitos que ser&aacute;n espec&iacute;ficamente determinados por el mismo proveedor, los cuales; figuraran en la P&aacute;gina Web del mismo producto. Estas condiciones adicionales o requisitos estar&aacute;n disponibles junto con los servicios pertinentes y formar&aacute;n parte del acuerdo que estableces con <strong>COMPRA FACIL</strong> al usar sus servicios.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>AL ACCEDER O UTILIZAR LOS SERVICIOS, SE COMPROMETE A RESPETAR ESTOS T&Eacute;RMINOS Y NUESTRA POL&Iacute;TICA DE PRIVACIDAD. SI NO EST&Aacute; DE ACUERDO CON ESTOS T&Eacute;RMINOS, NO ACCEDA NI UTILICE LA PLATAFORMA NI EL SITIOS WEB DE COMPRA FACIL</strong>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Al utilizar la Plataforma usted declara y garantiza que: usted tiene el poder legal y la autoridad para aceptar y estar sujeto a estos T&eacute;rminos; y, si nos ha proporcionado informaci&oacute;n como consecuencia del uso de la Plataforma, dicha informaci&oacute;n es verdadera y precisa. Proporcionar informaci&oacute;n falsa, enga&ntilde;osa o incorrecta puede dar lugar a responsabilidades tanto civiles como penales. Si tiene dudas al respecto, le recomendamos que consulte con su asesor legal.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>El acceso y utilizaci&oacute;n de la Plataforma o del Sitio Web atribuye al visitante la condici&oacute;n de cliente (en adelante, &ldquo;El Cliente&rdquo;) e implica su declaraci&oacute;n expresa de conocer y aceptar plenamente todas las disposiciones, normas, instrucciones, responsabilidades y pol&iacute;ticas contenidas en los presentes T&eacute;rminos y Condiciones de Uso. En consecuencia, el cliente debe leer detenidamente los presentes T&eacute;rminos cada vez que acceda a la Plataforma - Sitio Web, pues podr&iacute;an sufrir variaciones o actualizaciones sin previo aviso, en atenci&oacute;n a nuevos requerimientos legales o por necesidades internas para cumplir con las mejores pr&aacute;cticas de negocio.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><u>Pol&iacute;tica de Privacidad y Datos Personales</u> </strong></li>
</ul>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL</strong> informa al cliente que, de acuerdo a la Ley N&deg; 29733 - Ley de Protecci&oacute;n de Datos Personales, su Reglamento aprobado mediante Decreto Supremo N&deg; 003-2013-JUS y las dem&aacute;s disposiciones complementarias, <strong>COMPRA FACIL</strong> est&aacute; legalmente autorizado para dar tratamiento a los Datos Personales que el cliente le entrega con la finalidad de hacer uso de los Servicios. Asimismo, <strong>COMPRA FACIL</strong> informa al cliente que para dar cumplimiento a las obligaciones y/o requerimientos que se generen en virtud de las normas vigentes en el ordenamiento jur&iacute;dico peruano y/o en normas internacionales que le sean aplicables, <strong>COMPRA FACIL</strong> podr&aacute; dar tratamiento y eventualmente transferir sus Datos Personales a autoridades y terceros autorizados por ley.</p>
<p>Adicionalmente a lo dispuesto en el p&aacute;rrafo anterior, el cliente nos otorga autorizaci&oacute;n para acceder y usar la informaci&oacute;n de sus tel&eacute;fonos, correos y compras, as&iacute; como obtener informaci&oacute;n de patrones de conducta, ello con la finalidad de realizar an&aacute;lisis comerciales y estad&iacute;sticos, mejorar los servicios ofrecidos y/u ofrecer productos personalizados. Se excluye la informaci&oacute;n privada del cliente tales como fotos, acceso a mensajes de correos, SMS y mensajer&iacute;a instant&aacute;nea, videos, historial de llamadas y aplicaciones personales.</p>
<p>&nbsp;</p>
<p>El cliente puede ejercer sus derechos de cancelaci&oacute;n y oposici&oacute;n al p&aacute;rrafo anterior, dirigi&eacute;ndose al correo electr&oacute;nico soporte@comprafacil.com.pe<strong>. </strong>Adicionalmente, los clientes de <strong>COMPRA FACIL</strong> podr&aacute;n ejercer sus derechos a trav&eacute;s de las redes sociales o medios de contacto en la Plataforma de <strong>COMPRA FACIL.</strong></p>
<ul>
<li><strong><u>Tratamiento de Cookies</u></strong></li>
</ul>
<p>El presente tratamiento de privacidad de Cookies, tiene por finalidad informar la manera en que <strong>COMPRA FACIL</strong> trata la informaci&oacute;n y datos personales de sus clientes que recopilan a trav&eacute;s de Cookies y otras tecnolog&iacute;as. El Cliente declara haber le&iacute;do y aceptado de manera previa y expresa el mencionado tratamiento sujet&aacute;ndose a sus disposiciones.</p>
<p>Para efectos de del tratamiento de Cookies, se entender&aacute; a todos aquellos Clientes que utilicen la Plataforma y/o Sitio Web de <strong>COMPRA FACIL.</strong></p>
<p>Las Cookies son herramientas que tienen un papel esencial para la prestaci&oacute;n de numerosos servicios de la sociedad de la informaci&oacute;n que concentran la mayor inversi&oacute;n publicitaria, facilitan la navegaci&oacute;n del Cliente y ofrecen una publicidad basada en ocasiones en los h&aacute;bitos de navegaci&oacute;n. En ese sentido; informamos al Cliente que utilizamos nuestras propias cookies con la finalidad de reconocerte cuando visitas nuestra Plataforma o cuando utilizas nuestro Servicio. Adem&aacute;s, es importante mencionar que tambi&eacute;n utilizamos las cookies para medir el tr&aacute;fico y el rendimiento de nuestra Plataforma. Tambi&eacute;n nos permite almacenar ciertos datos sobre el dispositivo del Cliente o sobre la navegaci&oacute;n del mismo, es decir, recopila informaci&oacute;n estad&iacute;stica, facilita ciertas funcionalidades t&eacute;cnicas, ver qu&eacute; p&aacute;ginas han sido visitadas, el tiempo de conexi&oacute;n, la ciudad a la que est&aacute; asignada la direcci&oacute;n IP desde la que se accede, el n&uacute;mero de nuevos Clientes, la frecuencia y reincidencia de visitas, el tiempo de visita, el navegador u operador o tipo de terminal desde que se realiza la visita, analiza el funcionamiento de una p&aacute;gina, detecta problemas t&eacute;cnicos, entre otros.</p>
<p>Tener en cuenta que, las Cookies pueden borrarse del disco duro si el Cliente as&iacute; lo desea. La mayor&iacute;a de los navegadores aceptan las cookies de forma autom&aacute;tica, pero le permiten al Cliente cambiar las configuraciones de su navegador para que rechace la instalaci&oacute;n de cookies, sin que ello perjudique su acceso y navegaci&oacute;n por la Plataforma o el Sitio Web. Es importante recalcar que, s&iacute; aceptas el uso de cookies, deber&aacute;s controlar y/o borrar cookies desde las opciones de tu propio navegador. Los fabricantes de navegadores proporcionan ayuda para la gesti&oacute;n de cookies en sus productos.</p>
<p>Finalmente; <strong>COMPRA FACIL</strong> puede tratar la informaci&oacute;n recopilada a trav&eacute;s de &ldquo;Cookies&rdquo;, siempre que el Cliente brinde su consentimiento al ingresar a La Plataforma o Sitio Web. En caso de que el Cliente preste su consentimiento, COMPRA FACIL podr&aacute; utilizar &ldquo;Cookies&rdquo; que permitan obtener informaci&oacute;n acerca de sus preferencias, con el fin de personalizar nuestra Plataforma de acuerdo a sus intereses.</p>
<p><strong>COMPRA FACIL</strong> se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento el presente tratamiento. Cualquier modificaci&oacute;n, actualizaci&oacute;n y/o ampliaci&oacute;n producida ser&aacute; inmediatamente publicada en la Plataforma o Sitio Web de <strong>COMPRA FACIL</strong>.</p>
<p>&nbsp;</p>
<ol start="4">
<li><strong><u>Pol&iacute;tica de Garant&iacute;a </u></strong></li>
</ol>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong>COMPRA FACIL</strong> se compromete en ofrecer una satisfacci&oacute;n garantizada en todas las compras que realicen los &ldquo;Clientes&rdquo;, por tal motivo; con el fin de ofrecer un mejor servicio <strong>COMPRA FACIL </strong>se compromete en proporcionarle todos los datos necesarios del proveedor para que le brinde el soporte a su solicitud de garant&iacute;a.</p>
<p>Es importante se&ntilde;alar que; <strong>COMPRA FACIL</strong> no es responsable de la idoneidad de los productos ofrecidos por los proveedores a trav&eacute;s de la Plataforma. Adem&aacute;s, es importante se&ntilde;alar, que el plazo de garant&iacute;a lo establece el proveedor o la marca a trav&eacute;s del certificado de garant&iacute;a que llegar&aacute; con el producto y el comprobante de pago a nombre del proveedor. Asimismo; es responsabilidad del cliente verificar la descripci&oacute;n del producto antes de adquirir el producto en la Plataforma. En el supuesto que no figure la informaci&oacute;n referente a la garant&iacute;a del producto, el cliente debe contactar al proveedor a trav&eacute;s de la p&aacute;gina y estar de acuerdo con sus condiciones antes de concretar la adquisici&oacute;n del producto.</p>
<p><strong>COMPRA FACIL </strong>en ning&uacute;n caso brindar&aacute; asistencia t&eacute;cnica por los productos vendidos en su Plataforma, salvo que ese producto sea perteneciente a la misma empresa <strong>COMPRA FACIL E.I.R.L. </strong>En este supuesto, se har&aacute; efectiva la asistencia t&eacute;cnica y/o garant&iacute;a del producto solamente con la copia original del comprobante electr&oacute;nico mediante los medios telef&oacute;nicos y electr&oacute;nicos que <strong>COMPRA FACIL </strong>pone a disponibilidad de los &ldquo;Clientes&rdquo; en su Plataforma.</p>
<p><strong>COMPRA FACIL </strong>podr&aacute; invalidar la garant&iacute;a de los productos adquiridos en <strong>COMPRA FACIL E.I.R.L </strong>solo y solo si se incumplen los siguientes supuestos:</p>
<ul>
<li>Cuando el producto o cualquiera de sus componentes presenten fallas causadas por maltrato, desconocimiento de sus funciones, incumplimiento de instrucciones o uso distinto al de su propia naturaleza.</li>
<li>Al ocurrir hecho fortuito o de fuerza mayor de cualquier tipo posterior a la entrega.</li>
<li>En los supuestos en que se encuentre <u>fuera del periodo de garant&iacute;a</u> o provenga del uso natural de un producto; como el envejecimiento de un libro o el deterioro de una tela o estampado.</li>
</ul>
<p>&nbsp;</p>
<p>Cabe se&ntilde;alar que; para cualquier queja, reclamo y/o inconveniente con el producto adquirido, el cliente, se encuentra premunido de hacer valer sus derechos seg&uacute;n lo estipulado en la Ley No. 29571 &ndash; C&oacute;digo de Protecci&oacute;n y Defensa del Consumidor.</p> 
<p>Por otro lado; el cliente debe saber que las consideraciones para las solicitudes son las siguientes: (i) La garant&iacute;a del producto debe de estar vigente, (ii) El producto debe contar con el comprobante de pago que acredite la adquisici&oacute;n del producto (iii) El cliente debe contar con el certificado de garant&iacute;a y/o documento similar. (iv) El da&ntilde;o del producto debe ser por un defecto de fabricaci&oacute;n, falla t&eacute;cnica o irregularidad. (v) Para la aplicaci&oacute;n de la garant&iacute;a, el proveedor del producto determinar&aacute; previa evaluaci&oacute;n del da&ntilde;o o falla, si procede la aplicaci&oacute;n de la garant&iacute;a, para ello emitir&aacute; un informe t&eacute;cnico. Las dem&aacute;s condiciones con relaci&oacute;n al certificado de la garant&iacute;a del producto deber&aacute;n ser brindadas por el proveedor mas no por <strong>COMPRA FACIL</strong>.</p>
<ol start="5">
<li><strong><u>Devoluciones:</u></strong></li>
</ol>
<strong>
COMPRA FACIL E.I.R.L ofrece a los “Clientes” la posibilidad de realizar cambios o devoluciones (por el total del monto de la compra) de los productos vendidos durante los primeros cinco (5) días naturales después de la entrega del pedido. Esto está sujeto a que el producto esté como nuevo y a que el cliente se acerque a nuestras oficinas en Avenida Caminos del Inca 306, Surco.
<p>&nbsp;</p>
Para ejercer este derecho, el cliente deberá contactarse vía Whatsapp al número de Atención al Cliente de COMPRA FACIL (+51 994552270) o enviar una solicitud electrónica de devolución al correo soporte@comprafacil.com.pe, con el asunto “Solicito devolución”, explicando de manera breve el motivo.
<p>&nbsp;</p>
Tener en cuenta que todo cambio o devolución se realizará en los días naturales acordados en la coordinación electrónica previa. En tal sentido; COMPRA FACIL utilizará un plazo razonable (máximo 3 días útiles), así como estimará el cambio o la devolución de acuerdo a la disponibilidad del stock del producto.
</strong>
<p>&nbsp;</p>
<ol start="6">
<li><strong><u>Sobre los plazos, costos de env&iacute;o y comprobantes de pago:</u></strong></li>
</ol>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong>COMPRA FACIL</strong> solo se responsabiliza por lo indicado en los presentes &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo;. Asimismo; se informa que los productos adquiridos a trav&eacute;s de la Plataforma online de <strong>COMPRA FACIL</strong> se sujetar&aacute;n a las condiciones que estipule el proveedor de los productos. En tal sentido; todo lo referente a plazos de despacho, entregas y env&iacute;os ser&aacute;n publicados y estar&aacute;n disponibles a trav&eacute;s de la informaci&oacute;n de los Proveedores.</p>
<p>En el supuesto que alg&uacute;n producto no cuente con la informaci&oacute;n disponible, es entera responsabilidad del cliente contactar y/o comunicarse con el proveedor para solicitar la referida informaci&oacute;n.</p>
<p>Sobre el particular; <strong>COMPRA FACIL </strong>solamente emitir&aacute; el comprobante electr&oacute;nico si el producto vendido es realizado por <strong>COMPRA FACIL E.I.R.L, </strong>es decir; la misma empresa. No obstante; en los otros supuestos en que el producto sea vendido por el proveedor, <strong>COMPRA FACIL</strong> solo emitir&aacute; el comprobante electr&oacute;nico por su servicio de coordinaci&oacute;n de env&iacute;o, mientras que la boleta electr&oacute;nica y/o factura (Comprobantes de Pago) estar&aacute;n a cargo del proveedor del producto, al igual que el certificado de garant&iacute;a del producto, en caso aplique.</p>
<p>&nbsp;</p>
<p>Es importante recalcar que, a excepci&oacute;n de que se indique lo contrario en el momento de hacer el <em>checkout</em>, el costo de transporte (flete) ser&aacute; asumido por el cliente. En consecuencia; <strong>COMPRA FACIL </strong>cobrar&aacute; el equivalente a 7 soles (Siete y 00/100 Soles) por el servicio de coordinaci&oacute;n de env&iacute;o y, de ser un env&iacute;o a provincia, 12 soles (Doce y 00/100 Soles) adicionales por producto. Los montos adicionales por env&iacute;o a provincia ir&aacute;n directamente a la cuenta del Proveedor, por lo que <strong>COMPRA FACIL</strong> no emitir&aacute; comprobante por este cobro. <strong>COMPRA FACIL </strong>se reserva el derecho de asumir el costo de transporte bajo ciertas condiciones las cuales ser&aacute;n comunicadas oportunamente al cliente. Asimismo, se pone en conocimiento del cliente que, si la orden de compra es mayor al monto de 200 soles (Doscientos y 00/100 Soles), no se cobrar&aacute; los 7 soles (Siete y 00/100 Soles), pero s&iacute; el monto adicional por producto cuando se trate de provincia.</p>
<p>&nbsp;</p>
<p>Por otro lado; si el cliente desea realizar una adquisici&oacute;n fuera de la Rep&uacute;blica del Per&uacute;, deber&aacute; comunicarse v&iacute;a electr&oacute;nica a la siguiente direcci&oacute;n: ventas@comprafacil.com.pe. En consecuencia; <strong>COMPRA FACIL </strong>cotizar&aacute; y le enviar&aacute; el presupuesto total del pedido a su correo electr&oacute;nico.</p>
<p>&nbsp;</p>
<p>Respecto al tiempo que le demorar&aacute; al cliente recibir sus pedidos, el tiempo aproximado en Lima ser&aacute; de dos d&iacute;as &uacute;tiles luego de que se procese la compra. En caso de provincias ser&aacute; cinco d&iacute;as. Resaltamos que estos tiempos son promedios y que, ante la duda, se puede consultar con el Proveedor mediante el chat interno. En ning&uacute;n caso se permite el pago contra entrega. Por &uacute;ltimo, se&ntilde;alamos que, si en una misma compra aparecen productos de m&aacute;s de un proveedor, estos podr&iacute;an llegar con horas o hasta d&iacute;as de diferencia.</p>
<p>&nbsp;</p>
<p>Es importante que si un producto lleva m&aacute;s de una semana sin ser entregado, el Cliente se contacte con el Proveedor para saber la situaci&oacute;n del mismo y asegurarse de que el producto se encuentra pr&oacute;ximo a estar entregado. En caso el producto lleve m&aacute;s de diez d&iacute;as sin ser entregado, se debe comunicar con <strong>COMPRA FACIL</strong> para que tome las acciones correspondientes y evite el pago al proveedor hasta que previamente haya entregado el producto.</p>
<p>&nbsp;</p>
<p>Tambi&eacute;n, se debe tener en cuenta que, <strong>COMPRA FACIL</strong> no es responsable de la decisi&oacute;n del Proveedor de la autorizaci&oacute;n para elegir si el producto seleccionado por el cliente se enviar&aacute; o no a Provincia. Por tanto; la posibilidad de env&iacute;o a provincia aparecer&aacute; dentro de la misma p&aacute;gina del producto y es responsabilidad del cliente verificar dicha informaci&oacute;n.&nbsp; En suma; queda a total discreci&oacute;n del Proveedor si vende y por ende env&iacute;a o no el producto a las 196 Provincias de la Rep&uacute;blica del Per&uacute;.</p>
<p>&nbsp;</p>
<p>Ahora bien; es exclusiva responsabilidad del cliente brindar la direcci&oacute;n exacta de env&iacute;o. Los plazos para el despacho y entrega est&aacute;n enteramente a cargo del Proveedor. En ese sentido; <strong>COMPRA FACIL </strong>solo valida la orden o pedido de compra y el medio de pago utilizado, mas no es responsable por el despacho, env&iacute;o y entrega del o los productos adquiridos a trav&eacute;s de la Plataforma.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>pone de conocimiento al cliente que, una vez realizada la compra, no se podr&aacute; cambiar la direcci&oacute;n de destino. Sin embargo; el cliente podr&aacute; comunicarse a la siguiente direcci&oacute;n electr&oacute;nica, soporte@comprafacil.com.pe, desde el mismo correo con el que se registr&oacute; para hacer la compra, y se&ntilde;alar el n&uacute;mero de orden y la nueva direcci&oacute;n de destino que desea. No obstante; no se garantiza que el cambio de direcci&oacute;n pueda ser aprobado, dado que; el env&iacute;o depende de la log&iacute;stica del Proveedor del producto y no de <strong>COMPRA FACIL E.I.R.L.</strong></p>
<p>&nbsp;</p>
<p>Por otro lado; cuando el cliente recibe un producto adquirido a trav&eacute;s de la plataforma de <strong>COMPRA FACIL</strong>, debe validar que la caja o bolsa que contenga el producto est&eacute; sellada y no tenga signos de apertura previa; en caso se detecte esto, no deber&aacute; recibir el producto o, en todo caso, tomar una foto al producto en mal estado y ponerse en contacto inmediatamente con el Proveedor. Si el cliente deja pasar m&aacute;s de dos d&iacute;as desde que firm&oacute; el cargo de recibido y no reporta ninguna falla del producto, se asumir&aacute; que lo recibi&oacute; en buenas condiciones y con todas las partes completas, de acuerdo con la descripci&oacute;n del producto. Bajo esas circunstancias el cliente no podr&aacute; presentar reclamo por da&ntilde;o del producto o faltante del mismo y solo se atender&aacute;n reclamos por temas de garant&iacute;a o pol&iacute;ticas descritas dentro de la Pol&iacute;tica de Devoluci&oacute;n del producto, dentro de los tiempos establecidos en estos &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo;.</p>
<p>&nbsp;</p>
<p>En relaci&oacute;n a lo anterior, el cliente debe saber que todo lo relacionado a los plazos de despacho, env&iacute;o y entrega se deber&aacute;n coordinar &iacute;ntegramente con el proveedor del producto. <strong>COMPRA FACIL </strong>solo se limita a brindar y facilitar la comunicaci&oacute;n entre el cliente y el proveedor del producto.</p>
<p>&nbsp;</p>
<p>Finalmente; seg&uacute;n el reglamento de Comprobantes de Pago aprobado por la Resoluci&oacute;n de Superintendencia No. 007-99/SUNAT (RCP) y el Texto &Uacute;nico Ordenado de la Ley del Impuesto General a las Ventas e Impuesto Selectivo al Consumo, aprobado mediante Decreto Supremo No. 055-99-EF y normas modificatorias (TUO del IGV): &ldquo; No existe ning&uacute;n procedimiento vigente que permita el canje de boletas de venta por facturas, m&aacute;s a&uacute;n, las notas de cr&eacute;dito no se encuentran previstas para modificar al adquirente o cliente que figura en el comprobante de pago original&rdquo;.</p>
<p>&nbsp;</p>
<p>Teniendo en cuenta dicha Resoluci&oacute;n, es obligaci&oacute;n del cliente decidir correctamente el documento que solicitar&aacute; como comprobante al momento de su compra, debido a que; seg&uacute;n los p&aacute;rrafos citados ,no proceder&aacute; cambio alguno.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>&nbsp;</strong></p>
<ol start="7">
<li><strong><u>Preguntas Frecuentes: </u></strong></li>
</ol>
<p>&nbsp;</p>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong>COMPRA FACIL</strong> pone a disposici&oacute;n de sus clientes algunas de las preguntas comunes que absolver&aacute;n la mayor&iacute;a de sus consultas:</p>
<p>&nbsp;</p>
<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &iquest;Qu&eacute; es COMPRA FACIL?</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL </strong>es una organizaci&oacute;n peruana formada en el 2018 bajo el tipo societario de Empresa Individual de Responsabilidad Limitada &ndash; E.I.R.L. Esta empresa privada mantiene su registro de Persona Jur&iacute;dica en el Libro de Empresas Individuales de Responsabilidad Limitada de la oficina registral Lima &ndash; SUNARP ZONA REGISTRAL IX &ndash; SEDE LIMA, tal como se desprende en su vigencia de poder en la Partida Electr&oacute;nica No. 14125241.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>es una marca registrada ante Indecopi y cuenta con el Registro &uacute;nico de Contribuyente &ndash; RUC No. 20603406690. Est&aacute; domiciliada en Calle Los Aviadores No. 306, Urbanizaci&oacute;n Santa Mar&iacute;a Lima, Distrito de Santiago de Surco, Provincia y Departamento de Lima. <strong>COMPRA FACIL</strong> presta el servicio de <strong>VENTA AL POR MENOR POR CORREO Y POR INTERNET</strong> a terceros de diversos productos tales como prendas de vestir, electrodom&eacute;sticos; entre otros.</p>
<p>&nbsp;</p>
<p><strong>&iquest;A qu&eacute; se dedica COMPRA FACIL E.I.R.L?</strong></p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>es un sitio web que brinda el servicio de venta al por menor de productos de terceros de forma r&aacute;pida y segura.</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>presta sus servicios a trav&eacute;s de una Plataforma de comercio electr&oacute;nico &ldquo;<em>e-commerce</em>&rdquo; mediante el mercado en l&iacute;nea &ldquo;<em>Marketplace</em>&rdquo;. Para m&aacute;s informaci&oacute;n le recomendamos ingresar a la siguiente p&aacute;gina web: www.comprafacil.com.pe/legales</p>
<p>&nbsp;</p>
<p><strong>&iquest;Cu&aacute;l es el costo de env&iacute;o?</strong></p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>le permite al cliente identificar autom&aacute;ticamente el costo de env&iacute;o debajo del subtotal del producto, luego de haber seleccionado su zona de destino. En consecuencia; luego de haber agregado el o los productos al carrito de compras, el cliente podr&aacute; verificar el costo total de env&iacute;o en la secci&oacute;n &ldquo;Resumen del pedido, Checkout&rdquo;.</p>
<p>&nbsp;</p>
<p>En relaci&oacute;n a lo mencionado en el punto No. 6 de los &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo;; <strong>COMPRA FACIL </strong>cobrar&aacute; el equivalente a siete (7) soles (Siete y 00/100 Soles) por el servicio de coordinaci&oacute;n de env&iacute;o para ventas menores a doscientos (200) soles (Doscientos y 00/100 Soles). Ahora bien; vale destacar que aunque los productos se env&iacute;en en uno o m&aacute;s paquetes, dicha situaci&oacute;n no cambiar&aacute; la tarifa establecida por la coordinaci&oacute;n.</p>
<p>&nbsp;</p>
<p>A su vez; <strong>COMPRA FACIL </strong>informa que, para las adquisiciones realizadas fuera de Lima Metropolitana o La Provincia Constitucional del Callao, es decir; Provincias, estar&aacute;n sujetas a un cobro adicional de doce (12) soles (Doce y 00/100 Soles) por producto adem&aacute;s de los siete (7) soles (Siete y 00/100 Soles) si es que la compra es menor a doscientos (200) soles. Es importante resaltar que el cobro de los doce (12) soles por producto siempre ser&aacute; aplicado para los env&iacute;os a provincia sin importar el monto total de la compra. Adem&aacute;s, se informa que el Proveedor es el encargado de habilitar si su producto podr&aacute; o no ser enviado a provincia.</p>
<p>&nbsp;</p>
<p><strong>&iquest;Cu&aacute;nto tiempo toma el env&iacute;o? </strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL </strong>pone a disposici&oacute;n del cliente que el tiempo de env&iacute;o aproximado desde que se confirma la compra es de dos (2) d&iacute;as h&aacute;biles si la entrega es en Lima Metropolitana o La Provincia Constitucional del Callao y de cinco (5) d&iacute;as h&aacute;biles si se trata de Provincia.</p>
<p>&nbsp;</p>
<p>Asimismo; si el cliente desea realizar una adquisici&oacute;n fuera de la Rep&uacute;blica del Per&uacute;, tanto el costo total, as&iacute; como el plazo del env&iacute;o en d&iacute;as h&aacute;biles, se deber&aacute; coordinar a trav&eacute;s de un correo a la siguiente direcci&oacute;n electr&oacute;nica: ventas@comprafacil.com.pe.</p>
<p>&nbsp;</p>
<p>El cliente debe tomar en cuenta que los d&iacute;as h&aacute;biles excluyen s&aacute;bados, domingos y d&iacute;as festivos y empiezan a contar a partir del d&iacute;a en que recibe el correo de confirmaci&oacute;n de compra. Adem&aacute;s, el proveedor se podr&aacute; comunicar con usted mediante el chat interno para resolver cualquier duda sobre la entrega.</p>
<p>&nbsp;</p>
<p>En el supuesto que el plazo haya vencido y el o los productos no hayan sido entregados o el proveedor haya dejado de responder sobre la ubicaci&oacute;n del producto, el cliente tendr&aacute; la potestad de enviar un correo a la siguiente direcci&oacute;n electr&oacute;nica de <strong>COMPRA FACIL</strong>: <a href="mailto:soporte@comprafacil.com.pe">soporte@comprafacil.com.pe</a> , con la finalidad de brindarle soporte log&iacute;stico y ayudarlo a realizar el seguimiento correcto del pedido.</p>
<p>&nbsp;</p>
<p>Por &uacute;ltimo, si surge el supuesto de que el producto se env&iacute;e a trav&eacute;s de un courie&rdquo;, el proveedor tiene la alternativa de facilitarle al cliente el n&uacute;mero de rastreo para conocer con mayor precisi&oacute;n la fecha estimada en d&iacute;as h&aacute;biles que tomar&aacute; la entrega satisfactoria del o los productos.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>&iquest;Est&aacute; protegida la informaci&oacute;n y medios de pago de los clientes?</strong></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>S&iacute;, en <strong>COMPRA FACIL </strong>garantizamos al 100% la seguridad de los pagos. La informaci&oacute;n de tu tarjeta de cr&eacute;dito o d&eacute;bito es cifrada mediante el protocolo de seguridad &ldquo;Secure Socket Layer&rdquo; &ndash; SSL.</p>
<p>&nbsp;</p>
<p>Es importante advertir que <strong>COMPRA FACIL </strong>solo compartir&aacute; la informaci&oacute;n con el proveedor durante un plazo no mayor a 20 d&iacute;as calendario desde el momento de haberse realizado la compra.</p>
<p>&nbsp;</p>
<p>Asimismo, seg&uacute;n el procedimiento de compra, se informa que <strong>COMPRA FACIL reci&eacute;n</strong> pagar&aacute; al proveedor una vez que hayan ocurrido los siguientes supuestos:</p>
<p>&nbsp;</p>
<ol>
<li>El proveedor le haya enviado una gu&iacute;a de remisi&oacute;n a <strong>COMPRA FACIL</strong>.</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li>El cliente final haya validado la recepci&oacute;n del o los productos seleccionados a trav&eacute;s de la plataforma web de <strong>COMPRA FACIL</strong>.</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li>Despu&eacute;s de haber transcurrido m&aacute;s de diez (10) d&iacute;as h&aacute;biles desde que se realiz&oacute; la compra satisfactoriamente a trav&eacute;s de la Plataforma.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>&iquest;Puedo cambiar el destino de mi pedido luego de haber efectuado la compra?</strong></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>No. <strong>COMPRA FACIL </strong>solo valida la orden de compra y el medio de pago utilizado, m&aacute;s no es responsable por el despacho, env&iacute;o y entrega del o los productos adquiridos a trav&eacute;s de la Plataforma. Por lo tanto; el cliente debe saber que todo lo relacionado a las anulaciones y/o cambios de destino del pedido luego de haber realizado la compra; se deber&aacute; coordinar &iacute;ntegramente con el proveedor del producto, pues, de ellos depende la cadena log&iacute;stica del o los productos<strong>.</strong></p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>solo es un intermediario y su funci&oacute;n se limitar&aacute; a facilitar la comunicaci&oacute;n entre el cliente y el proveedor del producto para solucionar las contingencias que se puedan originar.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>De realizarse una compra, &iquest;qu&eacute; ocurre en los casos que el proveedor indica que no tiene stock o que no puede atender el pedido del cliente?</strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p>Si se da el caso que el proveedor no indic&oacute; sobre la falta de mercader&iacute;a o desatendi&oacute; los pedidos del cliente, <strong>COMPRA FACIL </strong>considerar&aacute; estas acciones como faltas muy graves y tomar&aacute; las correcciones necesarias contra el proveedor para que esto no se repita. Asimismo, <strong>COMPRA FACIL</strong> devolver&aacute; la totalidad del monto al cliente y considerar&aacute; ofrecerle alguna compensaci&oacute;n dependiendo de la situaci&oacute;n.</p>
<p>&nbsp;</p>
<p>Para una mejor asistencia en estos casos, pedimos que se nos envi&eacute; un correo a la siguiente direcci&oacute;n electr&oacute;nica: soporte@comprafacil.com.pe, con el n&uacute;mero de orden de compra y explicando los motivos del problema.</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;Es segura la Pasarela de pagos de la Plataforma?</u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>S&iacute;, la pasarela de pagos de nuestra Plataforma es totalmente segura y garantiza al 100% la seguridad de los pagos.</p>
<p><strong>&nbsp;</strong></p>
<p>Hemos adoptado altas medidas de seguridad, como el certificado: &ldquo;<em>Secure Sockets Layer&rdquo;</em>- SSL, que son aplicadas a todas las compras que se hacen en nuestra p&aacute;gina. En ese sentido; los datos de compra que cada cliente ingresa en el sitio se encuentran cifrado mediante un sistema de protecci&oacute;n, restringiendo as&iacute; a <strong>COMPRA FACIL </strong>y a cualquier otro la visibilidad de los mismos.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Al adquirir m&aacute;s de un (1) producto, &iquest;llegar&aacute; en un solo env&iacute;o o por separado? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>S&iacute; es posible. No obstante; depender&aacute; de los factores que se exponen a continuaci&oacute;n:</p>
<p>&nbsp;</p>
<p>Para los productos comprados directamente a <strong>COMPRA FACIL</strong>, siempre se intentar&aacute; enviar todos los productos en un mismo paquete. Sin embargo, esto no siempre es posible, pues, incluso cuando la direcci&oacute;n de destino sea la misma para todos sus productos; el costo del env&iacute;o aumentar&aacute;. Por ejemplo, cuando las dimensiones del producto no permitan enviarlo en una sola caja, el peso sea un impedimento, entre otros factores.</p>
<p>&nbsp;</p>
<p>Por otro lado; si la adquisici&oacute;n pertenece a productos de un Proveedor, estos ser&aacute;n enviados directamente al cliente sin pasar por el almac&eacute;n de <strong>COMPRA FACIL. </strong>En tales casos; el cliente deber&aacute; contactarse con el proveedor para saber c&oacute;mo ser&aacute; la forma de env&iacute;o.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;C&oacute;mo funciona la garant&iacute;a del producto en COMPRA FACIL? </u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p>Existen dos (2) maneras de funcionamiento o aplicaci&oacute;n de la garant&iacute;a:</p>
<ul>
<li>S&iacute; el producto es exclusivamente procedente de la empresa <strong>COMPRA FACIL E.I.R.L: </strong></li>
</ul>
<p>Para hacer efectiva la asistencia t&eacute;cnica y/o garant&iacute;a del producto debe revisar que &eacute;sta se encuentre dentro del plazo de garant&iacute;a mencionado en las caracter&iacute;sticas del producto adquirido.</p>
<p>En ese sentido; te podr&aacute;s contactar con nosotros v&iacute;a correo o al n&uacute;mero de Whatsapp 994552270 y demostrarnos el defecto del o los productos, asimismo; se solicitar&aacute; fotograf&iacute;as y es indispensable que el cliente cuente con el original o copia simple de su comprobante de compra.<strong> COMPRA FACIL </strong>realizar&aacute; todo lo posible por agilizar el proceso de garant&iacute;a. El tiempo aproximado para reparar un producto o dar una soluci&oacute;n alternativa es de diez (10) d&iacute;as h&aacute;biles. No obstante; dependiendo de la complejidad del caso y del diagn&oacute;stico del mismo, este plazo se podr&iacute;a extender. Esto ser&aacute; comunicado oportunamente al cliente. Para m&aacute;s informaci&oacute;n ver los requisitos establecidos taxativamente en estos &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo; en el Punto No. 4 denominado: &ldquo;<em><u>Pol&iacute;ticas de Garant&iacute;a</u></em>&rdquo;.</p>
<ul>
<li>S&iacute; el producto es procedente de un proveedor distinto a <strong>COMPRA FACIL: </strong></li>
</ul>
<p>En dicho supuesto, <strong>COMPRA FACIL</strong> no se har&aacute; cargo ni ser&aacute; responsable por la garant&iacute;a ni por el proceso de garant&iacute;a, siendo que; el plazo de garant&iacute;a lo establece el proveedor o la marca a trav&eacute;s del certificado de garant&iacute;a que llegar&aacute; con el o los productos. Asimismo; es responsabilidad del cliente verificar la descripci&oacute;n del producto antes de adquirirlo en la Plataforma. Finalmente; en caso el cliente necesite los datos de alg&uacute;n proveedor, &eacute;ste se podr&aacute; contactar con nosotros v&iacute;a llamada o correo para brindarle lo requerido. Para m&aacute;s informaci&oacute;n ver los requisitos establecidos taxativamente en estos &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo; en el Punto No. 4 denominado: &ldquo;<em><u>Pol&iacute;ticas de Garant&iacute;a</u></em>&rdquo;.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;Qu&eacute; son y c&oacute;mo obtengo cupones de descuento?</u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p>Hay dos (2) maneras:</p>
<p>&nbsp;</p>
<ul>
<li>La primera manera es que <strong>COMRPA F&Aacute;CIL </strong>origine un cup&oacute;n promocional y los distribuya en sus redes sociales como parte de una campa&ntilde;a de publicidad que tendr&aacute; una cantidad limitada de tiempo, es decir; que se precisara la duraci&oacute;n total en d&iacute;as h&aacute;biles. Por tal motivo; dichos cupones tendr&aacute;n un c&oacute;digo de descuento que tendr&aacute;n una vigencia limitada y se ofrecer&aacute;n a trav&eacute;s de la promoci&oacute;n o promociones que considere aplicable <strong>COMPRA FACIL E.I.RL.</strong> Es importante que el cliente sepa que los cupones son en su mayor&iacute;a son &uacute;nicos e intransferibles, es decir; que solo ser&aacute;n utilizados una vez por cada cuenta.</li>
<li>***Es importante tomar en cuenta que no todos los c&oacute;digos de descuento aplican a todos los productos ni a todas las categor&iacute;as y que los productos a los que aplica, incluso de una misma categor&iacute;a, podr&iacute;an estar afectos a diferentes porcentajes de descuento, seg&uacute;n la autorizaci&oacute;n del proveedor. Tambi&eacute;n se debe tomar en cuenta que el descuento es sobre el precio de lista y no sobre el precio de oferta. Por lo tanto, un producto ya podr&iacute;a contar con un porcentaje de descuento mayor al del c&oacute;digo ingresado. Siempre se le dar&aacute; mayor descuento al cliente y todo esto aparecer&aacute; claramente en el Checkout al ingresar el c&oacute;digo.</li>
</ul>
<p><strong>&nbsp;</strong></p>
<ul>
<li>La segunda manera de obtener un cup&oacute;n promocional es que alguna persona relacionada a la Plataforma, tales como; vendedores comisionistas, personas influyentes (&ldquo;<em>influencers</em>&rdquo;); entre otros, le ofrezcan usar su c&oacute;digo personal de descuento. En tal sentido; <strong>COMPRA FACIL </strong>informa que se debe tener en cuenta que estos c&oacute;digos de descuento podr&iacute;an estar sujetos a una fecha de vencimiento o tener algunas restricciones en su uso.</li>
</ul>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong><u>&iquest;C&oacute;mo funcionan los c&oacute;digos de descuentos? </u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p>El cliente debe saber que a trav&eacute;s de la Plataforma <strong>COMPRA FACIL,</strong> podr&aacute; beneficiarse de cupones de descuento. Estos se aplicar&aacute;n digitando el c&oacute;digo de descuento al momento del &ldquo;<em>checkout</em>&rdquo;. El cliente debe respetar las letras min&uacute;sculas, may&uacute;sculas y/o n&uacute;meros al momento de colocar el c&oacute;digo de descuento.</p>
<p>&nbsp;</p>
<p>Sobre el particular; el cliente debe tomar en cuenta que los descuentos solamente se aplican sobre el precio de lista del producto mas no desde el precio de promoci&oacute;n. Adem&aacute;s, en el supuesto que el cliente posea varios c&oacute;digos de descuento, estos no ser&aacute;n acumulables, es decir; solo podr&aacute; utilizar el c&oacute;digo de mayor descuento sobre el precio de lista del producto.</p>
<p>&nbsp;</p>
<p>Los descuentos pueden darse como monto solo y solo si este sujeto a una compra m&iacute;nima, por otro lado; los descuentos pueden darse como porcentaje, solo y solo si el proveedor haya aceptado o no participar de alguna promoci&oacute;n.</p>
<p>&nbsp;</p>
<p>Tambi&eacute;n el cliente debe saber que, el c&oacute;digo de descuento podr&iacute;a dar en ciertos casos un descuento de siete 7 soles (Siete y 00/100 Soles) en el env&iacute;o, no obstante; deber&aacute; pagar siempre 10 soles (Diez y 00/100 Soles) soles por producto en el supuesto que el env&iacute;o sea a Provincia.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>informa al p&uacute;blico en general que no todos los productos podr&aacute;n acogerse al c&oacute;digo de descuento, dado que; estos dependen de la decisi&oacute;n final del proveedor. No obstante; el cliente podr&aacute; verificar en el <em>&ldquo;checkout&rdquo;</em> si el descuento fue compatible o no con el producto deseado. Es decir; que el cliente podr&aacute; verificar en el &ldquo;checkout&rdquo; a qu&eacute; productos se les aplic&oacute; el descuento y a cu&aacute;les no. Finalmente; el cliente tambi&eacute;n debe saber que <strong>COMPRA FACIL </strong>no reembolsar&aacute; el valor correspondiente al cup&oacute;n de descuento.</p>
<p><strong><u>&nbsp;</u></strong></p>
<p><strong>&iquest;Qu&eacute; le ocurre al cliente si su cuenta es suspendida por uso indebido? </strong></p>
<p><strong><u>&nbsp;</u></strong></p>
<p>Se configura la suspensi&oacute;n por uso indebido de un cliente seg&uacute;n lo que se detalla a continuaci&oacute;n:</p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL </strong>manifiesta expresamente que la suspensi&oacute;n de la cuenta estar&aacute; a supeditada a que exista sospecha o se verifique alg&uacute;n uso malintencionado y/o fraudulento contrario a estos T&eacute;rminos y Condiciones de Uso. Por lo cual; se le suspender&aacute; la cuenta al cliente que realiz&oacute; las siguientes prohibiciones:</p>
<p>&nbsp;</p>
<p>-Cuando el cliente coloque sus datos personales de contacto, para la existencia de una comunicaci&oacute;n directa con el proveedor que omita la presencia de la Plataforma de <strong>COMPRA FACIL </strong>en la intermediaci&oacute;n de las ventas.</p>
<p>&nbsp;</p>
<p>-Cuando se le atribuye la responsabilidad de provecho il&iacute;cito por uso de un medio de pago fraudulento.</p>
<p>&nbsp;</p>
<p>-Cuando induciendo o manteniendo en error al proveedor mediante enga&ntilde;o, u otra forma fraudulenta intentando estafar al proveedor brind&aacute;ndole informaci&oacute;n falsa o err&oacute;nea.</p>
<p>&nbsp;</p>
<p>De comprobarse alguna o todas de estas situaciones, <strong>COMPRA FACIL </strong>estar&aacute; premunido a tomar las v&iacute;as legales pertinentes y denunciar al cliente infractor. Finalmente; la cuenta podr&aacute; ser reabierta en cinco (5) d&iacute;as h&aacute;biles si se comprueba que no existi&oacute; ning&uacute;n uso mal intencionado ni fraudulento o si apareci&oacute; alguna prueba a favor del suspendido.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol>
<li><strong><u>Preguntas frecuentes de nuestros proveedores</u></strong><strong>:</strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&iquest;Despu&eacute;s de cu&aacute;ntos d&iacute;as de entregado al producto recibir&eacute; el pago total? </strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL </strong>se&ntilde;ala que, seg&uacute;n el procedimiento de compra, se pagar&aacute; al proveedor luego de la ocurrencia de los siguientes tres (3) casos:</p>
<p>&nbsp;</p>
<ol>
<li>Una vez que el proveedor le haya enviado una gu&iacute;a de remisi&oacute;n a <strong>COMPRA FACIL </strong>como sustento de la entrega de su producto,</li>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li>Una vez que el cliente final haya validado la recepci&oacute;n del o los productos seleccionados a trav&eacute;s de la plataforma web de <strong>COMPRA FACIL</strong> o,</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li>Despu&eacute;s de transcurridos m&aacute;s de diez (10) d&iacute;as h&aacute;biles desde que la compra fue procesada y el cliente no haya tenido observaciones al respecto.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>&iquest;Qu&eacute; acciones tomar frente a una solicitud de cambio de producto o el uso efectivo de la garant&iacute;a por parte de un cliente?&nbsp; </strong></p>
<p>&nbsp;</p>
<p>Como premisa es importante recalcar que <strong>COMPRA FACIL</strong> no es responsable de la idoneidad de los productos ofrecidos por los proveedores a trav&eacute;s de la Plataforma. Sin embargo; siempre buscaremos la conformidad de nuestros clientes. Por tal motivo; con el fin de ofrecer un mejor servicio <strong>COMPRA FACIL </strong>se compromete en proporcionarle todos los datos necesarios del proveedor para que le brinde el soporte en alguna circunstancia que surja la solicitud de cambio del producto o la utilizaci&oacute;n efectiva de la garant&iacute;a.</p>
<p>&nbsp;</p>
<p>Asimismo; para ofrecerle una satisfacci&oacute;n garantizada en todas las compras que realicen los clientes, exhortamos a todos nuestros proveedores a que cumplan la condici&oacute;n de enviar el comprobante de pago de las respectivas adquisiciones a los clientes, con la finalidad de que estos puedan acceder a su derecho de cambio y/o de uso de la garant&iacute;a.</p>
<p>Para mayor detalle sobre la devoluci&oacute;n y/o cambios de o los productos y sobre la utilizaci&oacute;n de la garant&iacute;a recomendamos al cliente revisar los Puntos Nos. <em>4 y 5</em> de nuestros &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo;.</p>
<p>&nbsp;</p>
<p><strong>&iquest;C&oacute;mo funciona la garant&iacute;a del producto en COMPRA FACIL para los proveedores? </strong></p>
<p>&nbsp;</p>
<p>S&iacute; el producto es procedente de un proveedor distinto a <strong>COMPRA FACIL E.I.R.L: </strong></p>
<p>En dicho supuesto, <strong>COMPRA FACIL</strong> no se har&aacute; cargo ni ser&aacute; responsable por la garant&iacute;a ni por el proceso de garant&iacute;a, siendo que; el plazo de garant&iacute;a lo establece el proveedor o la marca a trav&eacute;s del certificado de garant&iacute;a que llegar&aacute; con el o los productos. Asimismo; es responsabilidad del cliente verificar la descripci&oacute;n del producto antes de adquirirlo en la Plataforma. Finalmente; en caso el cliente necesite los datos de alg&uacute;n proveedor, &eacute;ste se podr&aacute; contactar con nosotros v&iacute;a llamada o correo para brindarle lo requerido. Para m&aacute;s informaci&oacute;n ver los requisitos establecidos taxativamente en estos &ldquo;T&eacute;rminos y Condiciones de Uso&rdquo; en el Punto No. 4 denominado: &ldquo;<em><u>Pol&iacute;ticas de Garant&iacute;a</u></em>&rdquo;.</p>
<p><strong>&nbsp;</strong></p>
<p><strong><u>&iquest;Qu&eacute; sucede si mi cuenta es suspendida por uso indebido? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>Se configura la suspensi&oacute;n por uso indebido de un proveedor seg&uacute;n lo que se expone a continuaci&oacute;n:</p>
<p>La omisi&oacute;n de cumplimiento de la obligaci&oacute;n establecida en el segundo p&aacute;rrafo del literal &ldquo;I&rdquo; de estos T&eacute;rminos y Condiciones de Uso da lugar a la suspensi&oacute;n definitiva del proveedor. Es decir; si el proveedor incumple con la obligaci&oacute;n de enviar el comprobante de pago al cliente o si busca establecer contacto fuera de la plataforma; <strong>COMPRA FACIL</strong> podr&aacute; tomar la decisi&oacute;n de suspender de forma temporal o definitiva al proveedor.</p>
<p><strong>&nbsp;</strong></p>
<p><strong><u>&iquest;C&oacute;mo puedo ofrecer mis productos para vender en la plataforma? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>Si eres proveedor de diversos productos y deseas ofrecerlos en nuestra la plataforma de <strong>COMPRA FACIL, </strong>solamente debes enviar los datos de tu empresa a la siguiente direcci&oacute;n electr&oacute;nica: <a href="mailto:ventas@comprafacil.com.pe">ventas@comprafacil.com.pe</a>&nbsp;</p>
<p>Los datos requeridos de tu empresa son la raz&oacute;n social, el domicilio y el RUC con condici&oacute;n habida y vigente. <strong>COMPRA FACIL </strong>se encargar&aacute; de revisar minuciosamente los antecedentes e historial de la empresa que sede unirse a trav&eacute;s de la p&aacute;gina. S&iacute; la calificaci&oacute;n es positiva y autorizada por <strong>COMPRA FACIL</strong>, se le enviara un formulario. Este formato debe ser debidamente llenado y completado con todos los datos solicitados.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;C&oacute;mo funcionan las campa&ntilde;as y cupones de descuento en COMPRA FACIL? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL</strong> indica que si eres proveedor de diversos productos debes saber que, al momento de crear un producto, el proveedor est&aacute; autorizado a elegir si desea participar o no de las campa&ntilde;as de descuento.</p>
<p>S&iacute; la respuesta es afirmativa, el proveedor deber&aacute; colocar el porcentaje (%) de descuento m&aacute;ximo que est&aacute; dispuesto a brindar a sus productos sobre el precio de lista.</p>
<p>En el supuesto que la promoci&oacute;n indique un descuento de 60%, el descuento que aplicar&aacute; a su producto nunca ser&aacute; mayor a 20% si ese fue el porcentaje m&aacute;ximo que el proveedor decidi&oacute; otorgarles a sus productos.</p>
<p>Es importante informar que las promociones no exceder&aacute;n veinte (20) d&iacute;as calendario al a&ntilde;o.</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;Cu&aacute;les son mis responsabilidades de obligatorio cumplimiento como proveedor? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>Si eres proveedor de diversos productos de la plataforma, estas obligado a cumplir las obligaciones prescritas por los &ldquo;<em>T&eacute;rminos y Condiciones de Uso</em>&rdquo; de <strong>COMPRA FACIL</strong>; de lo contrario el proveedor ser&aacute; suspendido.</p>
<p>&nbsp;</p>
<p>Las responsabilidades se se&ntilde;alan a continuaci&oacute;n:</p>
<p>&nbsp;</p>
<ol>
<li>Mantener debidamente actualizado el stock de los productos.</li>
<li>Asegurar la veracidad de los datos en la descripci&oacute;n de los productos que desean vender.</li>
<li>Asegurar que las fotos de los productos en la plataforma sean de una calidad &oacute;ptima y adem&aacute;s sean fotos 100% reales y no meras fotograf&iacute;as referenciales.</li>
<li>En ning&uacute;n caso el proveedor brindara sus datos de contacto al responder las preguntas o consultas que realicen los clientes. No se aceptar&aacute; dicha acci&oacute;n ni en la descripci&oacute;n de los productos ni en las fotos de los productos publicadas en la plataforma,</li>
<li>El proveedor es responsable de cumplir con el envio de los productos m&aacute;ximo al segundo d&iacute;a h&aacute;bil de haberse efectuado la compra.</li>
</ol>
<p>&nbsp;</p>
<ol start="6">
<li>El proveedor ser&aacute; encargado de presupuestar el costo del env&iacute;o de los productos a los diferentes destinos en Lima Metropolitana y La Provincia Constitucional de Callao.</li>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li>El proveedor es responsable de enviar el comprobante de pago al cliente (ya sea de forma f&iacute;sico o virtual), luego de haberse efectuado la venta.</li>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li>El proveedor est&aacute; obligado a ofrecer el mismo plazo de duraci&oacute;n de la garant&iacute;a, como si el producto hubiera sido adquirido en la tienda del proveedor.</li>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li>El proveedor es responsable de la idoneidad del producto y del estado del mismo, es decir; que cumpla altamente con los est&aacute;ndares de calidad.</li>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li>El proveedor es responsable del estado de los productos; o sea, que los productos deben de encontrarse en condiciones &oacute;ptimas al momento de su env&iacute;o.</li>
</ol>
<p>&nbsp;</p>
<ol start="11">
<li>El proveedor es responsable de mantener actualizados y vigentes su datos bancarios y financieros</li>
</ol>
<p>&nbsp;</p>
<ol start="12">
<li>El proveedor es responsable del registro id&oacute;neo de un producto en la plataforma.</li>
</ol>
<p>&nbsp;</p>
<ol start="13">
<li>El proveedor es responsable de autorizar o no la elecci&oacute;n del destino (Lima, Callao o Provincia) del producto. En el supuesto que se env&iacute;e a provincia recibir&aacute; adicionalmente el monto de diez (10) Soles por envio de cada producto.</li>
</ol>
<p>&nbsp;</p>
<ol start="14">
<li>El proveedor es responsable de reportar cualquier contingencia o problema en sus pagos hasta un (1) mes despu&eacute;s de haber generado una venta.</li>
</ol>
<p>&nbsp;</p>
<ol start="15">
<li>El proveedor es responsable de tener conocimiento del cobro de la comisi&oacute;n escalonada de <strong>COMPRA FACIL</strong>.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>&iquest;Bajo que condiciones sera suspendida mi cuenta como proveedor? </u></strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>COMPRA FACIL</strong> manifiesta que es de obligatorio cumplimiento realizar las obligaciones prescritas por los &ldquo;<em>T&eacute;rminos y Condiciones de Uso</em>&rdquo; de <strong>COMPRA FACIL</strong>.</p>
<p>&nbsp;</p>
<p>Si eres proveedor de la plataforma, estas obligado a cumplir las obligaciones establecidas anteriormente (ver pregunta anterior); de lo contrario el proveedor ser&aacute; suspendido.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="8">
<li><strong><u>Sobre los Enlaces e Indemnidad de COMPRA FACIL E.I.R.L</u></strong></li>
</ol>
<p><strong>&nbsp;</strong></p>
<p>&nbsp;</p>
<p><strong>COMPRA FACIL</strong> puede contener enlaces a otros sitios Web controlados u ofrecidos por terceras personas (no relacionadas con <strong>COMPRA FACIL)</strong>. De esta manera; <strong>COMPRA FACIL</strong> declara que no se hace responsable de ninguna informaci&oacute;n, material, producto o servicio que aparezca o que se ofrezca en cualquiera de los sitios pertenecientes a terceros. Al crear un enlace al sitio Web de una tercera persona, <strong>COMPRA FACIL</strong> no apoya ni recomienda ning&uacute;n producto o servicio que &eacute;sta ofrezca, ni tampoco ninguna informaci&oacute;n que &eacute;sta contenga. Tales sitios Web pueden tener t&eacute;rminos de uso y pol&iacute;ticas de privacidad distintas a las de <strong>COMPRA FACIL,</strong> as&iacute; como proveer menos seguridad de la que ofrece nuestra Plataforma.</p>
<p><strong>&nbsp;</strong></p>
<p>Con relaci&oacute;n a la Indemnidad, cabe mencionar que; se deber&aacute; indemnizar y mantener a <strong>COMPRA FACIL</strong> exento de cualquier incumplimiento por parte del Cliente o del Proveedor de estos T&eacute;rminos y Condiciones, incluido cualquier uso de la Plataforma distinto a los expresamente autorizados en este documento. El &ldquo;Cliente&rdquo; y el &ldquo;Proveedor&rdquo; aceptan que <strong>COMPRA FACIL</strong> no tendr&aacute; ninguna responsabilidad en relaci&oacute;n con dicho incumplimiento o uso no autorizado.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="9">
<li><strong><u>Derechos de Autor, Propiedad Intelectual y Ley Aplicable </u></strong></li>
</ol>
<p>&nbsp;</p>
<p>El Cliente acepta que <strong>COMPRA FACIL</strong> es la &uacute;nica y exclusiva titular sobre su marca y nombre comercial registrados en el Per&uacute;, y/o logotipos contenidos en la Plataforma. En tal sentido, se encuentra expresamente prohibido que dicha marca y/o logotipo sea copiada, reproducida, modificadas o utilizada de cualquier manera que se afecten los derechos de propiedad intelectual que <strong>COMPRA FACIL</strong> tiene sobre estos.</p>
<p>&nbsp;</p>
<p>El Cliente acepta que <strong>COMPRA FACIL</strong> se encuentra protegido por la normativa vigente sobre propiedad intelectual y derechos de autor. De modo que todas las p&aacute;ginas, im&aacute;genes, signos, logos, logotipos, informaci&oacute;n y materiales all&iacute; contenidos, as&iacute; como la forma en que la informaci&oacute;n y los materiales est&aacute;n dispuestos, pertenecen &uacute;nica y exclusivamente a <strong>COMPRA FACIL</strong>. En tal sentido, se encuentra expresamente prohibido, el empleo, modificaci&oacute;n, reproducci&oacute;n, distribuci&oacute;n o cualquier otro acto de disposici&oacute;n de los derechos de autor y de los derechos de propiedad intelectual involucrados, sin el consentimiento expreso de <strong>COMPRA FACIL</strong>, salvo en los casos permitidos por ley.</p>
<p>&nbsp;</p>
<p>Estos <u>T</u>&eacute;rminos y Condiciones de Uso se regir&aacute;n e interpretar&aacute;n de acuerdo con las leyes de la Rep&uacute;blica del Per&uacute;, sin tener en cuenta los conflictos de principios legales. Asimismo, el Cliente declara su conformidad de someter cualquier discrepancia a la jurisdicci&oacute;n de los jueces y tribunales de Lima, Per&uacute;.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="10">
<li><strong><u>Modificaciones</u></strong></li>
</ol>
<p>&nbsp;</p>
<p>Los presentes T&eacute;rminos y Condiciones han sido actualizados con fecha 24 de abril del 2021 y podr&aacute;n ser modificados por <strong>COMPRA FACIL</strong>. Cualquier cambio o modificaci&oacute;n ser&aacute; publicado en nuestra Plataforma.</p>
        
       
        </div>
      </div>
    )
  }
  
}

export default Legales;
