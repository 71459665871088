import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import '../common/index.scss';
import { connect } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import DetailsWithPrice from './common/product/details-price';
import DetailsTopTabs from './common/details-top-tabs';
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions';
import ImageZoom from './common/product/image-zoom';
import SmallImages from './common/product/small-image';
import { APP_NAME } from '../../utils';
import { withRouter } from 'react-router-dom';
import { ProductService } from '../../services';
import NewProduct from "../common/new-product";
import RelatedProduct from "../common/related-product";

class LeftSideBar extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      variantsObject: null,
      open: false,
      nav1: null,
      nav2: null,
      originalItem: {},
      newproducts: [],
      item: {
        name: '',
        images: [],
        category: {
          name: '',
        },
        user: {
          name: '',
        },
        ratings: [
          {
            message: '',
          },
        ],
        questions: [],
      },
      variants: [],
    };
  }

  componentDidMount() {
    
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    this.load();
  }
 
  load = async () => {
    
    const item = await ProductService.getById(this.props.match.params.id);
    const newproducts = await ProductService.relacionados(item.category_id);
    const variantsObject =
      item.variants && item.variants.length
        ? {
            variants: [item, ...item.variants],
            variantAttributes: item.variant_attributes,
          }
        : null;
    this.setState({
      originalItem: item,
      item,
      newproducts,
      variants: item.variants,
      variantsObject,
    });

  };

  filterClick = () => {
    if (document.getElementById('filter')) {
      document.getElementById('filter').style.left = '-15px';
    }
  };

  backClick() {
    document.getElementById('filter').style.left = '-365px';
  }

  onChangeVariant = (variant) => {
    const images = this.state.originalItem.images;
    if (!variant.images || !variant.images.length) variant.images = images;
    const item = {
      ...this.state.originalItem,
      ...variant,
    };
    if (item.variants) delete item.variants;
    this.setState({ item });
  };

  render() {
    const { symbol, addToCart, addToCartUnsafe, addToWishlist } = this.props;
    const { item } = this.state;
    console.log(item);
    const products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };
    const productsnav = {
      slidesToShow: 5,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };
    return (

      <div>
     <Helmet>
       <title>comprafácil</title>
       <meta charset="utf-8" />
       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
       <meta name="csrf_token" content="" />
       <meta property="type" content="website" />
       <meta property="url" content={'https://comprafacil.com.pe/detalle/productos/136'} />
       <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
       <meta name="msapplication-TileColor" content="#ffffff" />
       <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
       <meta name="theme-color" content="#ffffff" />
       <meta name="_token" content="" />
       <meta name="robots" content="noodp" />
       <meta property="title" content={'comprafacil33'} />
       <meta property="quote" content={'fdsfgd'} />
       <meta name="description" content={'prueba 23'} />
       <meta property="image" content={'https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG'} />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="website" />
       <meta property="og:title" content={'comprafacil3'} />
       <meta property="og:quote" content={'sdgsdgsfdg'} />
       <meta property="og:hashtag" content={'pray for ucranie'} />
       <meta property="og:image" content={'https://storage.googleapis.com/cmperstribe_storage_usha/Banner/IMG_3640.JPG'} />
       <meta content="image/*" property="og:image:type" />
       <meta property="og:url" content={'https://comprafacil.com.pe/detalle/productos/136'} />
       <meta property="og:site_name" content="CampersTribe" />
       <meta property="og:description" content={'otra descrsdfsadf'} />    
  </Helmet>
        <Breadcrumb parent={'Productos'} title={item.name} categoria={item.category.category_id} subcategoria={item.category_id} otro={item.category.name} principal={item.category.principal}/>
        {!!item && (
          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="filter-main-btn mb-4">




                            <span
                              onClick={this.filterClick}
                              className="filter-btn"
                            >
                              <i className="fa fa-filter" aria-hidden="true" />{' '}
                              Fitrar
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5 product-thumbnail">
                          <Slider
                            {...{
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              dots: false,
                              arrows: false,
                              fade: true,
                            }}
                            infinite={false}
                            asNavFor={this.state.nav2}
                            ref={(slider) => (this.slider1 = slider)}
                            className="product-slick product-custom-info border-shadow-custom"
                          >
                            {[...item.images].map((vari, index) => (
                              <div key={vari.id || index}>
                                <div className="d-flex justify-content-center mb-0">
                                  <ImageZoom image={vari.uri} />
                                </div>
                              </div>
                            ))}
                          </Slider>

                          <SmallImages
                            item={{
                              ...item,
                              images: [...item.images],
                            }}
                            settings={{
                              slidesToShow: 5,
                              alignItems: 'center',
                              verticalAlign: true,
                              swipeToSlide: true,
                              arrows: true,
                              dots: false,
                              focusOnSelect: true,
                              infinite: false,
                            }}
                            navOne={this.state.nav1}
                          />
                        </div>

                        <div className="col-lg-7 product-thumbnail mb-4">
                          <DetailsWithPrice
                            symbol={symbol}
                            item={item}
                            variantsObject={this.state.variantsObject}
                            variants={this.state.variants}
                            onChangeVariant={(variant) =>
                              this.onChangeVariant(variant)
                            }
                            navOne={this.state.nav1}
                            addToCartClicked={addToCart}
                            BuynowClicked={addToCartUnsafe}
                            addToWishlistClicked={addToWishlist}
                            onChangeProduct={(product) => {
                              const images = this.state.originalItem.images;
                              if (!product.images || !product.images.length)
                                product.images = images;
                              const item = {
                                ...this.state.item,
                                ...product,
                              };
                              this.setState({ item });
                            }}
                          />
                          <DetailsTopTabs
                            load={() => this.load()}
                            item={item}
                            history={this.props.history}
                            productId={this.props.match.params.id}
                          />
                        </div>
 
                        <div className="col-12">
                          
                        </div>

                        <div className="col-12">
                        <br></br>
                        <RelatedProduct items={this.state.newproducts}/>

                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.match.params.id;
  return {
    item: state.data.products.find((el) => el.id == productId),
    symbol: state.data.symbol,
  };
};

export default connect(mapStateToProps, {
  addToCart,
  addToCartUnsafe,
  addToWishlist,
})(withRouter(LeftSideBar)); 
