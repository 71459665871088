import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { APP_NAME } from '../../utils'
import Breadcrumb from "../common/breadcrumb";
import { getCartTotal,ProductService } from "../../services";
import { removeFromCart, incrementQty, decrementQty } from '../../actions'
import defaultImage from '../../assets/images/image_defaoult.jpg'
import RelatedProduct from "../common/related-product";
 
import './sytyle.scss';
class cartComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newproducts: [],
    
    };
  }

  componentDidMount() {
    
    this.load();
  }
 


  load = async () => {
    

      const newproducts = await ProductService.relacionados(70);
     
      this.setState({
        newproducts
      });
  }

 
  image = product => {
    const images = product.images;
    if (!images || !images.length) return defaultImage
    const image = images[0];
    return image.uri || image.url
  }

  name = item => {
    const product = item.product ? item.product : item;
    console.log('este producto: '+ product.code);
    return product.code + " " + product.name + " " + product.textvariantes
  }



  id = item => {
    const product = item.product ? item.product : item;
    return product.id
  }

  getPrice = ({ price }) => price;

  subtotal = ({ price, qty }) => (parseFloat(price) * qty).toFixed(2)


  total = items => items
    .map(it => parseFloat(this.subtotal(it)))
    .reduce((accumulator, it) => accumulator + it, 0)
    .toFixed(2)

  render() {
    const user = this.props.user;
    

    console.log('hola usuario: '+user);
    const { cartItems, symbol } = this.props;
    return (
      <>

        <Helmet>
          <title>{APP_NAME} | Carrito de compras</title>
          <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
        </Helmet>

        <Breadcrumb title={'Carrito de compras'} />

        {!!cartItems.length
          ?
          <div className="container">
            <section className="cart-section ">

              <div className="table-responsive">
                <table className="table" style={{ minWidth: 930 }}>
                  <thead>
                    <tr className="table-head" style={{ background: '#ECECEC', height: 50 }}>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', borderRadius: '20px 0 0 20px' }}>Imagen</th>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', }}>Nombre del producto</th>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', }}>Precio</th>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', }}>Cantidad</th>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', }}>Acciones</th>
                      <th scope="col" className="font-sand" style={{ border: 0, verticalAlign: 'middle', borderRadius: '0 20px 20px 0' }}>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item, index) =>
                      <tr key={index}>

                        <td>
                          <Link to={`/detalle/productos/${item.id}`}>
                            <img src={this.image(item)} alt="" />
                          </Link>
                        </td>

                        <td>
                          <Link to={`/detalle/productos/${this.id(item)}`} className="font-sand">{this.name(item)}</Link>
                          {/* <div className="mobile-cart-content row">
                            <div className="col-xs-3">
                              <div className="qty-box">
                                <div className="input-group">
                                  <input type="text" name="quantity" className="form-control input-number" defaultValue={item.qty} />
                                </div>
                              </div>
                            </div>
                            <div className="col-xs-3">
                              <h2 className="td-color">{symbol}{item.price}</h2>
                            </div>
                            <div className="col-xs-3">
                              <h2 className="td-color">
                                <a href="#" className="icon" onClick={() => this.props.removeFromCart(item)}>
                                  <i className="icon-close" />
                                </a>
                              </h2>
                            </div>
                          </div> */}
                        </td>

                        <td>
                          <h2>{symbol}{this.getPrice(item)}</h2>
                        </td>

                        <td>
                          <div className="qty-box">
                            <div className="input-group">
                              <span className="input-group-prepend align-self-center">
                                <div type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} datatype="minus" data-field="">
                                  <i className="fa fa-angle-left" />
                                </div>
                              </span>
                              <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number input_custom_40" style={{ height: 50 }} />

                              <span className="input-group-prepend align-self-center">
                                <div className="btn quantity-right-plus" onClick={() => this.props.incrementQty(item, 1)} datatype="plus" disabled={(item.qty >= item.stock)}>
                                  <i className="fa fa-angle-right" />
                                </div>
                              </span>

                            </div>
                          </div>
                          {(item.qty >= item.stock) ? <div className="text-danger font-sand">Solo quedan {item.stock} unidades</div> : ''}
                        </td>

                        <td>
                          <p className="icon cursor-pointer" onClick={() => this.props.removeFromCart(item)}>
                            <i className="fa fa-times" />
                          </p>
                        </td>

                        <td>
                          <h2 className="td-color font-sand">{symbol}{this.subtotal(item)}</h2>
                        </td>

                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4" className="text-left"><span className="mayusculas"  style={{ color: 'black', fontSize: '13px'}}>Recuerde que si su compra es de S/ 200 o más, recibira envio gratuito a lima o descuento a provincia</span></td>
                      <td className="font-sand">Precio total:</td>
                      <td><h2 className="font-sand">{symbol} {this.total(cartItems)} </h2></td>
                    </tr>
                  </tfoot>
                </table>
              </div>


            

              <div className="container m-4">
                <div className="row cart-buttons">
                  <div className="col-6">
                    <Link to={`/`} className="btn btn-solid">Seguir comprando</Link>
                  </div>
                  <div className="col-6">

                  {user == 5 || user == undefined ? (
                  <Link to={`pages/login`} className="btn btn-solid">IR AL PAGO</Link>
                    
                    
                ) : (
                  <Link to={`/checkout`} className="btn btn-solid">IR AL PAGO</Link>
                )}

                    



                  </div>
                </div>
              </div>


              <div className="container m-4">
                <div className="row">
                  <div className="col-12">
                 

                  <RelatedProduct items={this.state.newproducts}/>
                  </div>
                  
                </div>
              </div>


            </section>
          </div>
          :
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div >
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img src={`/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                      <h3>
                        <strong>Su carro esta vacío</strong>
                      </h3>
                      <h4>Explorar mas opciones.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart)
})

export default connect(mapStateToProps, { removeFromCart, incrementQty, decrementQty })(cartComponent)
