import {axios} from '../axios';
const END_POINT = 'sliders/get-all-activated';

const getAllActivated = async () => {
    try{
        const r = await axios.get(`${END_POINT}`);
        const items = r.data;
        return items;
    }catch(e){
        throw e
    }
}

export default {
    getAllActivated
}