import {axios} from '../axios';
const END_POINT = 'checkout';

   const save = async (params) => {
    try {
        const res = await axios.post(`${END_POINT}`, params);
        console.log(res);

        return res.data;
    } catch(e) {
        if (e.response) throw Error(e.response.data.error)
        throw Error("Disculpe ha ocurrido un error")
    }
} 

const getProducts = async (productIds = [], customIds = []) => {
    try {
        const res = await axios.post(`${END_POINT}/products`, {productIds, customIds});
        return res.data;
    } catch(e) {
        if (e.response) throw Error(e.response.data.error)
        throw Error("Disculpe ha ocurrido un error")
    }
}

export default {
    save,
    getProducts
}
