/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import  moment  from 'moment';
import { Link } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import Modal from 'react-responsive-modal';
import AttributeFilters from '../../../attribute-filters/AttributeFilters';
import { connect } from 'react-redux';
import { addToCart, addToCompare, addToWishlist } from '../../../../actions';
// import Details from './details';
import shipped from '../../../../assets/images/shipped.jpg';
import { getApi } from '../../../../services/getApi';
import { INFO_USER } from '../../../../services/endpoints';
import { Helmet } from 'react-helmet';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ProductService } from '../../../../services';

import { Globals } from '../../../../utils';
import './details-price.scss';
const PriceLabel = ({ product, symbol }) => {
  const { price, has_discount, offer_price } = product;

  if (!has_discount) return null;

  const rate = (100 - (offer_price / price) * 100).toFixed(1);

  return (
    <h4 className="font-sand">
      <del>
        {symbol} {price}{' '}
      </del>
      <span>{rate}% de dscto</span>
    </h4>
  );
};

const user = !!localStorage.getItem('state')
  ? JSON.parse(localStorage.getItem('state'))
  : '';
const userToken = !!user?.user?.access_token ? user?.user?.access_token : '';
let billType = null;

const callInfoUser = async () => {
  const urlApi = `${INFO_USER}${user.user.user.id}`;
  await getApi(urlApi, userToken)
    .then((response) => {
      // console.log('points', response)
      const data = response.data;
      if (response.status === 200) {
        billType = data.bill_type;
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log('C�digo de error en details price:', error.response.status);
      }
    });
};

class DetailsWithPrice extends Component {

  

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      stock: 'InStock',
      nav3: null,
      product: null,
      product_selected: {},
      wasChecked: false,
      bill_type: billType,
      title: '',
      message: '',
      correo: '',
      contacto: '',
      user_id: (this.props.user && this.props.user.id) || null,
      
    };
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => {
      return {
        ...this.state,
        form: {
          ...this.state.form,
          [name]: value,
        },
      };
    });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    console.log( this.props.item)
    this.setState({
      nav3: this.slider3,
      product: this.props.item,
    });
  }

  componentWillUpdate() {
    if (!!userToken) {
      callInfoUser();
    }
  }


calcularJuevesYViernesSanto(anio) {
  let a = anio % 19;
  let b = anio % 4;
  let c = anio % 7;
  let d = (19 * a + 24) % 30;
  let e = (2 * b + 4 * c + 6 * d + 5) % 7;
  let f = d + e + 22;

  let juevesSanto;
  let viernesSanto;

  if (f <= 31) {
    juevesSanto = new Date(anio, 2, 22 + f - 1);
    viernesSanto = new Date(anio, 2, 23 + f - 1);
  } else {
    juevesSanto = new Date(anio, 3, f - 31 - 3);
    viernesSanto = new Date(anio, 3, f - 30 -3);
  }

  return { juevesSanto, viernesSanto };
}



esDomingoOLunes(fecha) {
  const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  var numero = fecha.getDay();

  if (numero == 6){
    numero = 0;
  } else{
    numero = numero + 1;
  }

  const dia = diasSemana[numero];
  console.log('este es el supuesto dia: '+dia);
  return dia === 'domingo';
}


  addHours2(numOfHours, fecha) {

      var fecha = new Date (fecha)
      var fecha2 = moment(fecha);
      var fecha3 = fecha2.add(numOfHours, 'hours');
      var fecha4 =  fecha3.format('YYYY-MM-DD');

      return fecha4;

  };

  calcularSemanaSanta(anio) {
  let a = anio % 19;
  let b = Math.floor(anio / 100);
  let c = anio % 100;
  let d = Math.floor(b / 4);
  let e = b % 4;
  let f = Math.floor((b + 8) / 25);
  let g = Math.floor((b - f + 1) / 3);
  let h = (19 * a + b - d - g + 15) % 30;
  let i = Math.floor(c / 4);
  let k = c % 4;
  let l = (32 + 2 * e + 2 * i - h - k) % 7;
  let m = Math.floor((a + 11 * h + 22 * l) / 451);
  let n = Math.floor((h + l - 7 * m + 114) / 31);
  let p = (h + l - 7 * m + 114) % 31;
  let diaPascua = p + 1;
  let mesPascua = n;

  let fechaPascua = new Date(anio, mesPascua - 1, diaPascua);

  // Cálculo de la fecha de Jueves Santo (3 días antes de la fecha de Pascua)
  let juevesSanto = new Date(fechaPascua.getFullYear(), fechaPascua.getMonth(), fechaPascua.getDate() - 3);

  // Cálculo de la fecha de Viernes Santo (2 días antes de la fecha de Pascua)
  let viernesSanto = new Date(fechaPascua.getFullYear(), fechaPascua.getMonth(), fechaPascua.getDate() - 2);

  // Formateo de las fechas en Y-m-d
  let juevesSantoStr = juevesSanto.getFullYear() + '-' + (juevesSanto.getMonth() + 1).toString().padStart(2, '0') + '-' + juevesSanto.getDate().toString().padStart(2, '0');
  let viernesSantoStr = viernesSanto.getFullYear() + '-' + (viernesSanto.getMonth() + 1).toString().padStart(2, '0') + '-' + viernesSanto.getDate().toString().padStart(2, '0');

  // Devolución del objeto con las fechas
  return {
    juevesSanto: juevesSantoStr,
    viernesSanto: viernesSantoStr
  };
}



  validarferiados (fecha){
    let anio = 2023;

    let semanaSanta = this.calcularSemanaSanta(anio);

    console.log("el jueves santo es "+semanaSanta.juevesSanto);
     var lista = [
             // anio+'-03-06',
             // anio+'-03-06',
             // anio+'-03-07',
              semanaSanta.juevesSanto,
              semanaSanta.viernesSanto,
              anio+'-05-01',
              anio+'-07-06',
              anio+'-29-06',
              //'30/6',
              anio+'-07-28',
              anio+'-07-29',
              anio+'-09-06',
              anio+'-09-30',
              anio+'-10-08',
              // '9/10',
              anio+'-11-01',
              //  '7/12',
              anio+'-12-08',
              anio+'-12-09',
              anio+'-12-25',
              //'26/12',
              anio+'-01-01',
              //'2/1',
            ];

     return lista.includes(fecha);

  }


 addHours(numOfHours, date = new Date()) {

  let anio = 2023;
 // let fechassantas = this.calcularJuevesYViernesSanto(anio);
 

  //console.log(lista);

  var fecha = moment();
  var fecha2 = fecha.add(numOfHours, 'hours');
  var fecha3 =  fecha2.format('YYYY-MM-DD');

  var fecha4 = new Date(fecha3);

  console.log('la fecha es:'+fecha4+" la otra es "+fecha3);





  while (this.validarferiados(fecha3) || this.esDomingoOLunes(fecha4)){

    fecha3 = this.addHours2(48, fecha3);
    fecha4 = new Date(fecha3);


  }


    return fecha3;
  


  





 //verificar cuenta de horas viernes 24/06 + 60 horas = 28/06
  /*
startDate = new Date("09/17/2021");  
endDate = new Date("10/28/2021");  
// Calculate days_difference = (endDate milliseconds - startDate milliseconds) / (1000 * 60 * 60 * 24) to get results in days  
var days_difference = (endDate.getTime() - startDate.getTime()) / 86400000; 
// Calculate number of weekends during the whole duration
var weekdends = Math.floor(days_difference/7) * 2 ;
// Adjust weekends depend on start days 
(days_difference%7)+startDate.getDay() == 6 ? weekdends+=2 : 
  (days_difference%7)+startDate.getDay() == 5 ? weekdends+=1 :
  weekdends;
// Calculate Working Days 
var workDays = days_difference - weekdends
console.log("Working days",(workDays));
*/
}


  componentWillReceiveProps(props) {
    if (!this.state.wasChecked && props.item !== this.state.product) {
      const product = {
        ...props.item,
      };
      if (product.variants) delete product.variants;
      this.setState({ product });
    }
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock' });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.item.stock > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: 'Sin Stock!' });
    }
  };

  changeQty = (e) => {
    const quantity = parseInt(e.target.value);
    if (this.props.item.stock > quantity) this.setState({ quantity });
  };

  changeSelect = (product_selected) => {
    const product = this.props.item.variants.find(
      (element) => element.id === product_selected.value
    );
    if (product) {
      this.setState(
        {
          product,
          product_selected,
        },
        () => {
          this.props.onChangeVariant(product);
        }
      );
    }
  };


  submitAsk = async (e) => {
    e.preventDefault();
    try {
      const res = await ProductService.saveAsk(this.state.form);
      Globals.showSuccess(res.msg);
      this.setState(
        {
          form: this.IState.form,
        },
        () => this.props.load()
      );
    } catch (err) {
      console.log('>>: err > ', err);
      Globals.showError();
    }
  }; 

  getPrice = ({ price, has_discount, discount_rate, offer_price }) => {
    // if (offer_price) return offer_price;
    // if (has_discount) return (price-(price*(discount_rate/100))).toFixed(2)
    return has_discount ? offer_price : price;
  };

  aadCart = () => {
    console.log('>>: addCart.details');
    const product = this.state.product;
    product.price = this.getPrice(product);

    const _product = {
      product,
      price: this.getPrice(product),
      originalPrice: product.price,
    };
    this.props.addToCart(product, this.state.quantity);
  };
  renderTooltip = (props) => (
    <Tooltip {...props}>
      Si el producto que usted desea agregar a la lista de deseos no tiene
      stock, usted recibira en su correo electronico un mensaje cuando exista
      producto en el stock.
    </Tooltip>
  );
  render() {
    const { symbol, BuynowClicked, addToWishlistClicked } = this.props;
    const item = this.state.product || this.props.item;

    var acortado = item.name;
    var nuevo = acortado.substring(0,31);
    // console.log(item);
    return (
      <>
      

        <div className="product-right rtl-text product-description border-product">
        <div className="row">
        <div className="w-100 col-md-6">
            <h3 className="font-sand"> {nuevo}... </h3>

            <PriceLabel product={item} symbol={symbol} />


            {!!item.rating && (
              <div className="w-100 d-flex justify-content-center justify-content-lg-start">
                <ReactStars value={item.rating} edit={false} />
              </div>
            )}



            <div className="w-100">
            <h2>
              {symbol}
              {this.getPrice(item)}
              
            </h2>


            {item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                ''
                ) : (
                  
                  item.stock < 3 ? (
                    <span className="instock-cls block font-sand">
                      {item.stock} en stock
                    </span>
                    
                  ) : (
                    ''
                  )
                  
                )}

           

            {item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                ''
                ) : (
                  
                  
                  <div className="content-message font-sand">
                    {item.stock >= 1 && item.stock <= 5 ? (
                      
                      <span className="text-danger">
                        Este producto se está quedando sin stock.
                      </span>
                    ) : (
                      ''
                    )}
                    {item.stock <= 0 ? (
                      <span className="text-danger font-sand">
                        Este producto no tiene cantidad en el stock
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                )}




           
           
            
            {!!userToken && item.stock >= 1 ? (
              <>

              {(item.category.freelancer == 1 || item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252) ? (

                <div className="mr-2 justify-content-center">
                  Si esta interesado puede contactar al vendedor en la parte inferior derecha dando click en la opción "Contactar con el vendedor", llenar el formulario.
                </div>
                ) : (
                <div className="qty-box w-50">
                  <div className="input-group d-flex justify-content-center justify-content-lg-start">
                    {
                      <span className="input-group-prepend align-self-center">
                        <div
                          type="div"
                          className="btn quantity-left-minus"
                          onClick={this.minusQty}
                          datatype="minus"
                          data-field=""
                        >
                          <i className="fa fa-angle-left" />
                        </div>
                      </span>
                    }
                    <input
                      type="text"
                      min="1"
                      step="1"
                      name="quantity"
                      value={this.state.quantity}
                      onChange={this.changeQty}
                      className="form-control input-number mb-3"
                      style={{
                        minHeight: 50,
                        minWidth: 90,
                        fontSize: 15,
                        borderRadius: 10,
                      }}
                    />
                    {
                      <span className="input-group-prepend align-self-center">
                        <div
                          type="div"
                          className="btn quantity-right-plus"
                          onClick={this.plusQty}
                          datatype="plus"
                          data-field=""
                        >
                          <i className="fa fa-angle-right" />
                        </div>
                      </span>
                    }
                    <div className="align-self-center ml-3 d-flex mb-3">
                      {item.stock >= 1 ? (
                        <div className="mr-2 align-self-center">
                          <a
                            className="btn btn-solid font-sand"
                            onClick={this.aadCart}
                          >
                            Añadir al carrito
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="align-self-center">
                        <div
                          className="btn btn-solid font-sand btn-outline-primary"
                          onClick={() => addToWishlistClicked(item)}
                          style={{ background: 'transparent' }}
                        >
                          <i className="fa fa-heart text-primary" />
                          <span className="title-font text-primary"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}


   

               
              </>
            ) : (
            <div>
            { item.stock > 0
            ? 
            <div className="mt-4 mr-2 align-self-center">
                <Link className="btn btn-solid font-sand" to="/pages/login">
                  Inicie Sesión para comprar
                </Link>
              </div>
            : 
             ''
           
            }
              </div>



            )}
          </div>






          </div>

          { item.stock > 0
            ? 
            <div  className="col-md-6">
            {item.category_id == 254 || item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                <div className="col-md-12">
              
                
                </div>
                ) : (
                  
                  <div className="col-md-12">
              
                <p className=" mb-2" style={{ color: 'green', fontSize: 12 }}>

                Fecha entrega estimada (LIMA) {this.addHours(60)}
                </p> 

                {item.can_go_to_province !== 0 ? (
                  <p className=" mb-2" style={{ color: 'green', fontSize: 12 }}>

                  Fecha entrega estimada (PROVINCIA) {this.addHours(140)}
                  </p>
                ) : (
                  ""
                )}

                </div>
                  
                )}


            {item.condition == 0 ? (
              <div className="w-100 d-flex justify-content-center justify-content-lg-start">
               <h4 style={{ color: 'red'}}><strong> ARTÍCULO USADO </strong></h4>
              </div>
            ) : (
            ""
            )}


{!!userToken && item.stock >= 1 ? (
              <>
        {item.category_id == 254 || item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                ''
              ):(
              
                <div className="w-100 mt-3">           

                {!!item.paymentmethodoffer &&

          item.paymentmethodoffer?.transferencia !== false &&
          item.paymentmethodoffer?.paypal !== false &&
          item.paymentmethodoffer?.niubiz !== false &&
          item.paymentmethodoffer?.pagoEfectivo !== false ? (
            ''
          ) : (
            <div className="w-100 mt-3">
              <p className="text-info" style={{ fontSize: 13 }}>
                Si realiza su compra mediante los siguientes métodos de pago el
                precio a pagar será de
                <span className="text-danger font-weight-bold">{` S/ ${item?.offer_price}`}</span>
              </p>

              <table className="completo table table-hover table-striped mb-0">
                    <tbody>
                      <tr>
                      
                        <th className="font-sand">Métodos de Pago</th>
                        
                      </tr>
                      
                      {item.paymentmethodoffer?.transferencia ? (
                      <tr>
                        <td><img
                            src="https://www.electricidadtonin.com/wp-content/uploads/2017/03/transferencia-bancaria-waps.png"
                            alt="transferencia"
                            style={{ maxWidth: 100 }}
                          /></td>

                          </tr>
                            ) : (
                            ""
                            )}

                        

                      
                      {item.paymentmethodoffer?.paypal ? (
                      <tr>
                        <td><img
                      src="https://logos-marcas.com/wp-content/uploads/2020/04/PayPal-Logo.png"
                      alt="paypal"
                      style={{ maxWidth: 100 }}
                    /></td>
                        </tr>
                            ) : (
                              ""
                            )}

                        

                      
                  {item.paymentmethodoffer?.niubiz ? (
                      <tr>
                        <td><img
                      src="https://www.floresydetalles.pe/imagenes/ckfinder/userfiles/images/niubiz_visa_mastercard.png"
                      alt="niubiz"
                      style={{ maxWidth: 100 }}
                    /></td>
                        </tr>
                            ) : (
                              ""
                            )}

                        

                      {item.paymentmethodoffer?.pagoEfectivo ? (
                     <tr>
                        <td><img
                      src="https://sites.pagoefectivo.pe/logo-pagoefectivo.png"
                      alt="pagoEfectivo"
                      style={{ maxWidth: 100 }}
                    /></td>
                        </tr>
                            ) : (
                              ""
                            )}

                        

                      
                      </tbody>
                </table>
             
            </div>
          )}

          </div>

          
          )}

            </>
            ) : (
            <div>
       
              </div>



            )}

                </div>
            : 
             ''
           
            }

          
          
        </div>
          

          {!!item.size && (
            <div>
              <h6 className="product-title size-text">
                select size
                <span>
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#sizemodal"
                    onClick={this.onOpenModal}
                  >
                    size chart
                  </a>
                </span>
              </h6>
              <div
                className="modal fade"
                id="sizemodal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        prueba
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <img
                        src={`/assets/images/size-chart.jpg`}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="size-box">
                <ul>
                  {item.size.map((size, i) => {
                    return (
                      <li key={i}>
                        <a href="#">{size}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          
          <div className="w-100">
            <AttributeFilters
              variants={this.props.variantsObject}
              product={this.props.item}
              onChangeProduct={(product) => {
                if (this.props.onChangeProduct)
                  this.props.onChangeProduct(product);
              }}
            />
          </div>
          
          <div className="w-100">
            <div className="border-product centrado"></div>


      
          </div>

          <div className="w-100">
            <div className="d-flex  justify-content-between product-grid-details-info">
              <div className="product-icon product-details-info d-flex flex-row" style={{}} >
                <h6 className="product-title font-sand mr-3 align-self-center"> Publicar{' '} </h6>
                  <ul className="product-social align-self-center">
                    <li style={{ paddingRight: 10 }}>
                      <a //href="https://www.facebook.com/sharer/sharer.php?u=http%3A//comprafacil.com.pe/left-sidebar/product/136"
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://comprafacil.com.pe/detalle/productos/${item.id}`}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"> <i className="fab fa-facebook" />
                      </a>
                    </li>

                    <li>
                      <a href={`https://twitter.com/intent/tweet?text=https%3A//comprafacil.com.pe/detalle/productos/${item.id}`}
                        target="_blank">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                      <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
              </div>
              <div
                className="text-dark d-flex flex-row"
                style={{
                  borderRadius: 10,
                  border: '1px solid #dddddd',
                  padding: '5px 10px',
                }}
              >
                <h6 className="product-title mr-3 align-self-center">
                  Vendedor:
                </h6>
                
          
                  <p className="product-title align-self-center">
                  <Link to={'/catalogo/' + item.user.user_name}>
                      <h5 className="card-title m-0 font-sand">{item.user.user_name}</h5>
                  </Link>
                  </p>
                    
                    
                
                  
                
                {/* {!!item.user.uri &&
                          <img
                            src={item.user.uri}
                            alt={item.user.name}
                            style={{maxWidth: '80%'}}/>
                        } */}
              </div>

              {item.category_id == 254 || item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                ''
              ):( 

              <div>

               {item.can_go_to_province !== 0 ? (
                <div
                  className=""
                  style={{
                    borderRadius: 10,
                    border: '1px solid #dddddd',
                    padding: '13px 10px',

                  }}
                >
                 
                    <div className="text-dark text-center w-100 d-flex flex-row">
                      <h6 className="product-title mr-3 align-self-center">
                        Envío a Provincia
                      </h6>
                   
                      <img
                        src={`/assets/images/shipped.jpg`}
                        alt=""
                        style={{ width: 30 }}
                        className="img-fluid align-self-center"
                      />
                    </div>
                  
                </div> 

                ) : (
                    ''
                  )} 

                </div> 
              )}
              



              {item.user.fob !== 0 ? (
               <div
                  className=""
                  style={{
                    borderRadius: 10,
                    border: '1px solid #dddddd',
                    padding: '13px 10px',

                  }}
                >

                <div className="text-dark text-center w-100 d-flex flex-row">
                      <h6 className="emite product-title mr-3 align-self-center">
                        Emite Factura
                      </h6>
                 
                  <img
                    src={`/assets/images/factura.png`}
                    alt=""
                    style={{ width: 30 }}
                    className="img-fluid align-self-center"
                  />
                 
                </div>
                </div>
             ) : (
                    ''
                  )} 
               
             
              



            </div>
          </div>

           
          <div className="w-100">
          <div className="col-md-12 col-sm-12  product-icon centrado ">
          {item.category_id == 6 || item.category_id == 15  ? (
                ''

                ) : (
                 <div className="text-dark text-center w-100 d-flex flex-row">
                  {item.adult === 1 ? (
                   
                      <div className="col-md-12">
                      <h5 className="product-title align-self-center align-self-center" style={{ color: 'red' }}>
                        <i className="fa fa-times" /> NOTA: Esta prohibida la venta de este producto a menores de edad.
                      </h5>
                      </div>
                    
                  ) : (
                    ''
                  )} 
                  </div>
                  
                )}
          </div>
          </div>

          {/* <div className="col-md-3 col-sm-12  product-icon centrado ">
            <OverlayTrigger placement="top" overlay={this.renderTooltip}>
              <button
                className="wishlist-btn"
                onClick={() => addToWishlistClicked(item)}
              >
                <i className="fa fa-heart" />
                <span className="title-font text-dark">
                  A�adir a la lista de deseos
                </span>
              </button>
            </OverlayTrigger>
          </div> */}
          {/*item.category_id == 251 || item.category_id == 23 || item.category_id == 54 || item.category_id == 55 || item.category_id == 56 || item.category_id == 57 || item.category_id == 58 || item.category_id == 252 ? (
                <div>
                
                <br></br>
                  <h3 className="font-sand"> Contactar al vendedor </h3>
                 
                 
                
                   <form className="theme-form mt-3" onSubmit={this.submit}>
                <div className="form-row">
                  <div className="col-md-6">
                    <label htmlFor="review font-sand">Ingrese su correo electrónico</label>
                    <input
                      type="text"
                      name="title"
                      value={this.state.correo}
                      onChange={this.onChange}
                      className="form-control"
                      id="review"
                      placeholder="Escriba su correo"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="review font-sand">Ingrese su Número de contacto</label>
                    <input
                      type="text"
                      name="title"
                      value={this.state.contacto}
                      onChange={this.onChange}
                      className="form-control"
                      id="review"
                      placeholder="Escribe su número de teléfono"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="review font-sand">Asunto</label>
                    <input
                      type="text"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                      className="form-control"
                      id="review"
                      placeholder="Escribe el titulo"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="review font-sand">Detalle</label>
                    <textarea
                      name="message"
                      onChange={this.onChange}
                      value={this.state.message}
                      className="form-control"
                      placeholder="Escribe tu mensaje"
                      id="exampleFormControlTextarea1"
                      rows="6"
                    />
                  </div>
                  <div className="col-md-12">
                  <br></br>
                    <button
                      className="btn btn-solid font-sand btn-sm"
                      type="submit"
                      disabled={!user}
                    >
                      Contactar
                    </button>
                  </div>
                </div>
              </form>
              </div>
                ) : (
                  
                  ''
              
                )*/}

         




          <Modal open={this.state.open} onClose={this.onCloseModal} center>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Sheer Straight Kurta
                  </h5>
                </div>
                <div className="modal-body">
                  <img
                    src={`/assets/images/size-chart.jpg`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default connect(null, { addToCart, addToWishlist, addToCompare })(
  DetailsWithPrice
);
