import { ADD_CHECKOUT_TOTAL } from "../constants/ActionTypes";

export default function checkoutReducer(state = { total: 0 }, action) {
  switch (action.type) {
    case ADD_CHECKOUT_TOTAL:
      const total = action.total;
        return { ...state, total };
    default:
        return state
  }
}