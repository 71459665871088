import React from "react";
import Image from "../../widgets/Image";

const ViewCell = ({image, selected, onClick}) => {
  
  const getTitle = () => {
    const {view} = image
    if (view===1) return 'Frontal'
    if (view===2) return 'Posterior'
    return ''
  }
  
  const handleClick = () => {
    if (!onClick) return
    onClick(image.view)
  }
  
  return (
    <div
      className="text-center"
      style={{
        padding: '4px',
        width: '',
      }}
      title={ getTitle() }>
    
      <Image
        onClick={handleClick}
        className="selected"
        url={image.uri}
        resize="contain"
        style={{
          width: '66px',
          height: '66px',
          borderRadius: '6px',
          border: selected ? '2px solid #093467' : '0.5px solid lightgray'
        }}/>
  
        <span className="font-small text-center font-sand">{getTitle()}</span>
        
    </div>
  )
  
}

export default ViewCell
