import { axios } from '../axios';
const AUTH = 'authpagoefectivo';
const CIP = 'genCIP';

/* const get = async (amount, email) => {
    console.log(amount)
    const fixedAmount = amount.toFixed(2)
    try{
        const res = await axios.get(`${END_POINT}/${fixedAmount}/${email}`);
        //console.log(res)
        return res.data;
    }catch(e){
        throw e
    }
} */
const authorization = async (params) => {
  try {
    const r = await axios.post(`${AUTH}`, params);
    return r.data;
  } catch (e) {
    throw e;
  }
};

const cip = async (params) => {
  try {
    const r = await axios.post(`${CIP}`, params);
    return r.data;
  } catch (e) {
    throw e;
  }
};

export default {
  authorization,
  cip,
};
