import {axios} from "../axios";
import {fetchUpload, jsonToParams} from "../index";
import store from "../../store";

const END_POINT = '/prints';

class PrintDao {
  
  async getPrints() {
    const query = {
      all: true,
      enable: 1
    }
    const r = await axios.get(`${END_POINT}${jsonToParams(query)}`);
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    const prints = r.data
    store.dispatch({type: 'SET_PRINTS', payload: prints});
    return prints
  }
  
  async upload(image) {
    const user_id = store.getState().user.user.id
    const r = await fetchUpload(`${END_POINT}/upload`, 'image', image, {user_id});
    if (!r.ok) throw 'Disculpe ha ocurrido un error'
    return await r.json();
  }
  
}

const printDao = new PrintDao()

export default printDao;
