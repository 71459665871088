import React, { Component } from 'react';
import { emailDao } from '../../services';
import InputLabel from './InputLabel';
import "./form.scss";

class EmailInput extends Component {
  state = {
    value: '',
    loading: false,
    valid: false,
    msg: '',
    error: '',
  };

  componentDidMount() {
    const { value } = this.props;
    console.log('rol', this.props.rol)
    if (!value) return;
    this.setValue(value).then();
  }

  setValue = async (value) => {
    this.setState({
      value,
      valid: false,
      loading: true,
      error: '',
    });
    try {
      const { success, msg } = await emailDao.emailExist(value);
      this.setState({
        valid: success,
        msg: msg,
      });
      if (!this.props.onChange) return;
      this.props.onChange(value, this.props.name);
    } catch (e) {
      this.setState({ error: 'No se pudo verificar su correo electrónico' });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChange = async (evt) => await this.setValue(evt.target.value);

  isValid = () => this.state.valid;

  render() {
    const {
      id = (10000 * Math.random()).toFixed(0),
      label = '',
      name,
      required = false,
      className,
      placeholder,
      inputClassName = 'form-control',
      style= {}
    } = this.props;
    return (
      <div className={className}>
        <InputLabel htmlFor={name || id} required={required} text={label} />

        <div className="h-box align-items-center">
          <input
            id={name || id}
            //style={{ flex: 1 }}
            className={inputClassName}
            type="email"
            name={name}
            required={required}
            placeholder={placeholder || label}
            value={this.state.value}
            onChange={this.handleChange}
            style={style}
          />

          {this.state.loading && <div className="spinner ml-2" />}

          {this.state.valid && (
            <i
              className="ml-2 fa fa-check text-success"
              style={{  height: '15px' }}
            />
          )}
        </div>

        {!!this.state.msg && (
          <div
            id={name || id}
            className="form-text text-danger font-sand menos"
            style={{ fontWeight: 'bold', fontSize: '12'}}
          >
            {this.state.msg}, por favor{' '}
            {this.state.valid === false ? (
              <a
                href={
                  this.props.rol === 'seller'
                    ? '/pages/loginR/seller'
                    : this.props.rol === 'reseller'
                    ? '/pages/loginR/reseller'
                    : '/pages/login'
                }
              >
                Inicie Sesion
              </a>
            ) : null}{' '}
            para continuar.
          </div>
        )}
        {this.state.error && (
          <div
            id={name || id}
            className="form-text text-danger font-sand"
            style={{ fontWeight: 'normal', fontSize: 'x-small' }}
          >
            {this.state.error}
          </div>
        )}
      </div>
    );
  }
}

export default EmailInput;
