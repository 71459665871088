import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { AuthService } from '../../services';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import { Globals } from '../../utils';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: '',
        password: '',
      },
    };
  }
  componentDidMount() {
    // if (this.props.user) this.props.history.push('/paginas/tablero');
    console.log(this.props.match.params.rol);
  }

  submit = async (e) => {
    e.preventDefault();
    try {
      const res = await AuthService.login(this.state.form);
      await this.props.dispatch({
        type: 'SET_USER',
        payload: res,
      });
      if(this.props.match.params.rol === 'seller'){
        await this.props.history.push('/registrarse');
      }

       if(this.props.match.params.rol === 'reseller'){
        await this.props.history.push('/registrarse/comisionista');
      }

      if(this.props.match.params.rol === null || this.props.match.params.rol === undefined){
        await this.props.history.push('/paginas/tablero');
      }
      /* switch (this.props.match.params.rol) {
        case 'seller':

          break;
        case 'reseller':
          await this.props.history.push('/reseller');

          break;

        default:
          console.log('normal login')
          await this.props.history.push('/paginas/tablero');
          break;
      } */
    } catch (err) {
      Globals.showError(err?.response?.data.error);
      console.log('>>: error > ', err);
    }
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => {
      return {
        ...this.state,
        form: {
          ...this.state.form,
          [name]: value,
        },
      };
    });
  };

  render() {
    return (
      <div>
        <Breadcrumb title={'Login'} />
        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>Iniciar sesión</h3>
                <div className="theme-card">
                  <form className="theme-form" onSubmit={this.submit}>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={this.onChange}
                        value={this.state.form.email}
                        className="form-control"
                        id="email"
                        placeholder="Escribe tu email"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="review">Contraseña</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.form.password}
                        onChange={this.onChange}
                        className="form-control"
                        id="review"
                        placeholder="Escribe tu contraseña"
                        required=""
                      />
                    </div>
                    <button type="submit" className="btn btn-solid">
                      Iniciar Sesion
                    </button> &nbsp;&nbsp;&nbsp;&nbsp;
                   
                    <Link to={`/pages/forget-password`} data-lng="es">
                    ¿Olvidó su contraseña?
                    </Link>                 
                  
                   
                  </form>
                </div>
              </div>
              <div className="col-lg-6 right-login">
                <h3>Nuevo cliente</h3>
                <div className="theme-card authentication-right">
                  <h6 className="title-font">Crear una cuenta</h6>
                  <p>
                    Regístrese para obtener una cuenta gratuita en nuestra
                    tienda. El registro es rápido y fácil. Eso le permite
                    realizar pedidos en nuestra tienda. Para comenzar a comprar,
                    haga clic en Registrarse.
                  </p>
                  <a href="https://comprafacil.com.pe/pages/register" className="btn btn-solid">
                    Registrarse
                  </a>

                  

                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
