import React from "react";

const Search = ({onChangeText, className, style}) => {
  
  const handleChangeText = e => {
    if (!onChangeText) return
    onChangeText(e.target.value)
  }
  
  return <div
    className={`h-box center p-0 m-m ${className} col-6`}

    style={{
      background: 'lightgray',
      borderRadius: 10,
      ...style
    }}>
  
    <div
      className="h-box center"
      style={{
        minWidth: '30px',
        minHeight: '30px',
        maxWidth: '30px',
        maxHeight: '30px'
      }}>
      
      <i className="fa fa-search"/>
      
    </div>
    
    <input
      style={{
        border: 'none',
        background: 'transparent',
        minWidth: '75px',
        height: 45
      }}
      className="less-focus m-0 p-0 expand input_custom_40"
      type="text"
      onChange={handleChangeText}
      placeholder="Buscar"/>
  </div>
  
}

export default Search
