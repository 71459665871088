import {axios} from '../axios';
const END_POINT = 'users';

const beSeller = async params => {
    try{
        const res = await axios.upload(`${END_POINT}/be-seller`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const beComs = async params => {
    try{
        const res = await axios.upload(`${END_POINT}/be-coms`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const profile = async params => {
    try{
        const res = await axios.post(`${END_POINT}/profile`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const suscribe = async params => {
    try{
        const res = await axios.post(`${END_POINT}/suscribe`, params);
        return res.data;
    }catch(e){
        throw e
    }
}


const getUserData = async userId => {
    try{
        const res = await axios.get(`${END_POINT}/get-user-data/${userId}`);
        return res.data;
    }catch(e){
        throw e
    }
} 

const purchases = async params => {
    try{
        const res = await axios.get(`${END_POINT}/purchases`, params);
        return res.data;
    }catch(e){
        throw e
    }
}


const recibidos = async params => {
    try{
        const res = await axios.post(`${END_POINT}/purchases/recibido`, params);
        return res.data;
    }catch(e){
        throw e
    } 
}

const qualifyPurchase = async params => {
    try{
        const res = await axios.post(`${END_POINT}/qualify-purchase`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const getShopById = async params => {
    try{
        const res = await axios.post(`${END_POINT}/get-shop`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const beSeller2 = async params => {
    try{
        const res = await axios.post(`${END_POINT}/be-seller-2`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    getUserData,
    getShopById,
    qualifyPurchase,
    purchases,
    recibidos,
    beComs,
    profile,
    beSeller,
    beSeller2,
    suscribe
}
