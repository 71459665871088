import {axios} from '../axios';

const END_POINT = 'forgot-password';
const send = async params => {
    try{
        const res = await axios.post(`${END_POINT}`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

const changePassword = async params => {
    try{
        const res = await axios.post(`${END_POINT}/change-password`, params);
        return res.data;
    }catch(e){
        throw e
    }
}

export default {
    changePassword,
    send
}