import React, {Component} from "react";
import termService from "../../services/modules/termService";
import {Globals} from "../../utils";
import "./conditions.css"

class Condition extends Component {
  
  state = {
    p: [],
    term: ""
  }
  
  componentDidMount() {
    this.fetchTerm().then()
  }
  
  fetchTerm = async () => {
    Globals.setLoading();
    try {
      const res = await termService.index();
      const p = res.text
        ? res.text.split("\n")
        : [];
      this.setState({p, term: res.text});
    } catch (e) {
      alert(e.message)
    } finally {
      Globals.quitLoading();
    }
  }
  
  render() {
    return (
      <div id="conditions" style={{ padding: "0px 240px", color: "black" }}>
        
        <br/>
        
        <h2>Términos y condiciones</h2>
        
        <br/>

        {!!this.state.term &&
          <div style={{color: "black"}} dangerouslySetInnerHTML={{__html: this.state.term}}/>
        }
        
        {/*{this.state.p.map( (item, index) =>*/}
        {/*  <p key={index} className="text-black-50">*/}
        {/*    {item}*/}
        {/*  </p>*/}
        {/*)}*/}
        
      </div>
    )
  }
  
}

export default Condition;
