import {axios} from "../axios";
import {fetchUpload} from "../index";

const END_POINT = '/product-customs';

class ProductCustomDao {
    
  async store(productCustom) {
    const r = await axios.post(END_POINT, productCustom)
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    return r.data
  }
  
  async upload(image, view, product_custom_id) {
    const r = await fetchUpload(`${END_POINT}/upload`, 'image', image, {view, product_custom_id});
    if (!r.ok) throw 'Disculpe ha ocurrido un error'
    return await r.json();
  }
  
}

const productCustomDao = new ProductCustomDao()

export default productCustomDao
