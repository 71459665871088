import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {ForgetPasswordService} from '../../services'
import {Globals} from '../../utils'
class ForgetPassword extends Component {

    constructor (props) {
        super (props)
    }

    state = {
        form: {
            email: '',
            origin: window.location.href
        }
    }

    componentDidMount(){
        console.log('props',  this.props)
    }

    change = async e => {
		const name = e.target.name
		this.setState({
            form: {
                ... this.state.form,
                [name]: e.target.value
            }
		});
	}

    submit = async (e) => {
        e.preventDefault()
        try {
            const {msg} = await ForgetPasswordService.send(this.state.form)
            Globals.showSuccess(msg)
        } catch (error) {
            Globals.showError(error.response.data.msg)
        }
    }

    changePassword = async (e) => {
        e.preventDefault()
        try {
            const {msg} = await ForgetPasswordService.changePassword({
                password: this.state.form.password,
                password_confirmation: this.state.form.password_confirmation,
                token:  this.props.match.params.token
            })
            Globals.showSuccess(msg)
            this.props.history.push('/pages/login')
        } catch (error) {
            Globals.showError(error.response.data.msg)
        }
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Recuperar Contraseña'}/>
                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Recuperar Contraseña</h2>
                                {
                                    this.props.match.params.token
                                    ?
                                        <form className="theme-form" onSubmit={this.changePassword}>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        onChange={this.change}
                                                        id="password"
                                                        placeholder="Escribe tu nueva contraseña"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password_confirmation"
                                                        onChange={this.change}
                                                        id="password_confirmation"
                                                        placeholder="Confirmar contraseña"
                                                        required
                                                    />
                                                </div>
                                                <button
                                                    disabled={!this.state.form.password || !this.state.form.password_confirmation}
                                                    className="btn btn-solid"
                                                    type="submit"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </form>
                                    :
                                        <form className="theme-form" onSubmit={this.submit}>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        onChange={this.change}
                                                        id="email"
                                                        placeholder="Escribe tu email"
                                                        required
                                                    />
                                                </div>
                                                <button
                                                    disabled={!this.state.form.email}
                                                    className="btn btn-solid"
                                                    type="submit"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default ForgetPassword