import React from "react";
import Required from "./Required";

const InputLabel = ({htmlFor, text, required, leftIcon}) => {
  
  const iconStyle = {
    height: "16px",
    marginRight: "4px"
  }
  
  return leftIcon==null
    ?
    <label htmlFor={htmlFor} className="form-label font-sand">
      {text} <Required required={required}/>
    </label>
    :
    <div className="h-box align-items-center mb-1">
    
      <img
        src={leftIcon}
        alt=""
        className="img-fluid"
        style={iconStyle}/>
    
      <label htmlFor={htmlFor} className="form-label expand p-0 m-0 font-sand">
        {text} <Required required={required}/>
      </label>
  
    </div>
  
}

export default InputLabel
