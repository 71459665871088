import React, {Component} from "react";
import Select from "react-select";
import {UserService} from "../../services";
import {Globals} from "../../utils";
import CreateAddress from "./create-address";
import {Button, Modal} from "../../widgets";
import {connect} from 'react-redux'

const btnCollapsedStyle = {
  width: "20px",
  height: "20px"
}

class AddressInput extends Component {
  
  state = {
    addresses: [],
    address_id: '',
    loading: false,
    showModal: false
  }
  
  componentDidMount() {
    this.fetchData().then();
  }
  
  fetchData = async () => {
    this.setState({loading: true});
    try {
      const user = await UserService.getUserData(this.props.user.id)
      this.setState({
        addresses: user.addresses || [],
      })
    } catch(e) {
      Globals.showError()
      console.log('>>: error > ', e)
    } finally {
      this.setState({loading: false});
    }
  }
  
  changeAddress = address_id => {
    const address = this.state.addresses.find( it => it.id === address_id.value);
    if (!address) return;
    this.setState({address_id});
    if (this.props.onChange) this.props.onChange(address, this.props.name);
  }
  
  showModal = () => this.setState({showModal: true});
  
  dismissModal = () => this.setState({showModal: false});
  
  handleCreateAddress = () => {
    this.dismissModal();
    this.fetchData().then()
  }
  
  render() {
    const {addresses, address_id, showModal} = this.state;
    return (
      <div className={this.props.className}>
  
        <Modal visible={showModal}
               title="Crear direcciones"
               onClose={this.dismissModal} size="sm">
          <CreateAddress onClose={this.handleCreateAddress}/>
        </Modal>
  
        <div className="field-label h-box align-items-center">
          <span className="font-sand">Dirección</span>
          <div onClick={this.showModal}
                  className="badge badge-primary cur-pointer ml-2"
                  small={true}
                  type="button">
            <i className="fa fa-plus" /> Nuevo
          </div>
        </div>
        
        <Select
          options={addresses.map( it => {
            const {id: value} = it
            const label = it.district.region.name+' - '+it.district.province.name+', '+it.district.name+' | '+it.address
            return {label, value}
          })}
          styles={{
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: '100%',
              display: 'flex',
              border: '1px solid #DDDDDD',
              borderRadius: 5,
              height: 60,
              padding: '0px 15px'
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: '500'
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              fontWeight: '500',
              padding: 0
            }),
          }}
          onChange={this.changeAddress}
          value={address_id}
          placeholder="Seleccione su dirección"
          className="font-sand"
          noOptionsMessage={ () => 'No hay registros, debe añadir direcciones en su perfil' } />
        
      </div>
    )
  }
  
}

const mapStateToProps = state => ({
  user: state.user.user
})

export default connect(mapStateToProps)(AddressInput)
