import React from "react";
import {FormControl} from "react-bootstrap";
import MoneyText from "../../widgets/MoneyText";

const Item = ({size, index, onChangeSize}) => {
  
  const handleChangeQuantity = evt => {
  if (!onChangeSize) return;
  let newQuantity = parseInt(evt.target.value, 10);
  if (isNaN(newQuantity) || newQuantity < 0) {
    newQuantity = 0;
    // Actualizar el estado directamente con el valor 0
    size.quantity = newQuantity;
    onChangeSize(size, index);
  } else {
    // Si es un número válido, actualizar el estado normalmente
    size.quantity = newQuantity;
    onChangeSize(size, index);
  }
}
  
  return (
    <div
      className="d-flex flex-column flex-md-row justify-content-between mb-3"
      style={{
      }}>
      
      <span className="font-sand align-self-md-center">
         {size.value}
      </span>
  
      <div className="align-self-md-center">
      <MoneyText
        className=""
        text={size.price}/>
      </div>
  
      <FormControl
        className="text-center input_custom_40 font-weight-light"
        style={{ height: 40, width: 200 }}
        size="sm"
        type="number"
        max={size.stock}
        min="0"
        placeholder="Cantidad"
        onChange={handleChangeQuantity}/>
  
      <MoneyText
        className="align-self-md-center"
        text={size.price*size.quantity}/>
        
    </div>
  )
}

const SizeControl = ({sizes, product, variants, color, onChangeSizes}) => {
  if (!product || !color) return null
  
  if (onChangeSizes && (!sizes || !sizes.length)) {
    const sizes = variants
      .filter( variant => variant.stock )
      .filter( variant => {
        const attributeColor = variant.attributes
          .find( productAttribute => productAttribute.attribute.color )
        return attributeColor.value===color.name
      })
      .map( variant => {
        const attribute = variant.attributes.find( it => it.attribute.size )
        return {
          id: variant.id,
          attribute_id: attribute.id,
          value: attribute.value,
          quantity: 0,
          price: variant.price,
          stock: variant.stock
        }
      }) || []
    onChangeSizes(sizes)
    return null
  }
  
  if (!sizes || !sizes.length) return null
  
  const handleChangeSize = (size, index) => {
    if (!onChangeSizes) return
    const arr = [...sizes]
    arr[index] = size
    onChangeSizes(sizes)
  }
  
  return (
    <div className="v-box">
      <span className="mt-5 mb-2 font-sand">
        Tallas
      </span>
      {sizes.map( (size, index) =>
        <Item
          key={size.id}
          size={size}
          index={index}
          onChangeSize={handleChangeSize}/>
      )}
    </div>
  )
  
}

export default SizeControl
