import React, { Component } from 'react';
import productService from '../../services/modules/productService';
import { toast } from 'react-toastify';
import { Globals } from '../../utils';
import ProductView from './ProductView';
import LateralBar from './LateralBar';
import { Link } from 'react-router-dom';
import imgEmptySearch from '../../assets/images/empty-search.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumb from '../common/breadcrumb';
import './search.scss';
import HeadOptions from './HeadOptions';
import {useLocation} from "react-router-dom";

class Search extends Component {
  state = {
    products: [],
    hasMore: true,
    total: 0,
    lastPage: 0,
    orderBy: '',
    query: {
      perPage: 12,
      page: 1,
      orderBy: null,
      category_id: null,
      product_id: null
    },
    orderByPrice: false,
    orderByName: false,
    productsBackup: [],
    modeView: 'row',
    allProductsLoaded: false,
    findedProducts: true
  };

  constructor(props) {
    super(props);
    const params = props.location.state;
    
    const search = this.props.location.search;
    const category_id = new URLSearchParams(search).get("category");
    const product_id = new URLSearchParams(search).get("search");
    

    if (category_id){

      this.state.query = {
        ...this.state.query,
        
        category_id
      };
      
     } 

    if (product_id){

      this.state.query = {
        ...this.state.query,
        
        product_id
      };
      
     } 

    if (!params) return;



    this.state.query = {
      ...this.state.query,
      ...params,
    };
    

  }


  componentDidMount() {
  //  console.log(this.state);
    this.fetchProduct().then();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {

    //  console.log('update', this.props.location, prevProps.location);

      

      const search = this.props.location.search;
      const category_id = new URLSearchParams(search).get("category");
      const product_id = new URLSearchParams(search).get("search");
      const searchviejo = prevProps.location.search;
      const category_id2 = new URLSearchParams(searchviejo).get("category");

      if (product_id) {
        this.setQuery({
          ...this.state.query,
          perPage: 12,
          search: null,
          page: 1,
          product_id,
        });
      }

      if (category_id && category_id != category_id2){
   //     console.log('nuevo');
        this.setQuery({
          ...this.state.query,
          perPage: 12,
          search: null,
          page: 1,
          category_id,
          product_id,
        });
      }


      



      
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.location !== this.props.location &&
      nextProps.location.state
    ) {
      this.setQuery({
        perPage: 12,
        page: 1,
        ...nextProps.location.state,
      });
    }
  }

  fetchProduct = async () => {
    Globals.setLoading();
    try {
      // console.log('>>: Search.fetchProduct.query: ', this.state.query);
      const res = await productService.search(this.state.query);
      // console.log('>>: Search.fetchProduct.res: ', res);
      this.setState({
        products: res.data,
        totalProducts: res.total,
        total: res.total,
        lastPage: res.last_page,
        productsBackup: res.data,
        allProductsLoaded: true
      });
    } catch (e) {
      toast.error('Disculpe ha ocurrido un error');
    } finally {
      Globals.quitLoading();
    }
  };

  setQuery = (query) => this.setState({ query }, this.fetchProduct);

  updateQuery = async (params) => {
    
    const query = {
      ...this.state.query,
      ...params,
      page: 1,
    };
    this.setState({  products: [] });
   
    this.setState({ query }, this.fetchProduct);
  };

  fetchMore = async (fromFilter = false) => {
    const { lastPage } = this.state;
    const page = this.state.query.page;

    if (!fromFilter && page >= lastPage) {
      this.setState({ hasMore: false });
      return;
    }

    const query = { ...this.state.query };
    const products = [...this.state.products];
    if (!fromFilter) query.page += 1;
    else query.filter = this.state.orderBy;
    try {
      const res = await productService.search(query);
      let _products = [];
      if (!fromFilter) {
        _products = [ ...products,...res.data];
      } else {
        _products = res.data;
      }
      this.setState({
        products: _products,
        totalProducts: res.total,
        total: res.total,
        lastPage: res.last_page,
        productsBackup: _products,
        query,
        findedProducts: res.data.length > 0,
        hasMore: parseInt(res.to) !== parseInt(res.total)
      });
    } catch (e) {
      // console.log('>>: error > ', e);
      toast.error('Disculpe ha ocurrido un error');
    } finally {

    }
  };
  orderByPrice = (needToCallOrderByName = false) => {
    let products = [];
    if (this.state.orderByPrice) {
      products = [...this.state.productsBackup];
    } else {
      products = [...this.state.products];
      products = products.sort((a, b) => {
        return (
          parseFloat((a.has_discount && a.offer_price) || a.price) -
          parseFloat((b.has_discount && b.offer_price) || b.price)
        );
      });
    }
    this.setState(
      {
        products,
        orderByPrice: !this.state.orderByPrice,
      },
      () => {
        if (needToCallOrderByName && this.state.orderByName) this.orderByName();
      }
    );
  };

  orderByName = (needToCallOrderByPrice = false) => {
    let products = [];
    // console.log('>>: state > ', this.state);
    if (this.state.orderByName) {
      products = [...this.state.productsBackup];
    } else {
      // console.log('>>: else condition');
      products = [...this.state.products];
      products = products.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      // console.log('>>: products > ', products);
    }
    this.setState(
      {
        products,
        orderByName: !this.state.orderByName,
      },
      () => {
        if (needToCallOrderByPrice && this.state.orderByPrice)
          this.orderByPrice();
      }
    );
    // console.log('>>: order By Name > ');
  };
  change = async (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === 'orderBy') {
          this.fetchMore(true);
        }
      }
    );
  };
  handleModeVist = (mode) => {
    if (mode === 'row') {
      this.setState({
        modeView: 'row',
      });
    } else {
      this.setState({
        modeView: 'list',
      });
    }
  };
  render() {
   
   
   //console.log(category_id);

    const { products, query } = this.state;

   // console.log(query);
    return (
      <>
        <Breadcrumb title="Buscar" url="search" />

        <div className="container mt-4 mb-4" id="bar_search_grid">
          <LateralBar
            query={query}
            onSetQuery={this.setQuery}
            onChangeQuery={this.updateQuery}
          />

          <div className="products card_custom" style={{ border: 0, paddingTop: 0 }}>
            <div className="border-shadow-custom pt-5 mb-4">
              <HeadOptions
                onChangeOrder={this.change}
                orderBy={this.state.orderBy}
                handleModeVist={this.handleModeVist}
                modeView={this.state.modeView}
              />
            </div>
            {this.state.allProductsLoaded ? <>
              {!!products.length ? (
                <>
                  <InfiniteScroll
                    dataLength={this.state.products.length} //This is important field to render the next data
                    className={'listado_' + this.state.modeView + ' product_search_content'}
                    style={{ flex: 1 }}
                    next={this.fetchMore}
                    hasMore={this.state.hasMore}
                  >
                    {products.map((i) => (
                      <ProductView
                        key={i.id}
                        product={i}
                        history={this.props.history}
                        style={{ padding: '16px' }}
                        modeView={this.state.modeView}
                        symbol="S/"
                      />
                    ))}
                  </InfiniteScroll>
                  {!this.state.hasMore ? <div className="mt-4 w-100 d-flex justify-content-center">
                    <p className="seen-cls seen-it-cls alert alert-danger">
                      <b>No hay más elementos que cargar.</b>
                    </p>
                  </div> : <div className="mt-4 d-flex justify-content-center align-items-center w-100">
                    <i className="fa fa-circle-notch fa-spin text-primary fa-2x mr-2"></i>
                    <div className="font-sand f-sand-300">Cargando</div>
                  </div>}
                </>
              ) : (
                <div style={{ flex: 1 }}>
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img src={imgEmptySearch} className="img-fluid mb-4" />
                    <h3>
                      Lo sentimos! No se pudo encontrar el producto que buscaba!!!
                    </h3>
                    <p>
                      Compruebe sí ha escrito mal algo o intente buscar con otras
                      palabras.
                    </p>
                    <Link to={`/`} className="btn btn-solid">
                      seguir comprando
                    </Link>
                  </div>
                </div>
              )}
            </> : <div className="d-flex justify-content-center align-items-center">
              <i className="fa fa-circle-notch fa-spin text-primary fa-2x mr-2"></i>
              <div className="font-sand f-sand-300">Cargando</div>
            </div>}
          </div>
        </div>
      </>
    );
  }
}

export default Search;
