import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class Breadcrumb extends Component {
    render (){
        const {title, parent, url, otro, principal, categoria, subcategoria} = this.props;

        console.log("hola", this.props);
        return (
            <div className="breadcrumb-section d-flex align-items-center" style={{ height: 60, background: '#093467' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="page-title">
                                <h2 className="font-sand" style={{ fontSize: 13 }}>{title}</h2>
                            </div>
                        </div> 
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb" className="theme-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item text-white font-sand" style={{ fontSize: 13 }}><Link to={``}>Inicio</Link></li>
                                    {principal?
                                    <li className="breadcrumb-item text-white font-sand" style={{ fontSize: 13 }}><Link to={`/buscar?category=${categoria}`}>{principal}</Link></li>:''
                                    } 
                                    {otro?
                                    <li className="breadcrumb-item text-white font-sand" style={{ fontSize: 13 }}><Link to={`/buscar?category=${subcategoria}`}>{otro}</Link></li>:''
                                    }
                                    

                                    <li className="breadcrumb-item active text-white font-sand" aria-current="page" style={{ fontSize: 13 }}>{url || title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}
 
export default Breadcrumb;