import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import ReactStars from 'react-rating-stars-component';
import { connect } from 'react-redux';
import { ProductService } from '../../../services';

import { ContactService } from '../../../services';
import { Globals } from '../../../utils';
import moment from 'moment'
import HorizontalSlider from '../../layouts/common/logo-block'
import "./detalles.scss"

class DetailsTopTabs extends Component {
  IState = {
    form: {
      rating: 0,
      title: '',
      content: '',
      correo: '',
      contacto: '',
      
      user_id: (this.props.user && this.props.user.id) || null,
      product_id: this.props.productId,
    },
    tabs: [
      {
        label: 'Descripcion',
        condition: null,
      },
      {
        label: 'Video',
        condition: 'item.video_id',
      },
      {
        label: 'Pregunta',
        condition: 'item.category.especial',
      },
      {
        label: 'Calificación',
        //condition: 'userHasPurchased',
      },
      {
        label: 'Contactar con el vendedor',
        condition: 'user',
        className: 'gradiente',
        style: {
          minWidth: 240
        }
      },
    ],
  };
  state = this.IState;

  

  ratingChanged = (rating) => {
    this.setState({
      form: {
        ...this.state.form,
        rating,
      },
    });
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => {
      return {
        ...this.state,
        form: {
          ...this.state.form,
          [name]: value,
        },
      };
    });
  };

  submit = async (e) => {
    e.preventDefault();
    try {
      const res = await ContactService.openChat(this.state.form);
      await ProductService.saveitem({'receptor_mensaje':this.state.form.contacto,'evento_tipo':"chat"})
      Globals.showSuccess(res.msg);
      this.setState(
        {
          form: this.IState.form,
        },
        () => this.props.load()
      );
    } catch (err) {
      console.log('>>: err > ', err);
      Globals.showError();
    }
  };

  submitRating = async (e) => {
    e.preventDefault();
    try {
      const res = await ProductService.saveRating(this.state.form);
      await ProductService.saveitem({'receptor_mensaje':this.state.form.message,'evento_tipo':"calificacion del producto"})
      Globals.showSuccess(res.msg);
      this.setState(
        {
          form: this.IState.form,
        },
        () => this.props.load()
      );
    } catch (err) {
      console.log('>>: err > ', err);
      Globals.showError();
    }
  };

  submitAsk = async (e) => {
    e.preventDefault();

 


    try {
      const res = await ProductService.saveAsk(this.state.form);
      await ProductService.saveitem({'receptor_mensaje':this.state.form.message,'evento_tipo':"pregunta a vendedor"})

      


      Globals.showSuccess(res.msg);
      this.setState(
        {
          form: this.IState.form,
        },
        () => this.props.load()
      );
    } catch (err) {
      console.log('>>: err > ', err);
      Globals.showError();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    
    // console.log('item2', this.props.item);
    /* if(!!this.props.product.tags){
      const tags = this.props?.product?.tags
      // let arr = [];
      tags.map(tag => this.setState((state) => ({ etiquetas: [...state.etiquetas, tag.name] }))  )
    } */
  }
  handleClickTag = (e, tagName) => {
    e.preventDefault();
    const { history } = this.props;
    console.log(history);
    if (history.location.pathname === '/buscar') {
      history.replace('/buscar', { tagName });
    } else {
      history.push('/buscar', { tagName });
    }
  };



  
checkPurchaseStatus() {
  const { user, item } = this.props;
  return ProductService.hasPurchased(user.id, item.id);
}

  render() {

    const { user, item } = this.props;
    const userHasPurchased = 1;
    console.log('comprado: ' + this.props.item.category.especial);
    return (
      <section className="tab-product m-0">
        <div className="w-100">
          <Tabs className="tab-content nav-material">
            <TabList className="nav nav-tabs nav-material">
              <div className="row">
                {this.state.tabs.map((element, i) => {
                  if (
                    !element.condition ||
                    (element.label === 'Calificación' && userHasPurchased === 1) ||
                    (element.label === 'Video' && this.props.item.video_id) ||
                    (element.label === 'Pregunta' && !this.props.item.category.especial) ||
                    (element.label === 'Contactar con el vendedor' && this.props.item.category.especial)
                  )
                    return (
                      <Tab className="nav-item nav-custom-without-line" key={i}>
                        <div className="col-12 col-md-6 col-lg-3">
                          <span className="nav-link active" style={{
                            ...element.style,
                            fontSize: 12
                          }}>
                            <i className="icofont icofont-ui-home"></i>
                            <span className="font-sand">{element.label}</span>
                          </span>
                          <div className="material-border"></div>
                        </div>
                      </Tab>
                    );
                })}
              </div>
            </TabList>
            <TabPanel className="tab-pane fade mt-3 show active">
              <table className="completo table table-hover table-striped mb-0">
                <tbody>
                  <tr>
                    <th className="font-sand">Descripción</th>
                    <th className="font-sand">:</th>
                    <td className="font-sand">{this.props.item.description} </td>
                  </tr>
                  <tr>
                    <th className="font-sand">Garantía</th>
                    <th className="font-sand">:</th>
                    <td className="font-sand">
                    {!this.props.item.warranty ? (
                          'Contactar con el vendedor'
                        ) : (
                          this.props.item.warranty
                        )}
                    
                      
                    </td>
                  </tr>
                  <tr>
                    <th className="font-sand">Condición</th>
                    <th className="font-sand">:</th>
                    <td className="font-sand">
                    {!this.props.item.condition == 0 ? (
                          'Nuevo'
                        ) : (
                          'Usado'
                        )}
                    
                      
                    </td>
                  </tr>
                  {this.props.item &&
                    this.props.item.attributes &&
                    this.props.item.attributes.map((element, i) => {
                      return (
                        <>
                          {element.value_text === null ? null : (
                            <tr key={i}>
                              <th className="font-sand">{element.name}</th>
                              <th className="font-sand">:</th>
                              <td className="font-sand">{element.value_text}</td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  {!!this.props.item.tags ? (
                    <tr key={this.props?.item?.id}>
                      <th className="font-sand">Etiquetas</th>
                      <th className="font-sand">:</th>
                      <td>
                        <div className="d-flex flex-column">
                          {this.props?.item?.tags.map((tag) => {
                            return (
                              <a
                                className="font-sand"
                                href={'/buscar/'+tag.name}
                                onClick={(e) =>
                                  this.handleClickTag(e, tag.name)
                                }
                              >
                                {tag.name}
                              </a>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </TabPanel>
            {this.props.item.video_id && (
              <TabPanel>
                <div className="mt-3 text-center">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src={
                        'https://www.youtube.com/embed/' +
                        this.props.item.video_id
                      }
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </TabPanel>
            )}

          {!this.props.item.category.especial && (
            <TabPanel>

              {this.props.item.questions.length > 0 ? <div className="w-100">
                <div>
                  {this.props.item.questions.map((element, i) =>
                    <div className="card w-100 mb-4" key={i} style={{ border: 0 }}>
                      <div className="card-body border-shadow-custom">
                        <div className="row">
                          {element.user?.uri && (
                            <div className="col-4">
                              <img
                                src={element.user.uri}
                                alt={element.user.user_name}
                                className="rounded-circle"
                                style={{
                                  width: 40,
                                  height: 40
                                }} />
                            </div>
                          )}
                          <div className="col-8">
                            <h5 className="card-title font-sand">{element.user?.user_name}</h5>
                          </div>
                        </div>
                        <div className="font-sand mb-2" style={{ fontSize: 12 }}> {moment(element.created_at).format('DD-MM-YYYY HH:mm A')} </div>
                        <p className="card-text text-dark w-100 font-sand" style={{ whiteSpace: 'pre', fontSize: 12 }}>{element.message}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div> : ''}
              <form className="theme-form mt-3" onSubmit={this.submitAsk}>
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="ask-message" className="font-sand">Realiza tu pregunta</label>
                    <textarea
                      name="message"
                      onChange={this.onChange}
                      value={this.state.form.message}
                      className="form-control input_custom_40"
                      placeholder="Escribe tu pregunta"
                      id="ask-message"
                      style={{ minHeight: 60 }}
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-solid"
                      type="submit"
                      disabled={!this.props.user}
                    >
                      Enviar pregunta
                    </button>
                  </div>
                </div>
              </form>
               
            </TabPanel>
            )}

          
            <TabPanel>
              <div className={this.props.item.ratings.length > 0 ? '' : ''}>
                <HorizontalSlider
                  withArrow
                  items={this.props.item.ratings} />
              </div>
             {userHasPurchased === 1 && (
              <form className="theme-form" onSubmit={this.submitRating}>
                <div className="form-row">
                  <div className="col-md-12 ">
                    <div
                      className="media m-0"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <label style={{ margin: 0 }} className="font-sand">Califica nuestro producto</label>
                      <div className="media-body ml-3">
                        <ReactStars
                          count={5}
                          onChange={this.ratingChanged}
                          size={24}
                          isHalf={true}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="review" className="font-sand">Comentario</label>
                    <textarea
                      name="message"
                      onChange={this.onChange}
                      value={this.state.form.message}
                      className="form-control"
                      placeholder="Escriba su comentario"
                      id="exampleFormControlTextarea1"
                      rows="6"
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-solid font-sand"
                      type="submit"
                      disabled={!this.props.user}
                    >
                      Enviar calificación
                    </button>
                  </div>
                </div>
              </form>
              )}
              
            </TabPanel>
             
            <TabPanel>
              <form className="theme-form mt-3" onSubmit={this.submit}>
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="review font-sand">Título</label>
                    <input
                      type="text"
                      name="title"
                      value={this.state.form.title}
                      onChange={this.onChange}
                      className="form-control"
                      id="review"
                      placeholder="Escribe el titulo"
                      required
                    />
                  </div>
                  <div className='form-row'>
                    <div className="col-md-6">
                      <label htmlFor="review font-sand">Ingrese su correo electrónico</label>
                      <input
                        type="text"
                        name="correo"
                        value={this.state.form.correo}
                        onChange={this.onChange}
                        className="form-control"
                        id="review"
                        placeholder="Escriba su correo"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="review font-sand">Ingrese su Número de contacto</label>
                      <input
                        type="text"
                        name="contacto"
                        value={this.state.form.contacto}
                        onChange={this.onChange}
                        className="form-control"
                        id="review"
                        placeholder="Escribe su número de teléfono"
                        required
                      />
                    </div>


                  </div>



                  <div className="col-md-12">
                    <label htmlFor="review font-sand">Detalle</label>
                    <textarea
                      name="content"
                      onChange={this.onChange}
                      value={this.state.form.content}
                      className="form-control"
                      placeholder="Escribe tu mensaje"
                      id="exampleFormControlTextarea1"
                      rows="6"
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-solid font-sand"
                      type="submit"
                      disabled={!this.props.user}
                    >
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </form>
            </TabPanel>
          </Tabs>

        



        </div>
        <div className="col-md-12">
          {this.props.item.category.especial === 1 && (
            <span style={{color: 'red'}}>Compra Fácil no se hace responsable por el ofrecimiento de este producto. Compra Fácil solamente es un intermediario entre el Proveedor y el Cliente.</span>
          )}
        </div>
      </section>
    );
  }
}

export default connect((state) => {
  return {
    user: (state.user && state.user.user) || null,
  };
})(DetailsTopTabs);
