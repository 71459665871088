import axios from 'axios';

export const getApi = (urlApi, userToken = null) => {
  let config = null;
  if (userToken != null) {
    config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
  }
  return axios
    .get(urlApi, config)
    .then((res) => {
      if (res.status === 403) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/login';
      }
      return res;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = '/login';
        }
        return error.response;
      }
    });
};
