import React from "react";

const Switch = ({children, index}) => {
  
  const renderChildren = React.Children.map(children, (child, i) => {
    if (i===index) return child;
    return null;
  });
  
  return <>{renderChildren}</>
  
}

export default Switch
