import React from "react";
import { moneyFormat } from "../utils";

const PriceLabel = ({ product }) => {
  const { price, has_discount, offer_price } = product;

  if (!has_discount) return (
    <div className="d-flex align-self-center">
      <h4 className="text-primary mr-2 font-weight-bold">{moneyFormat(price)}</h4>
    </div>
  );

  // const rate = (100-((offer_price/price)*100)).toFixed(2);

  const originalPriceStyle = {
    color: "gray",
    fontSize: "small",
    textDecoration: "line-through"
  }

  return (
    <div className="d-flex flex-column align-self-center">
      <h4 className="text-primary mr-2 font-weight-bold" style={{ fontSize: 16 }}>{moneyFormat(offer_price)}</h4>
      <span style={{
        ...originalPriceStyle,
        fontSize: 12
      }}>{moneyFormat(price)}</span>
    </div>
  )

}

export default PriceLabel
