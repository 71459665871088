import React from 'react'
import "./compra.css"
import Slider from 'react-slick';
import { Modal } from '../../../widgets'
import PurchaseDetails from './purchases_details';
import { Accordion, Card, Button } from 'react-bootstrap'
import moment from 'moment'
class Purchases extends React.Component {

    state = {
        purchases: [],
        products: [],
        purchase: {},
        view: false,
        viewImage: false
    }

    componentDidMount() {
        this.load()

        window.addEventListener('scroll', () => {
            this.setState({ viewImage: true });
        });
    }

    load = () => {
        const { purchases, products } = this.props
        this.setState({
            products,
            purchases
        })

    }

    openModal = purchase => {
        this.setState({
            purchase,
            view: true
        })
    }

    close = () => {
        this.setState({
            purchase: null,
            view: false
        })
    }


    render() {
        return (
            <div className="dashboard-right">
                <Modal
                    title="Detalles del pedido"
                    visible={this.state.view}
                    onClose={this.close}
                >
                    <PurchaseDetails
                        onClose={this.close}
                        purchase={this.state.purchase}
                    />
                </Modal>
                <div className="dashboard border-shadow-custom">
                    <div className="page-title">
                        <h2 className="font-sand">Historial de compras</h2>
                    </div>
                    <div className="w-100">
                        <div className="table-responsive">
                            <div style={{ minWidth: 950 }}>
                                {
                                    this.state.products.map((element, i) => {
                                        return (
                                            <React.Fragment>
                                                <Accordion key={i}>
                                                    <Card style={{ border: 0, marginBottom: 10 }}>
                                                        <Card.Header className="border-shadow-custom">
                                                            <Accordion.Toggle onClick={_ => {
                                                                setTimeout(() => {
                                                                    this.setState({ viewImage: true })
                                                                }, 500);
                                                            }} as={Button} className="btn_custom" variant="link" eventKey={element.id} style={{
                                                                textDecoration: 'none',
                                                                width: '100%'
                                                            }}>
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <p className="text-dark text-left font-sand">
                                                                           Pedido Nº {element.payment_id} 
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <p className="text-dark text-left font-sand">
                                                                           {element.product?.name}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <p className="text-dark text-center font-weight-light font-sand">
                                                                            <span className="font-weight-bold">Fecha de compra:</span>
                                                                            {' ' + moment(element.created_at).format('DD-MM-YYYY')}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <p className="text-dark text-center font-weight-light font-sand">
                                                                            <img
                                                                                                                style={{
                                                                                                                    width: 40,
                                                                                                                    height: 40
                                                                                                                }}
                                                                                                                src={'https://cms-assets.tutsplus.com/cdn-cgi/image/width=850/uploads/users/523/posts/32694/final_image/tutorial-preview-large.png'}
                                                                                                            
                                                                                                            />
                                                                        </p>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                            </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey={element.id}>
                                                            <Card.Body className="border-shadow-custom mt-2">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                       
                                                                        <div className="row">
                                                                           

                                                                            <div className="col-12">
                                                                              <table className="table tabla2" width="100%">
                                                                            <tr>
                                                                                <th rowSpan={4} >
                                                                                    <div style={{ width: 100 }}>
                                                                                        <Slider>
                                                                                            {
                                                                                            element.product.images[0] && // Verificar si element.product.images[0] está definido y no es null ni undefined
                                                                                                    <div key={0}>
                                                                                                        <div
                                                                                                            className="home text-center p-right"
                                                                                                        >
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    width: 100,
                                                                                                                    height: 100
                                                                                                                }}
                                                                                                                src={element.product.images[0].uri}
                                                                                                                alt={element.product.images[0]}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                
                                                                                            }
                                                                                        </Slider>
                                                                                    </div>

                                                                                    
                                                                                </th>
                                                                             
                                                                                
                                                                            </tr>
                                                                            <tr>
        
                                                                                <th colSpan={4}>    
                                                                                   { element.product.name }
                                                                                </th>
                                                                          
                                                                                
                                                                                
                                                                            </tr>
                                                                            
                                                                            <tr>
                                                                                <th >    
                                                                                    Código producto
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    {element.product.code}
                                                                                </th>
                                                                             
                                                                                
                                                                          
                                                                             
                                                                                <th rowSpan={3} >  
                                                                                   <div
                                                                                        className="btn btn-solid btn-sm text-center"
                                                                                        style={{
                                                                                            margin: '3% 0'
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            let findPurc = this.state.purchases.find(p => parseInt(p.id) === parseInt(element.payment_id))
                                                                                            this.openModal(findPurc)
                                                                                            setTimeout(() => {
                                                                                                this.setState({ viewImage: true })
                                                                                            }, 500);
                                                                                        }}
                                                                                    >
                                                                                        Ver detalles
                                                                                    </div>
                                                                                </th>
                                                                              
                                                                                
                                                                               
                                                                            </tr>
                                                                            <tr>
                                                                             <th>    
                                                                                    Cantidad
                                                                                </th>
                                                                                <th>
                                                                                :
                                                                                </th>
                                                                                <th>
                                                                                    {element.quantity}
                                                                                </th>
                                                                            </tr>
                                                                           
                                                                        </table>

                                                                                
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row">                          
                                                                                        <ul className="progressbar">

                                                                                           
                                                                                           
                                                                                    
                                                                                                <li className={`${element.estadoenvio1}`}> 
                                                                                             
                                                                                              

                                                                                           

                                                                                                <div className="col-12">
                                                                                                    <img style={{ maxHeight: 40, height: 40, }}
                                                                                                        src={'https://cdn2.iconfinder.com/data/icons/time-date-management-1/64/time__data_management-20-512.png'}
                                                                                                        alt={'nada'}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    Pedido recibido
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className={`${element.estadoenvio2}`}> 
                                                                                                <div className="col-12">
                                                                                                    <img style={{ maxHeight: 40, height: 40, }}
                                                                                                        src={'https://cdn0.iconfinder.com/data/icons/social-messaging-ui-color-shapes-3/3/36-512.png'}
                                                                                                        alt={'nada'}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    Pago Aprobado
                                                                                                </div>
                                                                                            
                                                                                            </li>
                                                                                            <li className={`${element.estadoenvio3}`}> 
                                                                                                <div className="col-12">
                                                                                                    <img style={{ maxHeight: 40, height: 40, }}
                                                                                                        src={'https://toppng.com/uploads/preview/icon-checklist-blue-check-list-blue-ico-11562997148qqjqygmtxm.png'}
                                                                                                        alt={'nada'}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    Compra Procesada
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className={`${element.estadoenvio4}`}> 
                                                                                                <div className="col-12">
                                                                                                    <img style={{ maxHeight: 40, height: 40, }}
                                                                                                        src={'https://www.freeiconspng.com/thumbs/truck-icon/truck-icon-23.png'}
                                                                                                        alt={'nada'}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    Pedido Enviado
                                                                                                </div>
                                                                                                
                                                                                          </li>
                                                                                          <li className={`${element.estadoenvio5}`}> 
                                                                                                <div className="col-12">
                                                                                                    <img style={{ maxHeight: 40, height: 40, }}
                                                                                                        src={'https://static.vecteezy.com/system/resources/previews/003/213/466/non_2x/delivered-parcel-and-courier-vector.jpg'}
                                                                                                        alt={'nada'}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    Entregado
                                                                                                </div>
                                                                                          
                                                                                          </li>
            
                                                                                        </ul>
                                                                                   
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Purchases