import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import SellerSection from './SellerSection';
import { toast } from 'react-toastify';
import { AuthService } from '../../services';
import ClientSection from './ClientSection';
import BankSection from './BankSection';
import { APP_NAME } from '../../utils';
import ContactSection from './ContactSection';
import Switch from '../switch/Switch';
import { Link } from 'react-router-dom';

class Register extends Component {
  constructor(props) {
    super(props);
    console.log('usuario', this.props.user?.user);
    this.state = {
      loading: false,
      client: null,
      vendor: null,
      bank: null,
      contacts: [],
      agree: false,
      adult: false,
      index: !this.props.user || this.props?.user?.user?.id == 5 ? 0 : 1,
      nuevo: !this.props.user || this.props?.user?.user?.id == 5 ? 0 : 1,
      sectionLength: 4,
    };


    console.log('este es el index '+this.state.index);
    console.log('este es el nuevo '+this.state.nuevo);
  }

  handleChangeClient = (client) => {
    const contacts = [
      {
        name: client.name,
        email: client.email,
        phone: client.phone,
      },
    ];
    this.setState({ client, contacts });
  };


  setChecked = () => this.setState({ agree: true});
  handleChangeSeller = (vendor) => this.setState({ vendor });

  handleChangeBank = (bank) => this.setState({ bank });

  handleChangeContacts = (contacts) => this.setState({ contacts });

  handleNextCard = () => this.setState({ index: this.state.index + 1 });

  handlePreviousCard = () => this.setState({ index: this.state.index - 1 });

  getRucDefault = () =>
    this.state.client == null
      ? ''
      : this.state.client.document_type === '1'
      ? this.state.client.ruc
      : '';

  submit = async (evt) => {
    evt.preventDefault();

    const { user } = this.props;
    const { index, sectionLength, agree } = this.state;
    // VALIDANDO LA SECCIÓN ACTUAL
    if (!this.section.isValid()) return;

    if (index !== sectionLength - 1) return this.handleNextCard();

    if (!agree) return toast.error('Debes aceptar los t�rminos y condiciones');

    this.setState({ loading: true });
    try {
      const form = {
        user_id: user ? user.user.id : null,
        client: this.state.client,
        vendor: this.state.vendor,
        bank: this.state.bank,
        contacts: this.state.contacts,
      };
      console.log(form.vendor);
      const res = await AuthService.registerVendor(form);
      this.props.dispatch({
        type: 'SET_USER',
        payload: res,
      });
      if (!!form.vendor) {
        toast.success('Se registro su cuenta como vendedor');
      }
      this.props.history.push('/paginas/tablero');
    } catch (e) {
     // console.log('hola '+e)
      toast.error('Ya tienes una solicitud pendiente');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { user } = this.props;
    const {
      agree,
      index,
      nuevo,
      sectionLength,
      loading,
      client,
      vendor,
      bank,
      contacts,
    } = this.state;

    return (
      <>
        <Breadcrumb title="Registro de vendedor" />
        <section className="container">
          <form className="theme-form" onSubmit={this.submit}>
            <Switch index={index}>
              <ClientSection
                ref={(it) => (this.section = it)}
                onChangeClient={this.handleChangeClient}
                value={client}
                rol="seller" 
              />

              <SellerSection
                ref={(it) => (this.section = it)}
                onChangeSeller={this.handleChangeSeller}
                value={vendor}
                defaultRuc={this.getRucDefault()}
              />

              <BankSection
                ref={(it) => (this.section = it)}
                formType="seller"
                onChange={this.handleChangeBank}
                value={bank}
              />

              <ContactSection
                ref={(it) => (this.section = it)}
                onChange={this.handleChangeContacts}
                value={contacts}
              />
            </Switch>

            <div className="form-check col-12 mb-3 mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={agree}
                id="agree"
                checked={agree}
                onChange={() =>{
                  const termsId = this.state.index;
                  window.open('https://comprafacil.com.pe/api/web/terms-pdf/${termsId}', '_blank');this.setState({ agree: !agree })} }
              />
              <label
                className="form-check-label font-sand ml-2 mr-2"
                htmlFor="agree"
              >
                <a
                  href="https://comprafacil.com.pe/api/web/terms-pdf/1"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={()=>this.setChecked()}
                >
                  Acepto los t&eacute;rminos y condiciones de uso en {APP_NAME}
                </a>
              </label>

            </div>

            {/*<div className="form-check col-12 mb-3">*/}
            {/*  <input*/}
            {/*    className="form-check-input"*/}
            {/*    type="checkbox"*/} 
            {/*    value={adult}*/}
            {/*    id="adult"*/}
            {/*    checked={adult}*/}
            {/*    onChange={ () => this.setState({adult: !adult}) }/>*/}
            {/*  <label className="form-check-label" htmlFor="adult">*/}
            {/*    Reconozco que soy mayor de edad para  el uso de {APP_NAME}*/}
            {/*  </label>*/}
            {/*</div>*/}

            <div className="h-box justify-content-end">
              {this.state.index != 0 && (
                <button
                  className="btn btn-solid mb-4 mr-2"
                  type="button"
                  onClick={this.handlePreviousCard}
                >
                 Anterior
                </button>
              )}

              <button
                type="submit"
                className="btn btn-solid mb-4"
                disabled={loading}
              >
                {loading
                  ? 'Enviando...'
                  : index === sectionLength - 1
                  ? 'Crear cuenta'
                  : 'Siguiente'}
              </button>
            </div>
          </form>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Register);
