import React from 'react'

const Parallax = (props) => {
    return(
        <section className="p-0">
            <div
                className={"full-banner parallax text-center p-left"+(!props.image ? ' parallax-banner11' : '')}
                style={{
                    backgroundImage: `url(${props.image})` || ''
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {/* <div className="banner-contain">
                                <h2>2021</h2>
                                <h3>Tendencias</h3>
                                <h4>Ofertas especiales</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Parallax