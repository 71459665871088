import React from "react";
import ViewCell from "./ViewCell";

const ProductViewControl = ({product, onViewChange, view}) => {
  if (!product) return null;
  
  const handleClick = view => {
    if (!onViewChange) return
    console.log(view)
    onViewChange(view)
  }
  
  return (
    <div className="v-box">
      
      <div className="mt-4 mb-2 font-sand">
        Seleccione vista
      </div>
      
      <div className="d-flex">
        {product.images
          .filter(it => it.view)
          .sort( (a, b) => a.view - b.view )
          .map( image =>
            <ViewCell
              key={image.id}
              image={image}
              onClick={handleClick}
              selected={image.view===view}/>
          )}
      </div>
      
    </div>
  )
  
}

export default ProductViewControl
