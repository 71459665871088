import {axios} from '../axios';
import store from '../../store';
import {jsonToParams} from "../index";
const END_POINT = 'categories';

const get = async () => {
  try {
    const r = await axios.get(`${END_POINT}`);
    const items = r.data;
    store.dispatch({type: 'SET_CATEGORIES', payload: items});
    return items;
  } catch(e) {
    throw e
  }
}

const getAll = async () => {
  try {
    const r = await axios.get(`${END_POINT}/get-all-activated`);
    const items = r.data;
    store.dispatch({type: 'SET_CATEGORIES', payload: items});
    return items;
  } catch(e) {
    throw e
  }
}

const search = async (query=null) => {
  // console.log('search categories')

  console.log('prueba de envio de datos');
  try {
    const r = await axios.get(`${END_POINT}/search${jsonToParams(query)}`, query);
    // console.log('categories', r)
    if (r.statusText!=='OK') throw 'Disculpe ha ocurrido un error'
    return r.data
  } catch(e) {
    throw e
  }
}

export default {
  getAll,
  get,
  search
}
