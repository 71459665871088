import React, {Component} from "react";
import CurrencyDao from "../../services/modules/CurrencyDao";
import {toast} from "react-toastify";
import {moneyFormat} from "../../utils";

class ExchangeRateBanner extends Component {
  
  state = {
    exchangeRate: null,
    loading: false,
    error: null
  }
  
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      exchangeRate: props.exchangeRate || null
    }
  }
  
  componentDidMount() {
    this.fetchCurrency().then();
  }
  
  fetchCurrency = async () => {
    if (this.state.exchangeRate) return;
    this.setState({loading: true, error: null});
    try {
      const {venta} = await CurrencyDao.get();
      this.setState({exchangeRate: venta});
      if (this.props.onChange) this.props.onChange(venta)
    } catch (e) {
      this.setState({error: "No se ha podido obtener la tasa de cambio"})
      toast.error(e.message);
    } finally {
      this.setState({loading: false});
    }
  }
  
  getMessage = () => {
    const {exchangeRate, loading, error} = this.state;
    if (loading) return "Cargando tasa de cambio...";
    if (error) return error;
    return `Tasa de cambio actual ${moneyFormat(exchangeRate)} total: ${moneyFormat(this.props.total/exchangeRate, "$")}`
  }
  
  render() {
    const {exchangeRate, loading, error} = this.state;
    return (
      <div className={`alert mt-3 ${ error ? 'alert-danger' : 'alert-warning' }`} role="alert">
        {this.getMessage()}
      </div>
    )
  }
  
}

export default ExchangeRateBanner;
