import React from "react";
import {moneyFormat} from "../../utils";

const MoneyText = ({value, className, style, signed="", color}) => {
  
  const _style = color
    ? {...style, color}
    : style;
  
  return (
    <div className={`d-flex justify-content-end text-right ${className}`} style={_style}>
      <div className="mr-2 font-sand">{signed}</div> <span className="font-sand">{moneyFormat(value)}</span>
    </div>
  )
}


export default MoneyText
