/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLE_SELLER, ROLE_RESELLER, Globals } from '../../../../utils';
import { withRouter } from 'react-router-dom';
import { on } from 'jetemit';
import { EventService } from '../../../../services';
import moment from 'moment';
import './topbar.css';
class TopBar extends Component {
  state = {
    events: [],
    event_text: '',
  };

  componentDidMount() {
    on('logout', () => {
      this.logout();
    });
    this.getEvents();
  }

  logout = () => { 
    this.props.dispatch({
      type: 'SET_USER',
      payload: null,
    });
    this.props.history.push('/');
  };

  getEvents = async () => {
    try {
      Globals.setLoading();
      const res = await EventService.get();
      // console.log(res)
      let event_text = res.map((element) => `${element.message}`);
      event_text = event_text.join(' | ');
      this.setState({
        event_text,
        events: res,
      });
    } catch (error) {
      console.log('>>: erorr > ', error);
    } finally {
      Globals.quitLoading();
    }
  };

  render() {
    return (
      <div className="top-header d-none d-lg-block">
        {this.state.events.length > 0 && (
          <div className="top-animation">
            <div
              className="bar"
              style={{
                backgroundColor:
                  (this.state.events[0] && this.state.events[0].color) || '',
              }}
            >
              {[
                ...this.state.events,
                /* {
                  url_event: '/',
                  text_color: 'white',
                  message: 'Promoción de prueba en el top banner'
                },
                {
                  url_event: '/',
                  text_color: 'white',
                  message: 'Promoción2 de prueba en el top banner'
                }, */
              ].map((element, i) => {
                return (
                  <a
                    href={!!element.url_event ? element.url_event : '/'}
                    key={i}
                    className="bar_content p-1 font-sand"
                    target="_blank"
                    style={{
                      color: element.text_color || '',
                      fontSize: 14
                    }}
                  >
                    {element.message}
                  </a>
                );
              })}
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="header-dropdown">
                <li className="mobile-wishlist compare-mobile"></li>

                <li className="onhover-dropdown mobile-account card_header_account d-flex justify-content-end">


                  {this.props.user &&
                    this.props.user.user &&
                    this.props.user.user.uri ? (
                    <img
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      className="rounded-circle"
                      src={this.props.user.user.uri}
                      alt={this.props?.user?.user?.name}
                    />
                  ) : (
                    <img src={`http://wp.alithemes.com/html/nest/demo/assets/imgs/theme/icons/icon-user.svg`} width="15" className="mr-2 align-self-center" />
                  )}
                  {(this.props.user && this.props?.user?.user?.name) ||
                    'Mi cuenta'}
                  <ul className="onhover-show-div card-dropdown-custom" style={{ left: 'auto' }}>
                    {this.props.user ? (
                      <React.Fragment>
                        <li>
                          <Link to={`/paginas/tablero`}>Cuenta</Link>
                        </li>
                        <li>
                          <Link to={`/pages/profile`}>Perfil</Link>
                        </li>
                        <li>
                          <Link to={`/pages/chat`}>Chat</Link>
                        </li>
                        {/* <li>
                                                        <Link to={`/pages/support`} >Soporte</Link>
                                                    </li> */}
                        {(this.props.user.user?.role_id === ROLE_SELLER ||
                          this.props.user.user?.role_id === ROLE_RESELLER) && (
                            <li>
                              <Link
                                to={`/admin`}
                                onClick={() =>
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 500)
                                }
                              >
                                Administrativo
                              </Link>
                            </li>
                          )}
                        <li className="w-100 text-left">
                          <a onClick={() => this.logout()}>Cerrar sesión</a>
                        </li>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <li>
                          <Link to={`/pages/login`} data-lng="es">
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link to={`/pages/register`} data-lng="es">
                            Registrarse
                          </Link>
                        </li>
                        <li>
                          <Link to={`/pages/forget-password`} data-lng="es">
                            Recuperar Contraseña
                          </Link>
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withRouter(TopBar));
