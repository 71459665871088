import { axios } from '../axios';
const END_POINT = 'contact';

const post = async (params) => {
  try {
    const r = await axios.post(`${END_POINT}`, params);
    return r.status;
  } catch (e) {
    throw e;
  }
};

export default {
  post,
};
