import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class Unsuscribe extends Component {

    constructor (props) {
        super (props)
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Desuscribirse'}/>
                
                
                {/*Collection section*/}
                <section className="collection section-b-space">
                    <div className="container">
                        <div className="row partition-collection">
                            <div className="col-lg-12 col-md-12">
                                <div className="collection-block">
                                    
                                        <div className="collection-content">
                                            <h3>Estas seguro que deseas desuscribirte al sitio web?</h3>
                                            <h4>No recibiras promociones, ni notificaciones acerca de las ofertas eventos destacados</h4>
                                           
                                            
                                        </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Unsuscribe