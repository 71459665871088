import {axios} from "../axios";

const END_POINT = 'email/';

class EmailDao {
  
  async emailExist(nick) {
    const r = await axios.get(`${END_POINT}${nick}`)
    return r.data
  }
  
}

const emailDao = new EmailDao()

export default emailDao
