import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Input from '../form/Input';
import InputFile from '../form/InputFile';
import Options from '../form/Options';

const billOptions = [
  { value: '', text: 'Seleccione' },
  { value: '1', text: 'Boleta' },
  { value: '0', text: 'Factura' },
  { value: '2', text: 'Recibo por honorarios' },
];

class ResellerSection extends Component {
  state = {
    file: null,
    ruc: '',
    ruc_file: null,
    bill_type: '',
  };

  constructor(props) {
    super(props);
    const { value } = props;
    if (!value) return;
    this.state = {
      ruc: value.ruc || '',
      ruc_file: value.ruc_file || null,
      bill_type: value.bill_type || '',
    };
  }

  handleChangeInput = (text, name) =>
    this.setState(
      {
        ...this.state,
        [name]: text,
      },
      this.onChange
    );

  onChange = () => {
    if (!this.props.onChange) return;
    this.props.onChange(this.mapStateToValue());
  };

  mapStateToValue = () => ({
    ruc: this.state.ruc,
    ruc_file: this.state.ruc_file,
    bill_type: this.state.bill_type,
  });

  isValid = () => {
    const { ruc_file, ruc } = this.state;
    if (!ruc_file) {
      toast.warn('La imagen del ruc es requerida');
      return false;
    }
    if (ruc.length < 8) {
      toast.warn('El ruc debe tener al menos 8 caracteres');
      return false;
    }
    return true;
  };

  render() {
    const { title, className, style } = this.props;
    const { ruc, bill_type, file, ruc_file } = this.state;
    return (
      <div className={className} style={style}>
        <h3> {title || 'Datos del comisionista'} </h3>
        {!!ruc_file ? (
					<div className="row mt-4 mb-4">
						<div className="col">
							<div className="content-image d-flex justify-content-center aling-items-center">
								{ruc_file.includes("data:application/pdf") ? (
									<embed
										src={ruc_file}
										type="application/pdf"
										width="75%"
										height="300px"
									/>
								) : (
									<img src={ruc_file} alt="imgruc" />
								)}
							</div>
						</div>
					</div>
				) : null}
        {/* BUSINESS NAME, RUC, RUC FILE */}
        <div className="row mb-4">
          {/* RUC */}
          <Input
            name="ruc"
            className="col"
            required={true}
            label="RUC"
            value={ruc}
            minLength={8}
            maxLength={11}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />

          {/* RUC FILE */}
          <InputFile
            className="col"
            name="file"
            label="Agregue imagen de su RUC o DNI"
            placeholder={file?.name ? file.name : "Seleccionar"}
            required={!!file ? false : true}
            value={file}
            onChange={this.handleChangeInput}
            onBase64={(it) => this.handleChangeInput(it, 'ruc_file')}
            maxSize={5 * 1024 * 1024}
            onMaxSize={() => toast.error('No se permiten archivo mayor a 5MB')}
          />

          {/* BILL TYPE */}
          <Options
            name="bill_type"
            className="col"
            label="Comprobantes que emite"
            required={true}
            value={bill_type}
            options={billOptions}
            onChange={this.handleChangeInput}
            style={{ height: 60 }}
            inputClassName="mb-3 font-sand form-control input_custom_40 font-weight-500"
          />
        </div>
      </div>
    );
  }
}

export default ResellerSection;
