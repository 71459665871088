import axios from 'axios';

export const postApi = (urlApi, data, userToken) => {
    // console.log('Data que llega al postAPI:', urlApi, data, userToken);
  let config = null;
  if (userToken != null) {
    config = {
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };
  }
  return axios
    .post(urlApi, data, config)
    .then((res) => {
      if(res.status === 403){
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = '/login';
        }
      console.log('respuesta desde PostAPI:', res);
      return res;
    })
    .catch((error) => {
      console.log('hay un error', error);
      if(error.response.status === 403){
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = '/login';
      }
      return error;
    });
};
