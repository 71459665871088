import React from 'react'

const SubcategoryFilter = ({subcategories = [], onChangeSubcategory}) => {
  
  //if (!!subcategory || !subcategories.length) return null;
  
  const handleClick = subcategory => {
    if (onChangeSubcategory) onChangeSubcategory(subcategory)
  }
  
  return (
    <div>
    
      {subcategories.map( subcategory =>
        <div className="color-inherit font-sand f-sand-300 cur-pointer color_hover_primary"
          key={subcategory.id}
          onClick={ () => handleClick(subcategory) }>
          &nbsp;{subcategory.name}
        </div>
      )}
    </div>
  )
  
}

export default SubcategoryFilter
