import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
class NewProduct extends Component {
    render (){
        const {items, symbol} = this.props; 
        let arrays = [];
        if(items){
            while (items.length > 0) {
                arrays.push(items.splice(0, 3));
            }
        }
        return (
            <div className="theme-card">
                <h5 className="title-border">Productos Destacados</h5>
                <Slider className="offer-slider slide-1">
                    <div className="row col-md-12">
                    {arrays.map((products, index) =>
                        <div key={index} className="col-md-12 row">

                            {products.map((product, i) =>

                                <div className="media col-md-6 row" key={i}>
                                    <Link to={`/detalle/productos/${product.id}`}>
                                        { (!!product.images && !!product.images.length) &&
                                            <img className="img-fluid" src={`${product.images[0].uri}`} alt="" />
                                        }
                                    </Link>
                                    <div className="media-body align-self-center">
                                        <div className="rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <Link to={`/detalle/productos/${product.id}`}>
                                            <h6>{product.name}</h6>
                                        </Link>
                                        <h4>{symbol}{(product.price)}
                                            <del>
                                                <span className="money">{symbol}{product.price}</span>
                                            </del>
                                        </h4>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    </div>
                </Slider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        // items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, null)(NewProduct);
