import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {fabric} from 'fabric';
import {Helmet} from 'react-helmet'
import HeaderOne from '../common/headers/header-one'
import FooterOne from "../common/footers/footer-one"
import Breadcrumb from '../common/breadcrumb'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {File} from '../../widgets'
import {Globals} from '../../utils'
import {ClothService} from '../../services'
import store from '../../store'

export default function TShirtMaker(){
    const user = store.getState().user
    let [canvas, setCanvas] = useState();
    let [cloths, setCloths] = useState([])
    let tshirts = new Array(); //prototype: [{style:'x',color:'white',front:'a',back:'b',price:{tshirt:'12.95',frontPrint:'4.99',backPrint:'4.99',total:'22.47'}}]
    let a;
    let b;
    let line1;
    let line2;
    let line3;
    let line4;
    let _hoveredTarget;
    const [tshirt, setTshirt] = React.useState({
        dimensions: {},
        uri: 'http://comprafacil.com.pe/api/public/crew_front.png'
    })
    const [backTshirt, setBackTshirt] = React.useState({
        dimensions: {},
        uri: 'http://comprafacil.com.pe/api/public/crew_back.png'
    })
    const [isFront, setIsFront] = React.useState(true)
	const onObjectSelected = (e) => {
        console.log('>>: objectSelected > ', e)
	    var selectedObject = e.target;
	    $("#text-string").val("");
	    selectedObject.hasRotatingPoint = true
	    if (selectedObject && selectedObject.type === 'text') {
	    	//display text editor
	    	$("#texteditor").css('display', 'block');
	    	$("#text-string").val(selectedObject.getText());
	    	// $('#text-fontcolor').miniColors('value',selectedObject.fill);
	    	// $('#text-strokecolor').miniColors('value',selectedObject.strokecss);
	    	$("#imageeditor").css('display', 'block');
	    }
	    else if (selectedObject && selectedObject.type === 'image'){
	    	//display image editor
	    	$("#texteditor").css('display', 'none');
	    	$("#imageeditor").css('display', 'block');
	    }
    }
	const onSelectedCleared = (e) => {
		 $("#texteditor").css('display', 'none');
		 $("#text-string").val("");
		 $("#imageeditor").css('display', 'none');
    }

	const setFont = (font) => {
        var activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'text') {
            activeObject.fontFamily = font;
            canvas.renderAll();
        }
    }

	const removeWhite = () =>{
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'image') {
			  activeObject.filters[2] =  new fabric.Image.filters.RemoveWhite({threshold: 100, distance: 10});//0-255, 0-255
			  activeObject.applyFilters(canvas.renderAll.bind(canvas));
		  }
    }
    const addText = () => {
        const text = $("#text-string").val();
        const textSample = new fabric.Text(text, {
            left: fabric.util.getRandomInt(0, 200),
            top: fabric.util.getRandomInt(0, 400),
            fontFamily: 'helvetica',
            angle: 0,
            fill: '#000000',
            scaleX: 0.5,
            scaleY: 0.5,
            fontWeight: '',
            hasRotatingPoint:true
        });
        canvas.add(textSample);
        canvas.item(canvas.item.length-1).hasRotatingPoint = true;
        $("#texteditor").css('display', 'block');
        $("#imageeditor").css('display', 'block');
    }
    const addImage = e => {
        const el = e.target;
        /*temp code*/
        const offset = 50;
        const left = fabric.util.getRandomInt(0 + offset, 200 - offset);
        const top = fabric.util.getRandomInt(0 + offset, 400 - offset);
        const angle = fabric.util.getRandomInt(-20, 40);
        const width = fabric.util.getRandomInt(30, 50);
        const opacity = (function(min, max){ return Math.random() * (max - min) + min; })(0.5, 1);
        
        fabric.Image.fromURL(el.src, (image) => {
            image.set({
                left,
                top,
                angle: 0,
                padding: 10,
                // cornersize: 10,
                hasRotatingPoint:true
            });
            //image.scale(getRandomNum(0.1, 0.25)).setCoords();
            canvas.add(image);
        });
    }
    const change = async e => {
        try {
            const base64 = await Globals.toBase64(e.target.value)
            // setTshirt(base64)
            addImage({
                target:{
                    src: base64
                }
            })
        } catch (error) {
            Globals.showError('Ha ocurrido un error cargando su imagen')
            console.log('>>: error > ', error)
        }
	}
    const load = () => {
		//setup front side canvas
 		canvas = new fabric.Canvas('tcanvas', {
		  hoverCursor: 'pointer',
		  selection: true,
		  selectionBorderColor:'blue'
		});
 		canvas.on({
			 'object:moving': function(e) {
			    e.target.opacity = 0.5;
			  },
			  'object:modified': function(e) {
			    e.target.opacity = 1;
			  },
			 'object:modified': onObjectSelected,
			 'selection:cleared': onSelectedCleared
		 });
        // piggyback on `canvas.findTarget`, to fire "object:over" and "object:out" events
 		canvas.findTarget = (function(originalFn) {
		  return function() {
            //@ts-ignore
		    var target = originalFn.apply(this, arguments);
		    if (target) {
		      if (_hoveredTarget !== target) {
		    	  canvas.fire('object:over', { target });
		        if (_hoveredTarget) {
		        	canvas.fire('object:out', { target: _hoveredTarget });
		        }
		        _hoveredTarget = target;
		      }
		    }
		    else if (_hoveredTarget) {
		    	canvas.fire('object:out', { target: _hoveredTarget });
		      _hoveredTarget = null;
		    }
		    return target;
		  };
		})(canvas.findTarget);

 		canvas.on('object:over', function(e) {
		  e.target.set('fill', 'red');
		  canvas.renderAll();
		});
		
 		canvas.on('object:out', function(e) {
		  e.target.set('fill', 'green');
		  canvas.renderAll();
		});
        const item = document.getElementById('add-text')
        if(item){
            item.onclick = addText
        }
	  	$("#text-string").keyup(function(){
	  		var activeObject = canvas.getActiveObject();
		      if (activeObject && activeObject.type === 'text') {
                //@ts-ignore
                activeObject.text = this.value;
                canvas.renderAll();
		      }
	  	});
        const removed = document.getElementById('remove-selected')
        if(removed)
            removed.onclick = () => {
                var activeObject = canvas.getActiveObject(),
                    activeGroup = canvas.getActiveGroup();
                if (activeObject) {
                    canvas.remove(activeObject);
                    $("#text-string").val("");
                }
                else if (activeGroup) {
                    var objectsInGroup = activeGroup.getObjects();
                    canvas.discardActiveGroup();
                    objectsInGroup.forEach((object) =>{
                        canvas.remove(object);
                    });
                }
            };
        const bring = document.getElementById('bring-to-front');
        if(bring)
            bring.onclick = function() {
                var activeObject = canvas.getActiveObject(),
                    activeGroup = canvas.getActiveGroup();
                if (activeObject) {
                    activeObject.bringToFront();
                }
                else if (activeGroup) {
                    var objectsInGroup = activeGroup.getObjects();
                    canvas.discardActiveGroup();
                    objectsInGroup.forEach(function(object) {
                        object.bringToFront();
                    });
                }
            };
        const sendBack = document.getElementById('send-to-back');
        if(sendBack)
	        sendBack.onclick = function() {
                var activeObject = canvas.getActiveObject(),
                    activeGroup = canvas.getActiveGroup();
                if (activeObject) {
                    activeObject.sendToBack();
                }
                else if (activeGroup) {
                    var objectsInGroup = activeGroup.getObjects();
                    canvas.discardActiveGroup();
                    objectsInGroup.forEach(function(object) {
                        object.sendToBack();
                    });
                }
            };
	  $("#text-bold").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
		    activeObject.fontWeight = (activeObject.fontWeight == 'bold' ? '' : 'bold');
		    canvas.renderAll();
		  }
		});
	  $("#text-italic").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.fontStyle = (activeObject.fontStyle == 'italic' ? '' : 'italic');
		    canvas.renderAll();
		  }
		});
	  $("#text-strike").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.textDecoration = (activeObject.textDecoration == 'line-through' ? '' : 'line-through');
		    canvas.renderAll();
		  }
		});
	  $("#text-underline").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.textDecoration = (activeObject.textDecoration == 'underline' ? '' : 'underline');
		    canvas.renderAll();
		  }
		});
	  $("#text-left").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.textAlign = 'left';
		    canvas.renderAll();
		  }
		});
	  $("#text-center").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.textAlign = 'center';
		    canvas.renderAll();
		  }
		});
	  $("#text-right").click(function() {
		  var activeObject = canvas.getActiveObject();
		  if (activeObject && activeObject.type === 'text') {
			  activeObject.textAlign = 'right';
		    canvas.renderAll();
		  }
		});
	  $("#font-family").change(function() {
	      var activeObject = canvas.getActiveObject();
	      if (activeObject && activeObject.type === 'text') {
            //@ts-ignore
	        activeObject.fontFamily = this.value;
	        canvas.renderAll();
	      }
	    });
		// $('#text-bgcolor').miniColors({
		// 	change: function(hex, rgb) {
		// 	  var activeObject = canvas.getActiveObject();
		//       if (activeObject && activeObject.type === 'text') {
		//     	  activeObject.backgroundColor = this.value;
		//         canvas.renderAll();
		//       }
		// 	},
		// 	open: function(hex, rgb) {
		// 		//
		// 	},
		// 	close: function(hex, rgb) {
		// 		//
		// 	}
		// });
		// $('#text-fontcolor').miniColors({
		// 	change: function(hex, rgb) {
		// 	  var activeObject = canvas.getActiveObject();
		//       if (activeObject && activeObject.type === 'text') {
		//     	  activeObject.fill = this.value;
		//     	  canvas.renderAll();
		//       }
		// 	},
		// 	open: function(hex, rgb) {
		// 		//
		// 	},
		// 	close: function(hex, rgb) {
		// 		//
		// 	}
		// });
		
		// $('#text-strokecolor').miniColors({
		// 	change: function(hex, rgb) {
		// 	  var activeObject = canvas.getActiveObject();
		//       if (activeObject && activeObject.type === 'text') {
		//     	  activeObject.strokeStyle = this.value;
		//     	  canvas.renderAll();
		//       }
		// 	},
		// 	open: function(hex, rgb) {
		// 		//
		// 	},
		// 	close: function(hex, rgb) {
		// 		//
		// 	}
		// });
	
		//canvas.add(new fabric.fabric.Object({hasBorders:true,hasControls:false,hasRotatingPoint:false,selectable:false,type:'rect'}));
	   $("#drawingArea").hover(
	        function() {
	        	 canvas.add(line1);
		         canvas.add(line2);
		         canvas.add(line3);
		         canvas.add(line4);
		         canvas.renderAll();
	        },
	        function() {
	        	 canvas.remove(line1);
		         canvas.remove(line2);
		         canvas.remove(line3);
		         canvas.remove(line4);
		         canvas.renderAll();
	        }
	    );
	   
	   $('.color-preview').click(function(){
           const color = $(this).css("background-color");
           //@ts-ignore
		   document.getElementById("shirtDiv").style.backgroundColor = color;
	   });
	   
	   $('#flip').click(
		   function() {
			   	if ($(this).attr("data-original-title") == "Show Back View") {
			   		$(this).attr('data-original-title', 'Show Front View');
                    setTshirt('http://comprafacil.com.pe/api/public/crew_front.png')
			        a = JSON.stringify(canvas);
			        canvas.clear();
			        try
			        {
			           var json = JSON.parse(b);
			           canvas.loadFromJSON(b);
			        }
			        catch(e)
			        {}
			        
			    } else {
			    	$(this).attr('data-original-title', 'Show Back View');
                    setTshirt('http://comprafacil.com.pe/api/public/crew_back.png')
			    	$("#tshirtFacing").attr("src","");
			    	b = JSON.stringify(canvas);
			    	canvas.clear();
			    	try
			        {
			           var json = JSON.parse(a);
			           canvas.loadFromJSON(a);
			        }
			        catch(e)
			        {}
			    }
			   	canvas.renderAll();
			   	setTimeout(function() {
			   		canvas.calcOffset();
			    },200);
        });
	//    $(".clearfix button,a").tooltip();
	   line1 = new fabric.Line([0,0,200,0], {"stroke":"#000000", "strokeWidth":1,hasBorders:false,hasControls:false,hasRotatingPoint:false,selectable:false});
	   line2 = new fabric.Line([199,0,200,399], {"stroke":"#000000", "strokeWidth":1,hasBorders:false,hasControls:false,hasRotatingPoint:false,selectable:false});
	   line3 = new fabric.Line([0,0,0,400], {"stroke":"#000000", "strokeWidth":1,hasBorders:false,hasControls:false,hasRotatingPoint:false,selectable:false});
       line4 = new fabric.Line([0,400,200,399], {"stroke":"#000000", "strokeWidth":1,hasBorders:false,hasControls:false,hasRotatingPoint:false,selectable:false});
       setCanvas(canvas)
    }
    const item = isFront ? tshirt : backTshirt
    const resizeWorkspace = () => {
        console.log('>>: resizeee')
        const container = document.getElementById('shirtDiv')
        canvas.setWidth(item.dimensions.width * 1.05)
        canvas.setHeight(item.dimensions.height * 1.05)
        canvas.calcOffset()
        container.style.width = item.dimensions.width
    }
    const changeImage = (element) => {
        console.log('>>: element > ', element)
        const frontRectangle = element.rectangles[0]
        const backRectangle = element.rectangles[1]
        const container = document.getElementById('shirtDiv')
        container.style.display = 'block'
        const containerWidth = container.clientWidth
        const containerHeight = container.clientHeight
        const front = {
            ... tshirt,
            dimensions: {
                top: frontRectangle.x * containerWidth,
                left: frontRectangle.y * containerHeight,
                width: frontRectangle.width * containerWidth,
                height: frontRectangle.height * containerHeight
            },
            uri: element.images[0].uri
        }
        const back = {
            ... backTshirt,
            dimensions: {
                top: backRectangle.x * containerWidth,
                left: backRectangle.y * containerHeight,
                width: backRectangle.width * containerWidth,
                height: backRectangle.height * containerHeight
            },
            uri: element.images[0].uri
        }
        canvas.setWidth(front.dimensions.width * 1.05)
        canvas.setHeight(front.dimensions.height * 1.05)
        canvas.calcOffset()
        container.style.width = front.dimensions.width
        console.log('>>: values > ', front, back)
        setTshirt(front)
        setBackTshirt(back)
        console.log('>>:  width >', containerWidth, containerHeight)
        // setTshirt(element.images[0].uri)
        // setBackTshirt(element.images[1].uri)
    }
    const getCloths = async () => {
        try {
            const query = {
                all: true,
                custom: 1,
                with: [
                    'images',
                    'category.attributes.units',
                    'category.attributes.values',
                    'attributes.attribute',
                    'variants.images',
                    'variants.attributes.attribute',
                    'variantAttributes'
                ]
            }
            const cloths = await ClothService.get(query)
            setCloths(cloths)
            changeImage(cloths[0])
        } catch (error) {
            console.log('>>: error > ', error)
        }
    }
    useEffect(() => {
        load()
        getCloths()
        $('.loader-wrapper').hide()
    }, []);
    return (
        <>
            <Helmet>
                <title>Compra Fácil - Polos personalizados</title>
            </Helmet>
            <HeaderOne logoName={'logo/logo-market.png'}/>
            <Breadcrumb title={'Polos Personalizados'}/>
            <div className="container">
                <section id="typography">
                    <div className="page-header">
                        <h1>Polos Personalizados</h1>
                    </div>
                {/* Headings & Paragraph Copy */}
                    <div className="row">
                        <div className="col-3">
                            <div className="span3">
                                <Tabs>
                                    <TabList>
                                        <Tab>Opciones del polo</Tab>
                                        <Tab>Avatar</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="tab-pane active" id="tab1">
                                            <div className="container">
                                                <File
                                                    label="Agregar estampado"
                                                    onChange={ change }
                                                    name="image"
                                                    inputStyle={{
                                                        display: 'contents'
                                                    }}
                                                    className="btn-product"
                                                />
                                            </div>
                                            <div className="container">
                                                <div className="row">
                                                    {
                                                        cloths.map((element, i) => {
                                                            return(
                                                                <div
                                                                    className="col-md-6 col-sm-12"
                                                                    key={i}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            maxWidth: '70%',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => changeImage(element)}
                                                                        src={element.images[0].uri}
                                                                        alt={element.name}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="tab-pane" id="tab2">
                                            <div className="well">
                                                <div className="input-append">
                                                    <input className="span2" id="text-string" type="text" placeholder="add text here..." />
                                                    <button
                                                        id="add-text"
                                                        className="btn"
                                                        onClick={() => addText()}
                                                    >
                                                        <i className="icon-share-alt" />
                                                    </button>
                                                    <hr />
                                                </div>
                                                <div id="avatarlist">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <img
                                                                onClick={e => addImage(e)}
                                                                style={{cursor: 'pointer'}}
                                                                className="img-polaroid"
                                                                src={require('../../assets/tshirts/invisibleman.jpg')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                      
                        <div className="col-6">
                            <div className="span6">
                                <div style={{minHeight: 32}}>
                                    <div className="clearfix">
                                        <div
                                            className="pull-right"
                                            id="imageeditor"
                                            style={{display: 'none'}}
                                        >
                                            <div className="btn-group">
                                                <button
                                                    className="btn"
                                                    id="bring-to-front"
                                                    title="Bring to Front"
                                                >
                                                    <i className="fa fa-arrow-circle-up" style={{height: 19}} />
                                                </button>
                                                <button className="btn" id="send-to-back" title="Send to Back">
                                                    <i className="fa fa-arrow-circle-down" style={{height: 19}} />
                                                </button>
                                                <button
                                                    id="flip"
                                                    type="button"
                                                    className="btn"
                                                    title="Show Back View"
                                                    onClick={async () => {
                                                        await setIsFront(false)
                                                        changeImage()
                                                    }}
                                                >
                                                    <i className="fa fa-undo" style={{height: 19}} />
                                                </button>
                                                <button id="remove-selected" className="btn" title="Delete selected item">
                                                    <i className="fa fa-trash" style={{height: 19}} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*	EDITOR      */}
                                <div
                                    id="shirtDiv"
                                    className="page"
                                    style={{
                                        display: 'none',
                                        position: 'relative',
                                        backgroundColor: 'rgb(255, 255, 255)'
                                    }}
                                >
                                    <img
                                        id="tshirtFacing"
                                        src={isFront ? tshirt.uri : backTshirt.uri}
                                        className="w-100"
                                    />
                                    <div id="drawingArea" style={{
                                        position: 'absolute',
                                        zIndex: 10,
                                        // border: 'solid 1px red',
                                        ...(isFront ? tshirt.dimensions : backTshirt.dimensions),
                                        width: (isFront ? tshirt.dimensions.width * 1.1 : backTshirt.dimensions.width * 1.1)
                                    }}>
                                        <canvas
                                            id="tcanvas"
                                            // width={200}
                                            // height={400}
                                            // className="hover"
                                            style={{
                                                WebkitUserSelect: 'none',
                                                width: '100%',
                                                height: '100%',
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div
                                    id="shirtBack"
                                    class="page"
                                    style={{
                                        width: 530,
                                        height: 630,
                                        position: 'relative',
                                        backgroundColor: 'rgb(255, 255, 255)'
                                    }}
                                >
                                    <img src="img/crew_back.png" />
                                    <div id="drawingArea" style={{position: 'absolute', top: 100, left: 160, zIndex: 10, width: 200, height: 400}}>
                                        <canvas
                                            id="backCanvas"
                                            width={200}
                                            height={400}
                                            // className="hover"
                                            style={{WebkitUserSelect: 'none'}}
                                        />
                                    </div>
                                </div> */}
                            {/*	/EDITOR		*/}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="span3">
                                <div className="well">
                                    <h3>Total Prices</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Short Sleeve</td>
                                                <td align="right">$12.49</td>
                                            </tr>
                                            <tr>
                                                <td>Front Design</td>
                                                <td align="right">$4.99</td>
                                            </tr>
                                            <tr>
                                                <td>Back Design</td>
                                                <td align="right">$4.99</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Total</strong>
                                                </td>
                                                <td align="right">
                                                    <strong>$22.47</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="button" className="btn btn-large btn-block btn-success" name="addToTheBag" id="addToTheBag">Add to bag <i className="icon-briefcase icon-white" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>{/* /container */}
            {/* Footer ================================================== */}
            <FooterOne
                logoName={'logo/logo-market.png'}
            />
        </>
    )
}
