import * as constants from './constants';
import Globals from './globals';
import Socket from './socket'
import numeral from "numeral";

export * from './constants';
export * from './globals';

function moneyFormat(number, symbol = "S/") {
    const value = isNaN(number) ? 0 : parseFloat(number)
    let money =  symbol + " " + numeral(value).format("0,0.00");
    money = money.replace(',', '');
    money = money.replace('.', ',');
    return money;
}

export {
    moneyFormat,
    Socket,
    Globals,
    constants
}
