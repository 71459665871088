import React from 'react'

const ConditionFilter = ({condition, onChangeCondition}) => {
  
  if (condition!=null) return null;
  
  const handleClick = condition => {
    if (!!onChangeCondition) onChangeCondition(condition)
  }
  
  return (
    <div className="section-items mb-5">
      <p className="title-section-items color-inherit">Condición</p>
      <p className="item-section-items color-inherit font-sand f-sand-300 color_hover_primary cur-pointer" onClick={ () => handleClick(1) }>Nuevo</p>
      <p className="item-section-items color-inherit font-sand f-sand-300 color_hover_primary cur-pointer" onClick={ () => handleClick(0) }>Usado</p>
    </div>
  )
  
}

export default ConditionFilter
