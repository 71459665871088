import React, { Component } from 'react';
import { connect } from 'react-redux'
import Breadcrumb from "../common/breadcrumb";
import { AuthService } from '../../services';
import { toast } from 'react-toastify';
import ClientSection from "../register/ClientSection";
import { APP_NAME } from "../../utils";
import { Link } from "react-router-dom";

class Register extends Component {

  state = {
    client: null,
    loading: false,
    agree: false,
  }

  handleChangeClient = client => this.setState({ client });
  setChecked = () => this.setState({ agree: true});

  submit = async e => {
    e.preventDefault()

    if (!this.state.agree) return toast.error('Debes aceptar los términos y condiciones');

    if (!this.clientSection.isValid()) return

    this.setState({ loading: true })
    try {
      const res = await AuthService.register(this.state.client)
      /*  this.props.dispatch({
         type: 'SET_USER',
         payload: res
       }) */
      this.props.history.push('/pages/verify-account')
    } catch (e) {
      toast.error(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  change = e => this.setState({
    form: {
      ...this.state.form,
      [e.target.name]: e.target.value
    }
  });

  render() {
    const { agree } = this.state;
    return (
      <div>
        <Breadcrumb title='Registro de usuarios' />
        <section className="register-page section-b-space container">
          <div className="theme-card">
            <form className="theme-form" onSubmit={this.submit}>

              <ClientSection ref={it => this.clientSection = it}
                title="Crear una cuenta"
                onChangeClient={this.handleChangeClient}
                rol="client" />

              <div className="form-check col-12 mb-3 mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={agree}
                  id="agree"
                  checked={agree}
                  onChange={() =>{window.open('https://comprafacil.com.pe/api/web/terms-pdf/2', '_blank');this.setState({ agree: !agree })} }/>
                 <label
                className="form-check-label font-sand ml-2 mr-2"
                htmlFor="agree"
              >
               <a 
                  href="https://comprafacil.com.pe/api/web/terms-pdf/2"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={()=>this.setChecked()}
                >
                  Acepto los términos y condiciones de uso en {APP_NAME}
                </a>
              </label>

              </div>

              <button className="btn btn-solid mt-4"
                type="submit"
                disabled={this.state.loading}>
                {this.state.loading ? "Enviando..." : "Crear cuenta"}
              </button>

            </form>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Register)
