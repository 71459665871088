/* eslint-disable no-sequences */
import React, { Component } from 'react';
import { If, Then, Else, When, Unless, Switch, Case, Default } from 'react-if';
import { connect } from 'react-redux';
import { PayPalButton as PaypalExpressBtn } from 'react-paypal-button-v2'; 
import Breadcrumb from '../common/breadcrumb';
import { CheckoutService } from '../../services';
import defaultImage from '../../assets/images/image_defaoult.jpg';
import ProductList from './ProductList';
import PromotionCode from './PromotionCode';
import PaymentForm from './PaymentForm';
import EventBar from '../register/EventBar'; 
import LabelRow from './LabelRow';
import PaymentMethodSelect from './PaymentMethodSelect';
import { toast } from 'react-toastify';
import ExchangeRateBanner from './ExchangeRateBanner';
import { Globals } from '../../utils';
import ModalIzipay from '../ModalIzipay';
import { addToPriceCheckout } from '../../actions';
import { throws } from 'assert';
import ModalPagoEfectivo from '../ModalPagoEfectivo';
import { removeCart, incrementQty, decrementQty } from '../../actions';
import NewProduct from "../common/new-product";
import  moment  from 'moment';

const promotionType = { 
  SHIPMENT: 1,
  FIX: 2,
  PERCENTAGE: 3,
}; 

export const paymentMethods = {
  IZIPAY: 1,
  PAYPAL: 2,
  TRANSFER: 3,
  TRANSFER2: 5,
  PAGOEFECTIVO: 4,
  OTRO: 0,
};




/**
 * Esta funci�n mapea el dato que viene del carrito almacenado en redux a un estructura del producto mas limpia y
 * manejable
 */
function mapCarItemToProduct(item, cart) {
   
  const custom = !!item.product;

  // Obtener imagen
  const images = item.images;
 // console.log('item', item.images);

  if (!images || !images.length) return defaultImage;
  const firstImage = images[0];
  let secondImage = images[1];

 // console.log('item', images[1]);

  if(images[1] === undefined) {
   secondImage = images[0];
  }
  


  const stock = custom ? item.product.stock : item.stock;

  const ref = cart.find((it) => it.id === item.id);
  const qty = ref.qty > stock ? stock : ref.qty;

  // Precio de venta
  const price = !!item.has_discount
    ? parseFloat(item.offer_price)
    : parseFloat(item.price);

  // Pecio lista
  const originalPrice = parseFloat(item.price);

  // Obteniendo el descuento aplicado al producto
  const discount = originalPrice - price;

  const shippingAmount = 0;
 
  // Porcentaje de descuento aplicado
  const rate = 1 - price / originalPrice;

  const total = qty * price;


  const subcategory = custom ? item.product.category : item.category;

  return {
    id: custom ? item.product.id : item.id,
    adult: item.adult,
    product_custom_id: custom ? item.id : null,
    custom: custom,
    name: custom ? item.product.name : item.name,
    code: custom ? item.product.code : item.code,
    textvariantes: custom ? item.product.textvariantes : item.textvariantes,
    image: firstImage.uri || firstImage.url,
    image2: secondImage.uri || secondImage.url,
    qty,
    seller_id: item.user_id,
    category_id: subcategory.category_id,
    subcategory_id: subcategory.id,
    has_discount: !!item.has_discount,
    discount_rate: parseFloat(item.discount_rate || 0),
    paymentmethodoffer: item.paymentmethodoffer,
    to_province: custom
      ? !!item.product.can_go_to_province
      : !!item.can_go_to_province,
    shippingAmount,
    price,
    offer_price: parseFloat(item.price),
    originalPrice,
    discount,
    rate, 
    total,
    stock,
  
  };
}

class Checkout extends Component {
  state = {
    products: [],
    newproducts: [],
    code: null,
    reseller_id: null,
    form: {},
    discountShipping: {},
    current_payment:0,
    discountShippingAmount: 0,
    discountFix: 0,
    subtotal: 0,
    subtotal6: 0,
    mayor: 0,
    shippingAmount: 0,
    total: 0,
    total2: 0,
    total3: 0,
    total4: 0,
    total5: 0,
    has_rut:false,
    fileimagen: null,
    hasProvince: false,
    paymentMethod: paymentMethods.OTRO,
    sending: false,
    promotion: null,
    paypalResponse: null,
    izipayResponse: null,
    pagoEfectivoResponse: null, 
    exchangeRate: null,
    activeAdult: null,
    discountMode: false,
    discountTransferenciaDefault: true,
  };


  onChange2 = (key, value) => {
    this.setState(() => {
        return {
            ...this.state,
            [key]: value
        };
    });
  };


/*
  constructor(props) {
    super(props);
  }
*/
  ProductType = () => {
    this.onChange2("has_rut",this.props.cartItems.map((item, index) => {if(item.user?.bill_type==0){return item;} } ).length > 0);
  }
  
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.cartItems.length === 0) nextProps.history.push('/');
  }
  
  componentDidMount() {
    this.fetchProducts()
    this.ProductType();
  }







  fetchProducts = async () => {
    Globals.setLoading();
    const cart = this.props.cartItems;
    // console.log('cart', cart);
    const productIds = cart.filter((it) => !it.custom).map((it) => it.id);
    const customIds = cart.filter((it) => !!it.custom).map((it) => it.id);
    try {
      const response = await CheckoutService.getProducts(productIds, customIds);
      // console.log(response);
      const products = response.map((it) =>{return mapCarItemToProduct(it, cart);} );
      // console.log('p', products)
      this.setProducts(products);
    } catch (e) {
      toast.error(e.message);
      this.props.history.push('/');
    } finally {
      Globals.quitLoading();
    }
  };


  validarenvio(products) {
    let bandera = 1;
    products.map((item) => {

  //    console.log('prueba 2: '+item.free_shipping);
        if (item.free_shipping != 1)
          bandera = 0; 
       
      });

    return bandera;
  }


  calculateTotales = (products, discountShipping, discountFix) => {
   // console.log("calculateTotales", products);

   // console.log('final34: '+discountShipping.discount_rate);
   // console.log('final35: '+discountShipping.discount_rate);
   // console.log('once: '+discountFix);
    const { hasProvince, paymentMethod } = this.state;
    let sum3 = 0;
    let sumapago = 0;
    let sumapaypal = 0;
    let sumaizi = 0;
    let suma6 = 0;

    let subtotal6 = 0; 
    let shippingAmount = 0;
    let discountShippingAmount = 0;
    let total = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total6 = 0;

    let subtotal = 0;
    let subtotal2 = 0;
    let subtotal3 = 0;
    let subtotal4 = 0;

    let calculo = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          if ( item.paymentmethodoffer.transferencia ) {
            sum3 += item.price * item.qty;
          }else{
            sum3 += item.originalPrice * item.qty;
   
          }
        } else {
          sum3 += item.originalPrice * item.qty;
        }
        return sum3;
      });

      let calculo2 = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          if ( item.paymentmethodoffer.paypal ) {
            sumapaypal += item.price * item.qty;
          }else{
            sumapaypal += item.originalPrice * item.qty;
   
          }
        } else {
          sumapaypal += item.originalPrice * item.qty;
        }
        return sumapaypal;
      });

      let calculo3 = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          if ( item.paymentmethodoffer.niubiz ) {
            sumaizi += item.price * item.qty;
          }else{
            sumaizi += item.originalPrice * item.qty;
   
          }
        } else {
          sumaizi += item.originalPrice * item.qty;
        }
        return sumaizi;
      });

      let calculo4 = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          if ( item.paymentmethodoffer.pagoEfectivo ) {
            sumapago += item.price * item.qty;
          }else{
            sumapago += item.originalPrice * item.qty;
   
          }
        } else {
          sumapago += item.originalPrice * item.qty;
        }
        return sumapago;
      });

      let calculo6 = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          
            suma6 += item.originalPrice * item.qty;
        } else {
          suma6 += item.originalPrice * item.qty;
        }
        return suma6;
      });




     subtotal = calculo.pop();

     subtotal2 = calculo2.pop();
     subtotal3 = calculo3.pop();
     subtotal4 = calculo4.pop();
     subtotal6 = calculo6.pop();
 

    const totalProductShippingAmount = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .reduce(
        (accumulator, item) =>
          accumulator + item.qty * item.shippingAmount,
        0
      );



    const validar = this.validarenvio(products);

   

    const applyShippingAmount = subtotal < 200;



 /*   if (subtotal < 200){
        if (this.validarenvio(products) == 0){

           applyShippingAmount = false;

           console.log('prueba 1: '+applyShippingAmount);
        }else{
           applyShippingAmount = true;

           console.log('prueba 2: '+applyShippingAmount);
        }

    }else{
       applyShippingAmount = false;

       console.log('prueba 3: '+applyShippingAmount);
    } */

    shippingAmount = (applyShippingAmount ? 7 : 0) + totalProductShippingAmount;
    discountShippingAmount = applyShippingAmount
      ? parseFloat(
        discountShipping && discountShipping.discount_rate
          ? discountShipping.discount_rate
          : 0
      )
      : 0;

    let CalcTotal =
      subtotal + shippingAmount - discountFix - discountShippingAmount;
      let CalcTotal2 =
      subtotal2 + shippingAmount - discountFix - discountShippingAmount;
      let CalcTotal3 =
      subtotal3 + shippingAmount - discountFix - discountShippingAmount;
      let CalcTotal4 =
      subtotal4 + shippingAmount - discountFix - discountShippingAmount;

      let CalcTotal6 =
      subtotal6;

     total = !!CalcTotal ? CalcTotal : 0;
     total2 = !!CalcTotal2 ? CalcTotal2 : 0;
     total3 = !!CalcTotal3 ? CalcTotal3 : 0;
     total4 = !!CalcTotal4 ? CalcTotal4 : 0;
     total6 = !!CalcTotal6 ? CalcTotal6 : 0;

    // console.log('total calTotales', total);
    this.props.addToPriceCheckout(total);

    return {
      discountFix,
      subtotal,
      subtotal6,
      shippingAmount,
      discountShippingAmount,
      total,
      total2,
      total3,
      total4,
      
      paymentMethod: total === 0 ? paymentMethods.TRANSFER : paymentMethod,
    };
  };

/*
  calculateTotalesviejo = (products, discountShipping, discountFix) => {
    // console.log("calculateTotales", products);
    const { hasProvince, paymentMethod } = this.state;
    let sum3 = 0;
    let sumapago = 0;
    let sumapaypal = 0;
    let sumaizi = 0;
    let calculo = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .map((item) => {
        // console.log(this.state.paymentMethod);
        if (discountFix < item.discount) {
          if ( item.paymentmethodoffer.niubiz) {
            sumaizi += item.price * item.qty;
          }
          if ( item.paymentmethodoffer.paypal ) {
            sumapaypal += item.price * item.qty;
          }
          if ( item.paymentmethodoffer.transferencia ) {
            sum3 += item.price * item.qty;
          }
          if ( item.paymentmethodoffer.pagoEfectivo ) {
            sumapago += item.price * item.qty;
          }
          if ( item.paymentmethodoffer.niubiz || item.paymentmethodoffer.pagoEfectivo || item.paymentmethodoffer.transferencia || item.paymentmethodoffer.paypal) {
          }else{
            sum3 += item.originalPrice * item.quantity;
            sumaizi += item.originalPrice * item.quantity;
            sumapaypal += item.originalPrice * item.quantity;
            sumapago += item.originalPrice * item.quantity;
          }
        } else {
          sum3 += item.originalPrice * item.quantity;
          sumaizi += item.originalPrice * item.quantity;
          sumapaypal += item.originalPrice * item.quantity;
          sumapago += item.originalPrice * item.quantity;
        }
        return sum3;
      });
    // console.log("calculo", calculo);
    let subtotal = calculo.pop();
    // console.log('subtotal', subtotal);
    const totalProductShippingAmount = products
      .filter(({ to_province }) => (!hasProvince ? true : to_province))
      .reduce(
        (accumulator, item) =>
          accumulator + item.quantity * item.shippingAmount,
        0
      );
    //console.log(totalProductShippingAmount);
    const applyShippingAmount = subtotal < 200;
    // console.log(applyShippingAmount);
    const shippingAmount =
      (applyShippingAmount ? 7 : 0) + totalProductShippingAmount;
    // console.log(shippingAmount);
    const discountShippingAmount = applyShippingAmount
      ? parseFloat(
        discountShipping && discountShipping.discount_rate
          ? discountShipping.discount_rate
          : 0
      )
      : 0;

    let CalcTotal =
      subtotal + shippingAmount - discountFix - discountShippingAmount;
    let total = !!CalcTotal ? CalcTotal : 0;
    // console.log('total calTotales', total);
    this.props.addToPriceCheckout(total);
    return {
      discountFix,
      subtotal,
      shippingAmount,
      discountShippingAmount,
      total,
      paymentMethod: total === 0 ? paymentMethods.TRANSFER : paymentMethod,
    };
  };

  */

  setProducts = (products, cb = null) => {
     //console.log(products)

    let mayor = 0;

    const { discountShipping, discountFix } = this.state;
    const totales = this.calculateTotales(
      products,
      discountShipping,
      discountFix
    );
    this.setState(
      {
        ...this.state,
        ...totales,
        products,
        mayor:products.find(p => p.adult == 1) ? 1 : 0,
      },
      cb
    );
  };

  handleFetchCode = ({ promotion, reseller = null }) =>
    this.setState({ promotion, reseller_id: reseller, }, this.calculateDiscount);

  calculateDiscount = () => {
    const { promotion } = this.state;

  //  console.log('hola3'+this.state.promotion);

    if (promotion == null) {

  //   console.log('aqui se quedi');
      return;
    } 

/// aca se calculan los diferentes descuentos de la promoción /////////////////////
    const discountShipment = promotion.promotion_codes.find(
      (it) => it.promotion_type_code_id === 1
    );
   // 
  //  console.log('si pasa por aqui:'+ promotion);
   // this.calculateDiscountShipment({id:0, discount_rate: 0});
   // this.calculatePercentageDiscount({id:0, discount_rate: 0});
   // this.calculateDiscountAmount({id:0, discount_rate: 0});

    if (discountShipment) {
     // console.log('descuento envio: '+discountShipment);
      this.calculateDiscountShipment(discountShipment);
      this.setState({ discountShipping: discountShipment });
    }else{
      const discountPercentage = promotion.promotion_codes.find( (it) => it.promotion_type_code_id === promotionType.PERCENTAGE );
      if (discountPercentage) {
        this.calculatePercentageDiscount(discountPercentage);
      } else {
        const discountAmount = promotion.promotion_codes.find(
          (it) => it.promotion_type_code_id === promotionType.FIX
        );
        if (discountAmount) {
          this.calculateDiscountAmount(discountAmount);
        }else{
         // this.calculatePercentageDiscount({id:0, discount_rate: 0});
        }
  
      }

      







    }

    
  };

  calculatePercentageDiscount = (discount, cb, mayor) => {
    const { hasProvince } = this.state;
    const discountRate = discount.discount_rate/100;

   // console.log('teneis razon', discountRate);
    const products = this.state.products.map((product) => {
      if (hasProvince && !product.to_province) return product;

      const applicable = this.isApplicableDiscount(product);
      if (!applicable) return product;

      const {
        offer_price,
        discount_rate,
        qty,
        has_discount,
      } = product;

    //  console.log('p1: '+offer_price);
    //  console.log('p2: '+discount_rate);
    //  console.log('p3: '+has_discount);
      
      //<editor-fold desc="EN ESTE BLOQUE DE CÓDIGO SE BUSCA OBTENER EL PORCENTAJE DE DESCUENTO DEFINITIVO">
      
      // Porcentaje de descuento entre el precio lista del producto (originalPrice) y el precio oferta (offer_price)
      const offer_price_rate = has_discount===1
      ? 1 - product.price / offer_price
      : 0;
    //  console.log(product.price);
      // Porcentaje de descuento maximo permitido
      const max_discount_rate = product.discount_rate;


     // console.log('holaaaa', max_discount_rate);

      //console.log(max_discount_rate+' hola');

      if (product.adult == 1) {
        mayor = 1;
      }



      // Limitando el porcentaje de descuento a aplicar par que no sea superior al maximo permitido
      const promotion_discount_rate_applied =
        max_discount_rate < discountRate ? max_discount_rate : discountRate;

      // Se elige el porcentaje que de el mayor beneficio
      const rate = Math.max(offer_price_rate, promotion_discount_rate_applied);

      //</editor-fold>

      const price = offer_price - offer_price * rate;

     // console.log('p5: '+price);
      const discount = offer_price - price;
      const total = qty * price;
      const total2 = qty * price;
      const total3 = qty * price;
      const total4 = qty * price;
      return {
        ...product,
        rate,
        price,
        discount,
        total,
        total2,
        total3,
        total4,
        mayor,
      };
    });

    this.setProducts(products, cb);
  };

  calculateDiscountAmount = (_discount, cb) => {

   // console.log('por aqui pasa en ambos?'+_discount.discount_rate+" oh: ");

    const { products, hasProvince } = this.state;

    let discountFix = 0;
    let amount = parseFloat(_discount.discount_rate);

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (hasProvince && !product.to_province) continue;



      const applicable = this.isApplicableDiscount(product);

     // console.log('aqui no pasa: '+applicable);
    //   console.log('doce: '+applicable);

      if (!applicable) continue;

      let { originalPrice } = product;

      // Si el descuento es mayor al precio de venta del producto, solo se descuenta la totalidad del producto.
      const discount = amount > originalPrice ? originalPrice : amount;

      // Se descuenta el monto total
      amount -= discount;
      discountFix += discount;

      //console.log('dos '+discountFix, discount, amount);
      /* this.setState({
        discountFix
      }) */
      if (amount < 0) break;
    }


    const { discountShipping } = this.state;
    const totales = this.calculateTotales(
      products,
      discountShipping,
      discountFix
    );

   // console.log('tres '+discountFix);

    const state = {
      ...this.state,
      ...totales,
    };

    this.setState(state, cb);
  };

  calculateDiscountShipment = (discountShipping) => {
    const { products, discountFix } = this.state;
    const totales = this.calculateTotales(
      products,
      discountShipping,
      discountFix
    );
    this.setState({
      ...this.state,
      ...totales,
      discountShipping,
    });
  };

  /**
   * Esta función comprueba si el producto es aplicable o no para el descuento.
   * @param product
   * @returns {boolean} true si es aplicable.
   */
  isApplicableDiscount = (product) => {
    const {
      sellers,
      categories,
      subcategories,
      products,
    } = this.state.promotion.scope;
    if (!!sellers.length && sellers.some((id) => product.seller_id === id))
      return false;
    if (
      !!categories.length &&
      !categories.some((id) => product.category_id === id)
    )
      return false;
    if (
      !!subcategories.length &&
      !subcategories.some((id) => product.subcategory_id === id)
    )
      return false;
    return !(!!products.length && !products.some((id) => product.id === id));
  };

  handleChangeForm = (form) => {
    //console.log(form)
    this.setState({ form }, this.setHasProvince)
  };


  handleChangeForm2 = (form) => {
  //  console.log('prueba de que si:' + form.file64);
    this.setState({
        fileimagen: form.file64,
      })
  };
  

  setHasProvince = () => {
    const { form } = this.state;
    // console.log('form?.address?.district?.province_id', form?.address?.district?.province_id)
    const hasProvince = form?.address
      ? form?.address?.district?.region_id !== '150100'
        ? true
        : false
      : false;

    const cb = () => {
      const { hasProvince, products } = this.state;
      const shippingAmount = hasProvince ? 12 : 0;
      const _products = products.map((product) => {
        const { qty, price } = product;
        const total = qty * price;
        const total2 = qty * price;
        const total3 = qty * price;
        const total4 = qty * price;

        return {
          ...product,
          shippingAmount,
          total,
          total2,
          total3,
          total4,


        };
      });
      this.setProducts(_products, this.calculateDiscount);
    };

    if (this.state.hasProvince !== hasProvince)
      this.setState({ hasProvince }, cb);
  };

  setPaymentMethod = async (paymentMethod) => {
  //  console.log('paymentMethod', paymentMethod);
    if (paymentMethod === 0) {
      await this.setState({
        paymentMethod: 0,
      });
      await this.setState({
        discountMode: null,
      });
    }
    let filterIzipay = '';
    if (paymentMethod === 1) {
      // console.log('izipay');
      filterIzipay = this.state.products.filter(
        (item) => item.paymentmethodoffer?.niubiz === true
      );
      await this.setState({
        paymentMethod: paymentMethods.IZIPAY,
      });
      const validateIzipay = filterIzipay?.length === 0 ? null : filterIzipay;
      //console.log('filterIzipay', validateIzipay);
      await this.setState({
        discountMode: validateIzipay,
      });

      const sub = await this.calculateTotales(
        this.state.products,
        this.state.discountShipping,
        this.state.discountFix
      );
     // console.log('cuatro '+sub);
      await this.setState({ ...sub });
    }

    let filterPaypal = '';
    if (paymentMethod === 2) {
      filterPaypal = this.state.products.filter(
        (item) => item.paymentmethodoffer?.paypal === true
      );
      await this.setState({
        paymentMethod: paymentMethods.PAYPAL,
      });
      const validatePaypal = filterPaypal?.length === 0 ? null : filterPaypal;
      // console.log('filterPaypal', filterPaypal);
      await this.setState({
        discountMode: validatePaypal,
      });

      const sub = await this.calculateTotales(
        this.state.products,
        this.state.discountShipping,
        this.state.discountFix
      );
   //   console.log('cinco '+sub);
      await this.setState({ ...sub });
    }

    let filterTransferencia = '';
    if (paymentMethod === 3) {
      filterTransferencia = this.state.products.filter(
        (item) => item.paymentmethodoffer?.transferencia === true
      );
      await this.setState({
        paymentMethod: paymentMethods.TRANSFER,
      });
      const validateTransferencia =
        filterTransferencia?.length === 0 ? null : filterTransferencia;
      //console.log('filterTransferencia', filterTransferencia);
      await this.setState({
        discountMode: validateTransferencia,
      });

      const sub = await this.calculateTotales(
        this.state.products,
        this.state.discountShipping,
        this.state.discountFix
      );
    //  console.log('seis '+sub);
    //  console.log('siete '+this.state);
      await this.setState({ ...sub });
    }

    let filterPagoEfectivo = '';
    if (paymentMethod === 4) {
      filterPagoEfectivo = this.state.products.filter(
        (item) => item.paymentmethodoffer?.pagoEfectivo === true
      );
      await this.setState({
        paymentMethod: paymentMethods.PAGOEFECTIVO,
      });
      const validatePagoEfectivo =
        filterPagoEfectivo?.length === 0 ? null : filterPagoEfectivo;
    // console.log('filterPagoEfectivo', filterPagoEfectivo);
      await this.setState({
        discountMode: validatePagoEfectivo,
      });

      const sub = await this.calculateTotales(
        this.state.products,
        this.state.discountShipping,
        this.state.discountFix
      );
    //  console.log('ocho '+sub);
      await this.setState({ ...sub });
    }
  };

  validateForm = (showMessages = false) => {
    const { form, products, hasProvince, paymentMethod, total, total2, total3, total4 } = this.state;
    const { name, phone, email, address, ref, file64 } = form;

    const _products = products.filter((product) =>
      !hasProvince ? true : product.to_province
    );

    if (!_products.length) {
      if (showMessages) toast.warn('Debe de haber mas de un producto');
      return false;
    }

    if (!name) {
      if (showMessages) toast.warn('El campo nombre es requerido');
      return false;
    }

    if (!phone) {
      if (showMessages) toast.warn('El campo teléfono es requerido');
      return false;
    }

    if (!email) {
      if (showMessages) toast.warn('El campo correo electrónico es requerido');
      return false;
    }

    if (!address) {
      if (showMessages) toast.warn('El campo dirección es requerido');
      return false;
    }

   /* if (paymentMethod === paymentMethod.TRANSFER && total > 0) {
      if (!ref) {
        if (showMessages)
          toast.warn('El campo referencia de la transferencia es requerido');
        return false;
      }

      if (!file64) {
        if (showMessages)
          toast.warn('El campo comprobante de la transferencia es requerido');
        return false;
      }
    } */

    return true;
  };

  handlePayPalSuccess = (paypalResponse) =>
    this.setState({ paypalResponse }, this.sendPayment);

  submit = async (e) => {
    e.preventDefault();
    if (!this.validateForm(true)) return;
    await this.sendPayment();
  };

  sendPayment = async () => {
    const {
      form,
      total,
      total2,
      total4,
      total3,
      total5,
      promotion,
      paymentMethod,
      reseller_id,
      fileimagen,
      shippingAmount,
      products,
      hasProvince,
      paypalResponse,
      izipayResponse,
      pagoEfectivoResponse,
      discountFix,
    } = this.state;

    const _products = products.filter((product) =>
      !hasProvince ? true : product.to_province
    );
    //console.log(form.ref)
    const data = {
      user_id: this.props.user.id,
      promotion_id: promotion ? promotion.id : '',
      payment_type_id: total === 0 ? paymentMethods.TRANSFER : paymentMethod,
      reseller_id: reseller_id || '',
      code: izipayResponse
        ? izipayResponse
        : paypalResponse
          ? paypalResponse.id
          : pagoEfectivoResponse
            ? pagoEfectivoResponse
            : form.ref,
      address_id: form.address.id,
      image: fileimagen,
      total,
      shipping_amount: shippingAmount,
      name: form.name,
      ruc: form.ruc,
      telefono: form.telefono,
      phone: form.phone,
      direccion: form.direccion,
      razon_social: form.razon_social,
      phone: form.phone,
      email: form.email,
      discount_fix: discountFix,
      products: _products.map((it) => {
       // console.log(it)
        return {
          adult: it.adult,
          seller_id: it.seller_id,
          product_id: it.id,
          product_custom_id: it.product_custom_id,
          price: it.price,
          qty: it.qty,
          subtotal: it.price + it.shippingAmount,
          total: it.total,
          shipping_amount: it.shippingAmount,
          discount_rate: it.rate,
          percentage_amount_discount: it.discount,
          paymentmethodoffer: it.paymentmethodoffer,
        };
      }),
    };


  //  console.log('estos son los datos:' + data.payment_type_id)
  //  return false;
    this.setState({ sending: true });
    try {
      const res = await CheckoutService.save(data);
    //()  console.log('aqui la respuesta: '+res);
      let totalsuccess = 0; 

      if (paymentMethod === 1) {
        totalsuccess = total;
      }
      if (paymentMethod === 2) {
        totalsuccess = total2;
      }
      if (paymentMethod === 3) { 
        totalsuccess = total3;
      }
      if (paymentMethod === 4) {
        totalsuccess = total4;
      }
      if (paymentMethod === 5) {
        totalsuccess = total5;
      }

      const state = {
        payment: res.payment,
        items: _products,
        orderTotal: totalsuccess,
        shippingValue: shippingAmount,
        symbol: this.props.symbol,
        address: form.address,
      };
      // console.log(state.items);
      this.setState({
        products: []
      })
      this.props.removeCart()
      this.props.history.push({
        pathname: '/order-success',
        state,
      });

      toast.success(res.msg);
    } catch (e) {
      toast.error('Tenemos una parada por ahora, la venta se ha ejecutado de igual manera');
      toast.error(e.message);
    } finally {
      this.setState({ sending: false });
    }
  };

  handleChangeRadio = (mode) => {
    if (mode === 1) {
      this.setState({
        activeAdult: true,
      });
    } else {
      this.setState({
        activeAdult: false,
      });
    }
  };

  handleIzipayResponse = (value) => {
    // console.log('value', value)
    this.setState({
      izipayResponse: value,
    });
  };

  handlePagoEfectivoResponse = (value) => {
    // console.log('value', value)
    this.setState({
      pagoEfectivoResponse: value,
    });
  };


  onChange5 = (productID) => {
    //console.log()
    const updatedListOfItems = [...this.state.products];
    const itemIndex = updatedListOfItems.map(e => e.id).indexOf(productID.id);
    productID.checked = productID.checked==true?false:true;
    //console.log(productID.checked)
    updatedListOfItems[itemIndex].checked = updatedListOfItems[itemIndex].checked==true?false:true;
    this.setState({products:updatedListOfItems});
  };



  removeProduct = (productID) => {
    let productsFilter = this.state.products.filter(function (product) {
      return product.id !== productID;
    });
    this.setState({
      products: productsFilter,
    });
    this.setProducts(productsFilter);
  };

   incrementar = () => {
    //console.log(product);
     // this.props.incrementQty(product, 1);
      this.fetchProducts();

    };






  calcularJuevesYViernesSanto(anio) {
  let a = anio % 19;
  let b = anio % 4;
  let c = anio % 7;
  let d = (19 * a + 24) % 30;
  let e = (2 * b + 4 * c + 6 * d + 5) % 7;
  let f = d + e + 22;

  let juevesSanto;
  let viernesSanto;

  if (f <= 31) {
    juevesSanto = new Date(anio, 2, 22 + f - 1);
    viernesSanto = new Date(anio, 2, 23 + f - 1);
  } else {
    juevesSanto = new Date(anio, 3, f - 31 - 3);
    viernesSanto = new Date(anio, 3, f - 30 -3);
  }

  return { juevesSanto, viernesSanto };
}



esDomingoOLunes(fecha) {
  const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  var numero = fecha.getDay();

  if (numero == 6){
    numero = 0;
  } else{
    numero = numero + 1;
  }

  const dia = diasSemana[numero];
 // console.log('este es el supuesto dia: '+dia);
  return dia === 'domingo';
}


  addHours2(numOfHours, fecha) {

      var fecha = new Date (fecha)
      var fecha2 = moment(fecha);
      var fecha3 = fecha2.add(numOfHours, 'hours');
      var fecha4 =  fecha3.format('YYYY-MM-DD');

      return fecha4;

  };

  calcularSemanaSanta(anio) {
  let a = anio % 19;
  let b = Math.floor(anio / 100);
  let c = anio % 100;
  let d = Math.floor(b / 4);
  let e = b % 4;
  let f = Math.floor((b + 8) / 25);
  let g = Math.floor((b - f + 1) / 3);
  let h = (19 * a + b - d - g + 15) % 30;
  let i = Math.floor(c / 4);
  let k = c % 4;
  let l = (32 + 2 * e + 2 * i - h - k) % 7;
  let m = Math.floor((a + 11 * h + 22 * l) / 451);
  let n = Math.floor((h + l - 7 * m + 114) / 31);
  let p = (h + l - 7 * m + 114) % 31;
  let diaPascua = p + 1;
  let mesPascua = n;

  let fechaPascua = new Date(anio, mesPascua - 1, diaPascua);

  // Cálculo de la fecha de Jueves Santo (3 días antes de la fecha de Pascua)
  let juevesSanto = new Date(fechaPascua.getFullYear(), fechaPascua.getMonth(), fechaPascua.getDate() - 3);

  // Cálculo de la fecha de Viernes Santo (2 días antes de la fecha de Pascua)
  let viernesSanto = new Date(fechaPascua.getFullYear(), fechaPascua.getMonth(), fechaPascua.getDate() - 2);

  // Formateo de las fechas en Y-m-d
  let juevesSantoStr = juevesSanto.getFullYear() + '-' + (juevesSanto.getMonth() + 1).toString().padStart(2, '0') + '-' + juevesSanto.getDate().toString().padStart(2, '0');
  let viernesSantoStr = viernesSanto.getFullYear() + '-' + (viernesSanto.getMonth() + 1).toString().padStart(2, '0') + '-' + viernesSanto.getDate().toString().padStart(2, '0');

  // Devolución del objeto con las fechas
  return {
    juevesSanto: juevesSantoStr,
    viernesSanto: viernesSantoStr
  };
}



  validarferiados (fecha){
    let anio = 2023;

    let semanaSanta = this.calcularSemanaSanta(anio);

   // console.log("el jueves santo es "+semanaSanta.juevesSanto);
     var lista = [
             // anio+'-03-06',
             // anio+'-03-06',
             // anio+'-03-07',
              semanaSanta.juevesSanto,
              semanaSanta.viernesSanto,
              anio+'-05-01',
              anio+'-07-06',
              anio+'-29-06',
              //'30/6',
              anio+'-07-28',
              anio+'-07-29',
              anio+'-09-06',
              anio+'-09-30',
              anio+'-10-08',
              // '9/10',
              anio+'-11-01',
              //  '7/12',
              anio+'-12-08',
              anio+'-12-09',
              anio+'-12-25',
              //'26/12',
              anio+'-01-01',
              //'2/1',
            ];

     return lista.includes(fecha);

  }


 addHours(numOfHours, date = new Date()) {

  let anio = 2023;
 // let fechassantas = this.calcularJuevesYViernesSanto(anio);
 

  //console.log(lista);

  var fecha = moment();
  var fecha2 = fecha.add(numOfHours, 'hours');
  var fecha3 =  fecha2.format('YYYY-MM-DD');

  var fecha4 = new Date(fecha3);

  //console.log('la fecha es:'+fecha4+" la otra es "+fecha3);





  while (this.validarferiados(fecha3) || this.esDomingoOLunes(fecha4)){

    fecha3 = this.addHours2(48, fecha3);
    fecha4 = new Date(fecha3);


  }


    return fecha3;
  
}











  render() {
    const {
      products,
      newproducts,
      discountFix,
      promotion,
      subtotal,
      subtotal6,
      total,
      total2,
      total3,
      total4,
      mayor,
      shippingAmount,
      discountShippingAmount,
      hasProvince,
      paymentMethod,
      sending,
      exchangeRate,
    } = this.state;
    
    const paypalEnv = process.env.REACT_APP_PAYPAL_ENV;

    const client = {
      sandbox: process.env.REACT_APP_PAYPAL_KEY_SANDBOX,
      production: process.env.REACT_APP_PAYPAL_KEY_PRODUCTION,
    };

    return (
      <div>
        <Breadcrumb title="Checkout" />

        <div className="container mt-3 mb-3">
          <EventBar />
        </div>

          
            

        <div className="mt-4 container checkout-page">
          <form className="checkout-form row" onSubmit={this.submit}>
          <div className="col-12 col-xl-6 mb-4">
          <div className="border-shadow-custom p-4">

             
                {this.state.activeAdult == 0 && mayor == 1 ? (
                      <div className="noseve text-danger">No podra avanzar en la compra de productos a menos que indique que es mayor de edad o borre los productos para mayoria de edad</div>
                      ): (<div> 
                  <PaymentForm 
                    className=""
                    paymentMethod={paymentMethod}
                    onChangeData={this.handleChangeForm}
                    total={total}
                    total2={total2}
                    total3={total3}
                    total4={total4}
                    has_rut={this.state.has_rut}
                    direction={this.state.form?.address?.id}
                    onChange4={this.onChange5}
                    products={products}
                    isProvince={hasProvince}
                    promotion={this.state.promotion?.code}
                    discountMode={this.state.discountMode}
                   
                    discountTransferenciaDefault={
                      this.state.discountTransferenciaDefault
                    }
                    removeProduct={this.removeProduct}
                    incrementar={this.incrementar}
                    discountFix={discountFix}
                  /> 
                  </div>
                )}
            </div>
         
            <div className="border-shadow-custom p-4" style={{}}>

            <div className="col-12 col-xl-12 mb-12" style={{ textAlign: 'center' }}>
              
                <p className=" mb-2" style={{ color: 'green', fontSize: 16 }}>

                Fecha entrega estimada (LIMA) {this.addHours(60)} <br></br> Fecha entrega estimada (PROVINCIA) {this.addHours(140)}
                </p> 
                <br></br>
               
                 
                

                </div>
            
              <NewProduct items={newproducts}/>
            
            </div>



            </div>
            <div className="col-12 col-xl-6 mb-4">
              <div className="border-shadow-custom p-4" style={{
                
              }}>
              <ProductList
                  products={products}
                  isProvince={hasProvince}
                  promotion={this.state.promotion?.code}
                  discountMode={this.state.discountMode}
                  paymentMethod={paymentMethod}
                  discountTransferenciaDefault={
                    this.state.discountTransferenciaDefault
                  }
                  removeProduct={this.removeProduct}
                  incrementar={this.incrementar}
                  direction={this.state.form?.address?.id}
                  discountFix={discountFix}
                /> 

              <LabelRow label="TOTAL PRODUCTOS" value={subtotal6} />

            {(subtotal6-subtotal) !== 0 ? (
              <LabelRow gradient={false} label="DESCUENTOS" value={subtotal6-subtotal} />
            ): ( 
                  '' 
                )}

              <LabelRow label="SUBTOTAL" value={subtotal} />
                <hr />
                <PromotionCode
                  products={products}
                  total={total4}
                  currentPayment={this.state.current_payment}
                  onFetchCode={this.handleFetchCode}

                />

                <LabelRow label="COSTO DE ENVÍO" gradient={true} value={shippingAmount} />
                {!!discountFix && (
                  <LabelRow
                    label="DESCUENTO FIJO"
                    gradient={false}
                    value={discountFix}
                  />
                )}

                {!!discountShippingAmount && (
                  <LabelRow
                    label="DESCUENTO DE ENVÍO"
                    gradient={false}
                    value={discountShippingAmount}
                  />
                )}
                <LabelRow label="PRECIO FINAL" value={total} />
<br></br>
                 {mayor === 1 ? (
                  <div className="col-12 col-xl-12 mb-12 row">
                  
                    <div className="card border-shadow-custom col-8 col-xl-8 mb-8">
                    
                    <h4 className="bold text-danger">Para Continuar, ¿Es usted mayor de edad?</h4>
                    </div>
                    <div className="content-radio col-4 col-xl-4 mb-4 row">
                    
                      <div className="radio d-inline-flex align-items-center col-12 col-xl-6 mb-6">
                        <h5 className="m-0 mr-2">SÍ</h5>
                        <input
                          name="radioAdult"
                          type="radio"
                          value={1}
                          onChange={() => this.handleChangeRadio(1)}
                        />
                      </div>
                      <div className="radio d-inline-flex align-items-center col-12 col-xl-6 mb-6">
                        <h5 className="m-0 mr-2">NO</h5>
                        <input
                          name="radioAdult"
                          type="radio"
                          value={0}
                          onChange={() => this.handleChangeRadio(0)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}


                {total > 0 && this.state.activeAdult==1 &&(
                  <PaymentMethodSelect
                    paymentMethod={paymentMethod}
                    name="paymentMethod"
                    onChangeData={this.handleChangeForm2}
                    total={total}
                    total2={total2}
                    total3={total3}
                    total4={total4}
                    sending={sending}
                    Setselected={this.onChange2}
                    onChange={this.setPaymentMethod}
                    direction={this.state.form?.address?.id}
                  />
                )}

                {total > 0 && mayor == 0 &&(
                  <PaymentMethodSelect
                    paymentMethod={paymentMethod}
                    onChangeData={this.handleChangeForm2}
                    name="paymentMethod"
                    total={total}
                    total2={total2}
                    total3={total3}
                    total4={total4}
                    sending={sending}
                    Setselected={this.onChange2}
                    onChange={this.setPaymentMethod}
                    direction={this.state.form?.address?.id}
                  />
                )}

                

                {/*<img src={`/assets/images/paypal.png`} alt=""/>*/}
                {paymentMethod === paymentMethods.IZIPAY &&
                  this.state.total === this.props.totalRedux && (
                    <ModalIzipay
                      total={total3}
                      handleIzipayResponse={this.handleIzipayResponse}
                      sendPayment={this.sendPayment}
                      setPaymentMethod={this.setPaymentMethod}
                      size="mmd"
                    />
                  )}

                {paymentMethod === paymentMethods.PAGOEFECTIVO &&
                  this.state.total === this.props.totalRedux && (
                    <ModalPagoEfectivo
                      total={total4}
                      handlePagoEfectivoResponse={this.handlePagoEfectivoResponse}
                      sendPayment={this.sendPayment}
                      setPaymentMethod={this.setPaymentMethod}
                      size="mmd"
                    />
                  )}
                {paymentMethod === paymentMethods.PAYPAL && (
                  <ExchangeRateBanner
                    total={total2}
                    exchangeRate={exchangeRate}
                    onChange={(exchangeRate) => this.setState({ exchangeRate })}
                  />
                )}

                
                {this.state.activeAdult || this.state.activeAdult === null ? (
                  <div className="text-right mt-3">
                    {(!this.validateForm() ||
                      paymentMethod === paymentMethods.TRANSFER) &&
                      total !== 0 ? (
                     <div></div>
                    ) : (
                      !!exchangeRate &&
                      paymentMethod === paymentMethods.PAYPAL && (
                        <PaypalExpressBtn
                          env={paypalEnv}
                          client={client}
                          currency="USD"
                          amount={(total2 / exchangeRate).toFixed(2)}
                          onSuccess={this.handlePayPalSuccess}
                          style={{
                            layout: 'horizontal',
                            tagline: false,
                          }}
                          options={{ clientId: client[paypalEnv] }}
                        />
                      )
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  user: state.user.user,
  totalRedux: state.checkout.total,
  incrementQty,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addToPriceCheckout: (total) => dispatch(addToPriceCheckout(total)),
    removeCart: () => dispatch(removeCart())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
