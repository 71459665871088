import { on } from 'jetemit';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { axios } from '../../services';
import { Globals, Socket } from '../../utils';
import { File, Modal } from '../../widgets';
import CreateChat from './create_support';
import Breadcrumb from '../common/breadcrumb';
import { Modal as ModalB, ModalBody } from 'react-bootstrap';
import './support.css'
class Support extends Component {
  state = {
    chat: null,
    chats: [],
    messages: [],
    message: '',
    unsuscriber: null,
    unsuscriberCreate: null,
    image: null,
    lightBox: { open: false, route: '' },
    makeChat: false,
    loading: true,
    chatLoaded: false
  };

  componentWillUnmount() {
    if (this.state.unsuscriber) {
      this.state.unsuscriber();
    }
    if (this.state.unsuscriberCreate) {
      this.state.unsuscriberCreate();
    }
  }

  componentDidMount() {
    this.loadChats();

    const unsuscriber = on('support-message', async (message) => {
      console.log('messsage', message);
      console.log('chat state', this.state.chat);
      if (this.state.chat && message.support_id === this.state.chat.id) {
        message.viewed = 1;

        await this.setState(
          {
            messages: [...this.state.messages, message],
          },
          this.scrollToBottom
        );
        if (message.user_id != this.props.user.id) {
          await axios.post('chat/viewed', { id: message.id }).catch((err) => {
            console.log(err);
          });
        }
      }
      this.loadChats(false);
    });

    this.setState({
      unsuscriber: unsuscriber,
    });
  }

  loadChats = () => {

    axios
      .all([
        axios.post('/support/chats'),
        // axios.post('chat/count')
      ])
      .then(
        axios.spread((res, count) => {
          console.log(res);
          if (res.data.result) {
            let chatsFilter = res.data.chats.data.filter(
              (i) => i.updated_at != null
            );
            const chats = chatsFilter.sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at);
            });
            this.setState({
              chats,
              chatLoaded: true
            });
            this.props.dispatch({
              type: 'SET_SUPPORT',
              payload: res.data.count,
            });

          }
        })
      )
      .catch((err) => {
        console.log(err);
        Globals.showError();
      })
  };

  loadMessages = () => {

    axios
      .post('support/messages', {
        id: this.state.chat.id,
        user_id: this.props.user.id,
      })
      .then((res) => {
        if (res.data.result) {
          let chats = this.state.chats;
          let index = chats.findIndex((i) => i.id == this.state.chat.id);
          chats[index] = res.data.chat;
          this.setState(
            {
              messages: res.data.chat.messages,
              chats: chats,
            },
            this.scrollToBottom
          );

        }
      })
      .catch((err) => {
        console.log(err);
        Globals.showError();
      })
  };

  setActive = async (chat) => {
    await this.setState({
      chat: chat,
    });
    this.loadMessages();
  };

  getPosition = (message) => {
    const position = message.user_id === this.props.user.id ? 'right' : 'left';
    return position;
  };

  scrollToBottom = () => {
    this.messages.scrollTop = this.messages.scrollHeight;
  };

  send = async (validate = true) => {
    if (validate) {
      if (this.state.message == '') {
        return false;
      }
    }
    let image = null;
    if (this.state.image) {
      await axios
        .upload('support/image', { image: this.state.image })
        .then((res) => {
          if (res.data.result) {
            image = res.data.route;
          }
        })
        .catch((err) => {
          Globals.showError();
        });
    }
    try {
      const params = {
        user_id: this.props.user.id,
        support_id: this.state.chat.id,
        message: !validate ? ' ' : this.state.message,
        file: image,
      };
      console.log(params);
      Socket.emit('support-message', params);
      this.setState(
        {
          message: !validate ? this.state.message : '',
          image: null,
        },
        () => {
          this.loadMessages();
          this.scrollToBottom();
        }
      );
    } catch (e) {
      console.log('>>: error > ', e);
    }
  };

  change = async (e) => {
    const name = e.target.name;
    await this.setState({
      [e.target.name]: e.target.value,
    });
    if (name == 'image') {
      this.send(false);
    }
  };

  onKeyUp = (e) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      this.send();
    }
  };
  closeMakeChat = () => {
    this.setState(
      {
        makeChat: false,
      },
      () => this.loadChats()
    );
  };
  render() {
    /*  const hostName = window.location.hostname;
     console.log(hostName) */
    let BASE_URL = 'https://comprafacil.com.pe/api/';

    return (
      <div>
        {/* <Modal
          title="Crear Chat"
          onClose={() => {
            this.setState(
              {
                makeChat: false,
              },
              () => this.loadChats()
            );
          }}
          visible={this.state.makeChat}
        >
          <CreateChat
            idUser={this.props.user.id}
            onClose={this.closeMakeChat}
            closeModal={this.closeMakeChat}
          />
        </Modal> */}
        <div id="support">
          <div className="container-chat">
            {this.state.makeChat ? <div className="">
              <div>
                <div className="btn btn-solid mb-4" onClick={() =>
                  this.setState({
                    makeChat: false,
                  })
                }>
                  <i className="fa fa-arrow-left text-white position-static mr-2"> </i><span className="font-sand f-sand-300" style={{ textTransform: 'none' }}>Regresar</span>
                </div>
              </div>
              <CreateChat
                idUser={this.props.user.id}
                onClose={this.closeMakeChat}
                closeModal={this.closeMakeChat}
              />
            </div> :
              <div className="chat_soporte_grid">
                <div className="border-shadow-custom mr-0 mb-4 mr-lg-4">
                  <div className="list-chats scroll_custom" style={{ borderRadius: 15 }}>
                    <div
                      className={`item-chat btn btn-primary text-white btn-block pt-4 pb-4`}
                      style={{ borderRadius: '0px 15px 0px 0px' }}
                      onClick={() =>
                        this.setState({
                          makeChat: true,
                        })
                      }
                    >
                      <h2 className="font-sand f-sand-300 text-white">Iniciar un chat con soporte</h2>
                    </div>
                    {this.state.chatLoaded ? (
                      this.state.chats.length !== 0 ? ([
                        ...this.state.chats,
                      ].map((i, index) => {
                        return (
                          <div
                            key={index}
                            className={`item-chat ${this.state.chat && this.state.chat.id === i.id
                              ? 'active'
                              : ''
                              }`}
                            onClick={() => this.setActive(i)}
                          >
                            {i.count > 0 && <div className="badge">{i.count}</div>}
                            <h2 className="font-sand">{'Soporte técnico'}</h2>
                            {i.messages.length > 0 && (
                              <>
                                <p className="font-sand">{i.messages[i.messages.length - 1].message}</p>
                                <p className="date font-sand">
                                  {moment(i.updated_at).format('DD/MM/YYYY HH:mm')}
                                </p>
                              </>
                            )}
                            {!i.messages.length && (
                              <p className="text-dark">No hay mensajes</p>
                            )}
                          </div>
                        );
                      })) : <p className="no-chat text-dark font-sand">Chat vacío</p>
                    ) : <p className="no-chat text-dark font-sand">Cargando chats...</p>}
                  </div>
                </div>
                <div className="border-shadow-custom mb-4">
                  <div className="container-messages">
                    {this.state.chat && (
                      <div className="top-messages" style={{ borderRadius: 15 }}>
                        <h2 className="font-sand" style={{ fontSize: 14 }}>
                          <span className="font-sand">Chat con:</span> Soporte técnico{' '}
                        </h2>
                      </div>
                    )}

                    <div
                      className="container-scroll"
                      ref={(ref) => (this.messages = ref)}
                    >
                      {!this.state.chat && (
                        <h2 className="no-chat font-sand" style={{ textTransform: 'none', fontSize: 16 }}>
                          Por favor, seleccione un chat con soporte
                        </h2>
                      )}

                      {this.state.messages.length !== 0 ? ([
                        ...this.state.messages,
                      ].map((i, index) => {
                        return (
                          <div
                            className={`mb-5 message ${this.getPosition(i)}`}
                            key={index}
                          >
                            <h2 className="font-sand text-dark">{i.message}</h2>
                            {i.file ? (
                              <img
                                src={BASE_URL + i.file}
                                alt={i.message}
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                  maxWidth: '450px',
                                  margin: '5px 0px',
                                }}
                                onClick={() => {
                                  console.log('open');
                                  this.setState({
                                    lightBox: {
                                      open: true,
                                      route: `${BASE_URL}${i.file}`,
                                    },
                                  });
                                }}
                              />
                            ) : null}
                            <p className="font-sand text-dark">
                              {moment(i.created_at).format('DD/MM/YYYY HH:mm')}
                            </p>
                            <div
                              className={`triangle-${this.getPosition(i)}`}
                            ></div>
                            {this.state.lightBox.open && (
                              <ModalB
                                show={this.state.lightBox.open}
                                onHide={() =>
                                  this.setState({ lightBox: { open: false } })
                                }
                                size="md"
                                centered
                              >

                                <ModalBody className="modalbody">
                                  <div className="content-lightBox">
                                    <img alt="" src={this.state.lightBox.route} />
                                  </div>
                                </ModalBody>
                              </ModalB>
                            )}
                          </div>
                        );
                      })) : !this.state.chat ? "" : <h2 className="no-chat font-sand"> Cargando mensajes</h2>}
                    </div>

                    <div className="container-input" style={{ background: 'none' }}>
                      <input
                        disabled={
                          !this.state.chat || this.state.chat.support_status == 0
                        }
                        value={this.state.message}
                        onChange={this.change}
                        name="message"
                        onKeyUp={this.onKeyUp}
                        className="input_custom_40"
                        placeholder="Escribe algo..."
                        style={{ height: 50, width: '100%', marginRight: 10, marginLeft: 10 }}
                      />
                      <button
                        className="btn btn-primary  btn-sm btn_custom"
                        disabled={!this.state.chat}
                        style={{ marginRight: 10 }}
                      >
                        {this.state.chat ? (
                          <File
                            renderItem={
                              <i
                                className="fa fa-paperclip"
                                style={{ fontSize: '20px', marginTop: '3px' }}
                              />
                            }
                            onChange={this.change}
                            name="image"
                            inputStyle={{
                              display: 'contents',
                            }}
                            className="btn-product"
                          />
                        ) : (
                          <i
                            className="fa fa-paperclip"
                            style={{ fontSize: '20px', marginTop: '3px' }}
                          />
                        )}
                      </button>
                      <button
                        className="btn btn-primary  btn-sm btn_custom"
                        onClick={this.send}
                        disabled={
                          !this.state.chat ||
                          this.state.chat.support_status == 0 ||
                          !this.state.message
                        }
                        style={{ marginRight: 10 }}
                      >
                        <i className="fa fa-paper-plane" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  fullUser: state.user,
  user: (state.user && state.user.user) || {},
});

export default connect(mapStateToProps)(Support);
