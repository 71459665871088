import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Icon from './icon';

registerLocale('es', es)
setDefaultLocale('es')

const CustomInput = ({ color, onClick, value }) => (
	<div className={ `container-datepicker ${ color ? color : '' }` } 
		onClick={ onClick }>
		<p>{ value }</p>
		<Icon name="calendar" />
	</div>
)

const _DatePicker = props => (
	<div className="form-group">
		{ props.label && <label className="label-datepicker">{ props.label }</label> }
		<DatePicker
			maxDate={ props.maxDate }
			minDate={ props.minDate }
		    selected={ props.value }
			onChange={ props.onChange }
			showYearDropdown={props.showYearDropdown}
			showMonthDropdown={props.showMonthDropdown}
		    dateFormat="dd/MM/yyyy"
		    customInput={ <CustomInput { ...props } /> }
		/>
	</div>
)

export default _DatePicker;