const user = !!localStorage.getItem('state')
  ? JSON.parse(localStorage.getItem('state'))
  : '';
const userToken = !!user?.user?.access_token ? user?.user?.access_token : null;

export const isLogin = async () => {
    let loading = false;
    if(!!userToken){
        loading = true;
    }
    console.log('isLoading', loading)
    return await loading
}

