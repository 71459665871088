import React, { Component } from 'react';
import { RegionService } from '../../services';
import { connect } from 'react-redux';
import InputSelect from '../form/InputSelect';
import Select from 'react-select'

class DistrictInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],  
      region: null,
      provinces: [],
      province: null,
      districts: [],
      district: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchRegions().then();
  }

  fetchRegions = async () => {
    if (!this.props.regions.length) this.setState({ loading: true });
    try {
      const regions = await RegionService.get();
      this.setState({ regions }, () => {
        if (this.props.district) this.setDistrict();
      });
    } catch (e) {
      alert('Disculpe ha ocurrido un error cargando las regions');
    } finally {
      this.setState({ loading: false });
    }
  };

  setDistrict = () => {
    const region =
      (this.props.district &&
        this.props.district.region &&
        this.state.regions.find(
          (element) => element.id === this.props.district.region.id
        )) ||
      null;
    const province =
      (region &&
        region.provinces &&
        region.provinces.find(
          (element) => element.id === this.props.district.province.id
        )) ||
      [];
    const districts = province.districts || [];
    this.setState({
      region,
      province,
      provinces: this.props.region?.provinces ? this.props.region?.provinces : region.provinces || [],
      districts,
      province: (this.props.district && this.props.district.province) || null,
      district: this.props.district || null,
    });
  };

  handleChangeRegion = (region) => {
    this.setState({
      region,
      province:null,
      district:null,
      provinces: region.provinces,

    });
   // this.handleChangeProvince(province);
   // this.handleChangeProvince(province)
  };


  handleChangeProvince = (province) =>{
    this.onProvinceChange(province);
    this.setState({
      province,
      district:null,
      districts: province.districts,
    });
  };

  handleChangeDistrict = (district) => {
    this.onDistrictChange(district);
    this.setState({ district });
  };

  onDistrictChange = (district) => {
    const { onDistrictChange, onChange, name } = this.props;
    const value = !district ? null : district.id;
    if (onDistrictChange) onDistrictChange(value);
    if (onChange && name) onChange(district, name);
  };

  onProvinceChange = (province) => {
    const { onProvinceChange, onChange, name } = this.props;
    const value = !province ? null : province.id;
    if (onProvinceChange) onProvinceChange(value);
    if (onChange && name) onChange(province, name);

    console.log('aqui estamos:' + province)
  };

  render() {

    const { column = false } = this.props;
    const className = column ? 'mb-2' : 'col-4';

    const styleSelect = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        display: 'flex',
        border: '1px solid #DDDDDD',
        borderRadius: 5,
        height: 60,
        padding: '0px 15px'
      }),
      option: (provided, state) => ({
        ...provided,
        fontWeight: '500'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontWeight: '500',
        padding: 0
      }),
    }

    const {
      regions,
      region,
      provinces,
      province, 
      districts,
      district,
    } = this.state;
    return this.props.addDivParent ? <>
      <div className={this.props.classNameParent}>
        <label className="font-sand text-muted">Seleccione su región</label>
        <Select
          label="Seleccione su región"
          value={this.props.region ? this.props.region : region}
          options={regions}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeRegion ? this.props.handleChangeRegion : this.handleChangeRegion}
          styles={styleSelect}
        />
      </div>

      <div className={this.props.classNameParent}>
        <label className="font-sand text-muted">Seleccione su provincia</label>
        <Select
          label="Seleccione su provincia"
          value={this.props.province ? this.props.province : province}
          options={this.props.provinces ? this.props.provinces : provinces}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeProvince ? this.props.handleChangeProvince : this.handleChangeProvince}
          styles={styleSelect}
        />
      </div>

      <div className={this.props.classNameParent}>
        <label className="font-sand text-muted">Seleccione su distrito</label>
        <Select
          label="Seleccione su distrito"
          value={this.props.district ? this.props.district : district}
          options={this.props.districts ? this.props.districts : districts}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeDistrict ? this.props.handleChangeDistrict : this.handleChangeDistrict}
          styles={styleSelect}
        />
      </div>
    </> :
      <>
        <Select
          label="Seleccione su región"
          className={className}
          value={this.props.region ? this.props.region : region}
          options={regions}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeRegion ? this.props.handleChangeRegion : this.handleChangeRegion}
          styles={styleSelect}
        />

        <Select
          label="Seleccione su provincia"
          className={className}
          value={this.props.province ? this.props.province : province}
          options={this.props.provinces ? this.props.provinces : provinces}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeProvince ? this.props.handleChangeProvince : this.handleChangeProvince}
          styles={styleSelect}
        />

        <Select
          label="Seleccione su distrito"
          className={className}
          value={this.props.district ? this.props.district : district}
          options={this.props.districts ? this.props.districts : districts}
          required={true}
          getOptionLabel={(it) => it.name}
          noOptionsMessage={_ => 'No hay registros'}
          onChange={this.props.handleChangeDistrict ? this.props.handleChangeDistrict : this.handleChangeDistrict}
          styles={styleSelect}
        />
      </>
  }
}

const mapStateToProps = (state) => ({
  regions: state.regions,
});

export default connect(mapStateToProps)(DistrictInput);
