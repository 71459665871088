import React, { Component } from 'react';
import Slider from 'react-slick';
import { Slider6 } from "../../../services/script";
import ReactStars from "react-rating-stars-component";
import moment from 'moment'
class LogoBlock extends Component {

    render() {
        return (

            <div className="d-flex flex-column w-100">
                {
                    this.props.items.map((element, i) => {
                        return (
                            <div className="card w-100 mb-4" style={{ border: 0 }}>
                                <div className="card-body border-shadow-custom">
                                    <div className="logo-block mb-2" key={i}>
                                        {
                                            element.rating && (
                                                <ReactStars
                                                    value={element.rating}
                                                    edit={false}
                                                />
                                            )
                                        }
                                        <div className="row">
                                            {
                                                element.user && element.user.uri && (
                                                    <div className="col-4">
                                                        <img
                                                            src={element.user.uri}
                                                            alt={element.user.name}
                                                            className="rounded-circle "
                                                            style={{
                                                                width: 40,
                                                                height: 40
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            <div className="col-8">
                                                {
                                                    element.user && (
                                                        <h4 className="text-dark font-sand"> {element.user.name} </h4>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            element.message && (
                                                <p style={{  }} className="text-dark font-sand mb-3"> {element.message} </p>
                                            )
                                        }
                                        {
                                            element.created_at && (
                                                <span className=" font-sand" style={{ fontSize: 12 }}> {moment(element.created_at).format('DD-MM-YYYY HH:mm A')} </span>
                                            )
                                        }
                                        {
                                            element.uri && (
                                                <img src={element.uri} alt="" className="metodo_pago_formas" style={{ maxWidth: 300 }} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        )
    }
}

export default LogoBlock;