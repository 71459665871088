import React, { Component } from 'react';
import ConditionFilter from './ConditionFilter';
import './search.css';
import Params from './Params';
import AttributeFilter from './AttributeFilter';
import { Globals } from '../../utils';
import categoryService from '../../services/modules/categoryService';
import { toast } from 'react-toastify';
import CategoryFilter from './CategoryFilter';

function mapAttributesToArrays(attributes) {
  const _attributes = attributes
    .map((it) => ({
      id: it.id,
      value: it.value,
      min: it.minValue,
      max: it.maxValue,
      unit: it.unit,
    }))
    .filter((it) => !!it.value || !!it.min || !!it.max || !!it.unit);

  const aI = [];
  const aV = [];
  const aU = [];
  const aG = [];
  const aL = [];

  function valueToString(input) {
    if (typeof input === 'string') return input;
    if (Array.isArray(input)) return input.join(',');
  }

  _attributes.forEach((it) => {
    aI.push(it.id || '');
    aV.push(valueToString(it.value) || '');
    aU.push(it.unit || '');
    aG.push(it.min || '');
    aL.push(it.max || '');
  });
  return { aI, aV, aU, aG, aL };
}

class LateralBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      attributes: [],
      attributeValues: [],
      count: 0,
      params: [],
      condition: null,
      category: null,
      subcategory: null,
      can_go_to_province: 0,
      in_offer: 0,
      showCategories: false, // Estado para controlar la visibilidad de las categorías
    };
  }

  componentDidMount() {
    this.fetchCategories().then();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.query !== this.props.query) {
      const oldQuery = prevProps.query;
      const newQuery = this.props.query;

      if (oldQuery.subcategory_id !== newQuery.subcategory_id) {
        if (newQuery.subcategory_id) {
          this.onUpdateSubcategoryId(newQuery.subcategory_id);
        }
      }
    }
  }

  onUpdateSubcategoryId = (subcategory_id) => {
    const estateSubcategoryId = this.state.subcategory
      ? this.state.subcategory.id
      : null;
    if (subcategory_id !== estateSubcategoryId) {
      this.setSubcategory(
        this.state.categories.find((it) => it.id === subcategory_id)
      );
    }
  };

  fetchCategories = async () => {
    Globals.setLoading();
    try {
      const categories = await categoryService.search({
        enable: 1,
        with: ['attributes.values', 'attributes.units'],
      });
      this.setState({ categories });
    } catch (e) {
      toast.error('Disculpe ha ocurrido un error');
    } finally {
      Globals.quitLoading();
    }
  };

  handleChangeParams = (params) => this.setState({ params });

  handleChangeQuery = (param) => {
    const { onChangeQuery } = this.props;
    if (!onChangeQuery) return;
    onChangeQuery({
      ...this.props.query,
      ...param,
    });
  };

  setCondition = (condition) => {
    let params = [...this.state.params];
    let query = { ...this.props.query };
    const type = 'condition';
    if (condition != null) {
      params = this.mutateParams(params, {
        type,
        key: condition ? 'Nuevo' : 'Usado',
        value: condition,
      });
      query.condition = condition;
    } else {
      params = this.removeParamsByType(params, type);
      delete query.condition;
    }
    this.setState({ condition, params }, () => this.props.onSetQuery(query));
  };

  setCategory = (category) => {
    console.log('hola');
    let { params, subcategory, attributes } = this.state;
    let query = { ...this.props.query };
    const type = 'category_id';

    if (category != null) {
      params = this.mutateParams(params, {
        type,
        key: category.name,
        value: category.id,
      });

      query.category_id = category.id;
    } else {
      attributes = [];
      params = this.removeParamsByType(params, type);
      delete query.category_id;

      if (this.state.subcategory) {
        params = this.removeParamsByType(params, 'subcategory_id');
        delete query.subcategory_id;
        query = this.removeAttributeQueries(query);
        subcategory = null;
      }
    }
    this.setState({ category, params, subcategory, attributes }, () =>
      this.props.onSetQuery(query)
    );
  };

  setSubcategory = (subcategory) => {
    let { params, category, categories, attributes } = this.state;
    let query = { ...this.props.query };
    const type = 'subcategory_id';

    if (subcategory != null) {
      params = this.mutateParams(params, {
        type,
        key: subcategory.name,
        value: subcategory.id,
      });
      query.subcategory_id = subcategory.id;
      attributes = subcategory.attributes || [];

      if (!category || subcategory.category_id !== category.id) {
        const _category = categories.find(
          (it) => it.id === subcategory.category_id
        );
        params = this.mutateParams(params, {
          type: 'category_id',
          key: _category.name,
          value: _category.id,
        });
        query.category_id = _category.id;
      }
    } else {
      attributes = [];
      params = this.removeParamsByType(params, type);
      delete query.subcategory_id;
      query = this.removeAttributeQueries(query);
    }
    this.setState({ subcategory, params, attributes, category }, () =>
      this.props.onSetQuery(query)
    );
  };

  setAttributes = (attributes) => {
    const array = mapAttributesToArrays(attributes);
    const query = {
      ...this.props.query,
      ...array,
    };
    this.setState({ attributes }, () => this.props.onSetQuery(query));
  };

  mutateParams = (params, param) => {
    const _params = [...params];
    const index = _params.findIndex(({ type }) => type === param.type);
    if (index < 0) {
      _params.push(param);
    } else {
      _params[index] = param;
    }
    return _params;
  };

  onChange = (key, value) => {
    this.setState(() => {
      return {
        ...this.state,
        [key]: value,
      };
    });
  };

  ImageOptimized = (e) => {
    let { params, attributes } = this.state;
    let query = { ...this.props.query };
    const type = 'can_go_to_province';
    if (this.state.can_go_to_province == 0) {
      this.onChange('can_go_to_province', 1);
      params = this.mutateParams(params, {
        type,
        key: 'se envia a provincia',
        value: 1,
      });
      query.can_go_to_province = 1;

      this.setState({ params, attributes }, () =>
        this.props.onSetQuery(query)
      );
    } else {
      this.onChange('can_go_to_province', 0);
      attributes = [];
      params = this.removeParamsByType(params, 'can_go_to_province');
      delete query.can_go_to_province;
      query = this.removeAttributeQueries(query);

      this.setState({ params, attributes }, () =>
        this.props.onSetQuery(query)
      );
    }
  };

  ImageOptimized2 = (e) => {
    let { params, attributes } = this.state;
    let query = { ...this.props.query };
    const type = 'in_offer';
    if (this.state.in_offer == 0) {
      this.onChange('in_offer', 1);
      params = this.mutateParams(params, {
        type,
        key: 'en oferta',
        value: 1,
      });
      query.in_offer = 1;

      this.setState({ params, attributes }, () =>
        this.props.onSetQuery(query)
      );
    } else {
      this.onChange('in_offer', 0);
      attributes = [];
      params = this.removeParamsByType(params, 'in_offer');
      delete query.in_offer;
      query = this.removeAttributeQueries(query);

      this.setState({ params, attributes }, () =>
        this.props.onSetQuery(query)
      );
    }
  };

  removeAttributeQueries = (_query) => {
    const query = { ..._query };
    delete query.aI;
    delete query.aV;
    delete query.aU;
    delete query.aG;
    delete query.aL;
    return query;
  };

  removeParamsByType = (params, type) => {
    const _params = [...params];
    return _params.filter((param) => param.type !== type);
  };

  toggleCategories = () => {
    this.setState((prevState) => ({
      showCategories: !prevState.showCategories,
    }));
  };

  render() {
    const {
      condition,
      categories,
      category,
      subcategory,
      params,
      attributes,
      showCategories,
    } = this.state;
    return (
      <div className="lateral-bar card_custom border-shadow-custom" id="lateral-bar">
        <Params
          params={params}
          onClearCondition={this.setCondition}
          onClearCategory={this.setCategory}
          onClearSubcategory={this.setSubcategory}
        />

        <ConditionFilter
          condition={condition}
          onChangeCondition={this.setCondition}
        />

        {/* Botón de alternancia para mostrar/ocultar categorías */}
        <button className="toggle-categories" onClick={this.toggleCategories}>
          {showCategories ? 'Ocultar categorías' : 'Mostrar categorías'}
        </button>

        {/* Renderizado condicional de las categorías */}
        {showCategories && (
          <CategoryFilter
            category={category}
            categories={categories.filter((it) => !it.category_id)}
            setSubcategory={this.setSubcategory}
            subcategory={subcategory}
            onChangeCategory={this.setCategory}
          />
        )}

        {/* Resto del código para los atributos y funcionalidades... */}
      </div>
    );
  }
}

export default LateralBar;