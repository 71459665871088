import React, {useState} from "react";
import Image from "../../widgets/Image";

const ProductCell = ({product, onClick, selected}) => {
  if (!product) return null;
  
  let height = 0;
  let [element, setElement] = useState(null)
  
  if (!!element && !height) height = element.clientWidth
  
  const handlePress = () => {
    if (!onClick) return
    onClick(product)
  }
  
  return <div
    ref={ it => {
      if (element) return
      element = it;
      setElement(element)
    }}
    className="p-2 product_polos_image"
    style={{
      height: 130,
      minWidth: '14%'
    }}>
  
    <Image
      onClick={handlePress}
      style={{
        cursor: 'pointer',
        width: '50%',
        height: '100%',
        borderRadius: 10,
        border: selected ? '2px solid #093467' : 'none'
      }}
      url={product.images[0].uri}/>

      <div>
        <span>{product.name}</span>
      </div>
    
  </div>
  
}

export default ProductCell
