import {axios} from '../axios';

const END_POINT = 'auth';

const login = async (data) => {
    try{
        const r = await axios.post(`${END_POINT}/login`, data);
        const user = await r.data;
        return user;
    }catch(e){
        throw e
    }
}

const register = async form => {
    try {
        const r = await axios.post(`${END_POINT}/register`, form);
        return r.data
    } catch (e) {
        if (e.response) {
            const data = e.response.data
            throw data.msg
        } else if (e.message) {
            throw e.message
        }
        throw e
    }
}
const registerHash = async hash => {
    try {
        const r = await axios.get(`client_active/${hash}`);
        console.log(r)
        return r.data
    } catch (e) {
        if (e.response) {
            const data = e.response.data
            throw data.msg
        } else if (e.message) {
            throw e.message
        }
        throw e
    }
}
const registerVendor = async form => {
    try {
        const r = await axios.post(`${END_POINT}/register-vendor`, form);
        return r.data
    } catch (e) {
        if (e.response) {
            const data = e.response.data
            throw data.msg
        } else if (e.message) {
            throw e.message
        }
        throw e
    }
}

const registerReseller = async form => {
    try {
        const r = await axios.post(`${END_POINT}/register-reseller`, form);
        return r.data
    } catch (e) {
        if (e.response) {
            const data = e.response.data
            throw data.msg
        } else if (e.message) {
            throw e.message
        }
        throw e
    }
}

export default {
    login,
    register,
    registerVendor,
    registerReseller,
    registerHash
}
