import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import {IntlProvider} from 'react-redux-multilingual'
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations'

// Layouts
import Fashion from './components/layouts/fashion/main';
import Kids from './components/layouts/kids/main';
import Watch from './components/layouts/watch/main';
import Beauty from './components/layouts/beauty/main';

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
import CollectionFullWidth from "./components/collection/collection-full-width";
import CollectionMetro from "./components/collection/collection-metro";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";
import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import Compare from './components/compare/index'
import wishList from './components/wishlist'
import checkOut from './components/checkout'
import newCheckout from './components/checkout/Checkout'
import orderSuccess from './components/checkout/success-page'

// Extra Pages
import aboutUs from './components/pages/about-us'
import Unsuscribe from './components/pages/unsuscribe'
import PageNotFound from './components/pages/404'
import lookbook from './components/pages/lookbook'
import Login from './components/pages/login'
import Register from './components/pages/register'
import VerifyAccount from './components/pages/verify-account';
import Search from './components/pages/search'
import Collection from './components/pages/collection'
import ForgetPassword from './components/pages/forget-password'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'

// Blog Pages
import RightSide from './components/blogs/right-sidebar'
import Details from './components/blogs/details'
import BlogPage from './components/blogs/blog-page'

// Theme Element
import ElementTitle from "./components/features/theme/element-title"
import ElementBanner from "./components/features/theme/element-banner";
import ElementSlider from "./components/features/theme/element-slider";
import ElementCategory from "./components/features/theme/element-category";
import ElementService from "./components/features/theme/element-service";
import ElementRatio from "./components/features/theme/element-ratio";
import ElementProductBox from "./components/features/product/element-product-box"
import ElementProductSlider from "./components/features/product/element-product-slider"
import ElementProductNoSlider from "./components/features/product/element-product-no-slider"
import ElementMultipleSlider from "./components/features/product/element-multiple-slider"
import ElementProductTab from "./components/features/product/element-product-tab"
import RegisterHidden from './components/register/Register'
import SearchPage from './components/search/Search';
import {Socket} from './utils'
import { emit } from "jetemit";
import GridCols from "./components/features/portfolio/grid-cols"
import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols"
import Cloth from './components/cloths/cloths';
import FormSeller from './components/forms/seller/beSeller';
import Profile from './components/profile/profile';
import Chat from './components/chat/chat'
import Support from './components/chat/support'
import CreateSupport from './components/chat/create_support'
import Shops from './components/pages/shops'
import {CategoryService} from './services'
import Custom from "./components/custom/Custom";
import RegisterReseller from "./components/register-reseller/RegisterReseller";
import Conditions from "./components/conditions/Conditions";
import Legales from "./components/Legales/legales";
import PrivateRoute from './utils/RoutePrivate';
import RegisterHash from './components/pages/registerHash';

class Root extends React.Component {
  
    componentDidMount() {
      CategoryService.getAll({
          with: ['subcategories']
      })
      Socket.on("message", data => emit("message", data));
      Socket.on("support-message", data => emit("support-message", data));
    }


    render() {
      return(
        <Provider store={store}>
          <IntlProvider translations={translations} locale='es'>
            <BrowserRouter basename={'/'} >
            <ScrollContext>
              <Switch>
                
                <Route exact path={`/`} component={Kids}/>
                <Route path={`/model-2`} component={Watch}/>
                <Route path={`/model-3`} component={Beauty}/>
                <Route path={`/cloths`} component={Cloth}/>
                
                <Layout>
  
                  <Route path="/condiciones" component={Conditions}/>
                  {/*Routes For Layouts*/}
                  <Route path={`/fashion`} component={Fashion}/>
                  <Route path={'/reseller'} component={RegisterReseller}/>
                  <Route path={'/registrarse'} component={RegisterHidden}/>
                  <Route path={'/pages/registerhash/:hash'} component={RegisterHash}/>
                  <Route path={'/buscar'} component={SearchPage}/>
                  <Route path={'/polos'} component={Custom}/>
                  {/*Routes For Features (Product Collection) */}
                  <Route path={`/left-sidebar/collection`} component={CollectionLeftSidebar}/>
                  <Route path={`/no-sidebar/collection`} component={CollectionNoSidebar}/>
                  <Route path={`/right-sidebar/collection`} component={CollectionRightSidebar}/>
                  <Route path={`/full-width/collection`} component={CollectionFullWidth}/>
                  <Route path={`/metro/collection`} component={CollectionMetro}/>
                  {/*Routes For Single Product*/}
                  <Route path={`/detalle/productos/:id`} component={LeftSideBar}/>
                  <Route path={`/right-sidebar/product/:id`} component={RightSideBar}/>
                  <Route path={`/no-sidebar/product/:id`} component={NoSideBar}/>
                  <Route path={`/col-left/product/:id`} component={ColumnLeft}/>
                  <Route path={`/col-right/product/:id`} component={ColumnRight}/>
                  <Route path={`/accordian/product/:id`} component={Accordian}/>
                  <Route path={`/column/product/:id`} component={Column}/>
                  <Route path={`/left-image/product/:id`} component={LeftImage}/>
                  <Route path={`/right-image/product/:id`} component={RightImage}/>
                  <Route path={`/vertical/product/:id`} component={Vertical}/>
                  {/*Routes For custom Features*/}
                  <Route path={`/carrito`} component={Cart}/>
                  <Route path={`/listadeseos`} component={wishList}/>
                  <Route path={`/compare`} component={Compare}/>
                  <Route path={`/checkout`} component={newCheckout}/>
                  {/*<Route path={`/new-checkout`} component={newCheckout}/>*/}
                  <Route path={`/order-success`} component={orderSuccess}/>
                  <Route path={`/sales/orders`} component={aboutUs}/>
                  <Route path={`/unsuscribe`} component={Unsuscribe}/>
                  {/*Routes For Extra Pages*/}
                  <Route path={`/pages/about-us`} component={aboutUs}/>
                  <Route path={`/pages/404`} component={PageNotFound}/>
                  <Route path={`/pages/lookbook`} component={lookbook}/>
                  <Route path={`/pages/login`} component={Login}/>
                  <Route path={`/pages/loginR/:rol`} component={Login}/>

                  <Route path={`/pages/register`} component={Register}/>
                  <Route path={`/pages/search`} component={Search}/>
                  <Route path={`/pages/collection`} component={Collection}/>
                  <Route path={`/pages/forget-password/:token?`} component={ForgetPassword}/>
                  <Route path={`/paginas/contacto`} component={Contact}/>
                  <PrivateRoute path={`/paginas/tablero`} component={Dashboard}/>
                  <Route path={`/pages/faq`} component={Faq}/>
                  <Route path={`/pages/profile`} component={Profile}/>
                  <Route path={`/pages/chat`} component={Chat}/> 
                  <Route path={`/pages/support`} component={Support}/>
                  <Route path={`/pages/create-support`} component={CreateSupport}/>
                  <Route path={`/pages/verify-account`} component={VerifyAccount}/>
                  <Route path={`/catalogo/:user_id`} component={Shops}/>
                  <Route path={`/forms/seller`} component={FormSeller}/>

                  {/*Features*/}
                  {/*Theme Elements*/}
                  <Route path={`/features/element-title`} component={ElementTitle}/>
                  <Route path={`/features/element-banner`} component={ElementBanner}/>
                  <Route path={`/features/element-slider`} component={ElementSlider}/>
                  <Route path={`/features/element-category`} component={ElementCategory}/>
                  <Route path={`/features/element-service`} component={ElementService}/>
                  <Route path={`/features/element-ratio`} component={ElementRatio}/>
      
                  {/*Product Elements*/}
                  <Route path={`/features/element-product-box`} component={ElementProductBox}/>
                  <Route path={`/features/element-product-slider`} component={ElementProductSlider}/>
                  <Route path={`/features/element-product-no-slider`} component={ElementProductNoSlider}/>
                  <Route path={`/features/element-product-multiple-slider/:category_id?`} component={ElementMultipleSlider}/>
                  <Route path={`/features/element-product-tab`} component={ElementProductTab}/>
      
                  {/*Portfolios*/}
                  <Route path={`/features/portfolio-grid/:columns`} component={GridCols}/>
                  <Route path={`/features/portfolio-masonary/:columns`} component={MasonaryGridCols}/>
      
                  {/*Blog Pages*/}
                  <Route path={`/blog/right-sidebar`} component={RightSide}/>
                  <Route path={`/blog/details`} component={Details}/>
                  <Route path={`/blog/blog-page`} component={BlogPage}/>


                  <Route path={`/legales`} component={Legales}/>
                  
                </Layout>
                
               </Switch>
              </ScrollContext>
            </BrowserRouter>
          </IntlProvider>
        </Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
