import Axios from "axios";
import { BASE_URL } from "../utils";
import store from "../store";

let axios = Axios.create({
  baseURL: `${BASE_URL || ""}`,
});
function createFormData(data) {
  var formdata = new FormData();
  for (var key in data) {
      formdata.append(key, data[key]);
  }
  return formdata;
}
axios.all = Axios.all;
axios.spread = Axios.spread;
axios.upload = (url,data) => {
  return axios.post(url,createFormData(data),{
    headers: {
              'Content-Type': 'multipart/form-data'
        }
  });
}
axios.interceptors.request.use(
  (config) => {
    const { user } = store.getState();
    if (user && user.access_token) {
      config.headers["Authorization"] = `Bearer ${user.access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function objectToFormData(object) {
  const formData = new FormData();
  for (const property in object) {
    const value = object[property];
    if (typeof value === "undefined") {
      continue;
    }

    if (typeof value === "number" || typeof value === "boolean") {
      formData.append(property, value.toString());
    } else {
      formData.append(property, value);
    }
  }

  return formData;
}
export{
  axios
}