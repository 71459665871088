import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeFromCart } from '../actions';
import CartPage from '../components/common/headers/common/cart-header';
import { getCartTotal } from '../services';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet'


//const user = this.props.user?.user?.id;
const CartContainer  = ({ cartList, symbol, removeFromCart, usuario }) => {

  
    
  const subtotal = ({ price, qty }) => (parseFloat(price) * qty).toFixed(2);
  
  const total = (items) =>
    items
      .map((it) => parseFloat(subtotal(it)))
      .reduce((accumulator, it) => accumulator + it, 0)
      .toFixed(2);
  let qtyArr = [];

  function mapCartList() {
    cartList.map((item) => {
      qtyArr.push(item.qty);
    });
  }
  mapCartList();
  let qtyTotal = qtyArr.reduce((a, b) => a + b, 0);
  return (
    <li className="onhover-div mobile-cart">
      <div className="cart-qty-cls">{!!qtyTotal ? qtyTotal : 0}</div>
      <Link to={`/carrito`}>
        <img
          src={'/assets/images/icon/icon-cart.png'}
          id="icon_cart_mobile"
          className="img-fluid"
          alt=""
        />
      </Link>
      <ul
        className="show-div shopping-cart card-dropdown-custom"
        style={{ minWidth: 380, maxHeight: 400, padding: '30px 20px' }}
      >
        {cartList.map((item, index) => (
          <CartPage
            key={index}
            item={item}
            total={total}
            symbol={symbol}
            removeFromCart={() => removeFromCart(item)}
          />
        ))}
        {cartList.length > 0 ? (
          <div>
            <li>
              <div className="total">
                <h5>
                  Precio Total:{' '}
                  <span className="text-primary font-weight-bold">
                    {symbol}
                    {total(cartList)}
                  </span>
                </h5>
              </div>
            </li>
            <li>
              <div className="buttons">
                <Link to={`/carrito`} className="view-cart">
                  <div
                    className="btn btn-outline-primary outline-primary-custom text-primary"
                    style={{ width: 150, padding: 8 }}
                  >
                    Ver carrito
                  </div>
                </Link>
                {usuario == 5 || usuario == undefined ? (
                  <Link to={`/pages/login`} className="checkout">
                  <div
                    className="btn btn-primary"
                    style={{ width: 150, padding: 8 }}
                  >
                    checkout
                  </div>
                    </Link>
                    
                ) : (
                  <Link to={`/checkout`} className="checkout">
                  <div
                    className="btn btn-primary"
                    style={{ width: 150, padding: 8 }}
                  >
                    checkout
                  </div>
                  </Link>
                )}

                  
                
               
                
              </div>
            </li>
          </div>
        ) : (
          <li>
            <h5>Su carrito esta vacio.</h5>
          </li>
        )}
      </ul>
    </li>
  );
};

function mapStateToProps(state) {
  return {
    cartList: state.cartList.cart,
    symbol: state.data.symbol,
  };
}

export default connect(mapStateToProps, { removeFromCart })(CartContainer);
