const environments = {
    local: 0,
    production: 1
}

let environment = environments.local;
if(window.location.hostname === 'comprafacil.com.pe')
    environment = environments.production

const getBaseUrl = () => {
    switch (environment) {
        case environments.local:
            return process.env.REACT_APP_API_URL_TEST;
        default:
            return 'https://'+window.location.hostname+'/api/web';
    }
}
const APP_NAME = 'Marketplace';
const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;
const STATUS_SELLER_PENDING_TO_ACCEPT = 2;
const STATUS_COMISSIONIST_PENDING_TO_ACEEPT = 3;
const PROMOTION_CODE_SHIPPING = 1;
const PROMOTION_CODE_FIXED_AMOUNT = 2;
const PROMOTION_CODE_PERCENTAGE = 3;
const Status = {
    default: [
        {label: 'Activo', value: STATUS_ACTIVE},
        {label: 'Inactivo', value: STATUS_INACTIVE}
    ]
}
const ROLE_ADMIN = 1;
const ROLE_SELLER = 2;
const ROLE_CLIENT = 3;
const ROLE_RESELLER = 4;
const Roles = [
    {
        label: 'Administrador',
        value: ROLE_ADMIN,
    },
    {
        label: 'Vendedor',
        value: ROLE_SELLER
    },
    {
        label: 'Clientes',
        value: ROLE_CLIENT
    },
    {
        label: 'Comisionista',
        value: ROLE_RESELLER
    },
]
const PAYMENT_TYPE_NIUBIZ = 1;
const PAYMENT_TYPE_PAYPAL = 2;
const PAYMENT_TYPE_TRANSFER = 3;
const BASE_URL = getBaseUrl();
const IN_LIMA_SHIPPING = 7
const OUTSIDE_LIMA_SHIPPING = 12
const LIMA_PROVINCE = '150100'
const LIMA_REGION = '150000'
export {
    LIMA_REGION,
    LIMA_PROVINCE,
    IN_LIMA_SHIPPING,
    OUTSIDE_LIMA_SHIPPING,
    PAYMENT_TYPE_NIUBIZ,
    PAYMENT_TYPE_PAYPAL,
    PAYMENT_TYPE_TRANSFER,
    ROLE_RESELLER,
    ROLE_CLIENT,
    ROLE_SELLER,
    ROLE_ADMIN,
    PROMOTION_CODE_SHIPPING,
    PROMOTION_CODE_FIXED_AMOUNT,
    PROMOTION_CODE_PERCENTAGE,
    STATUS_COMISSIONIST_PENDING_TO_ACEEPT,
    STATUS_SELLER_PENDING_TO_ACCEPT,
    APP_NAME,
    STATUS_INACTIVE,
    STATUS_ACTIVE,
    BASE_URL,
    Roles,
    Status
}
