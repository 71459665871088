let BASE_URL = 'https://comprafacil.com.pe/api/web';
if (window.location.hostname === 'comprafacil.com.pe') {
  BASE_URL = 'https://comprafacil.com.pe/api/web';
} else {
  BASE_URL = 'https://comprafacil.com.pe/api/web';
}
const INFO_USER = `${BASE_URL}/users/get-user-data/`;
const OFFERS = `${BASE_URL}/offerspecial`;
const WISH_LIST = `${BASE_URL}/wishlist`;
const WISH_LIST_DELETE = `${BASE_URL}/wishlist/delete`;
const SHARED_RS = `${BASE_URL}/socialshared`;
const SUGGEST = `${BASE_URL}/suggestions`;
const TOKEN_FORM_IZIPAY = `${BASE_URL}/paytest`;

export {
  INFO_USER,
  OFFERS,
  WISH_LIST,
  WISH_LIST_DELETE,
  SHARED_RS,
  SUGGEST,
  TOKEN_FORM_IZIPAY,
};
