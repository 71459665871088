import React, { Component, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Pace from 'react-pace-progress'
import NavBar from "./common/navbar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import { changeCurrency } from '../../../actions'
import { connect } from "react-redux";
import categoryService from "../../../services/modules/categoryService";
import { withRouter } from 'react-router-dom';
import { on } from 'jetemit';
import { ROLE_SELLER, ROLE_RESELLER, Globals } from '../../../utils';
import { EventService } from '../../../services';
import { AuthService } from '../../../services';
import moment from 'moment';
import './common/topbar.css';

// https://comprafacil.com.pe/api/web/products/search?perPage=12&page=1&search=ps5

const Search = ({ className, classNameParent }) => {

  const [search, setSearch] = useState('')
  const history = useHistory()

  const handleChange = e => {

   // let texto = e.target.value;

   //let resultado = texto.replace("a", "b");

   // console.log(resultado)
    setSearch(e.target.value)
  }

  const handleClick = e => {
  e.preventDefault();

  

  const newSearch = `${search.replace('#', '$')}`;

  // Actualiza la URL utilizando push en lugar de replace
  history.push(`/buscar?search=${newSearch}`);
  window.location.reload();
}

  return (
   <div className="row">
    <div className="col col-md-9 col-xs-9">
      <form autoComplete="off">
        <div className="form-group" style={{ margin: 0 }}>
          <input
            type="text"
            className={className + ' font-sand form-control'}
            id="exampleInputPassword1"
            placeholder="Buscar un producto"
            value={search}
            autoComplete="new-password"
            onChange={handleChange} />
        </div> 
      </form>
    </div>
    <div className="col-auto col-md-3 col-xs-3">
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!search}
        onClick={handleClick}
        style={{ marginTop: 1.8, marginLeft: 10 }}>
        <i className="fa fa-search" />
      </button>
    </div>
  </div>
  )

}

Search.defaultProps = {
  className: '',
  classNameParent: 'd-none d-lg-block'
}

class HeaderOne extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      search: '',
      categories: [],
      subcategories: [],
      categorySelected: null,
      visibleCategories: false,
      showNav: false,
      events: [],
      event_text: '',
      hoveredCategory: null,
    }

 


const categories = categoryService.search({
      root: true,
      with: ['sub_categories']
    }).then(res => {
      let findFirstCategory = res.find(c => c)
      if (findFirstCategory) {
        this.setState({
          subcategories: findFirstCategory.sub_categories || [],
          categorySelected: findFirstCategory,
          categories: res
        })
      }
    })
  }


  UpdateCatStatus()
  {
    this.setState({

      visibleCategories:false
  })
  }

  async componentDidMount() {
    setTimeout(() => document.querySelector(".loader-wrapper").style = "display: none", 2000);

    this.setState({ open: true });
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.addEventListener('keydown', this.handleKeyPress);


  }

  handleScroll = () => {
    /* const number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if ((window.innerWidth <= 991 && number >= 71) || number >= 300) {
      document.getElementById("sticky").classList.add('bar_top_scroll');
    } else {
      document.getElementById("sticky").classList.remove('bar_top_scroll');
    } */
  }

  handleKeyPress = (event) => {
      console.log('Key pressed:', event.key);
    if (event.key === 'Escape') {
      this.setState({ visibleCategories: false });
    }
  };

  closeSearch = () => {
    document.getElementById("search-overlay").style.display = "none";
  };

  openNav() {
    const openSlide = document.getElementById("mySidenav");
    if (openSlide) openSlide.classList.add('open-side')
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  componentDidMount() {
    on('logout', () => {
      this.logout();
    });
    this.getEvents();


  }

  logout = () => {
    this.props.dispatch({
      type: 'SET_USER',
      payload: null,
    });
    this.props.history.push('/');
  };



  getEvents = async () => {

    //console.log(this.props.user?.user?.id )
    if(typeof this.props.user?.user?.id=="undefined")
    {
      try {
        const res = await AuthService.login( {
          email: 'anonymususer@mail.com',
          password: '123456',
        });
        await this.props.dispatch({
          type: 'SET_USER',
          payload: res,
        });
   
      } catch (err) {
        Globals.showError(err?.response?.data.error);
        console.log('>>: error > ', err);
      }
    }
    try {
      Globals.setLoading();
      const res = await EventService.get();
      // console.log(res)
      let event_text = res.map((element) => `${element.message}`);
      event_text = event_text.join(' | ');
      this.setState({
        event_text,
        events: res,
      });
    } catch (error) {
      console.log('>>: erorr > ', error);
    } finally {
      Globals.quitLoading();
    }
  };

  render() {

    return (
      <div>
        <div style={{ position: 'fixed', height: '100%', width: '100%', background: 'none 0px 0px repeat scroll rgb(42 57 100 / 70%)', zIndex: 99, left: 0 }} className={this.state.showNav ? 'block' : 'd-none'} onClick={_ => this.setState({ showNav: false })}></div>
        <div style={{ position: 'fixed', height: '100%', background: '#fff', zIndex: 99, transition: '.5s' }} className={'navbar_mobile' + (this.state.showNav ? ' navbar_mobile_show' : '')}>
          {/* Otro código... */}
          <div className="d-flex" style={{ padding: '10px 20px' }}>
            <div className="align-self-center">
              <LogoImage logo={this.props.logoName} />
            </div>
          </div>
          <hr />
          <div className="" style={{ padding: 20 }}>
            <div className="mb-5">
              <Search className="movil_search" classNameParent="w-100" />
            </div>

            <div className="mb-5">
              <ul className="d-flex flex-column">
                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to={`/`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                    <i className="fas fa-home mr-2"></i>
                    <span>Inicio</span>
                  </Link>
                </li>
                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to="/polos" className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                  <span>  Polos </span>
                  </Link>
                </li>

                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to={`/buscar?category=13`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                  <span>  Ropa </span>
                  </Link>
                </li>
                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to={`/buscar?category=17`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                  <span>  Videojuegos </span>
                  </Link>
                </li>
                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to={`/buscar?category=11`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                  <span>  Juguetes </span>
                  </Link>
                </li>
                <li className="pb-2" style={{
                  borderBottom: '1px solid #ECECEC',
                  marginBottom: 10
                }}>
                  <Link to={`/buscar?category=11`} className="nav-link font-sand" style={{ textTransform: 'none', fontSize: 17, padding: 0, paddingBottom: 5 }}>
                  <span>  Funkos </span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mb-5">
              <div className="mb-3">

                <h4 className="font-sand font-weight-bold text-dark">Síguenos</h4>


              </div>
              <div>
                <ul className="d-flex">
                  <li className="movil_icon_rrss">
                    <a target="_blank" href={'https://www.facebook.com/'} ><i className="text-white fa fa-facebook" aria-hidden="true"></i></a>
                  </li>
                  <li className="movil_icon_rrss">
                    <a target="_blank" href={'https://plus.google.com/'} ><i className="text-white fa fa-google-plus" aria-hidden="true"></i></a>
                  </li>
                  <li className="movil_icon_rrss">
                    <a target="_blank" href={'https://twitter.com'}><i className="text-white fa fa-twitter" aria-hidden="true"></i></a>
                  </li>
                  <li className="movil_icon_rrss">
                    <a target="_blank" href={'https://instagram.com'}><i className="text-white fa fa-instagram" aria-hidden="true"></i></a>
                  </li>
                  <li className="movil_icon_rrss">
                    <a target="_blank" href={'https://rss.com/'}><i className="text-white fa fa-rss" aria-hidden="true"></i></a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-muted font-sand font-weight-light">

              <span>{(new Date()).getFullYear()} Compra Fácil</span>

            </div>
          </div>
        </div>

        <header id="sticky" className="sticky"
        >
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}

          {this.state.events.length > 0 && (
          <div className="top-animation">
            <div
              className="bar"
              style={{
                backgroundColor:
                  (this.state.events[0] && this.state.events[0].color) || '',
              }}
            >
              {[
                ...this.state.events,
                /* {
                  url_event: '/',
                  text_color: 'white',
                  message: 'Promoción de prueba en el top banner'
                },
                {
                  url_event: '/',
                  text_color: 'white',
                  message: 'Promoción2 de prueba en el top banner'
                }, */
              ].map((element, i) => {
                return (
                  <a
                    href={!!element.url_event ? element.url_event : '/'}
                    key={i}
                    className="bar_content p-1 font-sand"
                    target="_blank"
                    style={{
                      color: element.text_color || '',
                      fontSize: 14
                    }}
                  >
                    {element.message}
                  </a>
                );
              })}
            </div>
          </div>
        )}


          {/*<TopBar />*/}
 
          <div style={{ borderBottom: '1px solid #ececec' }}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="main-menu" id="main_meun_top"
                    style={{
                      height: '79px'
                    }}
                  >
                    <div className="d-block d-lg-none" onClick={_ => this.setState({ showNav: true })}>
                      <i className="fas fa-bars fa-2x" style={{ color: '#253D4E' }}></i>
                    </div>

                    <div className="menu-left">
                      <div className="brand-logo">
                        <LogoImage logo={this.props.logoName} />
                      </div>
                      <Search />
                    </div>


                    <div className="menu-right pull-right">
                      {/*Top Navigation Bar Component*/}
                      <div className="d-flex align-items-center">
                        <div className="icon-nav">
                          <ul className="d-flex align-items-center">
                              <li className="mobile-wishlist ">
                                  <a href="/admin" className="">
                                  <img
                                  style={{
                                        width: 30,
                                        height: 30
                                      }}
                                  src={`https://cdn0.iconfinder.com/data/icons/e-commerce-220/32/sell_ecommerce_market_shop_icon-512.png`} 
                                  className="rounded-circle" />
              
                                 </a> 
                                 &nbsp;&nbsp;
                                </li>
                              <ul className="header-dropdown">
                                

                                <li className="onhover-dropdown mobile-account card_header_account">
                                  {this.props.user &&
                                    this.props.user.user &&
                                    this.props.user.user.uri ? (
                                    <img
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginTop: '3px',
                                      }}
                                      className="rounded-circle"
                                      src={this.props.user.user.uri}
                                      alt={this.props?.user?.user?.name}
                                    />
                                  ) : (
                                    <img src={`/assets/images/icon/icon-user.png`} className="mr-2 align-self-center" />
                                  )}
                                  <ul className="onhover-show-div card-dropdown-custom d-flex flex-column" style={{ left: 'auto' }}>
                                    {this.props.user && this.props.user?.user?.id !==5 ? (
                                      <React.Fragment>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/paginas/tablero`}>Cuenta</Link>
                                        </li>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/pages/profile`}>Perfil</Link>
                                        </li>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/pages/chat`}>Chat</Link>
                                        </li>
                                        {/* <li>
                                                        <Link to={`/pages/support`} >Soporte</Link>
                                                    </li> */}
                                        {(this.props.user.user?.role_id === ROLE_SELLER ||
                                          this.props.user.user?.role_id === ROLE_RESELLER) && (
                                            <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                              <Link
                                                to={`/admin`}
                                                onClick={() =>
                                                  setTimeout(() => {
                                                    window.location.reload();
                                                  }, 500)
                                                }
                                              >
                                                Administrativo
                                              </Link>
                                            </li>
                                          )}
                                        <li className="w-100 text-left font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <a href="#" onClick={() => this.logout()}>Cerrar sesión</a>
                                        </li>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/pages/login`} data-lng="es">
                                            Login
                                          </Link>
                                        </li>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/pages/register`} data-lng="es">
                                            Registrarse
                                          </Link>
                                        </li>
                                        <li className="font-lato" style={{ padding: 0, fontSize: 16, paddingBottom: 10 }}>
                                          <Link to={`/pages/forget-password`} data-lng="es">
                                            Recuperar Contraseña
                                          </Link>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </li>
                             
                              </ul>
                            
                            <li className="mobile-wishlist "><Link to={`/listadeseos`}>
                              <img src={'/assets/images/icon/icon-heart.png'} />
                            </Link></li>
                            {/*Header Cart Component */}
                             <CartContainer usuario={this.props.user?.user?.id}/> 
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 d-none d-lg-block" id="bar-nav-content">
            <div className="main-menu" style={{ zIndex: 0, height: 71 }}>
              {/*Top Navigation Bar Component*/}
              <NavBar viewCategory={_ => this.setState({ visibleCategories: !this.state.visibleCategories })} visibleCategories={this.state.visibleCategories} />
            </div>
          </div>
          <div style={{
            width: '100%', 
            position: 'absolute',
            zIndex: 1,
            display: !this.state.visibleCategories ? 'none' : 'block'
          }}>
            <div className="container">
              <div style={{ background: '#fff', boxShadow: '0 12px 23px rgb(0 0 0 / 10%)' }}>
                <div className="" style={{ display: 'grid', gridTemplateColumns: '300px 1fr' }}>
                  <div className="">
                    <div className="" style={{ padding: '0 20px' }}>
                      <div className="scroll_custom" style={{ maxHeight: 500, overflowY: 'auto' }}>
                        {this.state?.categories ? this.state.categories.map((element, i) =>
                          <div
                            className="text-dark cursor-pointer category-nav"
                            key={i}
                            onMouseOver={() => this.setState({
                              subcategories: element.sub_categories || [],
                              categorySelected: element
                            })}
                            style={i === 0 ? { marginTop: 25 } : (i + 1 === this.state.categories.length ? { marginBottom: 25 } : {})}>
                            <p
                              className="text-dark cursor-pointer alinearp d-flex mb-4"
                              key={i}
                              style={{ cursor: 'pointer' }}>

                              <table style={{width:'100%'}}>
                                <thead>
                                  <tr>
                                    <th style={{width: '50%'}}> <div className={`font-sand color_hover_primary mr-2 ${parseInt(element.id) === parseInt(this.state.categorySelected.id) ? 'text-primary' : ''}`} style={{ maxWidth: 200 }}>{element.name}</div></th>
                                    <th style={{width:'50%'}}>{element.sub_categories && element.sub_categories.length > 0 && (
                                <i className="fa fa-chevron-right text-dark p-1" style={{ fontSize: 10 }} />


                              )}</th>
                                   
                                  </tr>
                                </thead>
                              </table>
                             
                              
                            </p>
                          </div>
                        ) : ''}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="" style={{ padding: '0 20px' }}>
                      <div className="row scroll_custom" style={{ maxHeight: 500, overflowY: 'auto' }}>
                        {
                          this.state?.categorySelected && (
                            <div className="w-100">
                              <div className="text-center">
                                <h2 className="text-blue font-sand mb-4" style={{ paddingTop: 25 }}>
                                  {
                                    this.state.categorySelected.name
                                  }
                                </h2>
                              </div>
                            </div>
                          )
                        }
                        {this.state?.subcategories?.map((element, i) =>
                          <div onClick={()=>this.UpdateCatStatus()} className="col-md-2 col-sm-6 p-2 centraritem subcategory-nav d-flex flex-column align-items-center mb-2">
                            {element.uri && (
                              <>
                               <Link
                                  to={{
                                    pathname: '/buscar',
                                    state: { subcategory_id: element.id }
                                  }}
                                  className="text-dark font-sand">
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 100 }}>
                                  <img
                                    src={element.uri}
                                    alt={element.name}
                                   
                                    style={{ maxWidth: '50%' }}
                                    className="mb-3"
                                  />
                                </div>
                               
                                  <span className="color_hover_primary">{element.name} </span>
                                </Link>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">�</span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Search a Product" />
                      </div>
                      <button type="submit" className="btn btn-primary"><i className="fa fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 
      </div>
    )
  } 
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withRouter(HeaderOne));

/* export default connect(null, { changeCurrency })(HeaderOne); */
