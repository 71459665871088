import React, { useState } from 'react';
import ProductRow2 from './ProductRow2';

const btnCollapsedStyle = {
  width: '25px',
  height: '25px',
};

const ProductList2 = ({
  products,
  isProvince,
  discountMode,
  promotion,
  paymentMethod,
  discountTransferenciaDefault,
  removeProduct,
  incrementar,
  direction,
  discountFix,
  onChange3
}) => {
  /* console.log(discountIzipay,
  discountTransferencia,
  discountPagoEfectivo,
  discountPaypal,) */
  const [collapsed, setCollapsed] = useState(false);

  const handlePressCollapsedButton = () => setCollapsed(!collapsed);

  return (
    <>
    
      <table className="col-md-12">
        <thead align="center">
          <tr className="">
            <th colSpan="2" align="">
              <h3>
                <strong className="text-dark font-sand"> Lista de productos </strong> 
              </h3>
              
            </th>
           
          </tr>
        </thead>
      </table>

      <div>
        {!direction ? (
        <span
          className="p-0 m-0 font-sand "
          style={{ fontWeight: 'normal', fontSize: 'small' }}
        >
         <strong className="text-danger font-sand"> Seleccione una dirección </strong> 
        </span>
      ) : (
       <div></div>
      )}
      </div>

      <div className="col-2 p-0 hidden font-sand">Productos</div>

     
      <div className="col text-right p-0  hidden font-sand">Precio final</div>

      {/*{!collapsed &&*/}
      {/*  <div className="col text-right p-0">*/}
      {/*    Envío*/}
      {/*  </div>*/}
      {/*}*/}

      <div className="col text-right p-0 hidden font-sand">Subtotal</div>

      <div className="table-responsive">
        {products.map((product) => (
          <ProductRow2
            key={product.id}
            product={product}
            collapsed={collapsed}
            promotion={promotion}
            isProvince={isProvince}
            discountMode={discountMode}
            paymentMethod={paymentMethod}
            removeProduct={removeProduct}
            incrementar={incrementar}
            direction={direction}
            discountFix={discountFix}
            onChange2={onChange3}
          />
        ))} 
      </div>

      <hr />
    </>
  );
};

export default ProductList2;
