import React, {Component} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom'

class MultiSliderItem extends Component {
    render (){
        const {items, symbol, NoOfProducts} = this.props;
        let arrays = [];
        while (items.length > 0) {
            arrays.push(items.splice(0, NoOfProducts));
        }

        return (
            <Slider className="offer-slider slide-1">
                {
                    arrays.map((products, index) =>
                        <div key={index}>
                            {
                                products.map((product, i) =>
                                    <div className="media" key={i}>
                                        <Link to={`/left-sidebar/product/${product.id}`}>
                                            { product.images && product.images[0] && product.images[0].uri && (<img
                                                src={product.images[0].uri}
                                                className="img-fluid"
                                                alt=""
                                            /> ) }


                                        </Link>
                                        <div className="media-body align-self-center">
                                            <div className="rating">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <Link to={`/left-sidebar/product/${product.id}`}>
                                                <h6>{product.name} </h6>
                                            </Link>
                                            <h4>
                                                {symbol}{product.price}
                                                <del>
                                                    <span className="money">{symbol}{product.price}</span>
                                                </del>
                                            </h4>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </Slider>
        )
    }
}

export default MultiSliderItem;
