import React, { Component } from "react";
import { toast } from "react-toastify";
import ContactRow from "./ContactRow";

class ContactSection extends Component {

  state = {
    contacts: [{
      name: '',
      email: '',
      phone: ''
    }]
  }   

  constructor(props) {
    super(props);
    const { value } = props;
    if (!value || !value.length) return
    this.state.contacts = value;
  }

  isValid = () => {
    const { contacts } = this.state;
    if (!contacts.length) {
      toast.error('Se requiere al menos un contacto');
      return false;
    }
    return true;
  }

  handleChangeContact = (contact, index) => {
    const contacts = [...this.state.contacts];
    contacts[index] = contact;
    this.setContacts(contacts)
  }

  addContact = () => {
    const contacts = [...this.state.contacts];
    contacts.push({ name: '', email: '', phone: '' });
    this.setContacts(contacts)
  }

  removeContact = index => {
    const contacts = [...this.state.contacts];
    contacts.splice(index, 1);
    this.setContacts(contacts)
  }

  setContacts = contacts => this.setState({ contacts }, this.onChangeContacts);

  onChangeContacts = () => {
    if (!this.props.onChange) return;
    this.props.onChange(this.state.contacts);
  }

  render() {
    const { title, className, style } = this.props
    const { contacts } = this.state
    return (
      <div className={className} style={style}>

        <h3> {title || "Datos del representante"} </h3>
        {contacts.map((contact, index) =>
          <ContactRow key={index}
            index={index}
            contact={contact}
            onChange={this.handleChangeContact}
            onAdd={this.addContact}
            onRemove={this.removeContact} />
        )}
      </div>
    )
  }

}

export default ContactSection;
