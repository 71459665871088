import React, { Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { UserService, axios } from '../../services';
import { connect } from 'react-redux';
import { Globals, Socket } from '../../utils';
import moment from 'moment';
import { on } from 'jetemit';
import { File } from '../../widgets';
import { Modal, ModalBody } from 'react-bootstrap';
class Chat extends Component {
  state = {
    chat: null,
    chats: [],
    messages: [],
    message: '',
    unsuscriber: null,
    unsuscriberCreate: null,
    image: null,
    lightBox: {},
    makeChat: false,
  };

  componentWillUnmount() {
    if (this.state.unsuscriber) {
      this.state.unsuscriber();
    }
    if (this.state.unsuscriberCreate) {
      this.state.unsuscriberCreate();
    }
  }

  componentDidMount() {
    this.loadChats();

    const unsuscriber = on('message', async (message) => {
      if (this.state.chat && message.chat.id == this.state.chat.id) {
        message.viewed = 1;

        await this.setState(
          {
            messages: [...this.state.messages, message],
          },
          this.scrollToBottom
        );
        if (message.user_id != this.props.user.id) {
          await axios.post('chat/viewed', { id: message.id }).catch((err) => {
            console.log(err);
          });
        }
      }
      this.loadChats(false);
    });

    this.setState({
      unsuscriber: unsuscriber,
    });
  }

  loadChats = (loading = true) => {
    if (loading) Globals.setLoading();
    axios
      .all([
        axios.post('chat/chats', { id: this.props.user.id }),
        // axios.post('chat/count')
      ])
      .then(
        axios.spread((res, count) => {
          if (res.data.result) {
            let chatsFilter = res.data.chats.data.filter(
              (i) => i.updated_at != null
            );
            const chats = chatsFilter.sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at);
            });
            this.setState({
              chats: chats,
            });
            this.props.dispatch({
              type: 'SET_SUPPORT',
              payload: res.data.count,
            });
          }
        })
      )
      .catch((err) => {
        console.log(err);
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  loadMessages = () => {
   
    axios
      .post('chat/messages', {
        id: this.state.chat.id,
        user_id: this.props.user.id,
      })
      .then((res) => {
        if (res.data.result) {
          let chats = this.state.chats;
          let index = chats.findIndex((i) => i.id == this.state.chat.id);
          chats[index] = res.data.chat;
          this.setState(
            {
              messages: res.data.chat.messages,
              chats: chats,
            },
            this.scrollToBottom
          );

          this.props.dispatch({
            type: 'SET_SUPPORT',
            payload: res.data.count,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Globals.showError();
      })
      
  };

  setActive = async (chat) => {
    await this.setState({
      chat: chat,
    });
    this.loadMessages();
  };

  getPosition = (message) => {
    console.log(message.user_id, this.props.user.id);
    const position = message.user_id === this.props.user.id ? 'right bg-primary' : 'left bg-info';
    return position;
  };

  scrollToBottom = () => {
    this.messages.scrollTop = this.messages.scrollHeight;
  };

  send = async (validate = true) => {
    if (validate) {
      if (this.state.message == '') {
        return false;
      }
    }
    let image = null;
    if (this.state.image) {
      await axios
        .upload('chat/image', { image: this.state.image })
        .then((res) => {
          if (res.data.result) {
            image = res.data.route;
          }
        })
        .catch((err) => {
          Globals.showError();
        });
    }
    try {
      const params = {
        user_id: this.props.user.id,
        chat_id: this.state.chat.id,
        text: !validate ? ' ' : this.state.message,
        file: image,
      };
      Socket.emit('message', params);
      this.setState(
        {
          message: !validate ? this.state.message : '',
          image: null,
        },
        () => {
          this.scrollToBottom();
        }
      );
    } catch (e) {
      console.log('>>: error > ', e);
    }
  };

  change = async (e) => {
    const name = e.target.name;
    await this.setState({
      [e.target.name]: e.target.value,
    });
    if (name == 'image') {
      this.send(false);
    }
  };

  onKeyUp = (e) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      this.send();
    }
  };

  getWhoTalk = (items = null) => {
    const _users = items || this.state.chat.users;
    const users = _users
      .filter((element) => element.id !== this.props.user.id)
      .map((element) => element.name)
      .join(' ');
    return users;
  };

  render() {
    let BASE_URL = 'https://comprafacil.com.pe/api/';
    return (
      <div>
        {/* <Modal
          title="Crear Chat"
          onClose={() => {
            this.setState(
              {
                makeChat: false,
              },
              () => this.loadChats()
            );
          }}
          visible={this.state.makeChat}
        >
          <CreateChat
            onClose={() =>
              this.setState(
                {
                  makeChat: false,
                },
                () => this.loadChats()
              )
            }
          />
        </Modal> */}
        <Breadcrumb title="Perfil" parent="pages" url="profile" />
        <div id="support">
          <div className="container-chat">
            <div className="row">
              <div className="col-md-4 no-padding-right">
                <div className="list-chats">
                  {/* <div
                    className={`item-chat`}
                    onClick={() =>
                      this.setState({
                        makeChat: true,
                      })
                    }
                  >
                    <h2>Iniciar un chat</h2>
                  </div> */}
                  {this.state.chats.length !==0? (this.state.chats.map((i, index) => {
                    return (
                      <div
                        key={index}
                        className={`item-chat ${
                          this.state.chat && this.state.chat.id === i.id
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => this.setActive(i)}
                      >
                        {i.count > 0 && <div className="badge">{i.count}</div>}
                        <h2>{this.getWhoTalk(i.users)}</h2>
                        {i.messages.length > 0 && (
                          <React.Fragment>
                            <p>{i.messages[i.messages.length - 1].message}</p>
                            <p className="date">
                              {moment(i.updated_at).format('DD/MM/YYYY HH:mm')}
                            </p>
                          </React.Fragment>
                        )}
                        {!i.messages.length && <p>No hay mensajes</p>}
                      </div>
                    );
                  })):<p className="no-chat text-dark">Cargando chats...</p>}
                </div>
              </div>
              <div className="col-md-8 no-padding-left">
                <div className="container-messages">
                  {this.state.chat && (
                    <div className="top-messages">
                      <h2>
                        <span>Chat con:</span> {this.getWhoTalk()}
                      </h2>
                    </div>
                  )}

                  <div
                    className="container-scroll"
                    ref={(ref) => (this.messages = ref)}
                  >
                    {!this.state.chat && (
                      <h2 className="no-chat">Por favor, seleccione un chat 2</h2>
                    )}

                    {this.state.messages.length !==0 ? (this.state.messages.map((i, index) => {
                      return (
                        <div
                          className={`message ${this.getPosition(i)}`}
                          key={index}
                        >
                          <h2>{i.text}</h2>
                          {i.file ? (
                            <img
                              alt={i.text}
                              src={ BASE_URL + i.file}
                              style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '450px',
                                margin: '5px 0px',
                              }}
                              onClick={() =>
                                this.setState({
                                  lightBox: {
                                    open: true,
                                    route: `${BASE_URL}${i.file}`,
                                  },
                                })
                              }
                            />
                          ) : null}
                          <p>
                            {moment(i.created_at).format('DD/MM/YYYY HH:mm')}
                          </p>
                          <div
                            className={`triangle-${this.getPosition(i)}`}
                          ></div>
                          {this.state.lightBox.open && (
                            <Modal
                              show={this.state.lightBox.open}
                              onHide={() =>
                                this.setState({ lightBox: { open: false } })
                              }
                              size="md"
                              centered
                            >
                              <ModalBody className="modalbody">
                                <div className="content-lightBox">
                                  <img alt="" src={this.state.lightBox.route} />
                                </div>
                              </ModalBody>
                            </Modal>
                          )}
                        </div>
                      );
                    })):!this.state.chat? "":  <h2 className="no-chat"> Cargando mensajes</h2>}
                  </div>

                  <div className="container-input">
                    <input
                      disabled={
                        !this.state.chat || this.state.chat.support_status == 0
                      }
                      value={this.state.message}
                      onChange={this.change}
                      name="message"
                      onKeyUp={this.onKeyUp}
                    />
                    <button
                      className="btn btn-primary  btn-sm"
                      disabled={!this.state.chat}
                    >
                      {this.state.chat ? (
                        <File
                          renderItem={
                            <i
                              className="fa fa-paperclip"
                              style={{ fontSize: '20px', marginTop: '3px' }}
                            />
                          }
                          onChange={this.change}
                          name="image"
                          inputStyle={{
                            display: 'contents',
                          }}
                          className="btn-product"
                        />
                      ) : (
                        <i
                          name="fa fa-paperclip"
                          style={{ fontSize: '20px', marginTop: '3px' }}
                        />
                      )}
                    </button>
                    <button
                      className="btn btn-primary  btn-sm"
                      onClick={this.send}
                      disabled={
                        !this.state.chat ||
                        this.state.chat.support_status == 0 ||
                        !this.state.message
                      }
                    >
                      <i className="fa fa-paper-plane" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  fullUser: state.user,
  user: (state.user && state.user.user) || {},
});

export default connect(mapStateToProps)(Chat);
