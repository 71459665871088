/* eslint-disable react/jsx-no-target-blank */
import React, { Component, useEffect } from 'react';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { postApi } from '../../services/postApi';
import { SHARED_RS, SUGGEST } from '../../services/endpoints';

import './success.css';
class orderSuccess extends Component {
  state = {
    hide: false,
    showForm: false,
    suggest: null,
    showRS: false,
    isAnonimus: false,
    loading: false,
    success: false,
    error: false,
    message: '',
    code: null,
  };
  set = setTimeout(() => {
    this.setState({
      hide: true,
    });
  }, 5000);
  componentDidMount() {
    console.log(this.props.location.state);
    const {payment, items} = this.props.location.state;
    let valueShippingArr = []
    let itemsArr = []
    items.map(item => {
      valueShippingArr.push(item.shippingAmount)
      itemsArr.push({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.total
      })
    })
    let totalShipping = valueShippingArr.reduce((a, b) => a + b, 0)
    window.gtag('event', 'purchase', {
      transaction_id: payment.pad_id,
      affiliation: 'Google online store',
      value: payment.total,
      currency: 'PEN',
      tax: payment.total - (payment.total / 1.18),
      shipping: totalShipping,
      items: itemsArr
    });
  }
  render() {
    const user = !!localStorage.getItem('state')
      ? JSON.parse(localStorage.getItem('state'))
      : '';
    const userID = user?.user?.user?.id;
    const userToken = !!user?.user?.access_token
      ? user?.user?.access_token
      : '';
    const handleChange = (event) => {
      event.preventDefault();
      this.setState({ suggest: event.target.value });
    };

    const handleChangeCheck = (event) => {
      event.preventDefault();
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ isAnonimus: value });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      this.setState({
        loading: true,
      });
      console.log('A suggest was submitted: ' + this.state.suggest);
      const dataForm = {
        user_id: this.state.isAnonimus ? null : userID,
        mensaje: this.state.suggest,
      };
      postApi(SUGGEST, dataForm, userToken)
        .then((response) => {
          this.setState({
            loading: true,
          });
          if (response.status === 200) {
            this.setState({
              success: true,
              suggest: '',
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: true,
          });
          if (error.response) {
            console.log('Error');
          }
        });
    };

    const handleShared = (product_id, rs) => {
      const dataForm = {
        user_id: userID,
        product_id,
        redsocial: rs,
      };
      postApi(SHARED_RS, dataForm, userToken)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.setState({
              message: response.data.msg,
              code: response.data.codigo,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('Error');
          }
        });
    }; 

    const {
      payment,
      items,
      symbol,
      orderTotal,
      shippingValue,
      address,
    } = this.props.location.state;
    return payment ? (
      <div>
        <Helmet>
          
        </Helmet>
        <section className="light-layout">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text font-sand">
                  <i className="fa fa-check-circle" aria-hidden="true" />
                  <h2>Gracias por comprar</h2>
                  <p>El Nº de su pedido ha sido registrado exitosamente</p>
                  <p>
                    Nº Pedido:{' '}
                    {payment.paymentID ? payment.paymentID : payment.id}
                  </p>
                </div>
              </div>
            </div>
      
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 mb-4">
                <div className="product-order border-shadow-custom p-4">
                  <h3 className="font-sand">Detalles del pedido</h3>
                  <div className="table-responsive">
                    <div style={{ minWidth: 600 }}>
                      {items.map((item, index) => {
                        return (
                          <div className="row product-order-detail" key={index}>
                            <div className="col-2">
                              <img
                                src={item.image}
                                alt={item.name}
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-3 order_detail">
                              <div>
                                <h4 className="font-sand">
                                  Nombre del producto
                                </h4>
                                <h5 className="font-sand">{item.name}</h5>
                              </div>
                            </div>
                            <div className="col-3 order_detail">
                              <div>
                                <h4 className="font-sand">Vendedor</h4>
                                <h5 className="font-sand">{item.seller}</h5>
                              </div>
                            </div>
                            <div className="col-2 order_detail">
                              <div>
                                <h4 className="font-sand">Cantidad</h4>
                                <h5 className="font-sand">{item.qty}</h5>
                              </div>
                            </div>
                            <div className="col-2 order_detail"> 
                              <div>
                                <h4 className="font-sand">Precio</h4>
                                <h5 className="font-sand">
                                  {symbol}
                                  {item.total || item.price}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="total-sec">
                    <ul>
                      <li className="font-sand">
                        Subtotal{' '}
                        <span>
                          {symbol}
                          {parseFloat(orderTotal) - parseFloat(shippingValue)}
                        </span>
                      </li>
                      <li className="font-sand">
                        Envío{' '}
                        <span>
                          {symbol}
                          {shippingValue}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="final-total">
                    <h3 className="font-sand text-primary">
                      total{' '}
                      <span>
                        {symbol}
                        {orderTotal}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="border-shadow-custom p-4">
                  <div className="row order-success-sec">
                    <div className="col-sm-6">
                      <h4 className="font-sand">Datos del pedido</h4>
                      <ul className="order-detail font-sand">
                        {payment.paymentID ? (
                          <div>
                            <li className="font-sand">
                              Comprador ID: {payment.payerID}
                            </li>
                            <li>
                              {' '}
                              className="font-sand"Pago ID: {payment.paymentID}
                            </li>
                          </div>
                        ) : (
                          <li className="font-sand">
                            Nº de orden: {payment.id}
                          </li>
                        )}
                        <li>
                          Fecha de la orden Orden:{' '}
                          {moment(payment.created_at).format(
                            'DD/MM/YYYY HH:mm A'
                          )}
                        </li>
                        <li className="font-sand">
                          Total del pedido: {symbol}
                          {orderTotal}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <h4 className="font-sand">Dirección de despacho</h4>
                      <ul className="order-detail font-sand">
                        <li>{address.address}</li>
                        <li>{address.district.region.name}</li>
                        <li>{address.district.province.name}</li>
                        <li>{address.district_id.label}</li>
                      </ul>
                    </div>

                    {/* <div className="col-sm-12 payment-mode">
                      <h4>payment method</h4>
                      <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net
                          banking acceptance subject to device availability.</p>
                  </div> */}
                  </div>
                </div>

                <div className="w-100">
                  <div className="delivery-sec">
                    <h3 className="font-sand">
                      {payment.payment_type_id === 3
                        ? 'Su pago sera evaluado y aprobado y luego le enviaremos un correo con los detalles de su compra.'
                        : 'Nos contactaremos con usted para darles una fecha de envío y recepción'}
                    </h3>
                    <h2 className="font-sand">¡Compra Fácil!</h2>
                  </div>
                </div>
              </div>

              <>
                <Modal
                  show={this.state.hide}
                  onHide={() => this.setState({ hide: false })}
                  centered
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      className="font-sand"
                    >
                      Mensaje
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      <h3
                        className="text-dark font-sand"
                        style={{ fontSize: 18 }}
                      >
                        Gracias por comprar con nosotros. Si desea, puede
                        compartir su compra en redes sociales para ganarse un
                        código de envío gratuito{' '}
                        <span className="font-weight-bold">(solo Lima)</span> en
                        su próxima compra. Si tiene alguna sugerencia por favor
                        haga clic en el siguiente botón.
                      </h3>
                      
                      <div className="d-flex mt-5 mb-5">
                        <div className="container d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-success font-sand"
                            onClick={() =>
                              this.setState({ showForm: !this.state.showForm })
                            }
                          >
                            Sugerencia
                          </button>
                        </div>
                        <div className="container d-flex justify-content-start">
                          <button
                            type="button"
                            className="btn btn-primary font-sand"
                            onClick={() =>
                              this.setState({ showRS: !this.state.showRS })
                            }
                          >
                            Compartir
                          </button>
                        </div>
                      </div>
                      {this.state.showForm ? (
                        <form onSubmit={handleSubmit}>
                          <div class="form-group container d-flex">
                            <div className="ml-2">
                              <label for="suggest-anon" className="font-sand">
                                Sugerencia Anonima
                              </label>
                            </div>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="suggest-anon"
                              checked={this.state.isAnonimus}
                              onChange={handleChangeCheck}
                            ></input>
                          </div>
                          <div class="form-group">
                            <label
                              for="sugerencia font-weight-bold text-2rem"
                              className="font-sand"
                            >
                              Sugerencia
                            </label>
                            <textarea
                              id="sugerencia"
                              rows="6"
                              required={this.state.showForm ? true : false}
                              value={this.state.suggest}
                              onChange={handleChange}
                              className="mb-3 font-sand form-control input_custom_40 font-weight-500"
                              style={{ height: 100 }}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex mt-3 justify-content-between align-items-center">
                                <div className="d-flex  justify-content-start">
                                  {this.state.loading ? (
                                    <span className="text-warning font-sand">
                                      Enviando mensaje...
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.success ? (
                                    <span className="text-success font-sand">
                                      Mensaje enviado
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.error ? (
                                    <span className="text-error font-sand">
                                      Mensaje no enviado
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="d-flex  justify-content-end font-sand">
                                  <input
                                    type="submit"
                                    value="Enviar"
                                    className="btn btn-solid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      ) : (
                        ''
                      )}
                      {this.state.showRS ? (
                        <div className="d-flex mt-5">
                          <div className="container d-flex justify-content-end">
                            <a
                              //href="https://www.facebook.com/sharer/sharer.php?u=http%3A//comprafacil.com.pe/left-sidebar/product/136"
                              href={`https://www.facebook.com/sharer/sharer.php?u=https://comprafacil.com.pe/detalle/productos/${items[0]?.id}`}
                              // eslint-disable-next-line react/jsx-no-target-blank
                              target="_blank"
                              className="text-2rem badge badge-primary"
                              onClick={() =>
                                handleShared(items[0]?.id, 'facebook')
                              }
                            >
                              <i className="fab fa-facebook" />
                            </a>
                          </div>
                          <div className="container d-flex justify-content-start">
                            <a
                              href={`https://twitter.com/intent/tweet?text=https%3A//comprafacil.com.pe/detalle/productos/${items[0]?.id}`}
                              target="_blank"
                              className="text-2rem badge badge-primary"
                              onClick={() =>
                                handleShared(items[0]?.id, 'twitter')
                              }
                            >
                              <i className="fab fa-twitter" />
                            </a>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            </div>
          </div>
        </section>
      </div>
    ) : (
      <section className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-section">
                <h1 className="font-sand">404</h1>
                <h2 className="font-sand">page not found</h2>
                <a href="index.html" className="btn btn-solid font-sand">
                  back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default orderSuccess;
