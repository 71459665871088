import {axios} from "../axios";

const END_POINT = 'terms';

class TermService {
  
  async index() {
    const r = await axios.get(END_POINT)
    return r.data
  }
  
}

const termService = new TermService()

export default termService;
