import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual';


// Custom Components
import HeaderOne from './common/headers/header-one';
import FooterOne from './common/footers/footer-one';
// ThemeSettings
import ThemeSettings from './common/theme-settings';
import ModalChat from './ModalChat';

class App extends Component {
  componentDidMount() {
    document
      .getElementById('color')
      .setAttribute('href', `/assets/css/color10.css`);
  }

  render() {
    return (
      <div>
      
        <HeaderOne logoName={'logo-market.png'} />
        {this.props.children}
        {/* Modal Chat */}
        <ModalChat />
        {/* Modal Chat End */}

        <FooterOne logoName={'logo-market.png'} />
        <ThemeSettings />

      </div>
    );
  }
}

export default withTranslate(App);
