import React from "react";

const EditorBackground2 = ({ color = "#ffffff", style, className, image, children, id, width }) =>
  <div
    id={id}
    className={`h-box center custom_image_polos ${className}`}
    style={{
      width: width,
      height: width,
      ...style
    }}>

    <img
      src={image}
      style={{
        position: 'absolute',
        maxWidth: width,
        maxHeight: width,
        backgroundColor: color,
      }}
      className="custom_image_polos"
      alt="" />

    <div
      className="custom_image_polos"
      style={{
        position: 'absolute',
        width: width,
        height: width,
        // zIndex: 20
      }}>
      {!!children && children}
    </div>

  </div>

export default EditorBackground2
