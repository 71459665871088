import React, { useState,useEffect } from 'react';
import { paymentMethods } from './Checkout';
import { connect } from 'react-redux';
import './paymentMethod.css';
import Input from '../form/Input';
import InputFile from '../form/InputFile2';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, Accordion, Card, Button } from 'react-bootstrap'



const PaymentMethodSelect = ({ 
  Setselected, 
  paymentMethod, 
  onChangeData,
  onChange, 
  name, 
  sending, 
  direction, 
  total, 
  total2, 
  total3, 
  total4, 
  total5 }) => {


  const [showModal, setShowModal] = useState(false);

  const handleClick = (paymentMethod) => {
    if (onChange) onChange(paymentMethod);
   // setShowModal(true);
  };

  const decimales = (numero) => {
    return parseFloat(numero).toFixed(2);
  }

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
 const [activeAccordion, setActiveAccordion] = useState(null);



 const handlePaymentMethodSelect = (methodId, accordionKey) => {
    Setselected("current_payment", methodId);
    setSelectedPaymentMethod(methodId);

        // Si el mismo acordeón se hace clic dos veces, ciérralo
    if (activeAccordion === accordionKey) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionKey);
    }
  };


  const [data, setState] = useState({

  });

  


const handleShowModal = () => {
  setShowModal(true);
};

const handleHideModal = () => {
  setShowModal(false);
};


  useEffect(() => {
    if (onChangeData) onChangeData(data);
  });

  const handleChangeInput = (text, name) => {
    //console.log();
    const newData = {
      ...data,
      [name]: text,
    };
    setState(newData);
  };

    const { file } = data;


  return (

    <div className="mt-2 row table-responsive">
    <table className="table table-hover table-stripped table-collapse">
        <thead align="center">
          <th colSpan="2" align="center">
            <strong className="font-sand titulo">MÉTODOS DE PAGO</strong>
          </th>
         
        </thead>
        <tbody>
         <tr>
          <th className='' colSpan="" style={{
                    height: 40,
                    border: 'none',
                  }}>
            <Accordion key={1} activeKey={activeAccordion}>
                <Card style={{ border: 0, marginBottom: 10 }}>
                  <Card.Header className="border-shadow-custom">
                    <Accordion.Toggle 
                        as={Button} className="btn_custom" variant="link" eventKey={4} style={{
                          textDecoration: 'none',
                          height: '40px',
                          width: '100%',
                           textAlign: 'left',
                        }}
                        onClick={() => handlePaymentMethodSelect(4,4)}
                        >
                        <table className="" style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                        }}>
                          <th style={{border: 'none'}}><input name="payment_method" checked={selectedPaymentMethod === 4} type="radio" /></th>

                          <th style={{border: 'none'}}><strong className="font-sand izipay">IZYPAY</strong>(sin cargo adicional)
                          </th>
                          <th style={{
                    color: 'black', textAlign: 'right', border: 'none'}}><i class="fa fa-chevron-down"></i>
                          </th>
                          <th style={{
                              color: 'black', height: 40, textAlign: 'right', border: 'none'}}>S/  {decimales(total3)}
                          </th>
                        </table>
                          
                        </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={4}>
                          <Card.Body className="border-shadow-custom mt-2">
                          <div>
                           { !direction ? (
                                <span style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'right',
                                  color: 'red'
                                }}>Para continuar, debe seleccionar la dirección de envío</span>
                              ) : null }
                          <div
                            className={
                              !!direction ? 'btn bg-rojo' : 'btn bg-rojo not-active'
                            }
                            name="payment-group"
                            id="payment-2"
                            checked={paymentMethod === paymentMethods.IZIPAY}
                            onClick={() => handleClick(paymentMethods.IZIPAY)}
                            disabled={!!direction ? true : false}
                          >
                            
                            <img
                              src={
                                'https://lh3.googleusercontent.com/CR5pDN6gS-Pcrn2EvU5AUfmLIpB8a_kETk3AhrFTBy5Xp0yx5-q6rXau8AZAt_Eiw-I'
                              }
                              className=""
                              style={{
                                width: 160,
                                height:55,
                                maxWidth: 100
                              }}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
            </th>
          
         
            
    
           </tr>
          <tr>
            <th  className='' colSpan="" style={{
                    height: 10,
                    border: 'none',

                  }}>
            <Accordion  key={1} activeKey={activeAccordion}>
                <Card style={{ border: 0 }}>
                  <Card.Header className="border-shadow-custom">
                    <Accordion.Toggle  
                          as={Button} className="btn_custom" variant="link" eventKey={1} style={{
                          textDecoration: 'none',
                          width: '100%',
                          height: '40px',
                          textAlign: 'left',
                          
                        }}
                        onClick={() => handlePaymentMethodSelect(1,1)}
                        >

                        <table className="" style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                          verticalAlign: 'middle',
                        }}>
                          <tr>
                            <th style={{border: 'none'}}><input name="payment_method" type="radio" checked={selectedPaymentMethod === 1} /></th>
                            <th style={{border: 'none'}}><strong className="font-sand transferencia">TRANSFERENCIA</strong>(+8 soles)
                            </th>
                            <th style={{ color: 'black', textAlign: 'right', border: 'none'}}><i class="fa fa-chevron-down"></i>
                            </th>
                            <th style={{
                                color: 'black', height: 40, textAlign: 'right', border: 'none'}}>S/  {decimales(total+8)}
                            </th>
                          </tr>
                        </table>

           

                           <span ></span>
                        </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={1}>
                          { !direction ? (
                                <span style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'right',
                                  color: 'red'
                                }}>Para continuar, debe seleccionar la dirección de envío</span>
                              ) : 

                          <Card.Body className="border-shadow-custom mt-2">
                          <div className="col-12">
                          <div
                            className={
                              !!direction ? 'btn ' : 'btn  not-active'
                            }
                            name="payment-group"
                            id="payment-2"
                            checked={paymentMethod === paymentMethods.TRANSFER}
                            onClick={() => handleClick(paymentMethods.TRANSFER)}
                          >

                          <div className="row">
                           
                            <div className="col-12">
                            
                             
                              <table style={{
                                fontSize: 12,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%'
                              }}>
                                <tbody>
                                <tr>
                                  
                                  <td rowSpan="2">
                                      <img
                                        src={
                                          'https://2.bp.blogspot.com/-sh6UMBFdpOQ/WfpRx6OARkI/AAAAAAAAAu4/tACN6PxhDi442xM3IYvDogyzj0nDtPtgACLcBGAs/w1200-h630-p-k-no-nu/InterBank-logo.png'
                                        }
                                        style={{
                                          width: 100,
                                          height:60,
                                          maxWidth: 100
                                        }}
                                      />
                                  </td>
                                  <td>N° Cuenta:</td>
                                  <td>0453001596513</td>
                                  
                                </tr>
                              
                                <tr>
                                  <td>Titular:</td>
                                  <td>Compra Fácil</td>
                                </tr>
                                
                                </tbody>
                              </table>
                             
                            </div>
                           
                            <div className="col-12">
                            
                              <table style={{
                                fontSize: 12,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%'
                              }}>
                                <tbody>
                                <tr>
                                  
                                  <td rowSpan="2">
                                      <img
                                        src={
                                          'https://s3-eu-west-1.amazonaws.com/rankia/images/valoraciones/0009/8953/Banco.jpg?1360328307'
                                        }
                                        style={{
                                          width: 100,
                                          height:60,
                                          maxWidth: 100
                                        }}
                                      />
                                  </td>
                                  <td>N° Cuenta:</td>
                                  <td>19310246712013</td>
                                </tr>
                               
                                <tr>
                                  <td>Titular:</td>
                                  <td>Luz Correa</td>

                                </tr>
                        
                                
                                </tbody>
                              </table>
                              
                    </div>

               <div className="col-12">
                            
                              <table style={{
                                fontSize: 10,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%',
                                textAlign: 'left'
                              }}>
                                <tbody>
                                <tr>
                                  <td>
                                  <strong style={{
                                      color: 'red',
                                      }}>NOTA: 
                                  </strong> 
                                  <strong> Para realizar la compra por transferencia debe enviar
                                   un correo a<br></br> la dirección  </strong>  
                                  <strong style={{
                                    color: 'red',
                                  }}> transferencias@comprafacil.com.pe
                                  </strong>
                                  <strong>
                                  , adjuntando  una imagen<br></br> de la  transferencia,  con este detalle los  administradores 
                                  procederán   a <br></br> verficar  el pago y  aprobar la compra. Si ha  realizado la  transferencia  
                                 <br></br> antes de generar el pedido puede  cargar el documento directamente 
                                  en el <br></br> siguiente campo y luego reservar:
                                  </strong>  

                                  <strong>
                                   
                                  </strong>

                                  </td>
                                </tr>
                        
                                
                                </tbody>
                              </table>
                    </div>   

    <div className="col-12">

                      <table style={{
                               
                                border: 'none',
                           
                                width: '100%',
                          
                              }}>
                        <tbody>
                          <tr>
                          <td colSpan="2">
                             
                          </td>
                          </tr>
                          <tr>
                            <td>
                              <InputFile
                                  className="mb-3 font-sand"
                                  name="file"
                                  label="Comprobante"
                                  onChange={handleChangeInput}
                                  maxSize={5 * 1024 * 1024}
                                  onBase64={(it) => handleChangeInput(it, 'file64')}
                                  onMaxSize={() => toast.error('No se permiten archivo mayor a 5MB')}
                              />
                            </td>
                            <td>
                              <button
                                  type="submit"
                                  className="btn-solid btn-success btn"
                                  disabled={sending}
                                  style={{
                                    marginTop: '1px'
                                  }}
                                >
                                  Reservar
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>        
                    </div> 
 
                             
                    




                    </div> 
                            
                      </div>
                        </div>
                      </Card.Body>
                       }
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
            </th>
            
      

          </tr>
          <tr>
            <th  className='' colSpan="" style={{
                    height: 40,
                    border: 'none',

                  }}>
            <Accordion key={1} activeKey={activeAccordion}>
                <Card style={{ border: 0, marginBottom: 10 }}>
                  <Card.Header className="border-shadow-custom">
                    <Accordion.Toggle as={Button} className="btn_custom" variant="link" eventKey={2} style={{
                          textDecoration: 'none',
                          width: '100%',
                          height: '40px',
                          textAlign: 'left',
                        }}
                        onClick={() => handlePaymentMethodSelect(2,2)}
                        >

                        <table className="" style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                        }}>
                          <th style={{border: 'none'}}><input name="payment_method" checked={selectedPaymentMethod === 2}  type="radio" /></th>

                          <th style={{border: 'none'}}><strong className="font-sand transferencia2">TRANSFERENCIA (Móvil)</strong>
                          </th>
                          <th style={{
                    color: 'black', textAlign: 'right', border: 'none'}}><i class="fa fa-chevron-down"></i>
                          </th>
                          <th style={{
                              color: 'black', height: 40, textAlign: 'right', border: 'none'}}>S/  {decimales(total)}
                          </th>
                        </table>

                       

                           <span ></span>
                        </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={2}>
                          { !direction ? (
                                <span style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'right',
                                  color: 'red'
                                }}>Para continuar, debe seleccionar la dirección de envío</span>
                              ) : 
                          <Card.Body className="border-shadow-custom mt-2">
                          <div>
                          <div
                            className={
                              !!direction ? 'btn ' : 'btn  not-active'
                            }
                            name="payment-group"
                            id="payment-2"
                            checked={paymentMethod === paymentMethods.TRANSFER2}
                            onClick={() => handleClick(paymentMethods.TRANSFER2)}
                          >
                            
                            <div className="row">
                           
                            <div className="col-12">
                            
                             
                              <table style={{
                                fontSize: 12,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%'
                              }}>
                                <tbody>
                                <tr>
                                  <td rowSpan="2">
                                      <img
                                        src={
                                          'https://is5-ssl.mzstatic.com/image/thumb/Purple122/v4/ca/02/56/ca02562d-2394-6869-3e9c-0f80593047b0/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png'
                                        }
                                        style={{
                                          width: 120,
                                          height:80,
                                          maxWidth: 120
                                        }}
                                      />
                                  </td>
                                  <td>Titular : </td>
                                  <td>Luz  Correa </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <img
                                          src={`/assets/images/qryape.jpeg`}
                                          style={{
                                            width: 140,
                                            height:140,
                                            maxWidth: 140
                                          }}
                                        />
                                    </td>
                                </tr>
                                 
                                
                                
                                </tbody>
                              </table>
                             
                            </div>
                           
                            <div className="col-12">
                            
                              <table style={{
                                fontSize: 12,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%'
                              }}>
                                <tbody>
                                <tr>
                                  <td rowSpan="2">
                                      <img
                                        src={
                                          'https://www.casosexitosos.com/wp-content/uploads/2022/01/PLIN-1.png'
                                        }
                                        style={{
                                          width: 120,
                                          height:80,
                                          maxWidth: 120
                                        }}
                                      />
                                  </td>
                                  <td>Titular :</td>
                                  <td>Mauricio León</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <img
                                          src={`/assets/images/qrplin.jpeg`}
                                          style={{
                                            width: 140,
                                            height:140,
                                            maxWidth: 140
                                          }}
                                        />
                                    </td>
                                </tr>
                               
     
                                </tbody>
                              </table>
                 
                           
                            </div>

                            <div className="col-12">
                            
                              <table style={{
                                fontSize: 10,
                                border: 'none',
                                verticalAlign: 'middle',
                                width: '80%',
                                textAlign: 'left'
                              }}>
                                <tbody>
                                <tr>
                                  <td>
                                  <strong style={{
                                      color: 'red',
                                      }}>NOTA: 
                                  </strong> 
                                  <strong> Para realizar la compra por transferencia debe enviar
                                   un correo a la <br></br>dirección  </strong>  
                                  <strong style={{
                                    color: 'red',
                                  }}> transferencias@comprafacil.com.pe
                                  </strong>
                                  <strong>
                                  , adjuntando  una imagen de la <br></br> transferencia,  con este detalle los  administradores 
                                  procederán   a verficar <br></br> el pago y  aprobar la compra. Si ha  realizado la  transferencia  
                                  antes de<br></br> generar el pedido puede  cargar el documento directamente 
                                  en el  siguiente <br></br>campo y luego reservar:
                                  </strong>  

                                  <strong>
                                   
                                  </strong>

                                  </td>
                                </tr>
                        
                                
                                </tbody>
                              </table>
                    </div>      

                    <div className="col-12">

                      <table style={{
                               
                                border: 'none',
                           
                                width: '100%',
                          
                              }}>
                        <tbody>
                         <tr>
                          <td colSpan="2">
                             
                          </td>
                          </tr>
                          <tr>
                            <td>
                              <InputFile
                                  className="mb-3 font-sand"
                                  name="file"
                                  label="Comprobante"
                                  onChange={handleChangeInput}
                                  maxSize={5 * 1024 * 1024}
                                  onBase64={(it) => handleChangeInput(it, 'file64')}
                                  onMaxSize={() => toast.error('No se permiten archivo mayor a 5MB')}
                              />
                            </td>
                            <td
                              
                            >
                            
                              <button
                                  type="submit"
                                  className="btn-solid btn-success btn"
                                  disabled={sending}
                                  style={{
                                    marginTop: '1px'
                                  }}
                                >
                                  Reservar
                                </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>        
                    </div> 


                          </div> 

                          </div>
                        </div>
                      </Card.Body>
                       }
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
            </th>
          
    

          </tr>
          <tr>
        
          <th className='' colSpan="" style={{ 
                    height: 40,
                    border: 'none',
                  }}>
            <Accordion key={1} activeKey={activeAccordion}>
                <Card style={{ border: 0, marginBottom: 10 }}>
                  <Card.Header className="border-shadow-custom">
                    <Accordion.Toggle 
                        as={Button} className="btn_custom" variant="link" eventKey={3} style={{
                          textDecoration: 'none',
                          height: '40px',
                          width: '100%',
                          textAlign: 'left',
                        }}
                        onClick={() => handlePaymentMethodSelect(3,3)}
                        >
                         <table className="" style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                        }}>
                          <th style={{border: 'none'}}><input name="payment_method" checked={selectedPaymentMethod === 3} type="radio" /></th>

                          <th style={{border: 'none'}}><strong className="font-sand paypal">PAYPAL</strong>(sin cargo adicional)
                          </th>
                          <th style={{
                    color: 'black', textAlign: 'right', border: 'none'}}><i class="fa fa-chevron-down"></i>
                          </th>
                          <th style={{
                              color: 'black', height: 40, textAlign: 'right', border: 'none'}}>S/  {decimales(total2)}
                          </th>
                        </table>
                          
                        </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={3}>
                          <Card.Body className="border-shadow-custom mt-2">
                          <div>

                             { !direction ? (
                                <span style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'right',
                                  color: 'red'
                                }}>Para continuar, debe seleccionar la dirección de envío</span>
                              ) : null }
                        
                          <div
                            className={
                              !!direction ? 'btn bg-white' : 'btn bg-white not-active'
                            }
                            name="payment-group"
                            id="payment-2"
                            checked={paymentMethod === paymentMethods.PAYPAL}
                            onClick={() => handleClick(paymentMethods.PAYPAL)}
                            disabled={!!direction ? true : false}
                          >
                            
                            <img
                              src={
                                'https://es.logodownload.org/wp-content/uploads/2019/07/paypal-logo21.png'
                              }
                              className=""
                              style={{
                                width: 130,
                                height:45,
                                maxWidth: 90
                              }}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion> 
            </th>
         
         
            

          </tr>

          <tr style={{display: 'none'}}>
          <th className='' colSpan="" style={{
                    height: 40,
                    border: 'none',
                  }}>
            <Accordion key={1} activeKey={activeAccordion}>
                <Card style={{ border: 0, marginBottom: 10 }}>
                  <Card.Header className="border-shadow-custom">
                    <Accordion.Toggle 
                        as={Button} className="btn_custom" variant="link" eventKey={5} style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                        }}
                        onClick={() => handlePaymentMethodSelect(5,5)}
                        >
                        <table className="" style={{
                          textDecoration: 'none',
                          width: '100%',
                          textAlign: 'left',
                        }}>
                          <th style={{border: 'none'}}><input name="payment_method" onClick={()=>Setselected("current_payment",5)} type="radio" /></th>

                          <th style={{border: 'none'}}><strong className="font-sand pagoefectivo">PAGOEFECTIVO</strong>(+4 soles)
                          </th>
                          <th style={{
                    color: 'black', textAlign: 'right', border: 'none'}}><i class="fa fa-chevron-down"></i>
                          </th>
                          <th style={{
                              color: 'black', height: 40, textAlign: 'right', border: 'none'}}>S/  {decimales(total4+4)}
                          </th>
                        </table>
                          
                        </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={5}>
                          <Card.Body className="border-shadow-custom mt-2">
                          <div>
                          <div
                            className={
                              !!direction ? 'btn bg-yellow' : 'btn bg-yellow not-active'
                            }
                            name="payment-group"
                            id="payment-2"
                            checked={paymentMethod === paymentMethods.PAGOEFECTIVO}
                            onClick={() => handleClick(paymentMethods.PAGOEFECTIVO)}
                            disabled={!!direction ? true : false}
                          >
                            <img
                              src={'https://sites.pagoefectivo.pe/logo-pagoefectivo.png'}
                              className=""
                              style={{
                                width: 140,
                                height:45,
                                maxWidth: 90
                              }}
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
            </th>
           
         
      
          </tr>
        </tbody>
      </table>
      {!!direction ? null : (
        <div className="d-flex align-item-center justify-content-center w-100 mb-4 p-2">
          <span className="text-danger font-sand">
            * Debe seleccionar una dirección para activar los métodos de pago.
          </span>
        </div>
      )}









    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(PaymentMethodSelect);

