import React, { Component } from 'react';
import AttributeFilter from './AttributFilter';

class AttributeFilters extends Component {
  state = {
    attribute: null,
    product: null,
    attributes: [],
    etiquetas: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('product', this.props.product)
    
    if (
      this.state.product == null &&
      this.props.product != null &&
      this.props.product.attributes != null
    ) {
      this.setState({ product: this.props.product });
      
    }
    if (
      this.state.attribute == null &&
      this.props.variants != null &&
      this.state.product != null &&
      this.props.variants.variantAttributes.length
    ) {
      const attribute = this.props.variants.variantAttributes[0].attribute;
      attribute.value = this.getDefaultValueByAttribute(attribute);
      const attributes = this.props.variants.variantAttributes.map((it) => ({
        ...it.attribute,
        value: this.getDefaultValueByAttribute(it.attribute),
      }));
      this.setState({ attribute, attributes });
    }
  }

  getDefaultValueByAttribute = (attribute) => {
    const { product } = this.state;
    if (product == null) return null;
    return product.attributes.find((it) => it.attribute_id === attribute.id)?.value_text;
  };

  handleChangeValue = (value, att) => {
    const attribute = {
      ...att,
      value,
    };
    const product = this.props.variants.variants.find((variant) => {
      return variant.attributes.some(
        (att) =>
          att.attribute_id === attribute.id &&
          att.value_text === attribute.value
      );
    });
    this.setState({ attribute, product }, () => {
      if (this.props.onChangeProduct) this.props.onChangeProduct(product);
    });
  };

  render() {
    if (!this.props.variants) return null;
    if (!this.props.variants.variantAttributes.length) return null;
    const { variantAttributes, variants } = this.props.variants;
    return (
      <div
        style={{
          width: '100%',
          margin: '0',
          padding: '0',
        }}
      >
        {variantAttributes.map((it) => {
          return (
            <AttributeFilter
              key={it.id}
              attribute={it.attribute}
              variants={variants}
              attributeFocus={this.state.attribute}
              //defaultValue={this.getDefaultValueByAttribute(it.attribute)}
              onChangeValue={(value) =>
                this.handleChangeValue(value, it.attribute)
              }
            />
          );
        })}
      </div>
    );
  }
}

export default AttributeFilters;
