import React, { useState } from 'react';
import { toast } from 'react-toastify';
import contactServices from '../../services/modules/contactServices';
import Breadcrumb from '../common/breadcrumb';
import {useHistory } from 'react-router-dom';
import './contacto.css';
import './contact.scss';
const Contact = () => {
  const [name, setName] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const sendMessage = async () => {
      const data = {
      name,
      last_name,
      phone,
      email,
      message,
    };
    try {
      const contact = await contactServices.post(data);
      if (contact === 200) {
        setLoading(false);
        toast.success(
          'Mensaje enviado con éxito, estaremos en contacto con usted.'
        );
        setTimeout(() => {
          history.push('/')
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      toast.error('Ocurrio un error al enviar el mensaje, intente de nuevo.');
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    sendMessage();
  };
  return (
    <div>
      <Breadcrumb title={'Contáctanos'} />
      <section className=" contact-page section-b-space">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <form className="theme-form">
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="name">Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Ingrese su nombre"
                        required={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="last_name">Apellido</label>
                      <input
                        type="text"
                        className="form-control"
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                        id="last_name"
                        placeholder="Ingrese su Apellido"
                        required={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phone">Numero de teléfono</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Ingrese su numero de teléfono"
                        required={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Ingrese su Email"
                        required={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="message">Escribe tu mensaje</label>
                      <textarea
                        className="form-control"
                        placeholder="Escriba su mensaje"
                        id="message"
                        rows="6"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        className={
                          loading
                            ? 'disabledBtn btn btn-solid'
                            : 'btn btn-solid'
                        }
                        type="submit"
                      >
                        {loading ? 'Enviando...' : 'Enviar'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Contact;
