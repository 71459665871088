import React from 'react';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';

class _Modal extends React.Component {

	// closeTooltip = async () => {
	// 	setTimeout( async () =>{
	// 		await $('[data-toggle="tooltip"]').tooltip('hide');
	// 	},300)
	// }

	render() {
		return (
			<Modal tabIndex="0" className={ this.props.className } show={ this.props.visible } onHide={ () => {
				this.props.onClose();
				// this.closeTooltip()
				} }>
				<Modal.Header closeButton>
				  <Modal.Title className="font-sand">{ this.props.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>{ this.props.children }</Modal.Body>
			</Modal>
		)
	}
}

export default _Modal;